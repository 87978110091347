<template>
  <v-container
    class="elevation-4 rounded-lg px-8 mb-5"
    style="background-color: #fff"
  >
    <v-form ref="formSubscriptionManage" v-model="validSubscriptionManage">
      <v-row justify="center">
        <v-col cols="12" sm="12">
          <v-btn
            color="primary"
            icon
            size="20"
            class="text-center"
            @click="$router.go(-1)"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <h3 class="secondary--text d-inline mr-2">Edit Subscription</h3>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="black" dark v-bind="attrs" v-on="on" size="32">mdi-translate</v-icon>
            </template>
            <span>Here you can choose the language of the exercise to translate it</span>
          </v-tooltip>
          <v-menu class="text-center">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon class="elevation-3">
                <v-avatar size="32">
                  <v-img :src="flagActualTranslate"></v-img>
                </v-avatar>
              </v-btn>
            </template>
            <v-list v-if="listLanguages">
              <v-list-item
                v-for="(lang, index) in listLanguages"
                :key="index"
                @click="changeLanguage(lang)"
              >
                <v-list-item-action>
                  <v-avatar size="32" class="elevation-3">
                    <v-img :src="lang.flag"></v-img>
                  </v-avatar>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="4" class="mx-auto text-left">
            <p class="secondary--text mt-5 text-left d-inline">Image</p>
            <v-card
            color="#efefef"
            class="rounded-lg elevation-0 d-flex mt-5 justify-center align-center"
            min-width="250"
            min-height="250"
            @click="$refs.subscriptionManageImage.$refs.input.click()"
            >
            <v-img :src="subscription.image.link ? subscription.image.link : subscription.image.img" cover width="250" class="rounded-lg" height="250">
            </v-img>
            
                <v-icon large color="primary" style="position: absolute; transform: translate(50%,-50%); top: 50%; right: 50%;"> mdi-camera </v-icon>

            </v-card>
             <div class="mx-auto mt-5 text-center" style="width:150px">
              <p class="secondary--text text-center pb-1">Order number</p>
              <v-text-field
                  v-model.number="subscription.orderNumber"
                  :rules="subscriptionRules.orderNumber"
                  @keypress="numberValid($event)"
                  maxlength="4"
                  class="centered-input"
                  solo
                  flat
                  background-color="#efefef"
              ></v-text-field>
            </div>
            <p class="secondary--text mt-3 pb-1 text-center">Enabled</p>
            <v-switch
            color="primary"
            class="d-flex justify-center center-switch"
            v-model="subscription.enabled"
            :input-value="subscription.enabled"
            flat
            ></v-switch>
        </v-col>
          <v-col cols="12" sm="12" md="4" class="mx-auto">
            <p class="secondary--text pb-1">Name</p>
            <v-text-field
            v-model="subscription.name[actualTranslateLang]"
            :rules="subscriptionRules.name"
            solo
            flat
            counter
            background-color="#efefef"
            ></v-text-field>
            <p class="secondary--text pb-1">Description</p>
            <v-textarea v-model="subscription.description[actualTranslateLang]" :rules="subscriptionRules.description" counter solo flat no-resize height="355" background-color="#efefef">
            </v-textarea>
          </v-col>
          <v-col cols="12" sm="12" md="4" class="mx-auto">
             <div class="d-flex">
                <div class="mr-2">
                  <p class="secondary--text pb-1">Quantity</p>
                 <v-text-field
                    v-model.number="subscription.duration.quantity"
                    @keypress="numberValid($event)"
                    maxlength="4"
                    class="centered-input"
                    solo
                    flat
                    background-color="#efefef"
                ></v-text-field>
                </div>
                <div class="">
                  <p class="secondary--text pb-1">Interval</p>
                  <v-select
                    v-model="subscription.duration.interval"
                    :items="intervalSubscription"
                    item-text="name"
                    item-value="value"
                    solo
                    flat
                    background-color="#efefef"
                  ></v-select>
                </div>
              </div>
              <div class="text-center">
                  <p class="secondary--text pb-1">Price</p>
                  <div class="d-flex align-center mx-auto" style="width:200px;">
                    <p class="secondary--text mb-0 pr-3">USD</p>
                    <v-text-field
                      v-model.number="subscription.price.USD"
                      @keypress="numberValid($event)"
                      maxlength="4"
                      class="centered-input"
                      hide-details
                      solo
                      flat
                      background-color="#efefef"
                    ></v-text-field>
                    <p class="secondary--text font-weight-bold mb-0 ml-3">$</p>
                  </div>
              </div>
               <div class="d-flex justify-center mx-auto mt-3 align-center" style="width:200px;">
                <div class="mr-2">
                  <p class="secondary--text pb-1">Offer</p>
                  <v-switch
                  color="primary"
                  class="d-flex justify-center center-switch"
                  v-model="subscription.offer.inOffer"
                  :input-value="subscription.offer.inOffer"
                  flat
                  ></v-switch>
                </div>
                <div v-if="subscription.offer.inOffer" class="d-flex align-center">
                  <v-text-field
                      v-model.number="subscription.offer.percentage"
                      @keypress="numberValid($event)"
                      maxlength="2"
                      class="centered-input"
                      hide-details
                      solo
                      flat
                      background-color="#efefef"
                    ></v-text-field>
                    <p class="secondary--text font-weight-bold mb-0 ml-3">%</p>
                </div>
              </div>
                <div class="d-flex justify-center align-center" v-if="subscription.offer.inOffer">
                  <p class="secondary--text text-decoration-line-through font-weight-thin mb-0 mr-2"> {{ subscription.price.USD }}$</p>
                  <p class="primary--text font-weight-bold mb-0 mr-2"> {{ calculateOffer }}$</p>
                  <p class="secondary--text mb-0">(-{{ subscription.offer.percentage }}%)</p>
                </div>
          </v-col>
      </v-row>
       <v-row justify="start" align="center">
          <v-col cols="12" sm="12" md="2">
            <p class="secondary--text mb-0">Subscription Features</p>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="12" md="2">
            <v-btn color="primary" class="float-right" :disabled="!subscription.image || !validSubscriptionManage || subscription.featuresList.length < 1" @click="uploadSubscriptionImage()">Finish</v-btn>
          </v-col>
       </v-row>
       <hr class="my-2"/>
       <v-row justify="start" align="center">
          <v-col cols="12" sm="12" md="12">
              <v-list flat>
          <v-list-item-group>
            <v-list-item
              v-for="(feature, i) in subscription.featuresList"
              :key="i"
            >
              <v-list-item-content>
                <v-list-item-title class="d-inline"
                  >
                  <p v-if="feature && feature.name[actualTranslateLang]" class="font-weight-medium d-inline">
                    {{ feature.name[actualTranslateLang] }}
                  </p>
                  <template v-else>
                    <p class="font-weight-thin font-italic d-inline">
                      This feature does'nt have translation in this language
                    </p>
                  </template>
                  <v-btn
                    icon
                    color="primary"
                    size="20"
                    small
                    @click="openDialogFeature(true, feature, i)"
                    ><v-icon>mdi-pencil</v-icon></v-btn>
                  <v-btn
                    icon
                    color="red"
                    size="20"
                    small
                    @click="deleteFeatureOfList(i)"
                    ><v-icon>mdi-delete</v-icon></v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-btn
                  color="primary"
                  class="mx-auto"
                  fab
                  dark
                  small
                  @click="openDialogFeature(false)"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-list-item>
          </v-list-item-group>
        </v-list>
          </v-col>
       </v-row>
    </v-form>
    <v-dialog
      v-model="dialogFeature.show"
      width="700"
      persistent
    >
    <v-card class="pt-4">
        <v-card-text>
           <p class="secondary--text pb-1 text-left d-inline mr-2">
             <span v-if="dialogFeature.editMode">Edit feature</span>
             <span v-else>Add Feature</span>
          </p>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="black" dark v-bind="attrs" v-on="on" size="32">mdi-translate</v-icon>
            </template>
            <span>Here you can choose the language of the exercise to translate it</span>
          </v-tooltip>
          <v-menu class="text-center">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon class="elevation-3">
                <v-avatar size="32">
                  <v-img :src="flagActualTranslate"></v-img>
                </v-avatar>
              </v-btn>
            </template>
            <v-list v-if="listLanguages">
              <v-list-item
                v-for="(lang, index) in listLanguages"
                :key="index"
                @click="changeLanguage(lang)"
              >
                <v-list-item-action>
                  <v-avatar size="32" class="elevation-3">
                    <v-img :src="lang.flag"></v-img>
                  </v-avatar>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-text-field
            :value="dialogFeature.feature.name[actualTranslateLang]"
            ref="modalFeatureData"
            :rules="subscriptionRules.name"
            class="mt-2"
            solo
            flat
            background-color="#efefef"
            @keyup.enter="saveDialogFeature()"
            ></v-text-field>
        </v-card-text>
        <v-card-actions class="pt-0 pb-4">
          <div class="ml-auto">
            <v-btn color="secondary" class="mr-1" @click="closeDialogFeature()">
              Cancel
            </v-btn>
            <v-btn color="primary" class="mr-1" @click="saveDialogFeature()">
              Save
            </v-btn>
          </div>
        </v-card-actions>
    </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogUploadImage"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Uploading image, please wait...
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="getLoadingStatusSubscriptionImage"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
        snackbar.text
      }}</v-snackbar>

     <v-file-input
      ref="subscriptionManageImage"
      class="d-none"
      color="white"
      accept="image/png, image/jpeg, image/svg+xml"
      dark
      outlined
      :show-size="1000"
      @change="getSubscriptionImage"
    />
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import modalReauth from "@/components/admin/modal-reauth.vue";
export default {
  name: "SubscriptionsManageEdit",
  components: { modalReauth },
  data: () => ({
      validSubscriptionManage:false,
      subscription:{
          name:{},
          description:{},
          enabled:true,
          image:{
              img:null,
              link:'',
              ref:'',
              size:'',
              type:''
          },
          orderNumber:'',
          duration:{
            quantity:0,
            interval:'months'
          },
          price:{
            USD:0
          },
          offer:{
            inOffer:false,
            percentage:0,
            priceInOffer:0
          },
          featuresList:[],
          activeUsersSubscribedCounter:0
      },
      subscriptionRules:{
        name: [v => !!v || 'Name is required',  v => (v && v.length >= 4) || 'Name must be greater than 4'],
        description: [(v) => !!v || "Description section is required"],
      },
      dialogFeature:{
        show: false,
        editMode: false,
        feature:{
          name:{}
        }
      },
      snackbar: {
        snackbar: false,
        timeout: 2000,
        text: null,
        color: "",
      },
      dialogUploadImage:false,
      intervalSubscription: [
        {
          name:"Minutes",
          value:"minutes",
        },
        {
          name:"Hours",
          value:"hours",
        },
        {
          name:"Days",
          value:"days",
        },
        {
          name:"Weeks",
          value:"weeks",
        },
        {
          name:"Months",
          value:"months",
        },
        {
          name:"Years",
          value:"years",
        },
      ],
      actualTranslateLang:"ENG",
      listLanguages:[
        {
          name:"English",
          code:"ENG",
          flag:require("@/assets/flags/usa-flag.png")
        },
        {
          name:"Japanese",
          code:"JAP",
          flag:require("@/assets/flags/japan-flag.jpg")
        },
        {
          name:"Spanish",
          code:"ESP",
          flag:require("@/assets/flags/spain-flag.png")
        },
        {
          name:"Korean",
          code:"KOR",
          flag:require("@/assets/flags/south-korea-flag.png")
        },
      ],

  }),
  created() {
    const vm = this
    vm.subscription = vm.$route.params
    delete vm.subscription.activeUsersSubscribedCounter
  },
  methods:{
    numberValid(e){
        e = (e) || window.event
        const charCode = (e.which) ? e.which : e.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault()
        } else {
            return true
        }
    },
    getFileSize(bytes, dp = 1) {
      const thresh = 1024;

      if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
      }
        //si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']  : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']; // original
      const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      let u = -1;
      const r = 10**dp;

      do {
        bytes /= thresh;
        ++u;
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

      return bytes.toFixed(dp) + ' ' + units[u];
    },
    twoDecimals(n) {
      let t=n.toString();
      let regex=/(\d*.\d{0,2})/;
      return t.match(regex)[0];
    },
    changeLanguage(lang){
      const vm = this
      vm.actualTranslateLang = lang.code
    },
    openDialogFeature(editMode, feature, index){
      const vm = this 
      if(editMode){
        vm.dialogFeature = {
          show: true,
          editMode,
          // feature,
          index
        }
        vm.dialogFeature.feature = feature
      }else{
        vm.dialogFeature.show = true
      }
    },
    saveDialogFeature(){
      const vm = this
      const inputValue = vm.$refs.modalFeatureData.$refs.input.value
      const feature = {
        name:{
            [vm.actualTranslateLang]: inputValue
        }
      }
      if(vm.dialogFeature.editMode){
        vm.$set(vm.subscription.featuresList, vm.dialogFeature.index, feature)
      }else{
        vm.subscription.featuresList.push(feature)
      }
      vm.closeDialogFeature();
    },
    closeDialogFeature(){
      const vm = this 
      vm.dialogFeature = {
        show: false,
        editMode: false,
        feature:{
          name:{}
        }
      }
      vm.$refs.modalFeatureData.reset()
      vm.$refs.modalFeatureData.resetValidation()
    },
    deleteFeatureOfList(index){
      const vm = this
      vm.subscription.featuresList.splice(index, 1)
    },
    getSubscriptionImage(file){
        let vm = this
        if(file){
          if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/svg+xml') {
            const fr = new FileReader();
            fr.onload = (el) => {
              vm.subscription.image = {
                img: el.target.result,
                file: file.type.split("/")[1],
                size:vm.getFileSize(file.size, 2),
                link:""
              }
            };
            fr.readAsDataURL(file);
          }else{
            let emitData = {
                snackbar: true,
                btn: "",
                y: "bottom",
                x: null,
                mode: "",
                timeout: 2000,
                text: "Only .png and .jpeg files are accepted",
                color: "red",
              };
            vm.$emit("showSnackBar", emitData);
          }
        }
    },
    uploadSubscriptionImage(){
      let vm = this;
      if(vm.subscription.image.img && vm.subscription.image.link == ""){
       vm.dialogUploadImage = true
       vm.$store
        .dispatch("UploadImageSubscription", vm.subscription)
        .then((storage) => {
          vm.subscription.image.link = storage.link
          vm.subscription.image.ref = storage.ref
          vm.dialogUploadImage = false
          vm.updateSubscription();
          
        }).catch((err) => {
          console.log(err);
          vm.dialogUploadImage = false

           let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Error uploading image",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);
        })
        
      }else{
        vm.updateSubscription()
      }
     
    },
   async updateSubscription(){
         let vm = this;
          try {
            console.log(vm.subscription)
            const id = await vm.$store.dispatch('UpdateSubscription', vm.subscription)
            await vm.$store.dispatch("GenerateLog", {
                id: id,
                name: vm.subscription.name['ENG'],
                type: "editedSubscription",
            })
            vm.dialogUploadVideo = false
          
            let emitData = {
                snackbar: true,
                btn: "",
                y: "bottom",
                x: null,
                mode: "",
                timeout: 2000,
                text: "Subscription Edited successfully",
                color: "success",
            };
            vm.$router.push("/admin/subscriptions-manage")
            .catch((err) => {
                vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
                vm.snackbar.color = "red darken-2";
                vm.snackbar.snackbar = true;
            })
            vm.$emit("showSnackBar", emitData);
          } catch (error) {
            vm.dialogUploadVideo = false

              let emitData = {
                  snackbar: true,
                  btn: "",
                  y: "bottom",
                  x: null,
                  mode: "",
                  timeout: 2000,
                  text: "Error editing Subscription",
                  color: "red",
              };
              vm.$emit("showSnackBar", emitData);
          }
    }
  },
  computed:{
      calculateOffer(){
          const vm = this
          const price = vm.subscription.price.USD
          const percentage = vm.subscription.offer.percentage
          const priceInOffer = vm.twoDecimals(price - (price / 100 * percentage))
          vm.$set(this.subscription.offer, 'priceInOffer', priceInOffer)
          return parseFloat(priceInOffer)
      },
      flagActualTranslate() {
        const vm = this;
        if(vm.listLanguages.length > 0){
          const currentFlag = vm.listLanguages.find(flag => flag.code === vm.actualTranslateLang);
          return currentFlag.flag;
        }
      },
      ...mapGetters(["getLoadingStatusSubscriptionImage"])
  },
  watch:{
    'subscription.offer.inOffer': function(inOffer) {
      if(!inOffer){
        this.$set(this.subscription.offer, 'percentage', 0)
      }
    }
  }
};
</script>
