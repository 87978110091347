import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  development: {
    apiKey: "AIzaSyC7VGUpjcpqMJOxYqcMGMjfbsLA5hymrxc",
    authDomain: "maxpro-dev.firebaseapp.com",
    databaseURL: "https://maxpro-dev.firebaseio.com",
    projectId: "maxpro-dev",
    storageBucket: "maxpro-dev.appspot.com",
    messagingSenderId: "452420342009",
    appId: "1:452420342009:web:b98604a8998589fe3f1228",
    measurementId: "G-43S47W7ZMT",
  },
  production: {
    apiKey: "AIzaSyDBEXnI06UCOjYmOefCJgQOZGtiCmPKehw",
    authDomain: "maxfit-app.firebaseapp.com",
    databaseURL: "https://maxfit-app.firebaseio.com",
    projectId: "maxfit-app",
    storageBucket: "maxfit-app.appspot.com",
    messagingSenderId: "278166805972",
    appId: "1:278166805972:web:d0a3ff9b30eaba64",
    measurementId: "G-KN07P98H3Y"
  },
}
let currentENV = firebaseConfig.production
// Initialize Firebase
firebase.default.initializeApp(currentENV);
// firebase.analytics();

const db = firebase.default.firestore()
const auth = firebase.default.auth()
const apiKey = currentENV.apiKey
export {
  db,
  auth,
  apiKey
}