<template>
    <v-container fluid>
        <v-form class="elevation-4 rounded-lg mx-4 mb-5 px-4" style="background-color: #fff" ref="formBackgroundAppImage"
            v-model="validForm">
            <v-col>
                <v-btn color="primary" icon size="20" class="text-center" @click="$router.go(-1)">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <h3 class="secondary--text d-inline mr-2">Create Splash</h3>
            </v-col>
            <v-row justify="center">
                <v-col cols="12" sm="12" md="6">
                    <v-row>
                        <v-col>
                            <p class="secondary--text text-left d-inline">Splash</p>
                            <v-card color="#efefef" class="rounded-lg elevation-0 d-flex mt-4 justify-center align-center"
                                min-width="250" min-height="600" @click="$refs.refSplashPicker.$refs.input.click()">
                                <v-img :src="featuredImages.splash.img ? featuredImages.splash.img : ''" cover width="250"
                                    class="rounded-lg" height="600">
                                </v-img>

                                <v-icon large color="primary"
                                    style="position: absolute; transform: translate(50%,-50%); top: 50%; right: 50%;">
                                    mdi-camera
                                </v-icon>
                            </v-card>
                        </v-col>
                        <v-col>
                            <p class="secondary--text text-left d-inline">Header</p>
                            <v-card color="#efefef" class="rounded-lg elevation-0 d-flex mt-4 justify-center align-center"
                                min-width="250" min-height="300" @click="$refs.refHeaderPicker.$refs.input.click()">
                                <v-img :src="featuredImages.header.img ? featuredImages.header.img : ''" cover width="250"
                                    class="rounded-lg" height="300">
                                </v-img>

                                <v-icon large color="primary"
                                    style="position: absolute; transform: translate(50%,-50%); top: 50%; right: 50%;">
                                    mdi-camera
                                </v-icon>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" sm="12" md="6" class="py-6">
                    <p class="secondary--text">Name</p>
                    <v-text-field v-model="featuredImages.name" :rules="featuredImagesRules.name" solo flat
                        background-color="#efefef" />
                    <p class="secondary--text pb-1">Date Range</p>
                    <v-date-picker width="100%" v-model="dateRange" color="primary" elevation="3" no-title range />
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <v-btn width="200px" height="48px" color="primary" class="float-right"
                        :disabled="!validForm || !featuredImages.splash.img || !featuredImages.header.img || dateRange.length < 2"
                        @click="uploadBackgroundAppImage()">save</v-btn>
                </v-col>
            </v-row>
        </v-form>

        <v-dialog v-model="dialogUploadImage" persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Uploading image, please wait...
                    <v-progress-linear color="white" class="mb-0" :value="getLoadingSplashImages"></v-progress-linear>
                </v-card-text>
            </v-card>
            <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
                snackbar.text
            }}</v-snackbar>
        </v-dialog>
        <v-file-input ref="refSplashPicker" class="d-none" color="white" accept="image/png, image/jpeg" dark outlined
            :show-size="1000" @change="(file) => getBackgroundAppImage(file, 'splash')" />
        <v-file-input ref="refHeaderPicker" class="d-none" color="white" accept="image/png, image/jpeg" dark outlined
            :show-size="1000" @change="(file) => getBackgroundAppImage(file, 'header')" />
    </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import modalReauth from "@/components/admin/modal-reauth.vue";
import moment from "moment";
export default {
    name: "BackgroundAppImageCreate",
    components: { modalReauth },
    data: () => ({
        validForm: false,
        featuredImages: {
            header: {
                file: '',
                img: '',
                size: ''
            },
            splash: {
                file: '',
                img: '',
                size: ''
            },
            name: ''
        },
        backgroundAppImage: {

        },
        snackbar: {
            snackbar: false,
            timeout: 2000,
            text: null,
            color: "",
        },
        featuredImagesRules: {
            name: [v => !!v || 'Name is required',  v => (v && v.length >= 4) || 'Name must be greater than 4'],
        },
        dialogUploadImage: false,
        dateRange: [],
        dataItem: {
            createdAt: undefined,
            startDate: undefined,
            endDate: undefined,
            name: '',
            header: '',
            headerRef: undefined,
            splash: '',
            splashRef: '',
        },
        dataItemToEdit: {
            createdAt: undefined,
            startDate: undefined,
            endDate: undefined,
            name: '',
            header: '',
            headerRef: undefined,
            splash: '',
            splashRef: '',
        },
        isEdit: false
    }),
    created() {
        let vm = this;
        console.log('params: ', vm.$route.params);
        const item = vm.$route.params;
        if (item && item.name && item.splash) {
            vm.isEdit = true;
            vm.dataItemToEdit = item;
            const date1 = moment(item.startDate).format('YYYY-MM-DD');
            const date2 = moment(item.endDate).format('YYYY-MM-DD');
            vm.dateRange = [date1, date2];
            vm.featuredImages.name = item.name;
            vm.featuredImages.splash.img = item.splash;
            vm.featuredImages.header.img = item.header;
        }
    },
    methods: {
        moment: function (value) {
            return moment(value);
        },
        numberValid(e) {
            e = (e) || window.event
            const charCode = (e.which) ? e.which : e.keyCode
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                e.preventDefault()
            } else {
                return true
            }
        },
        getFileSize(bytes, dp = 1) {
            const thresh = 1024;

            if (Math.abs(bytes) < thresh) {
                return bytes + ' B';
            }
            //si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']  : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']; // original
            const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            let u = -1;
            const r = 10 ** dp;

            do {
                bytes /= thresh;
                ++u;
            } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

            return bytes.toFixed(dp) + ' ' + units[u];
        },
        getImageData(file) {
            // console.log(file);
            let vm = this;
            if (file) {
                if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
                    const fr = new FileReader();
                    let imageData = {};
                    fr.onload = (el) => {
                        imageData = {
                            img: el.target.result,
                            file: file.type.split("/")[1],
                            size: vm.getFileSize(file.size, 2)
                        }
                    };
                    const image = fr.readAsDataURL(file);
                    imageData.data = image;
                    console.log('imageData: ',imageData);
                } else {
                    let emitData = {
                        snackbar: true,
                        btn: "",
                        y: "bottom",
                        x: null,
                        mode: "",
                        timeout: 2000,
                        text: "Only .png and .jpeg files are accepted",
                        color: "red",
                    };
                    vm.$emit("showSnackBar", emitData);
                }
            }
        },
        getBackgroundAppImage(file, type) {
            let vm = this;
            if (file) {
                if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
                    const fr = new FileReader();
                    fr.onload = (el) => {
                        const data = {
                            img: el.target.result,
                            file: file.type.split("/")[1],
                            size: vm.getFileSize(file.size, 2),
                            link: ""
                        }
                        console.log('data: ', data);
                        if (type === 'splash') {
                            vm.featuredImages.splash = data;
                        }
                        if (type === 'header') {
                            vm.featuredImages.header = data;
                        }
                    };
                    fr.readAsDataURL(file);
                } else {
                    let emitData = {
                        snackbar: true,
                        btn: "",
                        y: "bottom",
                        x: null,
                        mode: "",
                        timeout: 2000,
                        text: "Only .png and .jpeg files are accepted",
                        color: "red",
                    };
                    vm.$emit("showSnackBar", emitData);
                }
            }
        },
        async uploadBackgroundAppImage() {
            let vm = this;
            if (vm.isEdit === true) {
                vm.dialogUploadImage = true;

                let itemCopy = { ...vm.dataItemToEdit };

                if (vm.featuredImages.splash.img !== itemCopy.splash) {
                    try {
                        await vm.$store.dispatch('DeleteImageByRef', { ref: itemCopy.splashRef, type: 'splash' });
                    } catch (error) {
                        console.error(error);
                    }
                    const splashResult = await vm.$store.dispatch("UploadSplashImage", vm.featuredImages.splash);
                    itemCopy.splash = splashResult.link;
                    itemCopy.splashRef = splashResult.ref;
                    itemCopy.splashExt = vm.featuredImages.splash.file;
                }

                if (vm.featuredImages.header.img !== itemCopy.header) {
                    try {
                        await vm.$store.dispatch('DeleteImageByRef', { ref: itemCopy.headerRef, type: 'header' })
                    } catch (error) {
                        console.error(error);
                    }
                    const headerResult = await vm.$store.dispatch("UploadHeaderImage", vm.featuredImages.header);
                    itemCopy.header = headerResult.link;
                    itemCopy.headerRef = headerResult.ref;
                    itemCopy.headerExt = vm.featuredImages.header.file;
                }

                itemCopy.name = vm.featuredImages.name;

                const date1 = moment(this.dateRange[0]);
                const date2 = moment(this.dateRange[1]);
                const startDate = date1.isBefore(date2) ? date1 : date2;
                const endDate = date1.isBefore(date2) ? date2 : date1;

                itemCopy.startDate = startDate.startOf('day').toDate();
                itemCopy.endDate = endDate.endOf('day').toDate();

                itemCopy.updatedAt = new Date();

                vm.$store.dispatch("UpdateSplash", itemCopy);

                vm.dialogUploadImage = false;

                let emitData = {
                    snackbar: true,
                    btn: "",
                    y: "bottom",
                    x: null,
                    mode: "",
                    timeout: 2000,
                    text: "Updated successfully",
                    color: "success",
                };
                vm.$router.push("/admin/app-config/featured-splash")
                    .catch((err) => {
                        vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
                        vm.snackbar.color = "red darken-2";
                        vm.snackbar.snackbar = true;
                    })
                vm.$emit("showSnackBar", emitData);

                return;
            }
            try {

                vm.dialogUploadImage = true
                const splashResult = await vm.$store.dispatch("UploadSplashImage", vm.featuredImages.splash);

                vm.dataItem.splash = splashResult.link;
                vm.dataItem.splashRef = splashResult.ref;
                vm.dataItem.splashExt = vm.featuredImages.splash.file;

                const headerResult = await vm.$store.dispatch("UploadHeaderImage", vm.featuredImages.header);

                vm.dataItem.header = headerResult.link;
                vm.dataItem.headerRef = headerResult.ref;
                vm.dataItem.headerExt = vm.featuredImages.header.file;

                vm.saveData();

            } catch (error) {
                console.log(error);
                vm.dialogUploadImage = false
                let emitData = {
                    snackbar: true,
                    btn: "",
                    y: "bottom",
                    x: null,
                    mode: "",
                    timeout: 2000,
                    text: "Error uploading image",
                    color: "red",
                };
                vm.$emit("showSnackBar", emitData);
            }
        },
        saveData() {
            let vm = this;

            const date1 = moment(this.dateRange[0]);
            const date2 = moment(this.dateRange[1]);
            const startDate = date1.isBefore(date2) ? date1 : date2;
            const endDate = date1.isBefore(date2) ? date2 : date1;

            const data = {
                ...vm.dataItem,
                name: vm.featuredImages.name,
                createdAt: new Date(),
                startDate: startDate.startOf('day').toDate(),
                endDate: endDate.endOf('day').toDate()
            }

            vm.$store.dispatch('CreateCustomSplash', data)
                .then((id) => {
                    // vm.$store.dispatch("GenerateLog", {
                    //     id: id,
                    //     title: vm.backgroundAppImage.name,
                    //     type: "createdBackgroundAppImage",
                    // })
                    vm.dialogUploadImage = false;

                    let emitData = {
                        snackbar: true,
                        btn: "",
                        y: "bottom",
                        x: null,
                        mode: "",
                        timeout: 2000,
                        text: "Created successfully",
                        color: "success",
                    };
                    vm.$router.push("/admin/app-config/featured-splash")
                        .catch((err) => {
                            vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
                            vm.snackbar.color = "red darken-2";
                            vm.snackbar.snackbar = true;
                        })
                    vm.$emit("showSnackBar", emitData);

                }).catch((err) => {
                    vm.dialogUploadVideo = false

                    let emitData = {
                        snackbar: true,
                        btn: "",
                        y: "bottom",
                        x: null,
                        mode: "",
                        timeout: 2000,
                        text: "Error creating Custom splash",
                        color: "red",
                    };
                    vm.$emit("showSnackBar", emitData);

                })
        }
    },
    computed: {
        ...mapGetters(["getLoadingSplashImages"])
    }
};
</script>

<style scoped>
.v-picker__body {
    width: 100% !important;
}
</style>