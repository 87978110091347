<template>
  <v-container fluid>
    <v-row justify="space-between" class="px-10">
      <v-col cols="12" sm="12" md="4">
        <p class="text-h6 text--secondary font-weight-bold">Exercises by Users</p>
        <!-- <v-btn color="primary" @click="test()">test</v-btn> -->
      </v-col>
      <v-col cols="12" sm="12" md="2">
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          label="Search for a Exercise by User"
          v-model="search"
          single-line
          outlined
          rounded
          filled
          dense
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :search="search"
          :loading="loading"
          :headers="headers"
          :items="getExercisesByUsersList"
          item-key="id"
          fixed-header
          class="elevation-4"
        >
          <template v-slot:[`item.exercises`]="{ item }">
              {{ item.exercises.length }}
          </template>
          <template v-slot:[`item.request.addedToAppDate`]="{ item }">
            {{ parseToRelativeDate(item.request.addedToAppDate) }}
          </template>
          <template v-slot:[`item.enabled`]="{ item }">
            <v-switch
                color="primary"
                class="center-switch"
                :input-value="item.enabled"
                flat
                @click="disableExerciseByUser(item)"
            ></v-switch>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn icon color="primary" @click="showExerciseByUser(item)">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
        snackbar.text
      }}</v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "ExercisesByUsersList",
  data: () => ({
    search: "",
    loading: false,
    headers: [
      { text: 'Name', align:'center', value:'name', sortable:true },
      { text: 'User', align:'center', value:'userEmail', sortable:true },
      { text: 'Exercises', align:'center', value:'exercises', sortable:true },
      { text: 'Added to App', align:'center', value:'request.addedToAppDate', sortable:true },
      { text: 'Enabled', align:'center', value:'enabled', sortable:false },
      { text: "Action", align: "center", value: "action", sortable: false },
    ],
   snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
  }),
  created(){
      let vm = this
      vm.getAllExercisesByUsersList()
  },
  methods: {
    async test(){
      const vm = this
      console.log(vm.getRequestsExercisesList);
      await vm.$store.dispatch("GoToSave", vm.getRequestsExercisesList[0])
    },
    async getAllExercisesByUsersList(){
     const vm = this 
      try {
          vm.loading = true
          await vm.$store.dispatch("GetExercisesByUsersList")
          vm.loading = false
      } catch (error) {
          vm.loading = false
          console.log(error);
      }
    },
    showExerciseByUser(exerciseByUser){
        let vm = this;
        vm.$router.push({ name: "Exercise By User Show", params: exerciseByUser })
        .catch((err) => {
            vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
            vm.snackbar.color = "red darken-2";
            vm.snackbar.snackbar = true;
        })
    },
    async disableExerciseByUser(exerciseByUser){
      const vm = this
      const { enabled, id, name, userEmail } = exerciseByUser
      await vm.$store.dispatch('DisableExerciseByUser', { id, enabled })
      await vm.$store.dispatch("GenerateLog", {
        id: id,
        name,
        email: userEmail,
        type: "editedExerciseByUser",
      })
          
      let emitData = {
          snackbar: true,
          btn: "",
          y: "bottom",
          x: null,
          mode: "",
          timeout: 2000,
          text: "Exercise by User disabled successfully",
          color: "success",
      };
      vm.$emit("showSnackBar", emitData);
      vm.getAllExercisesByUsersList()
    },
    parseToRelativeDate(date){
     return moment(date.toDate()).fromNow()
    },
    getColorStatus(status){
        if(status === 'PENDING'){
            return 'amber--text'
        }else if (status === 'APPROVED'){
            return 'success--text'
        }else if(status === 'REJECTED'){
            return 'danger--text'
        }
    }
  },
  computed:{
      ...mapGetters(["getExercisesByUsersList"])
  }
};
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
</style>