<template>
  <v-container fluid>
    <v-row justify="space-between" class="px-10">
      <v-col cols="12" sm="12" md="4">
        <p class="text-h6 text--secondary font-weight-bold">
          Push Notifications By Group
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="5">
        <v-text-field
          label="Search for a Push Notification by Group"
          v-model="search"
          single-line
          outlined
          rounded
          filled
          dense
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="12" md="12">
        <v-data-table
          :search="search"
          :loading="loading"
          :headers="headers"
          :items="getPushNotificationsByGroupList"
          item-key="id"
          fixed-header
          class="elevation-4"
        >
          <template v-slot:[`item.action`]="{ item }">
            <v-btn icon color="primary" @click="goToSendPushNotificationByGroup(item)">
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color">{{
      snackbar.text
    }}</v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "PushNotificationsByGroupList",
  data: () => ({
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
    search:"",
    loading:false,
    headers: [
      { text: "Title", align: "center", value: "title.ENG", sortable: true },
      { text: "Topic Code", align: "center", value: "topic", sortable: true },
      { text: "", align: "center", value: "action", sortable: false },
    ],
  }),
  created() {
    let vm = this;
    vm.goGetPushNotificationsByGroupList();
  },
  methods: {
    goGetPushNotificationsByGroupList(){
      let vm = this
      vm.loading = true
      vm.$store.dispatch('GetPushNotificationsByGroup')
      .then((res)=>{
        vm.loading = false
      }).catch((err)=>{
        vm.loading = false
      })
    },
    goToSendPushNotificationByGroup(notification){
        const vm = this
        vm.$router.push({ name: "Push Notification By Group Send", params: notification })
        .catch((err) => {
            vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
            vm.snackbar.color = "red darken-2";
            vm.snackbar.snackbar = true;
        })
    }
  },
  computed: {
    ...mapGetters(["getPushNotificationsByGroupList"]),
  },
};
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
</style>