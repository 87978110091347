import firebase from "firebase";
import Vue from "vue";
import Axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, Axios);

const db = firebase.firestore();


Vue.use(VueAxios, Axios);

export default {
    state:{
        requestsExercisesList: [],
        exercisesByUsersList: []
    },
    mutations:{
        SET_REQUESTS_EXERCISES_LIST(state, requestsExercisesList){
            state.requestsExercisesList = requestsExercisesList
        },
        SET_EXERCISES_BY_USERS_LIST(state, exercisesByUsersList){
            state.exercisesByUsersList = exercisesByUsersList
        }
    },
    actions:{
        GoToSave({ }, request){
            return new Promise(async (resolve, reject) => {
                try {
                    const ref = db.collection("requestsForAddExercises").doc();
                    // const request = await ref.get();
                    console.log('ref', ref);
                    request.id = ref.id;
                    console.log(request);
                    await db.collection("requestsForAddExercises").doc(request.id).set(request)
                } catch (error) {
                    console.log(error);
                }
            })

        },
        GetRequestsExercisesList({ commit }){
            return new Promise(async (resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("requestsForAddExercises").get()
                    const requestsExercisesList = []
                    querySnapshot.forEach(async(doc) =>  {
                        const request = doc.data()
                        requestsExercisesList.push(request)
                    })
                    commit('SET_REQUESTS_EXERCISES_LIST', requestsExercisesList)
                    resolve(requestsExercisesList)
                } catch(error) {
                    reject(error)
                }
            })
        },
        GetExercisesByUsersList({ commit }){
            return new Promise(async (resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("exercisesByUsers").get()
                    const exercisesByUsersList = []
                    querySnapshot.forEach(async(doc) =>  {
                        const exerciseByUser = doc.data()
                        exercisesByUsersList.push(exerciseByUser)
                    })
                    commit('SET_EXERCISES_BY_USERS_LIST', exercisesByUsersList)
                    resolve(exercisesByUsersList)
                } catch(error) {
                    reject(error)
                }
            })
        },
        async GetEspecificExercise({}, exercise){
            return new Promise(async(resolve, reject) => {
                try {
                    // console.log('exercise', exercise.parent.id);
                    const collectionName = exercise.isReactNativeExercise || exercise.parent.id === 'excercisesNew' ? 'excercisesNew' : 'excercises'
                    const id = exercise.id
                    const res = await db.collection(collectionName).doc(id).get()
                    let exerciseGetted = res.data()
                    exerciseGetted.id = id
                    resolve(exerciseGetted)
                } catch (error) {
                    reject(error)
                }
            })

        },
        UpdateRequestExercise({}, requestExercise) {
            return new Promise(async(resolve, reject) => {
                try {
                    requestExercise = {
                        ...requestExercise,
                        'request.updatedAt': await firebase.firestore.FieldValue.serverTimestamp()
                    }
                    await db.collection("requestsForAddExercises").doc(requestExercise.idRequest).update(requestExercise)
                    resolve(requestExercise.idRequest);
                } catch (error) {
                    reject(error);
                }
            });
        },
        SaveInAppExerciseRequest({ dispatch }, requestExercise){
            return new Promise(async(resolve, reject) => {
                try {
                    for (let i = 0; i < requestExercise.exercises.length; i++) {
                        const collectionName = requestExercise.exercises[i].exercise.isReactNativeExercise ? 'excercisesNew' : 'excercises'
                        requestExercise.exercises[i].exercise = await db.collection(collectionName).doc(requestExercise.exercises[i].exercise.id)
                    }

                    requestExercise.user = await db.collection('users').doc(requestExercise.user.uid)

                    const ref = db.collection("exercisesByUsers").doc();
                    const newExerciseByUser = await ref.get();
                    requestExercise.id = ref.id;
                    requestExercise.request.savedOnDB = true
                    const payload = {
                        routeName: 'exercisesByUsers',
                        title: requestExercise.name,
                        id: requestExercise.idRequest,
                        img: null
                    }
                    
                    const dynamicLinks = await dispatch('GenerateDynamicLink', payload)
                    requestExercise.dynamicLink = dynamicLinks.shortLink
                    requestExercise.enabled = true
                    requestExercise.request.addedToAppDate = new Date()

                    await db.collection("exercisesByUsers").doc(newExerciseByUser.id).set(requestExercise)
                    await db.collection("requestsForAddExercises").doc(requestExercise.idRequest).update({ 'request.savedOnDB': true })
                    resolve(newExerciseByUser.id);
                } catch (error) {
                    reject (error);
                }
            })

        },
        DisableExerciseByUser({}, exerciseByUser){
            return new Promise(async(resolve, reject) => {
                try {
                    await db.collection("exercisesByUsers").doc(exerciseByUser.id).update({
                      enabled: !exerciseByUser.enabled
                    })
                    resolve(exerciseByUser.id);
                } catch (error) {
                    reject(error);
                }
            });
        },
        DeleteRequestExercise({}, requestExercise) {
            return new Promise(async(resolve, reject) => {
                try {
                    await db.collection("requestsForAddExercises").doc(requestExercise.id).delete()
                    resolve(requestExercise.id)
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
    getters:{
        getRequestsExercisesList(state){
            return state.requestsExercisesList;
        },
        getExercisesByUsersList(state){
            return state.exercisesByUsersList;
        },
    }
}