<template>
  <v-container fluid>
    <v-row justify="space-between" class="px-10">
      <v-col cols="12" sm="12" md="2">
        <p class="text-h6 text--secondary font-weight-bold">Coupons List</p>
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <v-btn text color="secondary" @click="createCoupon()">
          <v-icon left dark color="primary"> mdi-plus-circle </v-icon>
          New Coupon
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          label="Search for a Coupon"
          v-model="search"
          single-line
          outlined
          rounded
          filled
          dense
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :search="search"
          :loading="loading"
          :headers="headers"
          :items="getCouponsList"
          item-key="id"
          fixed-header
          class="elevation-4"
        >
         <template v-slot:[`item.isAndroid`]="{ item }">
            <h4 v-if="item.isAndroid" class="primary--text">Yes</h4>
            <template v-else>
              <h4 class="secondary--text">No</h4>
            </template>
          </template>
         <template v-slot:[`item.usersRedeemedCoupon`]="{ item }">
          {{ item.usersRedeemedCoupon ? item.usersRedeemedCoupon.length : 0 }}
          </template>
         <template v-slot:[`item.expirationDate`]="{ item }">
          {{ timestampToStringDate(item.expirationDate) }}
          </template>
         <template v-slot:[`item.enabled`]="{ item }">
            <v-switch
                color="primary"
                class="center-switch"
                :input-value="item.enabled"
                flat
                @click="disableCoupon(item)"
            ></v-switch>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn icon color="primary" @click="changeRouteCoupons(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              color="red"
              class="mr-3"
              @click="openDialogConfirm(item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog.show" width="300" class="rounded-xl">
        <v-card class="text-center">
            <v-icon class="text-center ma-2" size="120" color="primary"
            >mdi-alert</v-icon>
            <v-card-text class="pa-4 text-center"
            >This action is irreversible and will delete all data about this
            Coupon. Including the data, references and information</v-card-text>
            <v-card-text class="text-center">Are you sure?</v-card-text>

            <v-btn
            color="third"
            tile
            width="150"
            class="pa-0 text--secondary"
            @click="closeDialogConfirm()"
            >No</v-btn
            >

            <v-btn
            color="primary"
            tile
            width="150"
            class="pa-0"
            @click="openDialogReAuth('deleteCoupon', dialog.item)"
            >Yes</v-btn
            >
            <!-- </v-card-actions> -->
        </v-card>
    </v-dialog>
    <modal-reauth
      :dialogReAuth="dialogReAuth"
      @isSuccessReAuth="isSuccessReAuth"
    ></modal-reauth>
   <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
        snackbar.text
      }}</v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import modalReauth from "@/components/admin/modal-reauth.vue";
import moment from "moment";
export default {
  name: "CouponsList",
  components: { modalReauth },
  data: () => ({
    search: "",
    loading: false,
    headers: [
      { text: 'Title', align:'center', value:'title.ENG', sortable:true },
      { text: 'Code', align:'center', value:'code', sortable:true },
      { text: 'Is Android Coupon', align:'center', value:'isAndroid', sortable:true },
      { text: 'Enabled', align:'center', value:'enabled', sortable:true },
      { text: 'Max Uses', align:'center', value:'totalSupply', sortable:true },
      { text: 'Coupon Uses', align:'center', value:'usersRedeemedCoupon', sortable:true },
      { text: 'Expiration Date', align:'center', value:'expirationDate', sortable:true },
      { text: "Action", align: "center", value: "action", sortable: false },
    ],
    dialogReAuth: {
      show: false,
      action: "",
      item: {},
    },
    dialog: {
      show: false,
      item: {},
    },
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
  }),
  created(){
      let vm = this
      vm.getAllCoupons()
  },
  methods: {
    async getAllCoupons(){
     const vm = this 
      try {
          vm.loading = true
          await vm.$store.dispatch("GetCouponsList")
          vm.loading = false
      } catch (error) {
          vm.loading = false
          console.log(error);
      }
    },
    parseToRelativeDate(date){
     return moment(date.toDate()).fromNow()
    },
    timestampToStringDate(timestamp){
      const dateParsed = new Date(timestamp.seconds*1000)
      return moment(dateParsed).format('MMMM Do YYYY, h:mm:ss a')
    },
    openDialogConfirm(item) {
      const vm = this;
      vm.dialog.show = true;
      vm.dialog.item = item;
    },
    closeDialogConfirm() {
      const vm = this;
      vm.dialog.show = false;
      vm.dialog.item = "";
    },
    openDialogReAuth(action, item) {
      let vm = this;
      vm.dialogReAuth.show = true;
      vm.dialogReAuth.action = action;
      vm.dialogReAuth.item = item;
      vm.closeDialogConfirm();
    },
    isSuccessReAuth(dialogReAuthResponse) {
      const vm = this;
      switch (dialogReAuthResponse.action) {
        case "deleteCoupon":
          vm.deleteCoupon(dialogReAuthResponse.item);
          break;
        default:
          break;
      }
    },
     async deleteCoupon(coupon){
      const vm = this;
      try {
        vm.$emit("showOverlay", true);
        await vm.$store.dispatch("DeleteCoupon", coupon)
        await vm.$store.dispatch("GenerateLog", {
            id: coupon.id,
            title: coupon.title['ENG'],
            type: "deletedCoupon",
        });
        vm.getAllCoupons()
        vm.$emit("showOverlay", false);
        
      } catch (error) {
        vm.$emit("showOverlay", false);
          console.error(error);
          let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Error deleting Coupon",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);
      } 
    },
    async disableCoupon(coupon){
      const vm = this
      const { enabled, id, title } = coupon
      await vm.$store.dispatch('DisableCoupon', { id, enabled })
      await vm.$store.dispatch("GenerateLog", {
        id: id,
        title: title['ENG'],
        type: "editedCoupon",
      })
          
      let emitData = {
          snackbar: true,
          btn: "",
          y: "bottom",
          x: null,
          mode: "",
          timeout: 2000,
          text: "Coupon edited successfully",
          color: "success",
      };
      vm.$emit("showSnackBar", emitData);
      vm.getAllCoupons()
    },
    changeRouteCoupons(coupon){
        let vm = this;
        vm.$router.push({ name: "Coupons Edit", params: coupon })
        .catch((err) => {
            vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
            vm.snackbar.color = "red darken-2";
            vm.snackbar.snackbar = true;
        })
    },
    createCoupon() {
      let vm = this;
      vm.$router.push({ name: "Coupons Create" }).catch((err) => {
        vm.snackbar.text ="Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      });
    },
  },
  computed:{
      ...mapGetters(["getCouponsList"])
  }
};
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
.protrain-image-preview{
    width: 50px; 
    height: 50px; 
    border-radius: 50%; 
    object-fit: cover; 
    vertical-align: middle;
}
</style>