<template>
  <v-container
    class="elevation-4 rounded-lg px-8 mb-5"
    style="background-color: #fff"
  >
    <v-form ref="formLive" v-model="validLive">
      <v-row justify="center">
        <v-col cols="12" sm="12">
          <v-btn
            color="primary"
            icon
            size="20"
            class="text-center"
            @click="$router.go(-1)"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <h3 class="secondary--text d-inline mr-2">Create Live</h3>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon color="black" dark v-bind="attrs" v-on="on" size="32">mdi-translate</v-icon>
            </template>
            <span>Here you can choose the language of the Live to translate it</span>
            </v-tooltip>
            <v-menu class="text-center">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon class="elevation-3">
                <v-avatar size="32">
                    <v-img :src="flagActualTranslate"></v-img>
                </v-avatar>
                </v-btn>
            </template>
            <v-list v-if="listLanguages">
                <v-list-item
                v-for="(lang, index) in listLanguages"
                :key="index"
                @click="changeLanguage(lang)"
                >
                <v-list-item-action>
                    <v-avatar size="32" class="elevation-3">
                    <v-img :src="lang.flag"></v-img>
                    </v-avatar>
                </v-list-item-action>
                </v-list-item>
            </v-list>
            </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="4" class="mx-auto text-center">
            <!-- <p class="secondary--text mt-5 text-left d-inline">Avatar Trainer</p> -->
            <p class="secondary--text pb-1 text-left d-inline">Image</p>
            <v-card
            color="#efefef"
            class="rounded-lg elevation-0 d-flex mt-5 mb-4 mx-auto justify-center align-center"
            max-width="300"
            max-height="300"
            @click="$refs.liveImage.$refs.input.click()"
            >
            <v-img :src="live.image.img ? live.image.img : ''" cover width="250" class="rounded-lg" height="250">
            </v-img>
                <v-icon large color="primary" style="position: absolute; transform: translate(50%,-50%); top: 50%; right: 50%;"> mdi-camera </v-icon>
            </v-card>
            <p class="secondary--text pb-1 my-2 text-left d-inline">Charge Video Presentation</p>
             <v-card
                color="#efefef"
                class="rounded-lg elevation-0 d-flex mb-5 justify-center align-center mt-5"
                min-width="250"
                min-height="250"
                max-height="250px"
                style="position: relative"
                @click="$refs.livePresentationVideo.$refs.input.click()"
                >
                <video
                    v-if="live.presentationVideo"
                    ref="presentationVideoPreview"
                    width="100%"
                    height="250px"
                    max-height="250px"
                    class="mx-auto"
                    :src="live.presentationVideo ? live.presentationVideo.video : ''"
                ></video>

                <v-icon
                    v-if="!isLoadingVideo"
                    large
                    color="primary"
                    style="
                    position: absolute;
                    transform: translate(50%, -50%);
                    top: 50%;
                    right: 50%;
                    "
                >
                    mdi-upload
                </v-icon>
                <v-progress-circular
                    v-if="isLoadingVideo"
                    indeterminate
                    color="primary"
                    style="
                    position: absolute;
                    transform: translate(50%, -50%);
                    top: 50%;
                    right: 50%;
                    "
                ></v-progress-circular>
            </v-card>
            <p class="secondary--text pb-1">Orientation Video</p>
            <v-radio-group v-model="live.videoLandscape" class="radio-group-center" required row>
              <v-radio
                v-for="orientation in videoOrientationsList"
                :key="orientation.name"
                :value="orientation.value"
                :label="orientation.name"   
                class="mr-5"
              ></v-radio>
            </v-radio-group>
            <template v-if="live.videoLandscape">
               <p class="secondary--text pb-1">Turn video to</p>
                <v-radio-group v-model="live.turnOrientationLandscape" class="radio-group-center" required row>
                  <v-radio
                    v-for="turn in videoTurnOrientationsList"
                    :key="turn.name"
                    :value="turn.value"
                    :label="turn.name"   
                    class="mr-5"
                  ></v-radio>
                </v-radio-group>
            </template>
            
             <div class="mx-auto mt-5 text-center" style="width:150px">
                <p class="secondary--text text-center pb-1">Order number</p>
                <v-text-field
                    v-model.number="live.orderNumber"
                    @keypress="numberValid($event)"
                    maxlength="4"
                    class="centered-input"
                    solo
                    flat
                    background-color="#efefef"
                ></v-text-field>
            </div>
            <p class="secondary--text text-center pb-1">Enabled</p>
            <v-switch
                color="primary"
                class="d-flex justify-center center-switch"
                v-model="live.enabled"
                :input-value="live.enabled"
                flat
            ></v-switch>
            <p class="secondary--text text-center pb-1">Free</p>
            <v-switch
                color="primary"
                class="d-flex justify-center center-switch"
                v-model="live.isFree"
                :input-value="live.isFree"
                flat
            ></v-switch>
            <p class="secondary--text text-center pb-1">GrandFathered</p>
            <v-switch
                color="primary"
                class="d-flex justify-center center-switch"
                v-model="live.isGrandFathered"
                :input-value="live.isGrandFathered"
                flat
            ></v-switch>
        </v-col>
          <v-col cols="12" sm="12" md="4" class="mx-auto">
            <p class="secondary--text pb-1">Title</p>
            <v-text-field
            v-model="live.title[actualTranslateLang]"
            :rules="liveRules.title"
            solo
            flat
            counter
            background-color="#efefef"
            ></v-text-field>
            <p class="secondary--text pb-1">Type Video Link</p>
              <v-select
                v-model="live.typeVideoLink"
                :rules="liveRules.typeVideoLink"
                :items="typeVideoListLink"
                item-text="name"
                item-value="value"
                solo
                flat
                background-color="#efefef"
              ></v-select>
            <p class="secondary--text pb-1">Link</p>
             <v-text-field
              v-model="live.videoLink"
              :rules="liveRules.videoLink"
              solo
              flat
              background-color="#efefef"
            ></v-text-field>
            <p class="secondary--text pb-1">Description</p>
            <v-textarea 
            v-model="live.description[actualTranslateLang]" 
            :rules="liveRules.description" 
            solo 
            flat 
            counter 
            auto-grow 
            min-height="355" 
            background-color="#efefef"
            >
            </v-textarea>
            <p class="secondary--text pb-1">Subscription</p>
            <v-select
            v-model="live.subscription"
            :items="getSubscriptionsList"
            item-text="name.ENG"
            return-object
            solo
            flat
            background-color="#efefef"
          ></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="primary" class="float-right" :disabled="!validLive" @click="uploadLiveImage()">Create</v-btn>
         </v-col>
      </v-row>
      
    </v-form>
    <hr class="my-2 mt-5" />
    <v-row justify="start" class="py-3" v-if="filtersRendered">
      <v-col cols="12">
        <p class="secondary--text mb-0">Filters Selected</p>
      </v-col>
      <template v-for="(filter, i) in getLivesFilters">
        <v-col cols="3" v-if="live.filters[getLivesFilters[i].type] && live.filters[getLivesFilters[i].type].length > 0" :key="getLivesFilters[i].type">
          <div class="rounded-xl" style="border: #efefef solid 2px; height:100%;">
              <p class="secondary--text font-weight-bold pt-1 text-center">{{ getLivesFilters[i].name['ENG'] }}</p>
              <template v-for="(principalFilter, j) in live.filters[getLivesFilters[i].type]">
                <v-chip
                  v-if="live.filters[getLivesFilters[i].type][j]"
                  :key="live.filters[getLivesFilters[i].type][j].name"
                  color="primary"
                  dark
                  class="ma-2"
                  close
                  @click:close="deleteFilterChipParent(getLivesFilters[i].type, live.filters[getLivesFilters[i].type][j])"
                >
                  {{ live.filters[getLivesFilters[i].type][j].name }}
                </v-chip>
                <template v-if="live.filters[getLivesFilters[i].type][j].children && live.filters[getLivesFilters[i].type][j].children.length > 0">
                  <template v-for="(childrenFilter, k) in live.filters[getLivesFilters[i].type][j].children">
                    <v-chip
                      v-if="live.filters[getLivesFilters[i].type][j].children[k]"
                      :key="live.filters[getLivesFilters[i].type][j].children[k].name"
                      color="secondary"
                      dark
                      class="ma-2"
                      close
                      @click:close="deleteFilterChipChild(getLivesFilters[i].type, live.filters[getLivesFilters[i].type][j], live.filters[getLivesFilters[i].type][j].children[k])"
                    >
                      {{ live.filters[getLivesFilters[i].type][j].children[k].name }}
                    </v-chip>
                    <template v-if="live.filters[getLivesFilters[i].type][j].children[k] && live.filters[getLivesFilters[i].type][j].children[k].children && live.filters[getLivesFilters[i].type][j].children[k].children.length > 0">
                    <template v-for="(thirdLevel, x) in live.filters[getLivesFilters[i].type][j].children[k].children">
<!--fix click:close --><v-chip
                        v-if="live.filters[getLivesFilters[i].type][j].children[k].children[x]"
                        :key="live.filters[getLivesFilters[i].type][j].children[k].children[x].name"
                        color="black"
                        dark
                        class="ma-2"
                        close
                         @click:close="deleteFilterChipChild(getLivesFilters[i].type, live.filters[getLivesFilters[i].type][j], live.filters[getLivesFilters[i].type][j].children[k])" 
                      >
                        {{ live.filters[getLivesFilters[i].type][j].children[k].children[x].name }}
                      </v-chip>
                    </template>
                  </template>
                  </template>
                </template>
              </template>
          </div>
        </v-col>
      </template>
    </v-row>
  
    <v-row justify="start" class="py-3">
      <v-expansion-panels tile>
        <v-expansion-panel
        >
          <v-expansion-panel-header class="primary--text font-weight-bold">
            Filters
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row justify="start" v-if="filtersRendered">
              <v-col cols="3" v-for="(filter, i) in getLivesFilters" :key="i">
                <p class="secondary--text font-weight-bold pb-1 text-center">{{ filter.name['ENG'] }}</p>
                <v-treeview
                  v-model="filtersSelected[filter.type]"
                  selected-color="primary"
                  :items="filter.listFilters"
                  :selection-type="'leaf'"
                  :item-key="'name'"
                  selectable
                  return-object
                  open-all
                  @input="getUpdateTreeview($event, filter.type)"
                ></v-treeview>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
       </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-dialog
      v-model="dialogUploadImage"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Uploading image, please wait...
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="getLoadingStatusLiveImage"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
     <v-dialog
      v-model="dialogUploadPresentationVideo"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Uploading presentation, please wait...
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="getLoadingStatusLivePresentationVideo"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
     <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
        snackbar.text
      }}</v-snackbar>
     <v-file-input
      ref="liveImage"
      class="d-none"
      color="white"
      accept="image/png, image/jpeg"
      dark
      outlined
      :show-size="1000"
      @change="getLiveImage"
    />
    <v-file-input
      ref="livePresentationVideo"
      class="d-none"
      color="white"
      accept="video/mp4"
      dark
      outlined
      :show-size="1000"
      @change="getPresentationVideo"
    />
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name:"LivesCreate",
  data: () => ({
      validLive:false,
      live:{
          title:{
            ENG:"",
            KOR:"",
            ESP:"",
            JAP:"",
          },
          description:{
            ENG:"",
            KOR:"",
            ESP:"",
            JAP:"",
          },
          image:{
            img:null,
            link:'',
            ref:'',
            size:'',
            type:''
          },
          presentationVideo: null,
          enabled:true,
          isFree:false,
          orderNumber:'',
          typeVideoLink:'instagram',
          videoLink:"",
          parsedVideoLink:"",
          filters:{},
          subscription:'',
          dynamicLink:'',
          isGrandFathered:false,
          videoLandscape:false,
          turnOrientationLandscape:null,
      },
      snackbar: {
        snackbar: false,
        timeout: 2000,
        text: null,
        color: "",
      },
      isLoadingVideo:false,
      filtersRendered: false,
      filtersSelected:{},
      liveRules:{
        title: [v => !!v || 'Title is required'],
        description: [(v) => !!v || "Description section is required"],
        link:[v => /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(^$)/.test(v) || 'Insert a valid url, like: https://maxprofitness.com/']
      },
      dialogUploadImage:false,
      dialogUploadPresentationVideo:false,
      actualTranslateLang:"ENG",
      typeVideoListLink:[
        {
            name:"Instagram",
            value:"instagram",
        },
         {
            name:"Youtube",
            value:"youtube",
         },
      ],
      listLanguages:[
        {
            name:"English",
            code:"ENG",
            flag:require("@/assets/flags/usa-flag.png")
        },
        {
            name:"Japanese",
            code:"JAP",
            flag:require("@/assets/flags/japan-flag.jpg")
        },
        {
            name:"Spanish",
            code:"ESP",
            flag:require("@/assets/flags/spain-flag.png")
        },
        {
            name:"Korean",
            code:"KOR",
            flag:require("@/assets/flags/south-korea-flag.png")
        },
      ],
      videoOrientationsList: [
        {
          name: "Landscape",
          value: true,
        },
        {
          name: "Portrait",
          value: false,
        },
      ],
      videoTurnOrientationsList: [
        {
          name: "Left",
          value: 'left',
        },
        {
          name: "Right",
          value: 'right',
        },
      ],
  }),
  async created(){
      const vm = this
      await vm.getLivesFiltersList()
      await vm.getSubscriptionsToSet()
  },
  methods:{
   async getLivesFiltersList(){
       const vm = this;
       try {
           vm.$emit("showOverlay", true);
           await vm.$store.dispatch('GetFiltersListLives')
           for (let i = 0; i < vm.getLivesFilters.length; i++) {
            const filter = vm.getLivesFilters[i];
            vm.$set(vm.filtersSelected, filter.type, [])
          }
          // vm.asignValuesLiveFilter()
          // vm.syncVModelFilters()
           vm.filtersRendered = true
           vm.$emit("showOverlay", false);
       } catch (error) {
           vm.$emit("showOverlay", false);
           console.error(error)
       }
    },
    async getSubscriptionsToSet(){
      const vm = this
      try {
        vm.$emit("showOverlay", true);
        await vm.$store.dispatch('GetSubscriptionsList')
        vm.$emit("showOverlay", false);
      } catch (error) {
        console.error(error)
      }
    },
     deleteFilterChipParent(type, node){
      const vm = this
      vm.live.filters[type] = vm.live.filters[type].filter((parentFilter) => parentFilter.name !== node.name)
      vm.$set(vm.filtersSelected, type, vm.live.filters[type])
    },
    deleteFilterChipChild(type, parent, child){
      const vm = this

      const parentIndex = vm.live.filters[type].findIndex(parentFilter => parentFilter.name === parent.name)
      const childIndex = vm.live.filters[type][parentIndex].children.findIndex(childFilter => childFilter.name === child.name)
      const deletedChild = vm.live.filters[type][parentIndex].children.splice(childIndex, 1)

      const indexTreeview = vm.filtersSelected[type].findIndex(filter => filter.name === child.name)
      const deletedInTreeview = vm.filtersSelected[type].splice(indexTreeview, 1)
      console.log(vm.live);
    },
    getUpdateTreeview(updatedNodes, type){
      const vm = this
      vm.$set(vm.live.filters, type, [])

      const filterToUpdate = vm.getLivesFilters.find(filter => filter.type === type).listFilters

      updatedNodes.forEach((node) => {
        if(node.children){
          vm.live.filters[type].push(node)
        }else{
          filterToUpdate.forEach((principalFilter) => {
            let nodeParent = principalFilter.children.find(child => child.name === node.name)
            if(nodeParent){
              let nodeParentHasSaved = vm.live.filters[type].find(filterSaved => filterSaved.name === principalFilter.name)
              if(nodeParentHasSaved){
                let index = vm.live.filters[type].findIndex(filterSaved => filterSaved.name === nodeParentHasSaved.name)
                vm.live.filters[type][index].children.push(node)
              }else{
                vm.live.filters[type].push({ name:principalFilter.name, children:[node] })
              }
            }else{
              principalFilter.children.forEach((subFilter) =>{
                if(subFilter.children){
                  const thirdLevel = subFilter.children.find((subF => subF.name === node.name))
                  if(thirdLevel){
                    let indexPrincipalFilter = vm.live.filters[type].findIndex(filterSaved => filterSaved.name === principalFilter.name)
                    if(indexPrincipalFilter !== -1){
                      let indexSubFilter = vm.live.filters[type][indexPrincipalFilter].children.findIndex(subFilterSaved => subFilterSaved.name === subFilter.name)
                      if(indexSubFilter !== -1){
                        vm.live.filters[type][indexPrincipalFilter].children[indexSubFilter].children.push(node)
                      }else{
                        vm.live.filters[type][indexPrincipalFilter].children.push({ name:subFilter.name, children:[node] })
                      }
                    }else{
                      vm.live.filters[type].push({ name:principalFilter.name, children:[{ name:subFilter.name, children:[node] }] })
                    }
                  }
                }
              })
            }
          })
        }

      })
    },
    numberValid(e){
        e = (e) || window.event
        const charCode = (e.which) ? e.which : e.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault()
        } else {
            return true
        }
    },
    getFileSize(bytes, dp = 1) {
      const thresh = 1024;

      if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
      }
        //si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']  : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']; // original
      const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      let u = -1;
      const r = 10**dp;

      do {
        bytes /= thresh;
        ++u;
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

      return bytes.toFixed(dp) + ' ' + units[u];
    },
    changeLanguage(lang){
      const vm = this
      vm.actualTranslateLang = lang.code
    },
    getInstagramId(link){
        console.log('link', link);
        let regExp = /\/p\/(.*?)\//;
        let match = link.match(regExp);
        console.log(match);
        return match[1]
    },
    getLiveImage(file){
        let vm = this
        if(file){
        if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
          const fr = new FileReader();
          fr.onload = (el) => {
              //3415460
            console.log(vm.getFileSize(3415460, 2))
            vm.live.image = {
              img: el.target.result,
              file: file.type.split("/")[1],
              size:vm.getFileSize(file.size, 2),
              link: ""
            }
          };
          fr.readAsDataURL(file);
        }else{
           let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Only .png and .jpeg files are accepted",
              color: "red",
            };
          vm.$emit("showSnackBar", emitData);
        }
      }
    },
     getPresentationVideo(file){
        const vm = this;
        if (file) {
            if (file.type === 'video/mp4') {
            const fr = new FileReader();
            fr.onloadstart = () => {
                vm.isLoadingVideo = true;
                if (vm.live.presentationVideo) {
                    vm.live.presentationVideo = null;
                }
            };
            fr.onload = (el) => {
                let videoBlob = new Blob([new Uint8Array(el.target.result)], { type: file.type });
                let url = window.URL.createObjectURL(videoBlob);
                vm.live.presentationVideo = {
                    video: url,
                    blob:el.target.result,
                    filetype: file.type.split("/")[1],
                    link:""
                };
                vm.isLoadingVideo = false;
            };
            fr.onerror = () => {
                let emitData = {
                snackbar: true,
                btn: "",
                y: "bottom",
                x: null,
                mode: "",
                timeout: 2000,
                text: "Error charging video",
                color: "red",
                };
                vm.$emit("showSnackBar", emitData);
            };
            fr.readAsArrayBuffer(file);
            }else{
            let emitData = {
                snackbar: true,
                btn: "",
                y: "bottom",
                x: null,
                mode: "",
                timeout: 2000,
                text: "Only .mp4 files are accepted",
                color: "red",
                };
            vm.$emit("showSnackBar", emitData); 
            }
        }

    },
    uploadLiveImage(){
      let vm = this;
      if(vm.live.image.img && vm.live.image.link == ""){
      vm.dialogUploadImage = true
       vm.$store
        .dispatch("UploadImageLive", vm.live)
        .then((storage) => {
          vm.live.image.link = storage.link
          vm.live.image.ref = storage.ref
          vm.dialogUploadImage = false
          vm.uploadVideo();
          
        }).catch((err) => {
          console.log(err);
          vm.dialogUploadImage = false

           let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Error uploading image",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);
        })
      }else{
        vm.uploadVideo();
      }
    },
    uploadVideo(){
        const vm = this
        if(vm.live.presentationVideo && vm.live.presentationVideo.video && vm.live.presentationVideo.link == ""){
            vm.dialogUploadPresentationVideo = true
            vm.$store
                .dispatch("UploadPresentationVideoLive", vm.live)
                .then((storage) => {
                    delete vm.live.presentationVideo.blob
                    delete vm.live.presentationVideo.video
                    vm.live.presentationVideo.link = storage.link
                    vm.live.presentationVideo.ref = storage.ref
                    vm.createLive();
                
                }).catch((err) => {

                    vm.dialogUploadPresentationVideo = false
    
                    let emitData = {
                        snackbar: true,
                        btn: "",
                        y: "bottom",
                        x: null,
                        mode: "",
                        timeout: 2000,
                        text: "Error uploading video",
                        color: "red",
                    };
                    vm.$emit("showSnackBar", emitData);
                })

        }else{
            vm.createLive()
        }

    },
    async createLive(){
     const vm = this;
          try {
            const id = await vm.$store.dispatch('CreateLive', vm.live)
            const payload = {
                routeName: 'lives',
                title: vm.live.title['ENG'],
                id: id,
                img: vm.live.image.link
            }
            vm.$store.dispatch('GenerateDynamicLink', payload)
             .then(async (dynamicLinks) => {
               vm.live.dynamicLink = dynamicLinks.shortLink
               await vm.$store.dispatch('UpdateLive', vm.live)
               
                  await vm.$store.dispatch("GenerateLog", {
                    id: id,
                    name: vm.live.title['ENG'],
                    type: "createdLive",
                })
            })
          
          
            let emitData = {
                snackbar: true,
                btn: "",
                y: "bottom",
                x: null,
                mode: "",
                timeout: 2000,
                text: "Article Created successfully",
                color: "success",
            };
            vm.$router.push("/admin/lives")
            .catch((err) => {
                vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
                vm.snackbar.color = "red darken-2";
                vm.snackbar.snackbar = true;
            })
            vm.$emit("showSnackBar", emitData);
          } catch (error) {

              let emitData = {
                  snackbar: true,
                  btn: "",
                  y: "bottom",
                  x: null,
                  mode: "",
                  timeout: 2000,
                  text: "Error creating Live",
                  color: "red",
              };
              vm.$emit("showSnackBar", emitData);
          }
    }
  },
  computed:{
      flagActualTranslate() {
        let vm = this;
        if(vm.listLanguages.length > 0){
            let currentFlag = vm.listLanguages.find(flag => flag.code === vm.actualTranslateLang);
            return currentFlag.flag;
        }
      },
      ...mapGetters(["getLoadingStatusLiveImage","getLoadingStatusLivePresentationVideo","getLivesFilters", "getSubscriptionsList"])
  },
  watch:{
    "live.videoLandscape": function (value) {
      if(!value){
        this.live.turnOrientationLandscape = null
      }
    }
    
  }
};
</script>

<style lang="scss">
 .radio-group-center > .v-input__control > .v-input__slot > .v-input--radio-group__input{
    justify-content: center !important;
  }
</style>