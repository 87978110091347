import firebase from "firebase";
import Vue from "vue";
import Axios from "axios";
import VueAxios from "vue-axios";
import { v4 as uuidv4 } from "uuid";

Vue.use(VueAxios, Axios);

const db = firebase.firestore();


Vue.use(VueAxios, Axios);

const storageImages = firebase.storage().ref("trainersImages/");

export default {
    state:{
        trainersList: [],
        loadingStatusTrainerImage:0
    },
    mutations:{
        SET_TRAINERS_LIST(state, trainersList){
            state.trainersList = trainersList
        },
        SET_VALUE_LOADING_STATUS_TRAINER_IMAGE(state, progress) {
            state.loadingStatusTrainerImage = progress
        },
    },
    actions:{
        UploadImageTrainer({ commit }, trainer) {
            return new Promise((resolve, reject) => {
                let random = uuidv4();
                let storageRef = storageImages.child(random);
                console.log('storageRef', storageRef.name);
                let uploadTask = storageRef.putString(trainer.image.img, "data_url")
                uploadTask.on('state_changed', (listener) => {
                    let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
                    commit('SET_VALUE_LOADING_STATUS_TRAINER_IMAGE', progress);
                }, (error) => {
                    console.log('error', error);
                    reject(error)
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        resolve({ link: downloadURL, ref: storageRef.name });
                    })
                })
            });
        },
        GetTrainersList({ commit }){
            return new Promise(async (resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("trainersList").get()
                    const trainersList = []
                    querySnapshot.forEach((doc) =>  {
                        trainersList.push(doc.data())
                    })
                    commit('SET_TRAINERS_LIST', trainersList)
                    resolve(trainersList)
                } catch(error) {
                    reject(error)
                }
            })
        },
        CreateTrainer({}, trainer) {
            return new Promise(async(resolve, reject) => {
                try {
                    delete trainer.image.img
                    const ref = db.collection("trainersList").doc();
                    const newTrainer = await ref.get();
                    trainer.id = ref.id;
                    trainer.createdAt = new Date()
                    trainer.updatedAt = new Date()
                    await db.collection("trainersList").doc(newTrainer.id).set(trainer)
                    resolve(newTrainer.id);
                } catch (error) {
                    reject(err);
                }
            })
        },
        UpdateTrainer({}, trainer) {
            return new Promise(async(resolve, reject) => {
                try {
                    if(trainer.createdAt) {
                        delete trainer.createdAt
                    }
                    trainer.updatedAt = new Date()
                    if (trainer.image.img) {
                        delete trainer.image.img
                    }
                    await db.collection("trainersList").doc(trainer.id).update(trainer)
                    resolve(trainer.id);
                } catch (error) {
                    reject(err);
                }
            });
        },
        DeleteTrainer({}, trainer) {
            return new Promise(async(resolve, reject) => {
                try {
                    let storageRefImage = storageImages.child(trainer.image.ref);
                    await storageRefImage.delete()
                    await db.collection("trainersList").doc(trainer.id).delete()
                    resolve(trainer.id)
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
    getters:{
        getTrainersList(state){
            return state.trainersList;
        },
        getLoadingStatusTrainerImage(state){
            return state.loadingStatusTrainerImage;
        },
    }
}