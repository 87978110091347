import firebase from "firebase";
import Vue from "vue";
import Axios from "axios";
import VueAxios from "vue-axios";
import { v4 as uuidv4 } from "uuid";

Vue.use(VueAxios, Axios);

const db = firebase.firestore();


Vue.use(VueAxios, Axios);

const storageImages = firebase.storage().ref("articlesImages/");

export default {
    state:{
        articlesList: [],
        loadingStatusArticleImage:0,
        categoriesArticlesList:[]
    },
    mutations:{
        SET_ARTICLES_LIST(state, articlesList){
            state.articlesList = articlesList
        },
        SET_CATEGORIES_ARTICLES_LIST(state, categoriesArticlesList){
            state.categoriesArticlesList = categoriesArticlesList
        },
        SET_VALUE_LOADING_STATUS_ARTICLE_IMAGE(state, progress) {
            state.loadingStatusArticleImage = progress
        },
    },
    actions:{
        UpdateCreatedAtArticles({ commit }) {
            return new Promise(async(resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("articlesList").get()
                    const articlesList = []
                    for (let i = 0; i < querySnapshot.docs.length; i++) {
                        let article = querySnapshot.docs[i].data();
                        
                        await db.collection("articlesList").doc(article.id).update({
                            createdAt: new Date(),
                            updatedAt: new Date(),
                        })
                        console.log('documents updated:', i+1)
                        articlesList.push(article);
                    }
                    console.log(articlesList)
                    resolve(articlesList)
                } catch(error) {
                    reject(error)
                }
            });
        },
        UploadImageArticle({ commit }, article) {
            return new Promise((resolve, reject) => {
                let random = uuidv4();
                let storageRef = storageImages.child(random);
                console.log('storageRef', storageRef.name);
                let uploadTask = storageRef.putString(article.image.img, "data_url")
                uploadTask.on('state_changed', (listener) => {
                    let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
                    commit('SET_VALUE_LOADING_STATUS_ARTICLE_IMAGE', progress);
                }, (error) => {
                    console.log('error', error);
                    reject(error)
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        resolve({ link: downloadURL, ref: storageRef.name });
                    })
                })
            });
        },
        GetArticlesList({ commit }){
            return new Promise(async (resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("articlesList").get()
                    const articlesList = []
                    querySnapshot.forEach(async(doc) =>  {
                        let article = doc.data()
                        let category = await article.category.get()
                        article.category = category.data()
                        articlesList.push(article)
                    })
                    commit('SET_ARTICLES_LIST', articlesList)
                    resolve(articlesList)
                } catch(error) {
                    reject(error)
                }
            })
        },
        GetArticle({ }, article){
            return new Promise(async (resolve, reject) => {
                try {
                    let resArticle = await db.collection("articlesList").doc(article.id).get()
                    let gettedArticle = resArticle.data()
                    let category = await gettedArticle.category.get()
                    gettedArticle.category = category.data()
                    resolve(gettedArticle)
                } catch(error) {
                    reject(error)
                }
            })
        },
        GetCategoriesArticlesList({ commit }){
            return new Promise(async (resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("categoriesArticlesList").get()
                    const categoriesArticlesList = []
                    querySnapshot.forEach((doc) =>  {
                        categoriesArticlesList.push(doc.data())
                    })
                    console.log(categoriesArticlesList)
                    commit('SET_CATEGORIES_ARTICLES_LIST', categoriesArticlesList)
                    resolve(categoriesArticlesList)
                } catch(error) {
                    reject(error)
                }
            })
        },
        CreateArticle({}, article) {
            return new Promise(async(resolve, reject) => {
                try {
                    delete article.image.img
                    article.category = db.collection("categoriesArticlesList").doc(article.category.id);
                    const ref = db.collection("articlesList").doc();
                    const newArticle = await ref.get();
                    article.id = ref.id;
                    article.createdAt = new Date()
                    article.updatedAt = new Date()
                    await db.collection("articlesList").doc(newArticle.id).set(article)
                    resolve(newArticle.id);
                } catch (error) {
                    reject(error);
                }
            })
        },
        CreateCategoryArticle({}, categoryArticle) {
            return new Promise(async(resolve, reject) => {
                try {
                    const ref = db.collection("categoriesArticlesList").doc();
                    const newCategoryArticle = await ref.get();
                    categoryArticle.id = ref.id;
                    await db.collection("categoriesArticlesList").doc(newCategoryArticle.id).set(categoryArticle)
                    resolve(newCategoryArticle.id);
                } catch (error) {
                    reject(err);
                }
            })
        },
        UpdateArticle({}, article) {
            return new Promise(async(resolve, reject) => {
                try {
                    if (article.image.img) {
                        delete article.image.img
                    }
                    article.category = db.collection("categoriesArticlesList").doc(article.category.id);
                    if(article.createdAt) {
                        delete article.createdAt
                    }
                    article.updatedAt = new Date()
                    await db.collection("articlesList").doc(article.id).update(article)
                    resolve(article.id);
                } catch (error) {
                    reject(error);
                }
            });
        },
        UpdateCategoryArticle({}, category) {
            return new Promise(async(resolve, reject) => {
                try {
                    await db.collection("categoriesArticlesList").doc(category.id).update(category)
                    resolve(category.id);
                } catch (error) {
                    reject(error);
                }
            });
        },
        DeleteArticle({}, article) {
            return new Promise(async(resolve, reject) => {
                try {
                    let storageRefImage = storageImages.child(article.image.ref);
                    await storageRefImage.delete()
                    await db.collection("articlesList").doc(article.id).delete()
                    resolve(article.id)
                } catch (error) {
                    reject(error);
                }
            });
        },
        DeleteCategoryArticle({}, category) {
            return new Promise(async(resolve, reject) => {
                try {
                    await db.collection("categoriesArticlesList").doc(category.id).delete()
                    resolve(category.id)
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
    getters:{
        getArticlesList(state){
            return state.articlesList;
        },
        getLoadingStatusArticleImage(state){
            return state.loadingStatusArticleImage;
        },
        getCategoriesArticlesList(state){
            return state.categoriesArticlesList;
        },
    }
}