<template>
  <v-container fluid>
    <v-row justify="space-between" class="px-10">
      <v-col cols="12" sm="12" md="4">
        <p class="text-h6 text--secondary font-weight-bold">Requests for Exercises by Users</p>
        <!-- <v-btn color="primary" @click="test()">test</v-btn> -->
      </v-col>
      <v-col cols="12" sm="12" md="2">
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          label="Search for a Request"
          v-model="search"
          single-line
          outlined
          rounded
          filled
          dense
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :search="search"
          :loading="loading"
          :headers="headers"
          :items="getRequestsExercisesList"
          item-key="id"
          fixed-header
          class="elevation-4"
        >
          <template v-slot:[`item.exercises`]="{ item }">
              {{ item.exercises.length }}
          </template>
          <template v-slot:[`item.request.createdAt`]="{ item }">
            {{ parseToRelativeDate(item.request.createdAt) }}
          </template>
          <template v-slot:[`item.request.status`]="{ item }">
            <span :class="`${getColorStatus(item.request.status)} font-weight-bold`">{{ item.request.status }}</span>
          </template>
          <template v-slot:[`item.request.savedOnDB`]="{ item }">
            <span v-if="item.request.savedOnDB" class="primary--text font-weight-bold">Yes</span>
            <span v-else class="secondary--text font-weight-bold">No</span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn icon color="primary" @click="showRequest(item)">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
        snackbar.text
      }}</v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "RequestsExercisesList",
  data: () => ({
    search: "",
    loading: false,
    headers: [
      { text: 'Name', align:'center', value:'name', sortable:true },
      { text: 'User', align:'center', value:'userEmail', sortable:true },
      { text: 'Exercises', align:'center', value:'exercises', sortable:true },
      { text: 'Requested', align:'center', value:'request.createdAt', sortable:true },
      { text: 'Status', align:'center', value:'request.status', sortable:true },
      { text: 'Added to App', align:'center', value:'request.savedOnDB', sortable:true },
      { text: "Action", align: "center", value: "action", sortable: false },
    ],
   snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
  }),
  created(){
      let vm = this
      vm.getAllRequestsExercisesList()
  },
  methods: {
    async test(){
      const vm = this
      console.log(vm.getRequestsExercisesList);
      await vm.$store.dispatch("GoToSave", vm.getRequestsExercisesList[0])
    },
    async getAllRequestsExercisesList(){
     const vm = this 
      try {
          vm.loading = true
          await vm.$store.dispatch("GetRequestsExercisesList")
          vm.loading = false
      } catch (error) {
          vm.loading = false
          console.log(error);
      }
    },
    showRequest(requestExercise){
        let vm = this;
        vm.$router.push({ name: "Request Exercise Show", params: requestExercise })
        .catch((err) => {
            vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
            vm.snackbar.color = "red darken-2";
            vm.snackbar.snackbar = true;
        })
    },
    parseToRelativeDate(date){
     return moment(date.toDate()).fromNow()
    },
    getColorStatus(status){
        if(status === 'PENDING'){
            return 'amber--text'
        }else if (status === 'APPROVED'){
            return 'success--text'
        }else if(status === 'REJECTED'){
            return 'danger--text'
        }
    }
  },
  computed:{
      ...mapGetters(["getRequestsExercisesList"])
  }
};
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
</style>