<template>
  <v-container fluid>
    <v-row justify="space-between" class="px-10">
      <v-col cols="12" sm="12" md="4">
        <p class="text-h6 text--secondary font-weight-bold">
          Push Notifications
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-btn text color="secondary" @click="createPushNotification()">
          <v-icon left dark color="primary"> mdi-plus-circle </v-icon>
          Create New Push Notification
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="5">
        <v-text-field
          label="Search for a Push Notification"
          v-model="search"
          single-line
          outlined
          rounded
          filled
          dense
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="12" md="12">
        <v-data-table
          :search="search"
          :loading="loading"
          :headers="headers"
          :items="getPushNotifications"
          item-key="id"
          fixed-header
          class="elevation-4"
        >
          <template v-slot:[`item.message`]="{ item }">
            <span
              class="d-block text-truncate mx-auto"
              style="max-width: 550px !important"
            >
              {{ item.message }}
            </span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              icon
              color="primary"
              class="mr-3"
              @click="editPushNotification(item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              color="red"
              class="mr-3"
              @click="openModalConfirm(item, 'delete')"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn icon color="primary" @click="openModalConfirm(item, 'send')">
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </template>
          <!-- <template v-slot:footer="{}">
            <v-row justify="end">
              <v-col cols="1">
                <v-select
                  class="mr-auto"
                  v-model="itemsPerPage"
                  :items="[5,10,15,20,30]"
                >
              </v-select>
              </v-col>
              <v-col cols="2" class="text-right mr-3" align-self="center">
              <v-btn color="primary" x-small class="mx-1" :disabled="pageCounter === 1" @click="goPrevPagePushNotifications()">
                <v-icon size="15">
                  mdi-arrow-left
                </v-icon>
              </v-btn>
              <p class="d-inline">{{ pageCounter }}</p>
              <v-btn color="primary" x-small class="mx-1" :disabled="getPushNotifications.length < itemsPerPage || getPushNotifications.length === 0" @click="goNextPagePushNotifications()">
                <v-icon size="15">
                  mdi-arrow-right
                </v-icon>
              </v-btn>
              </v-col>
            </v-row>
          </template> -->
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog.show" width="300" class="rounded-xl">
      <v-card class="text-center" v-if="dialog.type === 'send'">
        <!-- <v-card-title class="headline">Use Google's location service?</v-card-title> -->
        <v-icon class="text-center ma-2" size="120" color="primary"
          >mdi-alert</v-icon
        >
        <v-card-text class="pa-4 text-center"
          >With this action you will send a mass notification to all users of
          MAXPRO</v-card-text
        >
        <v-card-text class="text-center">Are you sure?</v-card-text>

        <!-- <v-card-actions class="pa-0"> -->
        <!-- <v-spacer></v-spacer> -->

        <v-btn
          color="third"
          tile
          width="150"
          class="pa-0 text--secondary"
          @click="closeModalConfirm()"
          >No</v-btn
        >

        <v-btn
          color="primary"
          tile
          width="150"
          class="pa-0"
          @click="openDialogReAuth('sendPushNotification', dialog.item)"
          >Yes</v-btn
        >
        <!-- </v-card-actions> -->
      </v-card>
      <v-card class="text-center" v-if="dialog.type === 'delete'">
        <!-- <v-card-title class="headline">Use Google's location service?</v-card-title> -->
        <v-icon class="text-center ma-2" size="100" color="primary"
          >mdi-delete</v-icon
        >
        <v-card-text class="pa-4 text-center"
          >Are you sure to delete this notification?</v-card-text
        >
        <!-- <v-card-text class="text-center">Are you sure?</v-card-text> -->

        <!-- <v-card-actions class="pa-0"> -->
        <!-- <v-spacer></v-spacer> -->

        <v-btn
          color="third"
          tile
          width="150"
          class="pa-0 text--secondary"
          @click="closeModalConfirm()"
          >No</v-btn
        >
        <v-btn
          color="primary"
          tile
          width="150"
          class="pa-0"
          @click="openDialogReAuth('deletePushNotification', dialog.item)"
          >Yes</v-btn
        >
        <!-- </v-card-actions> -->
      </v-card>
    </v-dialog>
    <modal-reauth :dialogReAuth="dialogReAuth" @isSuccessReAuth="isSuccessReAuth"></modal-reauth>
    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color">{{
      snackbar.text
    }}</v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import modalReauth from '@/components/admin/modal-reauth.vue';
export default {
  components: { modalReauth },
  name: "PushNotifications",
  data: () => ({
    dialogReAuth:{
      show: false,
      action: '',
      item:{}
    },
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
    dialog: {
      show: false,
      item: "",
      type: "",
    },
    itemsPerPage:10,
    pageCounter:1,
    search:"",
    loading:false,
    headers: [
      { text: "Title", align: "center", value: "title", sortable: true },
      { text: "Message", align: "center", value: "message", sortable: true },
      { text: "Link", align: "center", value: "link", sortable: true },
      { text: "", align: "center", value: "action", sortable: false },
    ],
  }),
  created() {
    let vm = this;
    vm.getPushNotificationsList();
  },
  methods: {
    getPushNotificationsList(){
      let vm = this
      vm.loading = true
      vm.$store.dispatch('GetNotifications')
      .then((res)=>{
        vm.loading = false
      }).catch((err)=>{
        vm.loading = false
      })
    },
    getFirstPagePushNotifications(){
      let vm = this
      vm.$store.dispatch('GetPushNotificationsPaginatedFirstPage', vm.itemsPerPage)
      .then((res)=>{
        vm.pageCounter = 1
        vm.loading = false
      }).catch((err)=>{
        console.error(err)
        vm.loading = false
      })
    },
    goNextPagePushNotifications(){
      let vm = this
      vm.$store.dispatch('GetPushNotificationsNextPage', vm.itemsPerPage)
      .then((res)=>{
        if(res.length > 0){
          vm.pageCounter = vm.pageCounter + 1
        }
        vm.loading = false
      }).catch((err)=>{
        console.error(err)
        vm.loading = false
      })
    },
    goPrevPagePushNotifications(){
      let vm = this
      vm.$store.dispatch('GetPushNotificationsPrevPage', vm.itemsPerPage)
      .then((res)=>{
        vm.pageCounter = vm.pageCounter - 1
        vm.loading = false
      }).catch((err)=>{
        console.error(err)
        vm.loading = false
      })
    },
    isSuccessReAuth(dialogReAuthResponse){
      const vm = this 
        switch (dialogReAuthResponse.action) {
          case 'deletePushNotification':
            vm.deletePushNotification(dialogReAuthResponse.item)
            break;
          case 'sendPushNotification':
            vm.sendPushNotification(dialogReAuthResponse.item)
            break;
          default:
            break;
        }
    },
    openDialogReAuth(action, item){
      let vm = this;
      vm.dialogReAuth.show = true
      vm.dialogReAuth.action = action
      vm.dialogReAuth.item = item
      vm.closeModalConfirm();
    },
    editPushNotification(item) {
      let vm = this;
      vm.$router.push({ name: "Push Notification Edit", params: item })
      .catch((err) => {
        vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      })
    },
    createPushNotification() {
      let vm = this;
      vm.$router.push({ name: "Push Notification Create"})
      .catch((err) => {
        vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      })
    },
    deletePushNotification(item) {
      let vm = this;
        vm.$store
        .dispatch("DeleteNotification", item)
        .then((id) => {
          vm.$store.dispatch("GenerateLog", {
            id: id,
            title: item.title,
            type: "deletedPushNotification",
          });
          let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Notification deleted succesfully!",
            color: "success",
          };
          vm.$emit("showSnackBar", emitData);
        })
        .catch((err) => {
           let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Error deleting notification!",
            color: "error",
          };
          vm.$emit("showSnackBar", emitData);
          // vm.messageSnack(`Error deleting notification`, "error");
        });
    },
    sendPushNotification(pushNotification) {
      let vm = this;
      vm.$emit("showOverlay", true);
      vm.$store
        .dispatch("SendNotification", pushNotification)
        .then((res) => {
          vm.$store.dispatch("GenerateLog", {
            id: pushNotification.id,
            title: pushNotification.title,
            type: "sendedPushNotification",
          })
          vm.$emit("showOverlay", false);
          let emitNotification = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Notification massive sended succesfully!",
            color: "success",
          };
          vm.$emit("showSnackBar", emitNotification);
          
        })
        .catch((err) => {
          vm.$emit("showOverlay", false);
          let emitError = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Notification massive error!",
            color: "red",
          };
          vm.$emit("showSnackBar", emitError);
        });
    },
    openModalConfirm(item, type) {
      let vm = this;
      vm.dialog.show = true;
      vm.dialog.item = item;
      vm.dialog.type = type;
    },
    closeModalConfirm() {
      let vm = this;
      vm.dialog.show = false;
      vm.dialog.item = "";
      vm.dialog.type = "";
    },
  },
  destroyed() {
    let vm = this;
    // vm.$store.dispatch("GoUnsuscribeGetPushNotifications");
  },
  computed: {
    ...mapGetters(["getPushNotifications"]),
  },
  // watch:{
  //   itemsPerPage:{
  //     handler(newValue){
  //       this.getFirstPagePushNotifications()
  //     }
  //   }
  // }
};
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
</style>