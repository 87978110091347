<template>
  <v-container fluid>
    <v-row justify="space-between" class="px-10">
      <v-col cols="12" sm="12" md="4">
        <p class="text-h6 text--secondary font-weight-bold">Trainers</p>
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <v-btn text color="secondary" @click="createTrainer()">
          <v-icon left dark color="primary"> mdi-plus-circle </v-icon>
          New Trainer
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          label="Search for a Trainer"
          v-model="search"
          single-line
          outlined
          rounded
          filled
          dense
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :search="search"
          :loading="loading"
          :headers="headers"
          :items="getTrainersList"
          item-key="id"
          fixed-header
          class="elevation-4"
        >
          <template v-slot:[`item.image`]="{ item }">
             <v-tooltip top color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <img 
                  v-if="item.image.link" 
                  v-bind="attrs" 
                  v-on="on" 
                  class="protrain-image-preview" 
                  style="cursor:pointer;"
                  @click="openDialogPreviewImage(item.image.link)"
                  :src="item.image.link">  
              </template>
              <span>Show Image</span>
            </v-tooltip>
          </template>
         <template v-slot:[`item.firstName`]="{ item }">
            {{ item.firstName }} {{ item.lastName }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn icon color="primary" @click="changeRouteTrainer(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              color="red"
              class="mr-3"
              @click="openDialogConfirm(item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
      <v-dialog v-model="dialog.show" width="300" class="rounded-xl">
        <v-card class="text-center">
            <v-icon class="text-center ma-2" size="120" color="primary"
            >mdi-alert</v-icon>
            <v-card-text class="pa-4 text-center"
            >This action is irreversible and will delete all data about this
            Trainer. Including the image, data, references in excercises and information</v-card-text>
            <v-card-text class="text-center">Are you sure?</v-card-text>

            <v-btn
            color="third"
            tile
            width="150"
            class="pa-0 text--secondary"
            @click="closeDialogConfirm()"
            >No</v-btn
            >

            <v-btn
            color="primary"
            tile
            width="150"
            class="pa-0"
            @click="openDialogReAuth('deleteTrainer', dialog.item)"
            >Yes</v-btn
            >
            <!-- </v-card-actions> -->
        </v-card>
    </v-dialog>
    <modal-reauth
      :dialogReAuth="dialogReAuth"
      @isSuccessReAuth="isSuccessReAuth"
    ></modal-reauth>
   <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
        snackbar.text
      }}</v-snackbar>
    <v-dialog
        v-model="dialogPreviewImage.show" 
        max-width="400"
      >
        <v-card style="line-height: 0; !important;">
          <v-btn fab x-small color="red" style="position:absolute; top:5px; left: 5px;" @click="closeDialogImagePreview()">
              <v-icon size="15" color="white">mdi-close</v-icon>
          </v-btn>
            <img :src="dialogPreviewImage.img" style="width:100%; height:100%;">
        </v-card>
      </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import modalReauth from "@/components/admin/modal-reauth.vue";
export default {
  name: "TrainersList",
  components: { modalReauth },
  data: () => ({
    search: "",
    loading: false,
    headers: [
      { text: '', align:'center', value:'image', sortable:false },
      { text: 'Order Number', align:'center', value:'orderNumber', sortable:true },
      { text: 'Name', align:'center', value:'firstName', sortable:true },
      { text: "Action", align: "center", value: "action", sortable: false },
    ],
    dialogReAuth: {
      show: false,
      action: "",
      item: {},
    },
    dialog: {
      show: false,
      item: {},
    },
    snackbar:{
        snackbar:false,
        color:'dark',
        text:''
    },
    dialogPreviewImage:{
      show:false,
      video:'',
    },
  }),
  created(){
      let vm = this
      vm.getAllTrainers()
  },
  methods: {
    async getAllTrainers(){
     const vm = this 
      try {
          vm.loading = true
          await vm.$store.dispatch("GetTrainersList")
          vm.loading = false
      } catch (error) {
          vm.loading = false
          console.log(error);
      }
    },
    openDialogConfirm(item) {
      const vm = this;
      vm.dialog.show = true;
      vm.dialog.item = item;
    },
    closeDialogConfirm() {
      const vm = this;
      vm.dialog.show = false;
      vm.dialog.item = "";
    },
    openDialogReAuth(action, item) {
      let vm = this;
      vm.dialogReAuth.show = true;
      vm.dialogReAuth.action = action;
      vm.dialogReAuth.item = item;
      vm.closeDialogConfirm();
    },
    isSuccessReAuth(dialogReAuthResponse) {
      const vm = this;
      switch (dialogReAuthResponse.action) {
        case "deleteTrainer":
          vm.deleteTrainer(dialogReAuthResponse.item);
          break;
        default:
          break;
      }
    },
    openDialogPreviewImage(img){
      const vm = this
      vm.dialogPreviewImage = {
        show: true,
        img: img ? img : ''
      }
    },
    closeDialogImagePreview(){
      const vm = this
      vm.dialogPreviewImage.show = false
    },
   async deleteTrainer(trainer){
      const vm = this;
      try {
        vm.$emit("showOverlay", true);
        await vm.$store.dispatch("DeleteTrainer", trainer)
        await vm.$store.dispatch("GenerateLog", {
                id: trainer.id,
                name: trainer.firstName,
                type: "deletedTrainer",
              });
        vm.getAllTrainers()
        vm.$emit("showOverlay", false);
        
      } catch (error) {
        vm.$emit("showOverlay", false);
          console.error(error);
          let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Error deleting Trainer",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);
      } 
    },
    changeRouteTrainer(trainer){
        let vm = this;
        vm.$router.push({ name: "Trainer Edit", params: trainer })
        .catch((err) => {
            vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
            vm.snackbar.color = "red darken-2";
            vm.snackbar.snackbar = true;
        })
    },
    createTrainer() {
      let vm = this;
      vm.$router.push({ name: "Trainer Create" }).catch((err) => {
        vm.snackbar.text ="Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      });
    },
  },
  computed:{
      ...mapGetters(["getTrainersList"])
  }
};
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
.protrain-image-preview{
    width: 50px; 
    height: 50px; 
    border-radius: 50%; 
    object-fit: cover; 
    vertical-align: middle;
  }
</style>