import firebase from "firebase";
import Vue from "vue";
import Axios from "axios";
import VueAxios from "vue-axios";
import moment from 'moment';

const db = firebase.firestore();

Vue.use(VueAxios, Axios);
export default {
    state: {
        logList: [],
        unsubscribeGetLogList: null,
    },
    mutations: {
        SET_LOG_LIST(state, logList) {
            state.logList = logList;
        },
        SET_UNSUBSCRIBE_GET_LOG_LIST(state, unsubscribe) {
            state.unsubscribeGetLogList = unsubscribe;
        },
        GO_UNSUBSCRIBE_GET_LOG_LIST(state) {
            state.unsubscribeGetLogList();
        },
    },
    actions: {
        GenerateLog({ rootGetters }, log) {
            return new Promise(async (resolve, reject) => {
                const logMessage = {
                    enabled: `Has enabled the user ${log.user}`,
                    disabled: `Has disabled the user ${log.user}`,
                    validationEmail: `Has sent a validation email to the user ${log.user}`,
                    restorePassword: `Has sent a restore password email to the user ${log.user}`,
                    createdPushNotification: `Has created the notification: '${log.title}' [ID:${log.id}]`,
                    editedPushNotification: `Has edited the notification:'${log.title}' [ID:${log.id}]`,
                    deletedPushNotification: `Has deleted the notification:'${log.title}' [ID:${log.id}]`,
                    sendedPushNotification: `Has sended the massive push notification:'${log.title}' [ID:${log.id}]`,
                    sendedIndividualPushNotification: `Has sended the push notification:'${log.title}' to the user ${log.email} [ID:${log.id}]`,
                    createdProtrain: `Has created the ProTrain: '${log.title}' [ID:${log.id}]`,
                    editedProtrain: `Has edited the ProTrain: '${log.title}' [ID:${log.id}]`,
                    deletedProtrain: `Has deleted the ProTrain: '${log.title}' [ID:${log.id}]`,
                    createdExercise: `Has created the exercise: '${log.title}' [ID:${log.id}]`,
                    editedExercise: `Has edited the exercise: '${log.title}' [ID:${log.id}]`,
                    deletedExercise: `Has deleted the exercise: '${log.title}' [ID:${log.id}]`,
                    updateUserRole: `Has changed the role of the user: '${log.user}' to: ${log.role}`,
                    createdOnDemand: `Has created the On Demand: '${log.title}' [ID:${log.id}]`,
                    editedOnDemand: `Has edited the On Demand: '${log.title}' [ID:${log.id}]`,
                    deletedOnDemand: `Has deleted the On Demand: '${log.title}' [ID:${log.id}]`,
                    createdPWO: `Has created the Professional Workout: '${log.title}' [ID:${log.id}]`,
                    editedPWO: `Has edited the Professional Workout: '${log.title}' [ID:${log.id}]`,
                    deletedPWO: `Has deleted the Professional Workout: '${log.title}' [ID:${log.id}]`,
                    resolvedFeedback: `Has resolved the Feedback of the user: '${log.emailUser}' [ID:${log.id}]`,
                    createdFeedbackCategorie: `Has created the Categorie of Feedback: '${log.name}' [ID:${log.id}]`,
                    deletedFeedbackCategorie: `Has deleted the Categorie of Feedback: '${log.name}' [ID:${log.id}]`,
                    createdFeedbackStatus: `Has created the Status of Feedback: '${log.name}' [ID:${log.id}]`,
                    deletedFeedbackStatus: `Has deleted the Status of Feedback: '${log.name}' [ID:${log.id}]`,
                    changedCategorieToFeedback: `Has changed the Categorie to: '${log.name}' for Feedback of the user: ${log.emailUser} [ID:${log.id}]`,
                    changedStatusToFeedback: `Has changed the Status to: '${log.name}' for Feedback of the user: ${log.emailUser} [ID:${log.id}]`,
                    addEmailToListFeedback: `Has added the email: '${log.email}'to list for recieve feedbacks: [ID:${log.id}]`,
                    deletedEmailToListFeedback: `Has deleted the email: '${log.email}'from list for recieve feedbacks: [ID:${log.id}]`,
                    createdTutorial: `Has created the Tutorial: '${log.title}' [ID:${log.id}]`,
                    editedTutorial: `Has edited the Tutorial: '${log.title}' [ID:${log.id}]`,
                    deletedTutorial: `Has deleted the Tutorial: '${log.title}' [ID:${log.id}]`,
                    updatedFromDefaultEmailFeedback: `Has updated FROM address for send emails about Feedbacks: '${log.email}' [ID:${log.id}]`,
                    createdNewFilterProTrain: `Has created in ProTrain the new filter: '${log.name}' on [${log.doc}]`,
                    deletedFilterProTrain: `Has deleted in ProTrain the filter: '${log.name}' on [${log.doc}]`,
                    createdSubfilterProtrain: `Has created in ProTrain the subfilter: '${log.name}' on [${log.doc}]`,
                    createdThirdLevelProtrain: `Has created in ProTrain the third level filter: '${log.name}' on [${log.doc}]`,
                    deletedSubfilterProTrain: `Has deleted in ProTrain the subfilter: '${log.name}' on [${log.doc}]`,
                    deletedThirdLevelProTrain: `Has deleted in ProTrain the third level filter: '${log.name}' on [${log.doc}]`,
                    createdRootFilterProTrain: `Has created in ProTrain the root filter: '${log.name}' on [${log.doc}]`,
                    deletedRootFilterProTrain: `Has deleted in ProTrain the root filter: '${log.name}' on [${log.doc}]`,
                    createdProgram: `Has created the Program: '${log.title}' [ID:${log.id}]`,
                    editedProgram: `Has edited the Program: '${log.title}' [ID:${log.id}]`,
                    deletedProgram: `Has deleted the Program: '${log.title}' [ID:${log.id}]`,
                    createdBackgroundAppImage: `Has created the Background App Image: '${log.name}' [ID:${log.id}]`,
                    editedBackgroundAppImage: `Has edited the Background App Image: '${log.name}' [ID:${log.id}]`,
                    deletedBackgroundAppImage: `Has deleted the Background App Image: '${log.name}' [ID:${log.id}]`,
                    createdAchievement: `Has created the Achievement: '${log.name}' [ID:${log.id}]`,
                    editedAchievement: `Has edited the Achievement: '${log.name}' [ID:${log.id}]`,
                    deletedAchievement: `Has deleted the Achievement: '${log.name}' [ID:${log.id}]`,
                    createdSubscription: `Has created the Subscription: '${log.name}' [ID:${log.id}]`,
                    editedSubscription: `Has edited the Subscription: '${log.name}' [ID:${log.id}]`,
                    addedManuallySubscriptionToUser: `Has add the Subscription: '${log.name}' to the user '${log.user}' [ID:${log.id}]`,
                    deletedSubscription: `Has deleted the Subscription: '${log.name}' [ID:${log.id}]`,
                    editedTrainer: `Has edited the Trainer: '${log.name}' [ID:${log.id}]`,
                    deletedTrainer: `Has deleted the Trainer: '${log.name}' [ID:${log.id}]`,
                    statusRequestExerciseChanged: `Has changed the Status of the Requested Exercise: '${log.exerciseName}' to: '${log.status}' from user ${log.userEmail} [ID:${log.id}]`,
                    createdCategoryTip: `Has created the category Tip: '${log.title}' [ID:${log.id}]`,
                    editedCategoryTip: `Has edited the category Tip: '${log.title}' [ID:${log.id}]`,
                    deletedCategoryTip: `Has deleted the category Tip: '${log.title}' [ID:${log.id}]`,
                    createdTip: `Has created the Tip: '${log.title}' [ID:${log.id}]`,
                    editedTip: `Has edited the Tip: '${log.title}' [ID:${log.id}]`,
                    deletedTip: `Has deleted the Tip: '${log.title}' [ID:${log.id}]`,
                    createdPreSet: `Has created the Pre-Set: '${log.name}' [ID:${log.id}]`,
                    editedPreSet: `Has edited the Pre-Set: '${log.name}' [ID:${log.id}]`,
                    deletedPreSet: `Has deleted the Pre-Set: '${log.name}' [ID:${log.id}]`,
                    createdCategoryTutorial: `Has created the category Tutorial: '${log.title}' [ID:${log.id}]`,
                    editedCategoryTutorial: `Has edited the category Tutorial: '${log.title}' [ID:${log.id}]`,
                    deletedCategoryTutorial: `Has deleted the category Tutorial: '${log.title}' [ID:${log.id}]`,
                    createdArticle: `Has created the Article: '${log.title}' [ID:${log.id}]`,
                    editedArticle: `Has edited the Article: '${log.title}' [ID:${log.id}]`,
                    deletedArticle: `Has deleted the Article: '${log.title}' [ID:${log.id}]`,
                    createdCategoryArticle: `Has created the category Article: '${log.title}' [ID:${log.id}]`,
                    editedCategoryArticle: `Has edited the category Article: '${log.title}' [ID:${log.id}]`,
                    deletedCategoryArticle: `Has deleted the category Article: '${log.title}' [ID:${log.id}]`,
                    createdLive: `Has created the Live: '${log.name}' [ID:${log.id}]`,
                    editedLive: `Has edited the Live: '${log.name}' [ID:${log.id}]`,
                    deletedLive: `Has deleted the Live: '${log.name}' [ID:${log.id}]`,
                    createdFirmwareVersion: `Has created the firmware version: '${log.title}' [ID:${log.id}]`,
                    editedFirmwareVersion: `Has edited the firmware version: '${log.title}' [ID:${log.id}]`,
                    deletedFirmwareVersion: `Has deleted the firmware version: '${log.title}' [ID:${log.id}]`,
                    createdTypeAchievement: `Has created the Type Achievement: '${log.name}' [ID:${log.id}]`,
                    editedTypeAchievement: `Has edited the Type Achievement: '${log.name}' [ID:${log.id}]`,
                    deletedTypeAchievement: `Has deleted the Type Achievement: '${log.name}' [ID:${log.id}]`,
                    createdCategoryAchievement: `Has created the Category Achievement: '${log.name}' [ID:${log.id}]`,
                    editedCategoryAchievement: `Has edited the Category Achievement: '${log.name}' [ID:${log.id}]`,
                    deletedCategoryAchievement: `Has deleted the Category Achievement: '${log.name}' [ID:${log.id}]`,
                    createdIntervalAchievement: `Has created the Interval Achievement: '${log.name}' [ID:${log.id}]`,
                    editedIntervalAchievement: `Has edited the Interval Achievement: '${log.name}' [ID:${log.id}]`,
                    deletedIntervalAchievement: `Has deleted the Interval Achievement: '${log.name}' [ID:${log.id}]`,
                    createdNewFilterLives: `Has created in Lives the new filter: '${log.name}' on [${log.doc}]`,
                    deletedFilterLives: `Has deleted in Lives the filter: '${log.name}' on [${log.doc}]`,
                    createdSubfilterLives: `Has created in Lives the subfilter: '${log.name}' on [${log.doc}]`,
                    createdThirdLevelLives: `Has created in Lives the third Level filter: '${log.name}' on [${log.doc}]`,
                    deletedSubfilterLives: `Has deleted in Lives the subfilter: '${log.name}' on [${log.doc}]`,
                    deletedThirdLevelLives: `Has deleted in Lives the third level filter: '${log.name}' on [${log.doc}]`,
                    createdRootFilterLives: `Has created in Lives the root filter: '${log.name}' on [${log.doc}]`,
                    deletedRootFilterLives: `Has deleted in Lives the root filter: '${log.name}' on [${log.doc}]`,
                    resetLeaderBoardProTrain: `Has reset all Leadearboard of the Coached Class: '${log.title}' on [${log.id}]`,
                    createdNewFilterPreSets: `Has created in Pre-Sets the new filter: '${log.name}' on [${log.doc}]`,
                    deletedFilterPreSets: `Has deleted in Pre-Sets the filter: '${log.name}' on [${log.doc}]`,
                    createdSubfilterPreSets: `Has created in Pre-Sets the subfilter: '${log.name}' on [${log.doc}]`,
                    createdThirdLevelPreSets: `Has created in Pre-Sets the third level filter: '${log.name}' on [${log.doc}]`,
                    deletedSubfilterPreSets: `Has deleted in Pre-Sets the subfilter: '${log.name}' on [${log.doc}]`,
                    deletedThirdLevelPreSets: `Has deleted in Pre-Sets the third level filter: '${log.name}' on [${log.doc}]`,
                    createdRootFilterPreSets: `Has created in Pre-Sets the root filter: '${log.name}' on [${log.doc}]`,
                    deletedRootFilterPreSets: `Has deleted in Pre-Sets the root filter: '${log.name}' on [${log.doc}]`,
                    createdCoupon: `Has created the Coupon: '${log.title}' [ID:${log.id}]`,
                    editedCoupon: `Has edited the Coupon: '${log.title}' [ID:${log.id}]`,
                    deletedCoupon: `Has deleted the Coupon: '${log.title}' [ID:${log.id}]`,
                    editedExerciseByUser: `Has edited the exercise: '${log.name}' created by the user ${log.email} [ID:${log.id}]`,
                    createdProTrainerTypeOfExercise: `Has created the Coached Class type of Exercise: '${log.name}' [ID:${log.id}]`,
                    editedProTrainerTypeOfExercise: `Has edited the Coached Class type of Exercise: '${log.name}' [ID:${log.id}]`,
                    deletedProTrainerTypeOfExercise: `Has deleted the Coached Class type of Exercise: '${log.name}' [ID:${log.id}]`,
                    createdProTrainerTypeOfBody: `Has created the Coached Class type of Body: '${log.name}' [ID:${log.id}]`,
                    editedProTrainerTypeOfBody: `Has edited the Coached Class type of Body: '${log.name}' [ID:${log.id}]`,
                    deletedProTrainerTypeOfBody: `Has deleted the Coached Class type of Body: '${log.name}' [ID:${log.id}]`,
                    updateCoachStatus: `Has changed the status of the user: '${log.email}' to: '${log.isCoach ? 'Coach' : 'Regular User'}' [ID:${log.id}]`,
                    sendedPushNotificationByGroup: `Has sended the push notification:'${log.title}' to ${log.topicTitle} [ID:${log.id}]`,
                };

                const workoutTypes = [
                    'createdProtrain',
                    'editedProtrain',
                    'deletedProtrain',
                    'createdPreSet',
                    'editedPreSet',
                    'deletedPreSet',
                    'createdLive',
                    'editedLive',
                    'deletedLive',
                ];

                if (workoutTypes.includes(log.type)) {
                    const key = log.type.includes('Protrain') ? 'classes' : log.type.includes('PreSet') ? 'presets' : 'lives';
                    await db.collection('lastTimeUpdatedWorkouts').doc('oZhtAWiwcoQmloundK3y').update({ [key]: new Date() });
                }

                const newLog = db.collection("logs").doc();
                const newLogRef = await newLog.get();
                await db
                    .collection("logs")
                    .doc(newLogRef.id)
                    .set({
                        logId: newLog.id,
                        author: rootGetters.getAdmin.email,
                        message: logMessage[log.type],
                        date: firebase.firestore.FieldValue.serverTimestamp(),
                    })
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        GetLogList({ commit }) {
            return new Promise(async (resolve, reject) => {
                let unsubscribe = db.collection("logs").orderBy('date', 'desc').limit(10).onSnapshot((querySnapshot) => {
                    var logList = [];
                    querySnapshot.forEach((doc) => {
                        let log = doc.data()
                        log.date = moment(log.date.toDate()).fromNow()
                        logList.push(log);
                    });
                    commit("SET_LOG_LIST", logList);
                    resolve(logList);
                },
                    (error) => {
                        reject(error);
                    }
                );
                commit("SET_UNSUBSCRIBE_GET_LOG_LIST", unsubscribe);
            });
        },
        GetLogListPaginatedFirstPage({ commit, getters }, itemsPerPage) {
            return new Promise(async (resolve, reject) => {
                if (getters.getLogList.length > 0) {
                    commit("GO_UNSUBSCRIBE_GET_LOG_LIST");
                }
                let unsubscribe = db.collection("logs").orderBy('date', 'desc').limit(itemsPerPage).onSnapshot((querySnapshot) => {
                    let logList = [];
                    querySnapshot.forEach((doc) => {
                        let log = doc.data()
                        logList.push(log);
                    });
                    commit("SET_LOG_LIST", logList);
                    resolve(logList);
                },
                    (error) => {
                        reject(error);
                    }
                );
                commit("SET_UNSUBSCRIBE_GET_LOG_LIST", unsubscribe);
            });
        },
        GetLogNextPage({ commit, getters }, itemsPerPage) {
            return new Promise(async (resolve, reject) => {
                commit("GO_UNSUBSCRIBE_GET_LOG_LIST");
                console.log(getters.getLogList[getters.getLogList.length - 1].date);
                let unsubscribe = db.collection("logs").orderBy('date', 'desc').startAfter(getters.getLogList[getters.getLogList.length - 1].date).limit(itemsPerPage).onSnapshot((querySnapshot) => {
                    let logList = [];
                    querySnapshot.forEach((doc) => {
                        let log = doc.data()
                        logList.push(log);
                    });
                    console.log(logList);
                    if (logList.length > 0) {
                        commit("SET_LOG_LIST", logList);
                    }
                    resolve(logList);
                },
                    (error) => {
                        reject(error);
                    }
                );
                commit("SET_UNSUBSCRIBE_GET_LOG_LIST", unsubscribe);
            });
        },
        GetLogPrevPage({ commit, getters }, itemsPerPage) {
            return new Promise(async (resolve, reject) => {
                commit("GO_UNSUBSCRIBE_GET_LOG_LIST");
                let unsubscribe = db.collection("logs").orderBy('date', 'desc').endBefore(getters.getLogList[0].date).limitToLast(itemsPerPage).onSnapshot((querySnapshot) => {
                    let logList = [];
                    querySnapshot.forEach((doc) => {
                        let log = doc.data()
                        logList.push(log);
                    });
                    commit("SET_LOG_LIST", logList);
                    resolve(logList);
                },
                    (error) => {
                        reject(error);
                    }
                );
                commit("SET_UNSUBSCRIBE_GET_LOG_LIST", unsubscribe);
            });
        },
        GoUnsuscribeGetLogList({ commit }) {
            commit("GO_UNSUBSCRIBE_GET_LOG_LIST");
        },
    },
    getters: {
        getLogList(state) {
            return state.logList;
        },
    },
};