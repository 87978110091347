<template>
  <v-container fluid>
    <v-row justify="space-between" class="px-10">
      <v-col cols="12" sm="12" md="4">
        <p class="text-h6 text--secondary font-weight-bold">Log list</p>
      </v-col>
      <v-col cols="12" sm="12" md="8">
        <!-- <v-text-field
          label="Search for a log"
          v-model="search"
          single-line
          outlined
          rounded
          filled
          dense
          append-icon="mdi-magnify"
        ></v-text-field> -->
      </v-col>
        <v-col cols="12">
          <v-data-table
            dense
            :search="search"
            :headers="headers"
            :items="getLogList"
            :items-per-page="itemsPerPage"
            item-key="logId"
            hide-default-footer
            fixed-header
            class="elevation-4"
          >
           <template v-slot:[`item.date`]="{ item }">
            {{ parseToRelativeDate(item.date) }}
           </template>
           <template v-slot:footer="{}">
            <v-row justify="end">
              <v-col cols="1">
                <v-select
                  class="mr-auto"
                  v-model="itemsPerPage"
                  :items="[5,10,15,20,30]"
                >
              </v-select>
              </v-col>
              <v-col cols="2" class="text-right mr-3" align-self="center">
              <v-btn color="primary" x-small class="mx-1" :disabled="pageCounter === 1" @click="goPrevPage()">
                <v-icon size="15">
                  mdi-arrow-left
                </v-icon>
              </v-btn>
              <p class="d-inline">{{ pageCounter }}</p>
              <v-btn color="primary" x-small class="mx-1" :disabled="getLogList.length < itemsPerPage || getLogList.length === 0" @click="goNextPage()">
                <v-icon size="15">
                  mdi-arrow-right
                </v-icon>
              </v-btn>
              </v-col>
            </v-row>
          </template>
          </v-data-table>
        </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "Logs",
  data: () => ({
    headers: [
      { text: "Lod ID", align: "center", value: "logId",sortable: false, },
      { text: "Action", align: "start", value: "message", sortable: false },
      { text: "Author", align: "start", value: "author", sortable: true },
      { text: "Date", align: "center", value: "date", sortable: true },
    ],
    itemsPerPage:10,
    pageCounter:1,
    search:"",
    loading:true,
  }),
  created(){
    let vm = this
    vm.getFirstPage()
    // vm.$store.dispatch('GetLogList')
    // .then((res)=>{
    //   vm.loading = false
    // }).catch((err)=>{
    //   console.error(err)
    //   vm.loading = false
    // })
  },
  methods:{
    getFirstPage(){
      let vm = this
      vm.$store.dispatch('GetLogListPaginatedFirstPage', vm.itemsPerPage)
      .then((res)=>{
        vm.pageCounter = 1
        vm.loading = false
      }).catch((err)=>{
        console.error(err)
        vm.loading = false
      })
    },
    goNextPage(){
      let vm = this
      vm.$store.dispatch('GetLogNextPage', vm.itemsPerPage)
      .then((res)=>{
        if(res.length > 0){
          vm.pageCounter = vm.pageCounter + 1
        }
        vm.loading = false
      }).catch((err)=>{
        console.error(err)
        vm.loading = false
      })
    },
    goPrevPage(){
      let vm = this
      vm.$store.dispatch('GetLogPrevPage', vm.itemsPerPage)
      .then((res)=>{
        vm.pageCounter = vm.pageCounter - 1
        vm.loading = false
      }).catch((err)=>{
        console.error(err)
        vm.loading = false
      })
    },
    parseToRelativeDate(date){
     return moment(date.toDate()).fromNow()
    },
    test(){
      let vm = this 
      vm.$store.dispatch('SearchByKey')
    }
  },
  destroyed(){
    let vm = this
    vm.$store.dispatch('GoUnsuscribeGetLogList')
  },
  computed: {
    ...mapGetters(["getLogList"]),
  },
  watch:{
    itemsPerPage:{
      handler(newValue){
        this.getFirstPage()
      }
    }
  }
};
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
</style>