<template>
  <v-container fluid fill-height>
    <v-row no-gutters justify="center" align="center">
      <v-col cols="10">
        <v-img class="mx-auto my-10" src="@/assets/logo.svg" width="220" contain></v-img>
      </v-col>
      <v-col cols="auto" sm="8" md="8" lg="4" class="px-3">
        <v-card class="rounded-lg" tile>
          <v-col cols="10">
            <v-card-text class="ml-5 text-uppercase text-h6 secondary--text font-weight-bold">Login</v-card-text>
            <v-card-subtitle
              class="ml-5 py-0 secondary--text font-weight-regular"
            >Welcome to maxpro dashboard, login to your account</v-card-subtitle>
          </v-col>
          <v-card-actions>
            <v-row justify="center" align="center">
            <v-form v-model="loginData.valid" ref="form">
            <v-col lg="12">
                <v-row justify="center" align="center">
              <v-col cols="10">
                <v-text-field label="Email" v-model="loginData.email" :rules="emailRules"></v-text-field>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  :append-icon="showEye ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showEye ? 'text' : 'password'"
                  name="password"
                  label="Password"
                  counter
                  v-model="loginData.password"
                  :rules="passwordRules"
                  @click:append="showEye = !showEye"
                  @keyup.enter="goLogin()"
                ></v-text-field>
                <v-spacer></v-spacer>
                <div class="d-flex justify-end mt-5">
                  <v-btn
                    small
                    raised
                    color="primary"
                    class="text--white"
                    :disabled="!loginData.valid"
                    @click="goLogin()"
                  >Login</v-btn>
                </div>
              </v-col>
                </v-row>
              </v-col>
              </v-form>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color">{{ snackbar.text }}</v-snackbar>
  </v-container>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";
export default {
  name: "Home",
  components: {},
  data: () => ({
    showEye: false,
    loginData:{
      email: "",
      password: "",
      valid:false
    },
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "red darken-2"
    },
    emailRules: [
      v => !!v || "E-Mail is required",
      v =>
        /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(v) || "Email invalid"
    ],
    passwordRules: [
      v => !!v || "Password is required",
      v => v.length > 5 || "The password must have at least 6 digits"
    ]
  }),
  methods: {
    goLogin() {
      let vm = this;
      let data = {
        email: vm.loginData.email,
        password: vm.loginData.password
      };
 
      vm.$emit("showOverlay", true);
      vm.$store
        .dispatch("Login", data)
        .then(res => {
          let userID = res.user.uid;
          vm.getAdminValid(userID);
        })
        .catch(err => {
          vm.snackbar.text = "Something went wrong";
          vm.snackbar.snackbar = true;
          // vm.$emit("showSnackBar", vm.snackbar);
          vm.$emit("showOverlay", false);
          vm.logout();
        });
    },
    getAdminValid(userID) {
      let vm = this;
      vm.$store
        .dispatch("GetAdmin", userID)
        .then(admin => {
          const canGetFeedbackNotifications = admin.adminLevel !== 3 ? admin.allowedRoutes.some(route => route === 'Feedback Detail') : true
          if(canGetFeedbackNotifications){
            if(!vm.isSubscribedFeedbackListForBadge){
              vm.$store.dispatch('GetFeedbackListWithoutCategoriePaginatedFirstPageForBadge', 10)
              .then((res) => {
               vm.$emit("showOverlay", false);
                  if(!vm.isSubscribedFeedbackNotifications){
                    vm.$store.dispatch('GetFeedbackNotifications')
                    vm.verifyPermisionsToChangeRoute();
                  }else{
                    vm.verifyPermisionsToChangeRoute();
                  }
                })
                .catch((errFeedback) => {
                  console.log(errFeedback);
                  vm.$emit("showOverlay", false);
                  vm.snackbar.text = "Error connecting with notifications";
                  vm.snackbar.color = "red darken-2";
                  vm.snackbar.snackbar = true;
                });
            }else{
              vm.$emit("showOverlay", false);
              vm.verifyPermisionsToChangeRoute();
            }
          }else{
            vm.$emit("showOverlay", false);
            vm.verifyPermisionsToChangeRoute();
          }
        })
        .catch(err => {
          vm.snackbar.text = "Admin Not Found";
          vm.snackbar.snackbar = true;
          // vm.$emit("showSnackBar", vm.snackbar);
          vm.$emit("showOverlay", false);
          // vm.$router.push("/");
          vm.logout();
        });
    },
    verifyPermisionsToChangeRoute(){
      let vm = this;
      if(vm.getAdmin.adminLevel === 3){
        vm.$router.push({ name: 'Dashboard'})
      }else{
        vm.$router.push({ name: 'User List'});
      }
    },
    logout() {
      let vm = this;
      vm.$emit("showOverlay", true);
      vm.$store.dispatch("Logout").then(() => {
        if(vm.isSubscribedFeedbackListForBadge){
          vm.$store.dispatch("GoUnsuscribeGetFeedbackListForBadge")
        }
        if(vm.isSubscribedFeedbackNotifications){
          vm.$store.dispatch("GoUnsuscribeGetFeedbackNotifications")
        }
        vm.$emit("showOverlay", false);
      });
    }
  },
  computed: {
    ...mapGetters(["getAdmin", "getFeedbackNotifications", "isSubscribedFeedbackListForBadge", "isSubscribedFeedbackNotifications"])
  },
  // destroyed(){
  //   let vm = this
  //   if(vm.isSubscribedFeedbackList){
  //     vm.$store.dispatch("GoUnsuscribeGetFeedbackList")
  //   }
  //   if(vm.isSubscribedFeedbackNotifications){
  //     vm.$store.dispatch("GoUnsuscribeGetFeedbackNotifications")
  //   }
  // }
};
</script>
<style lang="scss">
body {
  background-repeat: no-repeat !important;
  background-position: top center !important;
  background-size: cover !important;
}
</style>
