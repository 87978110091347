import firebase from "firebase";
import Vue from "vue";
import Axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, Axios);

const db = firebase.firestore();


Vue.use(VueAxios, Axios);

export default {
    state:{
        maxProDevicesList: []
    },
    mutations:{
        SET_MAX_PRO_DEVICES_LIST(state, maxProDevicesList){
            state.maxProDevicesList = maxProDevicesList
        }
    },
    actions:{
        GetUserMaxProDevices({ }, uid){
            return new Promise(async (resolve, reject) => {
                try {
                    const userReference = db.collection("users").doc(uid);
                    const reference = db.collection("deviceCableCalculator")
                    const querySnapshot = await reference.where('users', 'array-contains', userReference).get()
                    const maxProDevices = []
                    querySnapshot.forEach((doc) =>  {
                        maxProDevices.push(doc.data())
                    })
                    resolve(maxProDevices)
                } catch(error) {
                    reject(error)
                }
            })
        },
        GetMaxProDevicesList({ commit }){
            return new Promise(async (resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("deviceCableCalculator").get()
                    const maxProDevicesList = []
                    querySnapshot.forEach((doc) =>  {
                        maxProDevicesList.push(doc.data())
                    })
                    commit('SET_MAX_PRO_DEVICES_LIST', maxProDevicesList)
                    resolve(maxProDevicesList)
                } catch(error) {
                    reject(error)
                }
            })
        },
    },
    getters:{
        getAllMaxProDevices(state){
            return state.maxProDevicesList;
        }
    }
}