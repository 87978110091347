<template>
  <v-container fluid>
    <v-row justify="space-between" class="px-10">
      <v-col cols="12" sm="12" md="4">
        <p class="text-h6 text--secondary font-weight-bold">MAXPRO Devices</p>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          label="Search for a MAXPRO Device"
          v-model="search"
          single-line
          outlined
          rounded
          filled
          dense
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :search="search"
          :loading="loading"
          :headers="headers"
          :items="getAllMaxProDevices"
          fixed-header
          class="elevation-4"
        >
          <template v-slot:[`item.users`]="{ item }">
            <v-btn icon color="secondary" @click="showDeviceUsers(item)">
              <v-icon>mdi-account</v-icon>
              <v-badge 
                color="primary"
                :content="item.users && item.users.length ? item.users.length : 0"/>
            </v-btn>
         </template>
        </v-data-table>
      </v-col>
    </v-row>
      <v-dialog
        v-model="dialogUsersList.show"
        transition="dialog-bottom-transition"
        max-width="900"
        @click:outside="closeDeviceUsers()"
        @keydown.esc="closeDeviceUsers()"
      >
        <v-card class="pa-5">
          <p class="text-h6 text--secondary font-weight-bold">Users</p>
          <template v-if="dialogUsersList.users.length === 0 || dialogUsersList.loadingResets">
              <div class="d-flex justify-center align-center" style="width:100%; height:300px">
                <v-progress-circular
                    indeterminate
                    :size="60"
                    :width="8"
                    color="primary"
                ></v-progress-circular>                   
              </div>
          </template>
          <template v-else>
            <div class="d-flex justify-center flex-column">
              <template v-for="user in dialogUsersList.users">
                  <div 
                    class="d-flex align-center mb-2"
                    style="cursor:pointer;"
                    :key="user.uid"
                    @click="changeRouteUser(user)"
                    >
                        <v-avatar class="mr-2">
                            <img v-if="user.photoURL" :src="user.photoURL">
                            <img v-else-if="user.photo" :src="user.photo">
                            <img v-else :src="require('@/assets/default-avatar.png')">
                        </v-avatar>
                        <p class="mb-0 mr-1 font-weight-bold">{{ `${user.firstName} ${user.lastName}` }}</p>
                        <p class="mb-0 font-weight-thin font-italic">{{ user.uid }}</p>
                  </div>
              </template>
            </div>
          </template>
          <template v-if="dialogUsersList.resets && dialogUsersList.resets.length > 0 && !dialogUsersList.loadingResets">
               <p class="text-h6 text--secondary font-weight-bold">Resets</p>
               <v-data-table
                :loading="dialogUsersList.loadingResets"
                :headers="headersResets"
                :items="dialogUsersList.resets"
                :items-per-page="5"
                fixed-header
                class="elevation-4"
              >
              <template v-slot:[`item.resetAt`]="{ item }">
                <span>{{ parseToRelativeDate(item.resetAt) }}</span>
              </template>
              </v-data-table>
          </template>
        </v-card>
      </v-dialog>
       <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
        snackbar.text
      }}</v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "MaxProDevicesList",
  data: () => ({
    search: "",
    loading: false,
    headers: [
      { text: 'MAC', align:'center', value:'maxproMac', sortable:false },
      { text: 'Left Used', align:'center', value:'counterL', sortable:true },
      { text: 'Right Used', align:'center', value:'counterR', sortable:true },
      { text: "Users", align: "center", value: "users", sortable: false },
    ],
    headersResets: [
      { text: 'First Name', align:'center', value:'payloadUser.firstName', sortable:true },
      { text: 'Last Name', align:'center', value:'payloadUser.lastName', sortable:true },
      { text: 'Left Old Counter', align:'center', value:'counterL', sortable:true },
      { text: 'Right Old Counter', align:'center', value:'counterR', sortable:true },
      { text: 'Reset Date', align:'center', value:'resetAt', sortable:true },
    ],
    dialogUsersList:{
        show: false,
        loading: false,
        loadingResets:false,
        users:[],
        resets: []
    },
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
  }),
  created(){
      let vm = this
      vm.getMaxProDevices()
  },
  methods: {
    async getMaxProDevices(){
     const vm = this 
      try {
          vm.loading = true
          await vm.$store.dispatch("GetMaxProDevicesList")
          vm.loading = false
      } catch (error) {
          vm.loading = false
          console.log(error);
      }
    },
    async showDeviceUsers(item){
        const vm = this
        vm.dialogUsersList.show = true
        vm.dialogUsersList.loading = true
       await vm.getUsersDevices(item.users)
       if(item.resets && item.resets.length > 0){
         await vm.getResets(item.resets)
       }
    },
    async getUsersDevices(users){
     const vm = this 
      try {
        users.forEach(async (ref) =>{
           const user = await ref.get()
           vm.dialogUsersList.users.push(user.data())
        })
        vm.dialogUsersList.loading = false
      } catch (error) {
        vm.dialogUsersList.loading = false
        console.log(error);
      }
    },
    async getResets(resets){
     const vm = this 
      try {
        vm.dialogUsersList.loadingResets = true
        for (let i = 0; i < resets.length; i++) {
          const reset = resets[i]
          const user = await reset.user.get()
          const payloadUser = user.data()
          reset.payloadUser = payloadUser
          vm.dialogUsersList.resets.push(reset)
        }
        vm.dialogUsersList.loadingResets = false
      } catch (error) {
        vm.dialogUsersList.loadingResets = false
        console.log(error);
      }
    },
    closeDeviceUsers(){
        const vm = this 
        vm.dialogUsersList = {
            show: false,
            loading: false,
            users:[],
            resets: []
        }

    },
    parseToRelativeDate(date){
     return moment(date.toDate()).fromNow()
    },
    changeRouteUser(item){
      let vm = this
      vm.$router.push({ name: 'User Profile', params: item })
      .catch((err) => {
        vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      })
    },
  },
  computed:{
      ...mapGetters(["getAllMaxProDevices"])
  }
};
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
</style>