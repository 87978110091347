import firebase from "firebase";
import Vue from "vue";
import Axios from "axios";
import VueAxios from "vue-axios";
import { v4 as uuidv4 } from "uuid";

Vue.use(VueAxios, Axios);

const db = firebase.firestore();

const storageImages = firebase.storage().ref("achievementsIcons/");

Vue.use(VueAxios, Axios);

export default {
    state:{
        achievementsList:[],
        loadingStatusAchievementIcon:0,
        typesAchievementsList:[],
        categoriesAchievementsList:[],
        intervalsAchievementsList:[]
    },
    mutations:{
        SET_ACHIEVEMENTS_LIST(state, achievementsList) {
            state.achievementsList = achievementsList
        },
        SET_VALUE_LOADING_STATUS_ACHIEVEMENT_IMAGE(state, progress) {
            state.loadingStatusAchievementIcon = progress
        },
        SET_TYPES_ACHIEVEMENTS_LIST(state, typesAchievementsList){
            state.typesAchievementsList = typesAchievementsList
        },
        SET_CATEGORIES_ACHIEVEMENTS_LIST(state, categoriesAchievementsList){
            state.categoriesAchievementsList = categoriesAchievementsList
        },
        SET_INTERVALS_ACHIEVEMENTS_LIST(state, intervalsAchievementsList){
            state.intervalsAchievementsList = intervalsAchievementsList
        },
    },
    actions: {
        GetAchievementsList({ commit }) {
            return new Promise((resolve, reject) => {
                db.collection("achievementsList").get()
                    .then((querySnapshot) => {
                        var achievementsList = [];
                        querySnapshot.forEach((doc) => {
                            let achievement = doc.data();
                            achievement.id = doc.id;
                            achievementsList.push(achievement);
                        });
                        commit("SET_ACHIEVEMENTS_LIST", achievementsList);
                        resolve(achievementsList);
                    }).catch((err) => {
                        reject(err)
                    })
            });
        },
        GetAchievement({ }, achievement){
            return new Promise(async (resolve, reject) => {
                try {
                    let resAchievement = await db.collection("achievementsList").doc(achievement.id).get()
                    let gettedAchievement = resAchievement.data()

                    if(gettedAchievement.goalsReferences && gettedAchievement.goalsReferences.type !== ""){
                        let type = await gettedAchievement.goalsReferences.type.get()
                        gettedAchievement.type = type.data()
                    }
                    if(gettedAchievement.goalsReferences && gettedAchievement.goalsReferences.category !== ""){
                        let category = await gettedAchievement.goalsReferences.category.get()
                        gettedAchievement.category = category.data()
                    }
                    if(gettedAchievement.goalsReferences && gettedAchievement.goalsReferences.interval !== ""){
                        let interval = await gettedAchievement.goalsReferences.interval.get()
                        gettedAchievement.interval = interval.data()
                    }
                    if(gettedAchievement.goalsReferences && gettedAchievement.goalsReferences.specificWorkout &&  gettedAchievement.goalsReferences.specificWorkout.isReferenced){
                        let specificWorkout = await gettedAchievement.goalsReferences.specificWorkout.ref.get()
                        gettedAchievement.goalsReferences.specificWorkout.ref = specificWorkout.data()
                    }

                    resolve(gettedAchievement)
                } catch(error) {
                    reject(error)
                }
            })
        },
        DeleteAchievement({}, achievement) {
            return new Promise(async(resolve, reject) => {
                let storageRefImage = storageImages.child(achievement.icon.ref);
                await storageRefImage.delete()
                    .then(async() => {
                        await db.collection("achievementsList")
                            .doc(achievement.id)
                            .delete()
                            .then((res) => {
                                resolve(achievement.id);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    }).catch((errorImg) => {
                        console.log('errorImg deleting storage', errorImg);
                        // Uh-oh, an error occurred!
                    });
                   
            });
        },
        UploadImageAchievement({ commit }, achievement) {
            return new Promise((resolve, reject) => {
                let random = uuidv4();
                let storageRef = storageImages.child(random);
                console.log('storageRef', storageRef.name);
                let uploadTask = storageRef.putString(achievement.icon.img, "data_url")
                uploadTask.on('state_changed', (listener) => {
                    let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
                    commit('SET_VALUE_LOADING_STATUS_ACHIEVEMENT_IMAGE', progress);
                }, (error) => {
                    console.log('error', error);
                    reject(error)
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        resolve({ link: downloadURL, ref: storageRef.name });
                    })
                })
            });
        },
        CreateAchievement({}, achievement) {
            return new Promise(async(resolve, reject) => {
                delete achievement.icon.img
                const ref = db.collection("achievementsList").doc();
                const newAchievement = await ref.get();
                achievement.id = ref.id;
                achievement.createdAt = new Date()
                achievement.updatedAt = new Date()
                if(achievement.goalsReferences.type !== ""){
                    achievement.goalsReferences.type = db.collection("typesAchievementsList").doc(achievement.goalsReferences.type.id);
                }
                if(achievement.goalsReferences.category !== ""){
                    achievement.goalsReferences.category = db.collection("categoriesAchievementsList").doc(achievement.goalsReferences.category.id);
                }
                if(achievement.goalsReferences.interval !== ""){
                    achievement.goalsReferences.interval = db.collection("intervalsAchievementsList").doc(achievement.goalsReferences.interval.id);
                }
                if(achievement.goalsReferences && achievement.goalsReferences.specificWorkout && achievement.goalsReferences.specificWorkout.isReferenced){
                    achievement.goalsReferences.specificWorkout.ref = db.collection(achievement.goalsReferences.specificWorkout.collection).doc(achievement.goalsReferences.specificWorkout.ref.id);
                }

                await db.collection("achievementsList")
                    .doc(newAchievement.id)
                    .set(achievement)
                    .then(async(res) => {
                        resolve(newAchievement.id);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            })
        },
        UpdateAchievement({}, achievement) {
            return new Promise(async(resolve, reject) => {
                if(achievement.createdAt) {
                    delete achievement.createdAt
                }
                achievement.updatedAt = new Date()
                if (achievement.icon.img) {
                    delete achievement.icon.img
                }
                if(achievement.goalsReferences && achievement.goalsReferences.type !== ""){
                    achievement.goalsReferences.type = db.collection("typesAchievementsList").doc(achievement.goalsReferences.type.id);
                }
                if(achievement.goalsReferences && achievement.goalsReferences.category !== ""){
                    achievement.goalsReferences.category = db.collection("categoriesAchievementsList").doc(achievement.goalsReferences.category.id);
                }
                if(achievement.goalsReferences && achievement.goalsReferences.interval !== ""){
                    achievement.goalsReferences.interval = db.collection("intervalsAchievementsList").doc(achievement.goalsReferences.interval.id);
                }
                console.log(achievement);
                if(achievement.goalsReferences && achievement.goalsReferences.specificWorkout && achievement.goalsReferences.specificWorkout.isReferenced){
                    achievement.goalsReferences.specificWorkout.ref = db.collection(achievement.goalsReferences.specificWorkout.collection).doc(achievement.goalsReferences.specificWorkout.ref.id);
                }

                await db.collection("achievementsList")
                    .doc(achievement.id)
                    .update(achievement)
                    .then((res) => {
                        resolve(achievement.id);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },

        //Types Achievemenets
        GetTypesAchievementsList({ commit }){
            return new Promise(async (resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("typesAchievementsList").get()
                    const typesAchievementsList = []
                    querySnapshot.forEach((doc) =>  {
                        typesAchievementsList.push(doc.data())
                    })
                    commit('SET_TYPES_ACHIEVEMENTS_LIST', typesAchievementsList)
                    resolve(typesAchievementsList)
                } catch(error) {
                    reject(error)
                }
            })
        },
        CreateTypeAchievement({}, typeAchievement) {
            return new Promise(async(resolve, reject) => {
                try {
                    const ref = db.collection("typesAchievementsList").doc();
                    const newTypeAchievement = await ref.get();
                    typeAchievement.id = ref.id;
                    await db.collection("typesAchievementsList").doc(newTypeAchievement.id).set(typeAchievement)
                    resolve(newTypeAchievement.id);
                } catch (error) {
                    reject(err);
                }
            })
        },
        UpdateTypeAchievement({}, type) {
            return new Promise(async(resolve, reject) => {
                try {
                    await db.collection("typesAchievementsList").doc(type.id).update(type)
                    resolve(type.id);
                } catch (error) {
                    reject(error);
                }
            });
        },
        DeleteTypeAchievement({}, type) {
            return new Promise(async(resolve, reject) => {
                try {
                    await db.collection("typesAchievementsList").doc(type.id).delete()
                    resolve(type.id)
                } catch (error) {
                    reject(error);
                }
            });
        },
        //Categories Achievemenets
        GetCategoriesAchievementsList({ commit }){
            return new Promise(async (resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("categoriesAchievementsList").get()
                    const categoriesAchievementsList = []
                    querySnapshot.forEach((doc) =>  {
                        categoriesAchievementsList.push(doc.data())
                    })
                    commit('SET_CATEGORIES_ACHIEVEMENTS_LIST', categoriesAchievementsList)
                    resolve(categoriesAchievementsList)
                } catch(error) {
                    reject(error)
                }
            })
        },
        CreateCategoryAchievement({}, categoryAchievement) {
            return new Promise(async(resolve, reject) => {
                try {
                    const ref = db.collection("categoriesAchievementsList").doc();
                    const newCategoryAchievement = await ref.get();
                    categoryAchievement.id = ref.id;
                    await db.collection("categoriesAchievementsList").doc(newCategoryAchievement.id).set(categoryAchievement)
                    resolve(newCategoryAchievement.id);
                } catch (error) {
                    reject(err);
                }
            })
        },
        UpdateCategoryAchievement({}, category) {
            return new Promise(async(resolve, reject) => {
                try {
                    await db.collection("categoriesAchievementsList").doc(category.id).update(category)
                    resolve(category.id);
                } catch (error) {
                    reject(error);
                }
            });
        },
        DeleteCategoryAchievement({}, category) {
            return new Promise(async(resolve, reject) => {
                try {
                    await db.collection("categoriesAchievementsList").doc(category.id).delete()
                    resolve(category.id)
                } catch (error) {
                    reject(error);
                }
            });
        },
        //Intervals Achievemenets
        GetIntervalsAchievementsList({ commit }){
            return new Promise(async (resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("intervalsAchievementsList").get()
                    const intervalsAchievementsList = []
                    querySnapshot.forEach((doc) =>  {
                        intervalsAchievementsList.push(doc.data())
                    })
                    commit('SET_INTERVALS_ACHIEVEMENTS_LIST', intervalsAchievementsList)
                    resolve(intervalsAchievementsList)
                } catch(error) {
                    reject(error)
                }
            })
        },
        CreateIntervalAchievement({}, intervalAchievement) {
            return new Promise(async(resolve, reject) => {
                try {
                    const ref = db.collection("intervalsAchievementsList").doc();
                    const newIntervalAchievement = await ref.get();
                    intervalAchievement.id = ref.id;
                    await db.collection("intervalsAchievementsList").doc(newIntervalAchievement.id).set(intervalAchievement)
                    resolve(newIntervalAchievement.id);
                } catch (error) {
                    reject(err);
                }
            })
        },
        UpdateIntervalAchievement({}, interval) {
            return new Promise(async(resolve, reject) => {
                try {
                    await db.collection("intervalsAchievementsList").doc(interval.id).update(interval)
                    resolve(interval.id);
                } catch (error) {
                    reject(error);
                }
            });
        },
        DeleteIntervalAchievement({}, interval) {
            return new Promise(async(resolve, reject) => {
                try {
                    await db.collection("intervalsAchievementsList").doc(interval.id).delete()
                    resolve(interval.id)
                } catch (error) {
                    reject(error);
                }
            });
        },
        GetUserAchievements({ commit }, uid){
            return new Promise(async (resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("users").doc(uid).collection('achievementsUnlocked').orderBy('createdAt', 'desc').get()
                    const userAchievements = []
                    if(!querySnapshot.empty){
                        console.log(querySnapshot);
                        let achievementsGetted = querySnapshot.docs
                        for (let i = 0; i < achievementsGetted.length; i++) {
                            let achievement = achievementsGetted[i].data();
                            achievement.ref = await (await achievement.ref.get()).data()
                            userAchievements.push(achievement)
                        }
                    }
                    resolve(userAchievements)
                } catch(error) {
                    reject(error)
                }
            })
        }
    },
    getters:{
        getAchievementsList(state){
            return state.achievementsList;
        },
        getLoadingStatusAchievementIcon(state){
            return state.loadingStatusAchievementIcon
        },
        getTypesAchievementsList(state){
            return state.typesAchievementsList;
        },
        getCategoriesAchievementsList(state){
            return state.categoriesAchievementsList;
        },
        getIntervalsAchievementsList(state){
            return state.intervalsAchievementsList;
        },
    },
}