<template>
  <v-container fluid>
    <v-row justify="space-between" class="px-10">
      <v-col cols="12" sm="12" md="2">
        <p class="text-h6 text--secondary font-weight-bold">Articles List</p>
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <v-btn text color="secondary" @click="categoriesArticles()">
          <v-icon left dark color="primary"> mdi-format-list-bulleted </v-icon>
          Categories Articles
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <v-btn text color="secondary" @click="createArticle()">
          <v-icon left dark color="primary"> mdi-plus-circle </v-icon>
          New Article
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field label="Search for a Article" v-model="search" single-line outlined rounded filled dense
          append-icon="mdi-magnify"></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-data-table :search="search" :loading="loading" :headers="headers" :items="getArticlesList" item-key="id"
          fixed-header class="elevation-4" :page.sync="page" @update:page="onPageChange"
          :items-per-page.sync="itemsPerPage" @update:items-per-page="onItemsPerPageUpdate" :sortBy.sync="sortBy"
          @update:sortBy="onSortByChange">
          <template v-slot:[`item.image`]="{ item }">
            <img v-if="item.image.link" class="protrain-image-preview ma-1" :src="item.image.link">
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ timestampToStringDate(item.createdAt) }}
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
            {{ timestampToStringDate(item.updatedAt) }}
          </template>
          <template v-slot:[`item.enabled`]="{ item }">
            <v-switch v-if="item" class="center-switch" color="primary" :input-value="item.enabled" readonly
              flat></v-switch>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn icon color="primary" @click="changeRouteArticles(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon color="red" class="mr-3" @click="openDialogConfirm(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog.show" width="300" class="rounded-xl">
      <v-card class="text-center">
        <v-icon class="text-center ma-2" size="120" color="primary">mdi-alert</v-icon>
        <v-card-text class="pa-4 text-center">This action is irreversible and will delete all data about this
          Article. Including the image, data, references and information</v-card-text>
        <v-card-text class="text-center">Are you sure?</v-card-text>

        <v-btn color="third" tile width="150" class="pa-0 text--secondary" @click="closeDialogConfirm()">No</v-btn>

        <v-btn color="primary" tile width="150" class="pa-0"
          @click="openDialogReAuth('deleteArticle', dialog.item)">Yes</v-btn>
        <!-- </v-card-actions> -->
      </v-card>
    </v-dialog>
    <modal-reauth :dialogReAuth="dialogReAuth" @isSuccessReAuth="isSuccessReAuth"></modal-reauth>
    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
      snackbar.text
    }}</v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import modalReauth from "@/components/admin/modal-reauth.vue";
import moment from "moment";
export default {
  name: "ArticlesList",
  components: { modalReauth },
  data: () => ({
    search: "",
    loading: false,
    headers: [
      { text: '', align: 'center', value: 'image', sortable: false },
      { text: "Img size", align: "center", value: "image.size", sortable: false },
      { text: 'Title', align: 'center', value: 'title.ENG', sortable: true },
      { text: 'Category', align: 'center', value: 'category.title.ENG', sortable: true },
      { text: 'Creation Date', align: 'center', value: 'createdAt', sortable: true },
      { text: 'Last Update', align: 'center', value: 'updatedAt', sortable: true },
      { text: 'Enabled', align: 'center', value: 'enabled', sortable: true },
      { text: "Action", align: "center", value: "action", sortable: false },
    ],
    dialogReAuth: {
      show: false,
      action: "",
      item: {},
    },
    dialog: {
      show: false,
      item: {},
    },
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
    page: 0,
    itemsPerPage: 10,
    sortBy: "name.ENG"
  }),
  created() {
    let vm = this
    vm.getAllArticles();
    if (this.$route.params.previousRoute === 'Articles Edit') {
      const tableState = localStorage.getItem('articleTableState');
      if (tableState) {
        const tableStateObj = JSON.parse(tableState);
        this.search = tableStateObj.query;
        this.itemsPerPage = tableStateObj.hitsPerPage;
        this.page = tableStateObj.page;
      }
    }
  },
  methods: {
    async getAllArticles() {
      const vm = this
      try {
        vm.loading = true
        await vm.$store.dispatch("GetArticlesList")
        vm.loading = false
      } catch (error) {
        vm.loading = false
        console.log(error);
      }
    },
    parseToRelativeDate(date) {
      return moment(date.toDate()).fromNow()
    },
    openDialogConfirm(item) {
      const vm = this;
      vm.dialog.show = true;
      vm.dialog.item = item;
    },
    closeDialogConfirm() {
      const vm = this;
      vm.dialog.show = false;
      vm.dialog.item = "";
    },
    openDialogReAuth(action, item) {
      let vm = this;
      vm.dialogReAuth.show = true;
      vm.dialogReAuth.action = action;
      vm.dialogReAuth.item = item;
      vm.closeDialogConfirm();
    },
    isSuccessReAuth(dialogReAuthResponse) {
      const vm = this;
      switch (dialogReAuthResponse.action) {
        case "deleteArticle":
          vm.deleteArticle(dialogReAuthResponse.item);
          break;
        default:
          break;
      }
    },
    async deleteArticle(article) {
      const vm = this;
      try {
        vm.$emit("showOverlay", true);
        await vm.$store.dispatch("DeleteArticle", article)
        await vm.$store.dispatch("GenerateLog", {
          id: article.id,
          title: article.title['ENG'],
          type: "deletedArticle",
        });
        vm.getAllArticles()
        vm.$emit("showOverlay", false);

      } catch (error) {
        vm.$emit("showOverlay", false);
        console.error(error);
        let emitData = {
          snackbar: true,
          btn: "",
          y: "bottom",
          x: null,
          mode: "",
          timeout: 2000,
          text: "Error deleting Article",
          color: "red",
        };
        vm.$emit("showSnackBar", emitData);
      }
    },
    timestampToStringDate(timestamp) {
      const dateParsed = new Date(timestamp.seconds * 1000)
      return moment(dateParsed).format('MMMM Do YYYY, h:mm:ss a')
    },
    changeRouteArticles(article) {
      let vm = this;
      vm.$router.push({ name: "Articles Edit", params: article })
        .catch((err) => {
          vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
          vm.snackbar.color = "red darken-2";
          vm.snackbar.snackbar = true;
        })
    },
    createArticle() {
      let vm = this;
      vm.$router.push({ name: "Articles Create" }).catch((err) => {
        vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      });
    },
    categoriesArticles() {
      let vm = this;
      vm.$router.push({ name: "Articles Categories" }).catch((err) => {
        vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      });
    },
    onPageChange(page) {
      console.log(page);
    },
    onItemsPerPageUpdate(items) {
      console.log(items);
    },
    onSortByChange(sortBy, test) {
      console.log(sortBy, test);
    }
  },
  computed: {
    ...mapGetters(["getArticlesList"])
  },
  beforeRouteLeave(to, from, next) {
    console.log(to);
    if (to.name === 'Articles Edit') {
      const currentState = JSON.stringify({ hitsPerPage: this.itemsPerPage, query: this.search, page: this.page });
      localStorage.setItem('articleTableState', currentState);
    } else {
      localStorage.removeItem('articleTableState');
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === 'Articles Edit') {
      to.params.previousRoute = from.name;
    }
    next();
  }
};
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}

.protrain-image-preview {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  vertical-align: middle;
}
</style>