<template>
  <v-container
    class="elevation-4 rounded-lg px-8 mb-5"
    style="background-color: #fff"
  >
    <v-form ref="formBackgroundAppImage" v-model="validBackgroundAppImage">
      <v-row justify="center">
        <v-col cols="12" sm="12">
          <v-btn
            color="primary"
            icon
            size="20"
            class="text-center"
            @click="$router.go(-1)"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <h3 class="secondary--text d-inline mr-2">Edit Background</h3>
        </v-col>
        <v-col cols="12" sm="12" md="4" class="mx-auto text-left">
            <p class="secondary--text mt-5 text-left d-inline">Image</p>
            <v-card
            color="#efefef"
            class="rounded-lg elevation-0 d-flex mt-5 justify-center align-center"
            min-width="250"
            min-height="500"
            @click="$refs.refBackgroundAppImage.$refs.input.click()"
            >
            <v-img :src="backgroundAppImage.image.link ? backgroundAppImage.image.link : backgroundAppImage.image.img" cover width="250" class="rounded-lg" height="500">
            </v-img>
            
                <v-icon large color="primary" style="position: absolute; transform: translate(50%,-50%); top: 50%; right: 50%;"> mdi-camera </v-icon>

            </v-card>
        </v-col>
          <v-col cols="12" sm="12" md="4" class="mx-auto">
            <p class="secondary--text pb-1">Name</p>
            <v-text-field
                v-model="backgroundAppImage.name"
                :rules="backgroundAppImageRules.name"
                solo
                flat
                background-color="#efefef"
            ></v-text-field>
             <p class="secondary--text mt-3 pb-1 text-center">Days Active</p>
             <div class="d-flex justify-center">
               <v-date-picker
                v-model="backgroundAppImage.daysActive"
                color="primary"
                elevation="3"
                class="text-center"
                no-title
                range
                ></v-date-picker>
             </div>
             <p class="secondary--text mt-3 pb-1 text-center">Enabled</p>
            <v-switch
                color="primary"
                class="d-flex justify-center center-switch"
                v-model="backgroundAppImage.enabled"
                :input-value="backgroundAppImage.enabled"
                flat
            ></v-switch>
            <div class="mx-auto text-center" style="width:150px">
                <p class="secondary--text text-center pb-1">Order number</p>
                <v-text-field
                    v-model.number="backgroundAppImage.orderNumber"
                    :rules="backgroundAppImageRules.orderNumber"
                    @keypress="numberValid($event)"
                    maxlength="4"
                    class="centered-input"
                    solo
                    flat
                    background-color="#efefef"
                ></v-text-field>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="primary" class="float-right" :disabled="!validBackgroundAppImage || !backgroundAppImage.image || backgroundAppImage.daysActive.length <= 1 " @click="uploadBackgroundAppImage()">Finish</v-btn>
         </v-col>
      </v-row>
    </v-form>
    <v-dialog
      v-model="dialogUploadImage"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Uploading image, please wait...
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="getLoadingStatusBackgroundAppImage"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
        snackbar.text
      }}</v-snackbar>
     <v-file-input
      ref="refBackgroundAppImage"
      class="d-none"
      color="white"
      accept="image/png, image/jpeg"
      dark
      outlined
      :show-size="1000"
      @change="getBackgroundAppImage"
    />
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import modalReauth from "@/components/admin/modal-reauth.vue";
export default {
  name:"BackgroundAppImageEdit",
  components: { modalReauth },
  data: () => ({
      validBackgroundAppImage:false,
      backgroundAppImage:{
          name:'',
          enabled:true,
          image:{
              img:null,
              link:'',
              ref:'',
              size:'',
              type:''
          },
          orderNumber:'',
          daysActive:[]
      },
      snackbar: {
        snackbar: false,
        timeout: 2000,
        text: null,
        color: "",
      },
      backgroundAppImageRules:{
        name: [v => !!v || 'Name is required',  v => (v && v.length >= 4) || 'Name must be greater than 4'],
      },
      dialogUploadImage:false,

  }),
  created() {
      let vm = this
      vm.backgroundAppImage = vm.$route.params
  },
  methods:{
    numberValid(e){
        e = (e) || window.event
        const charCode = (e.which) ? e.which : e.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault()
        } else {
            return true
        }
    },
    getFileSize(bytes, dp = 1) {
      const thresh = 1024;

      if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
      }
        //si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']  : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']; // original
      const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      let u = -1;
      const r = 10**dp;

      do {
        bytes /= thresh;
        ++u;
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

      return bytes.toFixed(dp) + ' ' + units[u];
    },
    getBackgroundAppImage(file){
        let vm = this
        if(file){
        if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
          const fr = new FileReader();
          fr.onload = (el) => {
            vm.backgroundAppImage.image = {
              img: el.target.result,
              file: file.type.split("/")[1],
              size:vm.getFileSize(file.size, 2),
              link:""
            }
          };
          fr.readAsDataURL(file);
        }else{
           let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Only .png and .jpeg files are accepted",
              color: "red",
            };
          vm.$emit("showSnackBar", emitData);
        }
      }
    },
    uploadBackgroundAppImage(){
      let vm = this;
      if(vm.backgroundAppImage.image.img && vm.backgroundAppImage.image.link == ""){
      vm.dialogUploadImage = true
       vm.$store
        .dispatch("UploadBackgroundAppImage", vm.backgroundAppImage)
        .then((storage) => {
          vm.backgroundAppImage.image.link = storage.link
          vm.backgroundAppImage.image.ref = storage.ref
          vm.editBackgroundAppImage()
        }).catch((err) => {
            console.log(err);
          vm.dialogUploadImage = false

           let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Error uploading image",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);
        })
      }else{
          vm.editBackgroundAppImage()
      }
    },
    editBackgroundAppImage(){
        let vm = this;
        vm.$store.dispatch('UpdateBackgroundAppImage', vm.backgroundAppImage)
            .then((id) => {
            vm.$store.dispatch("GenerateLog", {
                id: id,
                title: vm.backgroundAppImage.name,
                type: "editedBackgroundAppImage",
            })
            vm.dialogUploadImage= false

            let emitData = {
                snackbar: true,
                btn: "",
                y: "bottom",
                x: null,
                mode: "",
                timeout: 2000,
                text: "Background App Image Edited successfully",
                color: "success",
            };
            vm.$router.push("/admin/app-config/bg-app")
            .catch((err) => {
                vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
                vm.snackbar.color = "red darken-2";
                vm.snackbar.snackbar = true;
            })
            vm.$emit("showSnackBar", emitData);
            
            }).catch((err)=>{
            vm.dialogUploadVideo = false

            let emitData = {
                snackbar: true,
                btn: "",
                y: "bottom",
                x: null,
                mode: "",
                timeout: 2000,
                text: "Error editing Background App",
                color: "red",
            };
            vm.$emit("showSnackBar", emitData);

            })
    }
  },
  computed:{
      ...mapGetters(["getLoadingStatusBackgroundAppImage"])
  }
};
</script>