<template>
  <v-container fluid fill-height>
    <v-row no-gutters justify="center" align="center">
      <v-col cols="10">
        <v-img class="mx-auto my-10" src="@/assets/logo.svg" width="220" contain></v-img>
      </v-col>
      <v-col cols="auto" sm="8" md="8" lg="4" class="px-3">
        <v-card class="rounded-lg" tile>
          <v-col cols="12">
            <v-card-text class="text-center text-uppercase text-h6 secondary--text font-weight-bold">Login</v-card-text>
            <v-card-subtitle class="py-0 text-center secondary--text font-weight-regular">Welcome to maxpro, enter your
              credential for account deleting</v-card-subtitle>
          </v-col>
          <v-card-actions>
            <v-row justify="center" align="center">
              <v-form v-model="loginData.valid" ref="form">
                <v-col lg="12">
                  <v-row justify="center" align="center">
                    <v-col cols="12">
                      <v-text-field label="Email" v-model="loginData.email" :rules="emailRules"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field :append-icon="showEye ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showEye ? 'text' : 'password'" name="password" label="Password" counter
                        v-model="loginData.password" :rules="passwordRules" @click:append="showEye = !showEye"
                        @keyup.enter="goLogin()"></v-text-field>
                      <v-spacer></v-spacer>
                      <div class="d-flex justify-end mt-5">
                        <v-btn small raised color="primary" class="text--white" :disabled="!loginData.valid"
                          @click="goLogin()">Login</v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-form>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="showDialog" width="300" class="rounded-xl">
      <v-card class="text-center">
        <v-icon class="text-center ma-2" size="120" color="primary">mdi-alert</v-icon>
        <v-card-text class="pa-4 text-center">This action is irreversible and will delete all data</v-card-text>
        <v-card-text class="text-center">Are you sure?</v-card-text>
        <v-btn color="third" tile width="150" class="pa-0 text--secondary" @click="closeDialogConfirm()">No</v-btn>
        <v-btn color="primary" tile width="150" class="pa-0" @click="deleteAccount()">Yes</v-btn>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color">{{ snackbar.text }}</v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "DeleteAccount",
  components: {},
  data: () => ({
    showEye: false,
    loginData: {
      email: "",
      password: "",
      valid: false
    },
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "red darken-2"
    },
    emailRules: [
      v => !!v || "E-Mail is required",
      v =>
        /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(v) || "Email invalid"
    ],
    passwordRules: [
      v => !!v || "Password is required",
      v => v.length > 5 || "The password must have at least 6 digits"
    ],
    userId: '',
    showDialog: false,
  }),
  methods: {
    logout() {
      let vm = this;
      vm.$emit("showOverlay", true);
      vm.$store.dispatch("Logout").then(() => {
        vm.$emit("showOverlay", false);
      });
    },
    deleteAccount() {
      let vm = this;
      vm.showDialog = false;
      vm.$store
        .dispatch("DeleteAccount", vm.userId)
        .then(res => {
          console.log(res);
          if (res.error === 'ACTIVE_SUBSCRIPTION') {
            vm.snackbar.text = "You have an active subscription, please cancel it before delete your account.";
            vm.snackbar.color = "red darken-2";
            vm.snackbar.snackbar = true;
          } else if (res.success === true) {
            vm.snackbar.text = "Account deleted.";
            vm.snackbar.color = "success";
            vm.snackbar.snackbar = true;
          } else {
            vm.snackbar.text = "Error deleting your account. Please try again or contact customer service.";
            vm.snackbar.color = "red darken-2";
            vm.snackbar.snackbar = true;
          }

          vm.logout();
        })
        .catch(err => {
          vm.snackbar.text = "Something went wrong";
          vm.snackbar.snackbar = true;
          // vm.$emit("showSnackBar", vm.snackbar);
          vm.$emit("showOverlay", false);
          vm.logout();
        });
    },
    goLogin() {
      let vm = this;
      let data = {
        email: vm.loginData.email,
        password: vm.loginData.password
      };

      vm.$emit("showOverlay", true);

      vm.$store
        .dispatch("Login", data)
        .then(res => {
          let userID = res.user.uid;
          vm.userId = userID;
          vm.showDialog = true;
          // vm.getAdminValid(userID);
        })
        .catch(err => {
          console.log(err);
          vm.snackbar.text = "Something went wrong";
          vm.snackbar.snackbar = true;
          // vm.$emit("showSnackBar", vm.snackbar);
          vm.$emit("showOverlay", false);
          vm.logout();
        });
    }
  }
};
</script>

<style lang="scss">
body {
  background-repeat: no-repeat !important;
  background-position: top center !important;
  background-size: cover !important;
}
</style>
