import firebase from "firebase";
import { v4 as uuidv4 } from "uuid";

const db = firebase.firestore();

const storage = firebase.storage().ref("tutorialsVideos/");
const storageImages = firebase.storage().ref("tutorialsImages/");

export default {
    state: {
        tutorialsList: [],
        loadingStatusTutorialVideo: 0,
        loadingStatusTutorialImage: 0,
        categoriesTutorialsList: []
    },
    mutations: {
        SET_TUTORIALS_LIST(state, tutorialsList) {
            state.tutorialsList = tutorialsList
        },
        SET_VALUE_LOADING_STATUS_TUTORIAL_VIDEO(state, progress) {
            state.loadingStatusTutorialVideo = progress
        },
        SET_VALUE_LOADING_STATUS_TUTORIAL_IMAGE(state, progress) {
            state.loadingStatusTutorialImage = progress
        },
        DELETE_TUTORIAL_FROM_ARRAY(state, tutorial) {
            const index = state.tutorialsList.indexOf(tutorial);
            if (index > -1) {
                state.tutorialsList.splice(index, 1);
            }
        },
        SET_CATEGORIES_TUTORIALS_LIST(state, categoriesTutorialsList){
            state.categoriesTutorialsList = categoriesTutorialsList
        },
    },
    actions: {
        GetTutorialsList({ commit }) {
            return new Promise((resolve, reject) => {
                db.collection("tutorialsList").get()
                    .then(async(querySnapshot) => {
                        var tutorialsList = [];
                        querySnapshot.forEach(async(doc) => {
                            let tutorial = doc.data();
                            tutorial.id = doc.id;
                            let category = await tutorial.category.get()
                            tutorial.category = category.data()
                            tutorialsList.push(tutorial);
                        });
                        commit("SET_TUTORIALS_LIST", tutorialsList);
                        resolve(tutorialsList);
                    }).catch((err) => {
                        reject(err)
                    })
            });
        },
        GetTutorial({ }, tutorial){
            return new Promise(async (resolve, reject) => {
                try {
                    let resTutorial = await db.collection("tutorialsList").doc(tutorial.id).get()
                    let gettedTutorial = resTutorial.data()
                    let category = await gettedTutorial.category.get()
                    gettedTutorial.category = category.data()
                    resolve(gettedTutorial)
                } catch(error) {
                    reject(error)
                }
            })
        },
        DeleteTutorial({}, tutorial) {
            return new Promise(async(resolve, reject) => {
                await db.collection("tutorialsList")
                    .doc(tutorial.id)
                    .delete()
                    .then((res) => {
                        resolve(tutorial.id);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        UploadVideoTutorial({ commit }, tutorial) {
            return new Promise((resolve, reject) => {
                let random = uuidv4();
                let storageRef = storage.child(random);
                let bytes = new Blob([new Uint8Array(tutorial.video.blob)]);
                console.log('storageRef', storageRef.name);
                let metadata = {
                    contentType: 'video/mp4'
                }
                let uploadTask = storageRef.put(bytes, metadata)
                uploadTask.on('state_changed', (listener) => {
                    let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
                    commit('SET_VALUE_LOADING_STATUS_TUTORIAL_VIDEO', progress);
                }, (error) => {
                    console.log('error', error);
                    reject(error)
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        resolve({ link: downloadURL, ref: storageRef.name });
                    })
                })
            });
        },
        UploadImageTutorial({ commit }, tutorial) {
            return new Promise((resolve, reject) => {
                let random = uuidv4();
                let storageRef = storageImages.child(random);
                console.log('storageRef', storageRef.name);
                let uploadTask = storageRef.putString(tutorial.image.img, "data_url")
                uploadTask.on('state_changed', (listener) => {
                    let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
                    commit('SET_VALUE_LOADING_STATUS_TUTORIAL_IMAGE', progress);
                }, (error) => {
                    console.log('error', error);
                    reject(error)
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        resolve({ link: downloadURL, ref: storageRef.name });
                    })
                })
            });
        },
        CreateTutorial({}, tutorial) {
            return new Promise(async(resolve, reject) => {
                delete tutorial.image.img
                tutorial.category = db.collection("categoriesTutorialsList").doc(tutorial.category.id);
                const ref = db.collection("tutorialsList").doc();
                const newTutorial = await ref.get();
                tutorial.id = ref.id;
                tutorial.createdAt = new Date()
                tutorial.updatedAt = new Date()
                await db.collection("tutorialsList")
                    .doc(newTutorial.id)
                    .set(tutorial)
                    .then(async(res) => {
                        resolve(newTutorial.id);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            })
        },
        UpdateTutorial({}, tutorial) {
            return new Promise(async(resolve, reject) => {
                if(tutorial.createdAt) {
                    delete tutorial.createdAt
                }
                tutorial.updatedAt = new Date()
                if (tutorial.image.img) {
                    delete tutorial.image.img
                }
                tutorial.category = db.collection("categoriesTutorialsList").doc(tutorial.category.id);
                await db.collection("tutorialsList")
                    .doc(tutorial.id)
                    .update(tutorial)
                    .then((res) => {
                        resolve(tutorial.id);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        GetCategoriesTutorialsList({ commit }){
            return new Promise(async (resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("categoriesTutorialsList").get()
                    const categoriesTutorialsList = []
                    querySnapshot.forEach((doc) =>  {
                        categoriesTutorialsList.push(doc.data())
                    })
                    commit('SET_CATEGORIES_TUTORIALS_LIST', categoriesTutorialsList)
                    resolve(categoriesTutorialsList)
                } catch(error) {
                    reject(error)
                }
            })
        },
        CreateCategoryTutorial({}, categoryTutorial) {
            return new Promise(async(resolve, reject) => {
                try {
                    const ref = db.collection("categoriesTutorialsList").doc();
                    const newCategoryTutorial = await ref.get();
                    categoryTutorial.id = ref.id;
                    await db.collection("categoriesTutorialsList").doc(newCategoryTutorial.id).set(categoryTutorial)
                    resolve(newCategoryTutorial.id);
                } catch (error) {
                    reject(err);
                }
            })
        },
        UpdateCategoryTutorial({}, category) {
            return new Promise(async(resolve, reject) => {
                try {
                    await db.collection("categoriesTutorialsList").doc(category.id).update(category)
                    resolve(category.id);
                } catch (error) {
                    reject(error);
                }
            });
        },
        DeleteCategoryTutorial({}, category) {
            return new Promise(async(resolve, reject) => {
                try {
                    await db.collection("categoriesTutorialsList").doc(category.id).delete()
                    resolve(category.id)
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
    getters: {
        getTutorials(state) {
            return state.tutorialsList
        },
        getLoadingStatusTutorialVideo(state) {
            return state.loadingStatusTutorialVideo
        },
        getLoadingStatusTutorialImage(state) {
            return state.loadingStatusTutorialImage
        },
        getCategoriesTutorialsList(state) {
            return state.categoriesTutorialsList
        },
    }
}