<template>
  <v-container
    class="elevation-4 rounded-lg px-8 mb-5"
    style="background-color: #fff"
  >
    <v-form ref="formCoupons" v-model="validCoupon">
      <v-row justify="center">
        <v-col cols="12" sm="12">
          <v-btn
            color="primary"
            icon
            size="20"
            class="text-center"
            @click="$router.go(-1)"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <h3 class="secondary--text d-inline mr-2">Create Coupon</h3>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon color="black" dark v-bind="attrs" v-on="on" size="32">mdi-translate</v-icon>
            </template>
            <span>Here you can choose the language of the Coupon to translate it</span>
            </v-tooltip>
            <v-menu class="text-center">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon class="elevation-3">
                <v-avatar size="32">
                    <v-img :src="flagActualTranslate"></v-img>
                </v-avatar>
                </v-btn>
            </template>
            <v-list v-if="listLanguages">
                <v-list-item
                v-for="(lang, index) in listLanguages"
                :key="index"
                @click="changeLanguage(lang)"
                >
                <v-list-item-action>
                    <v-avatar size="32" class="elevation-3">
                    <v-img :src="lang.flag"></v-img>
                    </v-avatar>
                </v-list-item-action>
                </v-list-item>
            </v-list>
            </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="4" class="mx-auto text-center">
            <p class="secondary--text pb-1">Title</p>
            <v-text-field
                v-model="coupon.title[actualTranslateLang]"
                :rules="couponRules.title"
                solo
                flat
                background-color="#efefef"
            ></v-text-field>
            <p class="secondary--text pb-1">Description</p>
            <v-textarea v-model="coupon.description[actualTranslateLang]" :rules="couponRules.description" solo flat counter auto-grow min-height="355" background-color="#efefef">
            </v-textarea>
            <div class="mx-auto text-center" style="width:150px">
              <p class="secondary--text text-center pb-1">Order number</p>
              <v-text-field
                  v-model.number="coupon.orderNumber"
                  @keypress="numberValid($event)"
                  maxlength="4"
                  class="centered-input"
                  solo
                  flat
                  background-color="#efefef"
              ></v-text-field>
            </div>
            <p class="secondary--text text-center pb-1">Enabled</p>
            <v-switch
                color="primary"
                class="d-flex justify-center center-switch"
                v-model="coupon.enabled"
                :input-value="coupon.enabled"
                flat
            ></v-switch>
            <p class="secondary--text text-center pb-1">Is a Android Coupon?</p>
            <v-switch
                color="primary"
                class="d-flex justify-center center-switch"
                v-model="coupon.isAndroid"
                :input-value="coupon.isAndroid"
                flat
            ></v-switch>
            <p v-if="coupon.isAndroid" class="secondary--text text-center pb-1">Discount Coupon</p>
            <v-switch
                v-if="coupon.isAndroid"
                color="primary"
                class="d-flex justify-center center-switch"
                v-model="coupon.isDiscountAndroid"
                :input-value="coupon.isDiscountAndroid"
                flat
            ></v-switch>
            <p v-if="coupon.isAndroid" class="secondary--text text-center pb-1">Coupon persistent?</p>
            <v-switch
                v-if="coupon.isAndroid"
                color="primary"
                class="d-flex justify-center center-switch"
                v-model="coupon.isPersistent"
                :input-value="coupon.isPersistent"
                flat
            ></v-switch>
        </v-col>
          <v-col cols="12" sm="12" md="4" class="mx-auto">
            <p class="secondary--text pb-1">Coupon Code</p>
            <v-text-field
                v-model="coupon.code"
                :rules="couponRules.code"
                solo
                flat
                background-color="#efefef"
            ></v-text-field>
            <p v-if="!coupon.isAndroid" class="secondary--text pb-1">Subscription</p>
                <v-select
                v-if="!coupon.isAndroid"
                v-model="coupon.subscription"
                :rules="couponRules.subscription"
                :items="getSubscriptionsList"
                item-text="name.ENG"
                return-object
                solo
                flat
                background-color="#efefef"
            ></v-select>
          <div class="mx-auto text-center" style="width:180px">
              <p class="secondary--text text-center pb-1">Max Coupon Uses</p>
              <v-text-field
                  v-model.number="coupon.totalSupply"
                  @keypress="numberValid($event)"
                  maxlength="5"
                  class="centered-input"
                  solo
                  flat
                  background-color="#efefef"
              ></v-text-field>
          </div>
        <!-- <p class="secondary--text text-center pb-1">Discount</p>
          <div class="mx-auto text-center d-flex align-center pl-5" style="width:100px">
              <v-text-field
                v-model.number="coupon.discountPercentage"
                @keypress="numberValid($event)"
                maxlength="2"
                class="centered-input"
                hide-details
                solo
                flat
                background-color="#efefef"
              ></v-text-field>
              <p class="secondary--text font-weight-bold mb-0 ml-3">%</p>
            </div> -->
             <p class="secondary--text text-center pb-1">Expiration</p>
             <v-datetime-picker 
              v-model="coupon.expirationDate"
              :text-field-props=" { backgroundColor: '#efefef', solo: true, flat: true, rules: couponRules.expirationDate }"
              :date-picker-props="{ color: 'primary' }"
              :time-picker-props="{ color: 'primary' }"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock</v-icon>
                </template>
                <template slot="actions" slot-scope="{ parent }">
                    <v-btn color="secondary" text @click.native="parent.clearHandler">Cancel</v-btn>
                    <v-btn color="primary" @click="parent.okHandler">Save</v-btn>
                  </template>
             </v-datetime-picker> 
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="primary" class="float-right" :disabled="!validCoupon" @click="verifyValidCoupon()">Create</v-btn>
         </v-col>
      </v-row>
    </v-form>
    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
        snackbar.text
      }}</v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";

import DatetimePicker from "@/components/admin/DatetimePicker.vue";

export default {
  name:"CouponsCreate",
  components:{ 'v-datetime-picker': DatetimePicker },
  data: () => ({
      validCoupon:false,
      coupon:{
        title:{},
        description:{},
        code:'',
        totalSupply:0,
        subscription:'',
        expirationDate:'',
        orderNumber:'',
        enabled:true,
        isAndroid:false,
        isDiscountAndroid:false,
        isPersistent:false
      },
      snackbar: {
        snackbar: false,
        timeout: 2000,
        text: null,
        color: "",
      },
      dateMenu:false,
      couponRules:{
        title: [v => !!v || 'Title is required'],
        description: [v => !!v || 'Description is required'],
        code: [v => !!v || 'Coupon Code is required'],
        totalSupply: [v => !!v || 'Total Users supply is required'],
        subscription: [v => !!v || 'Subscription is required'],
        expirationDate: [(v) => !!v || "Need a expiration Date"],
      },
      actualTranslateLang:"ENG",
      listLanguages:[
        {
            name:"English",
            code:"ENG",
            flag:require("@/assets/flags/usa-flag.png")
        },
        {
            name:"Japanese",
            code:"JAP",
            flag:require("@/assets/flags/japan-flag.jpg")
        },
        {
            name:"Spanish",
            code:"ESP",
            flag:require("@/assets/flags/spain-flag.png")
        },
        {
            name:"Korean",
            code:"KOR",
            flag:require("@/assets/flags/south-korea-flag.png")
        },
      ],
  }),
  async created(){
      const vm = this
      await vm.getSubscriptionsToSet()
  },
  methods:{
    async getSubscriptionsToSet(){
      const vm = this
      try {
        await vm.$store.dispatch('GetSubscriptionsList')
      } catch (error) {
        console.error(error)
      }
    },
    numberValid(e){
        e = (e) || window.event
        const charCode = (e.which) ? e.which : e.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault()
        } else {
            return true
        }
    },
    changeLanguage(lang){
      const vm = this
      vm.actualTranslateLang = lang.code
    },
    async verifyValidCoupon(){
      const vm = this
      try {
        const isEmpty = await vm.$store.dispatch('VerifyExistingCoupon', vm.coupon)
        if(isEmpty){
          vm.createCoupon()
        }else{
          let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 3000,
            text: "There is already a coupon with the code: " + vm.coupon.code,
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async createCoupon(){
     const vm = this;
          try {
            vm.$emit("showOverlay", true);
            const id = await vm.$store.dispatch('CreateCoupon', vm.coupon)
            await vm.$store.dispatch("GenerateLog", {
                id: id,
                title: vm.coupon.title['ENG'],
                type: "createdCoupon",
            })
          
            let emitData = {
                snackbar: true,
                btn: "",
                y: "bottom",
                x: null,
                mode: "",
                timeout: 2000,
                text: "Coupon Created successfully",
                color: "success",
            };
            vm.$router.push("/admin/coupons")
            .catch((err) => {
                vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
                vm.snackbar.color = "red darken-2";
                vm.snackbar.snackbar = true;
            })
            vm.$emit("showOverlay", false);
            vm.$emit("showSnackBar", emitData);
          } catch (error) {
              vm.$emit("showOverlay", false);
              console.log(error);
              let emitData = {
                  snackbar: true,
                  btn: "",
                  y: "bottom",
                  x: null,
                  mode: "",
                  timeout: 2000,
                  text: "Error creating Coupon",
                  color: "red",
              };
              vm.$emit("showSnackBar", emitData);
          }
    }
  },
  computed:{
      flagActualTranslate() {
        let vm = this;
        if(vm.listLanguages.length > 0){
            let currentFlag = vm.listLanguages.find(flag => flag.code === vm.actualTranslateLang);
            return currentFlag.flag;
        }
      },
      ...mapGetters(["getSubscriptionsList"])
  },
  watch:{
    "coupon.code": function (code) {
        this.coupon.code = code.toUpperCase()
    },
    "coupon.isAndroid": function (isAndroid) {
        if(isAndroid){
          this.coupon.subscription = ''
        }else{
          this.coupon.isDiscountAndroid = false
          this.coupon.isPersistent = false
        }
    },
  }
};
</script>
<style lang="scss">
.v-time-picker-custom .v-picker__title {
  height: 84px;
  padding-top: 10px;
}
  
</style>