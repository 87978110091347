<template>
  <v-container
    class="elevation-4 rounded-lg px-8 mb-5"
    style="background-color: #fff; max-width: 1400px !important"
  >
    <v-form ref="formEditExercise" v-model="validExercise">
      <v-row justify="center">
        <v-col cols="12" sm="12">
          <v-btn
            color="primary"
            icon
            size="20"
            class="text-center"
            @click="$router.go(-1)"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <h3 class="secondary--text d-inline mr-6">Edit Exercise</h3>
          <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="black" dark v-bind="attrs" v-on="on" size="32">mdi-translate</v-icon>
          </template>
          <span>Here you can choose the language of the exercise to translate it</span>
        </v-tooltip>
        <v-menu class="text-center">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon class="elevation-3">
              <v-avatar size="32">
                <v-img :src="flagActualTranslate"></v-img>
              </v-avatar>
            </v-btn>
          </template>
          <v-list v-if="listLanguages">
            <v-list-item
              v-for="(lang, index) in listLanguages"
              :key="index"
              @click="changeLanguage(lang)"
            >
              <v-list-item-action>
                <v-avatar size="32" class="elevation-3">
                  <v-img :src="lang.flag"></v-img>
                </v-avatar>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="4" class="mx-auto text-left">
          <p class="secondary--text pb-1" style="font-size: 14px">Name</p>
          <v-text-field
            v-model="exercise.name[actualTranslateLang]"
            counter
            solo
            flat
            background-color="#efefef"
          ></v-text-field>
          <p class="secondary--text pb-1" style="font-size: 14px">Description</p>
          <v-textarea
            v-model="exercise.description[actualTranslateLang]"
            counter
            solo
            flat
            no-resize
            height="355"
            background-color="#efefef"
          >
          </v-textarea>
          <p class="secondary--text pb-1" style="font-size: 14px">Variations</p>
          <v-textarea
            v-model="exercise.variations"
            maxlength="250"
            counter="250"
            solo
            flat
            no-resize
            height="120"
            background-color="#efefef"
          >
          </v-textarea>
          <div class="mx-auto text-center" style="width:150px">
          <p class="secondary--text text-center pb-1">Order number</p>
          <v-text-field
              v-model.number="exercise.orderNumber"
              @keypress="numberValid($event)"
              maxlength="4"
              class="centered-input"
              solo
              flat
              background-color="#efefef"
          ></v-text-field>
        </div>
        <div class="text-center d-flex align-center mt-5">
            <template v-if="exercise.dynamicLink && exercise.dynamicLink.length > 0">
              <v-text-field
                  v-model="exercise.dynamicLink"
                  ref="exerciseDynamicLinkRef"
                  hide-details="auto"
                  maxlength="4"
                  class="centered-input"
                  readonly
                  solo
                  flat
                  background-color="#efefef"
              ></v-text-field>
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn 
                    v-bind="attrs"
                      v-on="on"
                    icon 
                    color="primary"
                    v-clipboard:copy="exercise.dynamicLink"
                    v-clipboard:success="copyLink"
                    v-clipboard:error="onErrorCopyLink">
                  <v-icon>mdi-plus-box-multiple</v-icon>
                </v-btn>
                </template>
                <span>Copy to clipboard</span>
              </v-tooltip>
              
            </template>
            <template v-else>
              <p class="secondary--text mx-auto">There is no dynamic link for this workout yet</p>
            </template>
          </div>
          <div class="text-center mb-3">
            <v-btn class="mt-3 text-center" text color="secondary" :disabled="loadingDynamicLink" :loading="loadingDynamicLink" @click="generateDynamicLink()">
            <v-icon left dark color="primary"> mdi-link-plus </v-icon>
              Generate Dynamic Link
            </v-btn>
          </div>
          <div class="d-flex align-center">
            <v-switch
              color="primary"
              class="d-flex justify-center center-switch"
              v-model="exercise.enabled"
              :input-value="exercise.enabled"
              flat
            >
              <template v-slot:label>
                <p class="secondary--text d-inline mb-0" style="font-size: 14px">Enabled</p>
              </template>
            </v-switch>
            <v-switch
              color="primary"
              class="d-flex justify-center center-switch"
              v-model="exercise.basicPackage"
              :input-value="exercise.basicPackage"
              flat
            >
              <template v-slot:label>
                <p class="secondary--text d-inline mb-0" style="font-size: 14px">Basic Package</p>
              </template>
            </v-switch>
            <v-switch
              color="primary"
              class="d-flex justify-center center-switch"
              v-model="exercise.singleCord"
              :input-value="exercise.singleCord"
              flat
            >
              <template v-slot:label>
                <p class="secondary--text d-inline mb-0" style="font-size: 14px">Single Cord</p>
              </template>
            </v-switch>
            <v-switch
              color="primary"
              class="d-flex justify-center center-switch"
              v-model="exercise.suspension"
              :input-value="exercise.suspension"
              flat
            >
              <template v-slot:label>
                <p class="secondary--text d-inline mb-0" style="font-size: 14px">Suspension</p>
              </template>
            </v-switch>
          </div>
          <div class="d-flex align-center">
            <v-switch
              color="primary"
              class="d-flex justify-center center-switch"
              v-model="exercise.twoHands"
              :input-value="exercise.twoHands"
              flat
            >
              <template v-slot:label>
                <p class="secondary--text d-inline mb-0" style="font-size: 14px">Two Hands?</p>
              </template>
            </v-switch>
             <v-switch
              color="primary"
              class="d-flex justify-center center-switch"
              v-model="exercise.isGrandFathered"
              :input-value="exercise.isGrandFathered"
              flat
            >
              <template v-slot:label>
                <p class="secondary--text d-inline mb-0" style="font-size: 14px">GrandFathered</p>
              </template>
            </v-switch>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="4" class="mx-auto text-left">
          <v-tooltip top color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <p
                  class="secondary--text mb-2" 
                  style="font-size: 14px; cursor: pointer;"
                  v-bind="attrs" 
                  v-on="on"
                >
                  Old app trainers default Media
                </p>
              </template>
              <span>The images and videos that change in these fields will only cause changes in the old MAXPRO app</span>
            </v-tooltip>

            <div 
              style="
              width: 100%;
              border-bottom: 3px solid #ec5b01;"
            />

          <v-expansion-panels accordion flat>
            <v-expansion-panel style="border-bottom: #efeeee 2px solid">
              <v-expansion-panel-header>Alex</v-expansion-panel-header>
              <v-expansion-panel-content>
                <p class="secondary--text pb-1">Select Video Alex</p>
                <v-card
                  color="#efefef"
                  class="rounded-lg elevation-0 d-flex justify-center align-center mt-5 mb-5"
                  min-width="250"
                  min-height="250"
                  max-height="250px"
                  style="position: relative"
                  @click="$refs.inputVideoAlex.$refs.input.click()"
                >
     
                  <video
                    v-if="(exercise.storageData && exercise.storageData.videoAlex) || pickedVideo.videoAlex"
                    width="100%"
                    height="250px"
                    max-height="250px"
                    class="mx-auto"
                    :src="exercise.storageData && exercise.storageData.videoAlex ? exercise.storageData.videoAlex : pickedVideo.videoAlex"
                  ></video>

                  <v-icon
                    v-if="!isLoadingVideo.videoAlex"
                    large
                    color="primary"
                    style="
                      position: absolute;
                      transform: translate(50%, -50%);
                      top: 50%;
                      right: 50%;
                    "
                  >
                    mdi-upload
                  </v-icon>
                  <v-progress-circular
                    v-if="isLoadingVideo.videoAlex"
                    indeterminate
                    color="primary"
                    style="
                      position: absolute;
                      transform: translate(50%, -50%);
                      top: 50%;
                      right: 50%;
                    "
                  ></v-progress-circular>
                </v-card>
                <p class="secondary--text pb-1">Select Image Alex</p>
                <v-card
                  color="#efefef"
                  class="rounded-lg elevation-0 d-flex mt-5 justify-center align-center"
                  min-width="250"
                  min-height="250"
                  @click="goFocusImageFileInput('imageAlex')"
                >
                  <v-img
                    v-if="(exercise && exercise.storageData && exercise.storageData.imageAlex) || pickedImage.imageAlex"
                    :src="exercise && exercise.storageData && exercise.storageData.imageAlex ?  exercise.storageData.imageAlex : pickedImage.imageAlex"
                    cover
                    width="250"
                    class="rounded-lg"
                    height="250"
                  >
                  </v-img>
                  <v-icon
                    large
                    color="primary"
                    style="
                      position: absolute;
                      transform: translate(50%, -50%);
                      top: 50%;
                      right: 50%;
                    "
                  >
                    mdi-camera
                  </v-icon>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel style="border-bottom: #efeeee 2px solid">
              <v-expansion-panel-header>Justin</v-expansion-panel-header>
             <v-expansion-panel-content>
                <p class="secondary--text pb-1">Select Video Justin</p>
                <v-card
                  color="#efefef"
                  class="rounded-lg elevation-0 d-flex justify-center align-center mt-5 mb-5"
                  min-width="250"
                  min-height="250"
                  max-height="250px"
                  style="position: relative"
                  @click="$refs.inputVideoJustin.$refs.input.click()"
                >
                  <video
                    v-if="(exercise.storageData && exercise.storageData.videoJustin) || pickedVideo.videoJustin"
                    :src="exercise.storageData && exercise.storageData.videoJustin ?  exercise.storageData.videoJustin : pickedVideo.videoJustin"
                    width="100%"
                    height="250px"
                    max-height="250px"
                    class="mx-auto"
                  ></video>
                  <v-icon
                    v-if="!isLoadingVideo.videoJustin"
                    large
                    color="primary"
                    style="
                      position: absolute;
                      transform: translate(50%, -50%);
                      top: 50%;
                      right: 50%;
                    "
                  >
                    mdi-upload
                  </v-icon>
                  <v-progress-circular
                    v-if="isLoadingVideo.videoJustin"
                    indeterminate
                    color="primary"
                    style="
                      position: absolute;
                      transform: translate(50%, -50%);
                      top: 50%;
                      right: 50%;
                    "
                  ></v-progress-circular>
                </v-card>
                <p class="secondary--text pb-1">Select Image Justin</p>
                <v-card
                  color="#efefef"
                  class="rounded-lg elevation-0 d-flex mt-5 justify-center align-center"
                  min-width="250"
                  min-height="250"
                  @click="goFocusImageFileInput('imageJustin')"
                >
                  <v-img
                    v-if="(exercise && exercise.storageData && exercise.storageData.imageJustin) || pickedImage.imageJustin"
                    :src="exercise && exercise.storageData && exercise.storageData.imageJustin ?  exercise.storageData.imageJustin : pickedImage.imageJustin"
                    cover
                    width="250"
                    class="rounded-lg"
                    height="250"
                  >
                  </v-img>
                  <v-icon
                    large
                    color="primary"
                    style="
                      position: absolute;
                      transform: translate(50%, -50%);
                      top: 50%;
                      right: 50%;
                    "
                  >
                    mdi-camera
                  </v-icon>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel style="border-bottom: #efeeee 2px solid">
              <v-expansion-panel-header>Shauna</v-expansion-panel-header>
                <v-expansion-panel-content>
                <p class="secondary--text pb-1">Select Video Shauna</p>
                <v-card
                  color="#efefef"
                  class="rounded-lg elevation-0 d-flex justify-center align-center mt-5 mb-5"
                  min-width="250"
                  min-height="250"
                  max-height="250px"
                  style="position: relative"
                  @click="$refs.inputVideoShauna.$refs.input.click()"
                >
                  <video
                    v-if="(exercise && exercise.storageData && exercise.storageData.videoShauna) || pickedVideo.videoShauna"
                    :src="exercise && exercise.storageData && exercise.storageData.videoShauna ?  exercise.storageData.videoShauna : pickedVideo.videoShauna"
                    width="100%"
                    height="250px"
                    max-height="250px"
                    class="mx-auto"
                  ></video>
                  <v-icon
                    v-if="!isLoadingVideo.videoShauna"
                    large
                    color="primary"
                    style="
                      position: absolute;
                      transform: translate(50%, -50%);
                      top: 50%;
                      right: 50%;
                    "
                  >
                    mdi-upload
                  </v-icon>
                  <v-progress-circular
                    v-if="isLoadingVideo.videoShauna"
                    indeterminate
                    color="primary"
                    style="
                      position: absolute;
                      transform: translate(50%, -50%);
                      top: 50%;
                      right: 50%;
                    "
                  ></v-progress-circular>
                </v-card>
                <p class="secondary--text pb-1">Select Image Shauna</p>
                <v-card
                  color="#efefef"
                  class="rounded-lg elevation-0 d-flex mt-5 justify-center align-center"
                  min-width="250"
                  min-height="250"
                  @click="goFocusImageFileInput('imageShauna')"
                >
                  <v-img
                    v-if="(exercise && exercise.storageData && exercise.storageData.imageShauna) || pickedImage.imageShauna"
                    :src="exercise && exercise.storageData && exercise.storageData.imageShauna ?  exercise.storageData.imageShauna : pickedImage.imageShauna"
                    cover
                    width="250"
                    class="rounded-lg"
                    height="250"
                  >
                  </v-img>
                  <v-icon
                    large
                    color="primary"
                    style="
                      position: absolute;
                      transform: translate(50%, -50%);
                      top: 50%;
                      right: 50%;
                    "
                  >
                    mdi-camera
                  </v-icon>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel style="border-bottom: #efeeee 2px solid">
              <v-expansion-panel-header>Veon</v-expansion-panel-header>
               <v-expansion-panel-content>
                <p class="secondary--text pb-1">Select Video Veon</p>
                <v-card
                  color="#efefef"
                  class="rounded-lg elevation-0 d-flex justify-center align-center mt-5 mb-5"
                  min-width="250"
                  min-height="250"
                  max-height="250px"
                  style="position: relative"
                  @click="$refs.inputVideoVeon.$refs.input.click()"
                >
                  <video
                    v-if="(exercise && exercise.storageData && exercise.storageData.videoVeon) || pickedVideo.videoVeon"
                    :src="exercise && exercise.storageData && exercise.storageData.videoVeon ?  exercise.storageData.videoVeon : pickedVideo.videoVeon"
                    width="100%"
                    height="250px"
                    max-height="250px"
                    class="mx-auto"
                  ></video>
                  <v-icon
                    v-if="!isLoadingVideo.videoVeon"
                    large
                    color="primary"
                    style="
                      position: absolute;
                      transform: translate(50%, -50%);
                      top: 50%;
                      right: 50%;
                    "
                  >
                    mdi-upload
                  </v-icon>
                  <v-progress-circular
                    v-if="isLoadingVideo.videoVeon"
                    indeterminate
                    color="primary"
                    style="
                      position: absolute;
                      transform: translate(50%, -50%);
                      top: 50%;
                      right: 50%;
                    "
                  ></v-progress-circular>
                </v-card>
                <p class="secondary--text pb-1">Select Image Veon</p>
                <v-card
                  color="#efefef"
                  class="rounded-lg elevation-0 d-flex mt-5 justify-center align-center"
                  min-width="250"
                  min-height="250"
                  @click="goFocusImageFileInput('imageVeon')"
                >
                  <v-img
                    v-if="(exercise && exercise.storageData && exercise.storageData.imageVeon) || pickedImage.imageVeon"
                    :src="exercise && exercise.storageData && exercise.storageData.imageVeon ?  exercise.storageData.imageVeon : pickedImage.imageVeon"
                    cover
                    width="250"
                    class="rounded-lg"
                    height="250"
                  >
                  </v-img>
                  <v-icon
                    large
                    color="primary"
                    style="
                      position: absolute;
                      transform: translate(50%, -50%);
                      top: 50%;
                      right: 50%;
                    "
                  >
                    mdi-camera
                  </v-icon>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-tooltip top color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <p
                  class="secondary--text mt-6 mb-2" 
                  style="font-size: 14px; cursor: pointer;"
                  v-bind="attrs" 
                  v-on="on"
                >
                  New app trainers Media
                </p>
              </template>
              <span>The images and videos that change in these fields will only cause changes in the new MAXPRO app</span>
            </v-tooltip>

            <div 
              style="
              width: 100%;
              border-bottom: 3px solid #ec5b01;"
            />
            <template>
               <v-expansion-panels accordion flat>
                <v-expansion-panel  v-for="(trainer, index) in exercise.trainersList" :key="index" style="border-bottom: #efeeee 2px solid">
                  <v-expansion-panel-header>{{ trainer.firstName }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <!-- <v-btn color="red" dark>delete</v-btn> -->
                    <p class="secondary--text pb-1">Select Video {{ trainer.firstName }}</p>
                    <v-card
                      color="#efefef"
                      class="rounded-lg elevation-0 d-flex justify-center align-center mt-5 mb-5"
                      min-width="250"
                      min-height="250"
                      max-height="250px"
                      style="position: relative"
                      @click="$refs.refAddVideoTrainer.$refs.input.click(); indexToChangeVideo = index"
                    >
        
                      <video
                        v-if="trainer.exVideo"
                        width="100%"
                        height="250px"
                        max-height="250px"
                        class="mx-auto"
                        :src="trainer.exVideo.link ? trainer.exVideo.link : trainer.exVideo.video ? trainer.exVideo.video : ''"
                      ></video>

                      <v-icon
                        v-if="!trainer.isLoadingVideo"
                        large
                        color="primary"
                        style="
                          position: absolute;
                          transform: translate(50%, -50%);
                          top: 50%;
                          right: 50%;
                        "
                      >
                        mdi-upload
                      </v-icon>
                      <v-progress-circular
                        v-if="trainer.isLoadingVideo"
                        indeterminate
                        color="primary"
                        style="
                          position: absolute;
                          transform: translate(50%, -50%);
                          top: 50%;
                          right: 50%;
                        "
                      ></v-progress-circular>
                    </v-card>
                    <p class="secondary--text pb-1">Select Image {{ trainer.firstName }}</p>
                    <v-card
                      color="#efefef"
                      class="rounded-lg elevation-0 d-flex mt-5 justify-center align-center"
                      min-width="250"
                      min-height="250"
                      @click="$refs.refAddImageTrainer.$refs.input.click(); indexToChangeImage = index"
                    >
                      <v-img
                        v-if="trainer.exImage"
                        :src="trainer.exImage.link ? trainer.exImage.link : trainer.exImage.img ? trainer.exImage.img : ''"
                        cover
                        width="250"
                        class="rounded-lg"
                        height="250"
                      >
                      </v-img>
                      <v-icon
                        large
                        color="primary"
                        style="
                          position: absolute;
                          transform: translate(50%, -50%);
                          top: 50%;
                          right: 50%;
                        "
                      >
                        mdi-camera
                      </v-icon>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
               </v-expansion-panels>
            </template>

            <v-col cols="12" class="text-center">
              <v-btn
                  color="primary"
                  class="mx-auto"
                  fab
                  dark
                  small
                  @click="openDialogAddTrainer()"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-col>
             <v-tooltip top color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <p
                  class="secondary--text mb-2" 
                  style="font-size: 14px; cursor: pointer;"
                  v-bind="attrs" 
                  v-on="on"
                >
                  Single files for Excersises
                </p>
              </template>
              <span>In future updates of the app, these exercise files will replace the trainer exercises and will be only (1) image and (1) video only per exercise.</span>
            </v-tooltip>

            <div 
              style="
              width: 100%;
              border-bottom: 3px solid #ec5b01;"
            />

          <v-expansion-panels accordion flat>
            <v-expansion-panel style="border-bottom: #efeeee 2px solid">
              <v-expansion-panel-header>Unique Files</v-expansion-panel-header>
              <v-expansion-panel-content>
                <p class="secondary--text pb-1">Select Unique Video</p>
                <v-card
                  color="#efefef"
                  class="rounded-lg elevation-0 d-flex justify-center align-center mt-5 mb-5"
                  min-width="250"
                  min-height="250"
                  max-height="250px"
                  style="position: relative"
                  @click="$refs.inputUniqueVideo.$refs.input.click()"
                >
     
                  <video
                    v-if="exercise.video"
                    width="100%"
                    height="250px"
                    max-height="250px"
                    class="mx-auto"
                    :src="exercise.video && exercise.video.link ? exercise.video.link :  exercise.video &&  exercise.video.vid ?  exercise.video.vid : ''"
                  ></video>

                  <v-icon
                    v-if="!isLoadingUniqueVideo"
                    large
                    color="primary"
                    style="
                      position: absolute;
                      transform: translate(50%, -50%);
                      top: 50%;
                      right: 50%;
                    "
                  >
                    mdi-upload
                  </v-icon>
                  <v-progress-circular
                    v-if="isLoadingUniqueVideo"
                    indeterminate
                    color="primary"
                    style="
                      position: absolute;
                      transform: translate(50%, -50%);
                      top: 50%;
                      right: 50%;
                    "
                  ></v-progress-circular>
                </v-card>
                <p class="secondary--text pb-1">Select Unique Image</p>
                <v-card
                  color="#efefef"
                  class="rounded-lg elevation-0 d-flex mt-5 justify-center align-center"
                  min-width="250"
                  min-height="250"
                  @click="$refs.inputUniqueImage.$refs.input.click()"
                >
                  <v-img
                    v-if="exercise.image"
                    :src="exercise.image && exercise.image.link ? exercise.image.link :  exercise.image &&  exercise.image.img ?  exercise.image.img : ''"
                    cover
                    width="250"
                    class="rounded-lg"
                    height="250"
                  >
                  </v-img>
                  <v-icon
                    large
                    color="primary"
                    style="
                      position: absolute;
                      transform: translate(50%, -50%);
                      top: 50%;
                      right: 50%;
                    "
                  >
                    mdi-camera
                  </v-icon>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
        <v-col cols="12" sm="12" md="4" class="mx-auto text-left">
          <v-row justify="start">
            <v-col cols="6">
              <p class="secondary--text pb-1" style="font-size: 14px">Difficulty</p>
              <v-select
                v-model="exercise.difficulty"
                :items="listDifficulty"
                item-text="name"
                item-value="value"
                solo
                flat
                background-color="#efefef"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <p class="secondary--text pb-1" style="font-size: 14px">Goal</p>
              <v-select
                v-model="exercise.goal"
                :items="listGoal"
                item-text="name"
                item-value="value"
                solo
                flat
                background-color="#efefef"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <p class="secondary--text pb-1" style="font-size: 14px">Type</p>
              <v-select
                v-model="exercise.type"
                :items="listType"
                item-text="name"
                item-value="value"
                solo
                flat
                background-color="#efefef"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <p class="secondary--text pb-1" style="font-size: 14px">Gen Body Area</p>
              <v-select
                v-model="exercise.genBodyArea"
                :items="listGenBodyArea"
                item-text="name"
                item-value="value"
                solo
                flat
                background-color="#efefef"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-row justify="start" no-gutters>
                <v-col cols="4">
                  <v-tooltip bottom color="secondary" class="text-center">
                    <template v-slot:activator="{ on, attrs }">
                      <p
                        class="secondary--text pb-1 text-center"
                        style="font-size: 14px"
                      >
                        Base MET
                      </p>
                      <v-text-field
                        style="width: 50px"
                        class="mx-auto centered-input"
                        v-model="exercise.baseMET"
                        v-bind="attrs"
                        v-on="on"
                        solo
                        flat
                        readonly
                        background-color="#efefef"
                      ></v-text-field>
                    </template>
                    <v-icon color="white" class="mr-2">mdi-alert-circle</v-icon>
                    <span>This field is read-only</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="4">
                  <v-tooltip bottom color="secondary" class="text-center">
                    <template v-slot:activator="{ on, attrs }">
                      <p
                        class="secondary--text pb-1 text-center"
                        style="font-size: 14px"
                      >
                        Body Eng MET
                      </p>
                      <v-text-field
                        style="width: 50px; cursor: pointer"
                        class="mx-auto centered-input"
                        v-model="exercise.bodyEngMET"
                        v-bind="attrs"
                        v-on="on"
                        solo
                        flat
                        readonly
                        background-color="#efefef"
                      ></v-text-field>
                    </template>
                    <v-icon color="white" class="mr-2">mdi-alert-circle</v-icon>
                    <span>This field is read-only</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="4">
                  <v-tooltip bottom color="secondary" class="text-center">
                    <template v-slot:activator="{ on, attrs }">
                      <p
                        class="secondary--text pb-1 text-center"
                        style="font-size: 14px"
                      >
                        MET Power Factor
                      </p>
                      <v-text-field
                        style="width: 50px"
                        class="mx-auto centered-input"
                        v-model="exercise.METPowerFactorWO"
                        v-bind="attrs"
                        v-on="on"
                        solo
                        flat
                        readonly
                        background-color="#efefef"
                      ></v-text-field>
                    </template>
                    <v-icon color="white" class="mr-2">mdi-alert-circle</v-icon>
                    <span>This field is read-only</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <p
                class="secondary--text pb-1 text-center"
                style="font-size: 14px"
              >
                Spec Muscle Gr
              </p>
              <v-select
                v-model="exercise.specMuscleGr"
                :items="listSpecMuscleGr"
                item-text="name"
                item-value="value"
                solo
                flat
                background-color="#efefef"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <p
                class="secondary--text pb-1 text-center"
                style="font-size: 14px"
              >
                Sec Muscle Gr
              </p>
              <v-select
                v-model="exercise.secMuscleGr"
                :items="listSecMuscleGr"
                item-text="name"
                item-value="value"
                solo
                flat
                background-color="#efefef"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <p
                class="secondary--text pb-1 text-center"
                style="font-size: 14px"
              >
                One Gym Placement
              </p>
              <v-select
                v-model="exercise.oneGymPlacement"
                :items="listOneGymPlacement"
                item-text="name"
                item-value="value"
                solo
                flat
                background-color="#efefef"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <p
                class="secondary--text pb-1 text-center"
                style="font-size: 14px"
              >
                One Gym Sec Placement
              </p>
              <v-select
                v-model="exercise.oneGymSecPlacement"
                :items="listOneGymSecPlacement"
                item-text="name"
                item-value="value"
                solo
                flat
                background-color="#efefef"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <p
                class="secondary--text pb-1 text-center"
                style="font-size: 14px"
              >
                Accesory
              </p>
              <v-select
                v-model="exercise.accessory"
                :items="listAccessory"
                item-text="name"
                item-value="value"
                solo
                flat
                background-color="#efefef"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <p
                class="secondary--text pb-1 text-center"
                style="font-size: 14px"
              >
                Accesory Sec
              </p>
              <v-select
                v-model="exercise.secAccessory"
                :items="listSecAccessory"
                item-text="name"
                item-value="value"
                solo
                flat
                background-color="#efefef"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <p
                class="secondary--text pb-1 text-center"
                style="font-size: 14px"
              >
                Total MET
              </p>
              <v-text-field
                class="mx-auto centered-input"
                v-model.number="exercise.totalMet"
                type="number"
                solo
                flat
                background-color="#efefef"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <p
                class="secondary--text pb-1 text-center"
                style="font-size: 14px"
              >
                Reps
              </p>
              <v-text-field
                class="mx-auto centered-input"
                v-model.number="exercise.reps"
                type="number"
                solo
                flat
                background-color="#efefef"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <p
                class="secondary--text pb-1 text-center"
                style="font-size: 14px"
              >
                Power Score Avg Watts
              </p>
              <v-select
                v-model="exercise.powerScoreAvgWatts"
                :items="listPowerScoreAvgWatts"
                item-text="name"
                item-value="value"
                solo
                flat
                background-color="#efefef"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <p class="secondary--text pb-1" style="font-size: 14px">Subscription</p>
                 <v-select
                  v-model="exercise.subscription"
                  :items="getSubscriptionsList"
                  item-text="name.ENG"
                  return-object
                  solo
                  flat
                  background-color="#efefef"
                ></v-select>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" class="text-right">
          <v-btn small color="primary" @click="openDialogReAuth('uploadImagesExercise')"> save edit </v-btn>
        </v-col>
      </v-row>
    </v-form>


    <v-dialog
      v-model="dialogAddTrainer.show"
      width="700"
      persistent
    >
    <v-card>
      <v-card-text class="pb-0">
        <p class="secondary--text pb-1 pt-2 text-left">
          Add new trainer
        </p>
        <v-autocomplete
          v-model="dialogAddTrainer.trainer"
          :items="computedDisabledTrainers"
          item-text="firstName"
          item-value="id"
          color="primary"
          item-color="primary"
          return-object
          solo
          flat
          background-color="#efefef"
      >
      <template v-slot:item="data">
          <template v-if="(typeof data.item !== 'object')">
              <v-list-item-content class="success--text font-weight-bold" style="margin-left:56px;" v-text="data.item"></v-list-item-content>
          </template>
          <template v-else>
          <v-list-item-avatar>
              <img :src="data.item.image.link">
          </v-list-item-avatar>
          <v-list-item-content>
              <v-list-item-title v-html="data.item.firstName"></v-list-item-title>
          </v-list-item-content>
          </template>
      </template>
      </v-autocomplete>
      </v-card-text>
      <v-card-actions class="pt-0 pb-4">
          <div class="ml-auto">
            <v-btn color="secondary" class="mr-1" @click="closeDialogAddTrainer()">
              Cancel
            </v-btn>
            <v-btn color="primary" class="mr-1" @click="saveDialogAddTrainer()">
              Save
            </v-btn>
          </div>
      </v-card-actions>
    </v-card>
    </v-dialog>
      <!-- Unique reference inputs -->
    <v-file-input
      ref="inputUniqueVideo"
      class="d-none"
      color="white"
      accept="video/mp4"
      dark
      outlined
      :show-size="1000"
      @change="getUniqueVideo"
    />
    <v-file-input
      ref="inputUniqueImage"
      class="d-none"
      color="white"
      accept="image/png, image/jpeg"
      dark
      outlined
      :show-size="1000"
      @change="getUniqueImage"
    />
  
  <!-- Unique reference inputs -->
  <!-- New reference inputs -->
    <v-file-input
      ref="refAddVideoTrainer"
      class="d-none"
      color="white"
      accept="video/mp4"
      dark
      outlined
      :show-size="1000"
      @change="getVideoTrainer"
    />
    <v-file-input
      ref="refAddImageTrainer"
      class="d-none"
      color="white"
      accept="image/png, image/jpeg"
      dark
      outlined
      :show-size="1000"
      @change="getImageTrainer"
    />
  <!-- New reference inputs -->

   <!-- Old reference inputs -->

    <v-file-input
      ref="inputVideoAlex"
      class="d-none"
      color="white"
      accept="video/mp4"
      dark
      outlined
      :show-size="1000"
      @change="getVideoAlex"
    />
    <v-file-input
      ref="inputVideoJustin"
      class="d-none"
      color="white"
      accept="video/mp4"
      dark
      outlined
      :show-size="1000"
      @change="getVideoJustin"
    />
    <v-file-input
      ref="inputVideoShauna"
      class="d-none"
      color="white"
      accept="video/mp4"
      dark
      outlined
      :show-size="1000"
      @change="getVideoShauna"
    />
    <v-file-input
      ref="inputVideoVeon"
      class="d-none"
      color="white"
      accept="video/mp4"
      dark
      outlined
      :show-size="1000"
      @change="getVideoVeon"
    />
    <v-file-input
      ref="exercisesImages"
      class="d-none"
      color="white"
      accept="image/png, image/jpeg"
      dark
      outlined
      :show-size="1000"
      @change="getExerciseImage"
    />

    <v-dialog
      v-model="dialogUploadUnique.show"
      persistent
      width="400"
    >
      <v-card
        color="primary"
        dark
        class="pa-5"
      >
        <v-card-text v-if="exercise.video.vid">
          Uploading unique video...
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="getUploadUniqueVideoLoading"
          ></v-progress-linear>
        </v-card-text>
        <v-card-text v-if="exercise.image.img">
          Uploading unique image...
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="getUploadUniqueImageLoading"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

 <!-- Old reference inputs -->

    <v-dialog
      v-model="dialogVideoTrainerLoadingStatus.show"
      persistent
      width="400"
    >
      <v-card
        color="primary"
        dark
        class="pa-5"
      >
        <v-card-text>
          Uploading video ... ({{ counterVideo }}/{{ videosToUpload.length }})
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="getVideoTrainerLoadingStatus"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogImageTrainerLoadingStatus.show"
      persistent
      width="400"
    >
      <v-card
        color="primary"
        dark
        class="pa-5"
      >
        <v-card-text>
          Uploading image ... ({{ counterImage }}/{{ imagesToUpload.length }})
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="getImageTrainerLoadingStatus"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog
      v-model="dialogUploadVideo.show"
      persistent
      width="400"
    >
      <v-card
        color="primary"
        dark
        class="pa-5"
      >
        <v-card-text v-if="pickedVideo.videoAlex">
          Uploading video Alex...
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="getUploadVideoLoading.videoAlex"
          ></v-progress-linear>
        </v-card-text>
        <v-card-text v-if="pickedVideo.videoJustin">
          Uploading video Justin...
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="getUploadVideoLoading.videoJustin"
          ></v-progress-linear>
        </v-card-text>
        <v-card-text v-if="pickedVideo.videoShauna">
          Uploading video Shauna...
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="getUploadVideoLoading.videoShauna"
          ></v-progress-linear>
        </v-card-text>
        <v-card-text v-if="pickedVideo.videoVeon">
          Uploading video Veon...
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="getUploadVideoLoading.videoVeon"
          ></v-progress-linear>
        </v-card-text>
      <!-- </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogUploadImage.show"
      persistent
      width="400"
    >
      <v-card
        color="primary"
        dark
        class="pa-5"
      > -->
        <v-card-text v-if="pickedImage.imageAlex">
          Uploading image Alex...
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="getUploadImageLoading.imageAlex"
          ></v-progress-linear>
        </v-card-text>
        <v-card-text v-if="pickedImage.imageJustin">
          Uploading image Justin...
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="getUploadImageLoading.imageJustin"
          ></v-progress-linear>
        </v-card-text>
        <v-card-text v-if="pickedImage.imageShauna">
          Uploading image Shauna...
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="getUploadImageLoading.imageShauna"
          ></v-progress-linear>
        </v-card-text>
        <v-card-text v-if="pickedImage.imageVeon">
          Uploading image Veon...
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="getUploadImageLoading.imageVeon"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
        snackbar.text
      }}</v-snackbar>
    <modal-reauth :dialogReAuth="dialogReAuth" @isSuccessReAuth="isSuccessReAuth"></modal-reauth>
  </v-container>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import firebase from "firebase";
import modalReauth from '@/components/admin/modal-reauth.vue';
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)

export default {
  name: "EditExcercise",
  components: { modalReauth },
  data: () => ({
    validExercise: false,
    dialogReAuth:{
      show: false,
      action: '',
    },
    exercise: {
      name: {},
      description: {},
      enabled:true,
      variations: "",
      METPowerFactorWO: 0,
      accessory: 0,
      baseMET: 0,
      basicPackage: false,
      bodyEngMET: 0,
      difficulty: 0,
      genBodyArea: 0,
      goal: 0,
      imageAlex: null,
      imageAlexSize: "",
      imageJustin: null,
      imageJustinSize: "",
      imageShauna: null,
      imageShaunaSize: "",
      imageVeon: null,
      imageVeonSize: "",
      storageData:null,
      oneGymPlacement: 0,
      oneGymSecPlacement: 0,
      powerMETFactor: 0,
      powerScoreAvgWatts: 0,
      reps: 0,
      secAccessory: 0,
      secMuscleGr: 0,
      singleCord: false,
      specMuscleGr: 0,
      suspension: false,
      totalMet: 0,
      type: 0,
      videoAlex: null,
      videoAlexSize: "",
      videoJustin: null,
      videoJustinSize: "",
      videoShauna: null,
      videoShaunaSize: "",
      videoVeon: null,
      videoVeonSize: "",
      orderNumber:'',
      trainersList:[],
      twoHands:false,
      isGrandFathered:false,
      image:{
        img:null,
        link:'',
        ref:'',
        size:'',
        type:''
      },
      video:{
        vid:null,
        link:'',
        ref:'',
        size:'',
        type:''
      }
    },
    loadingDynamicLink:false,
    generatingDynamicLink:false,
        // new logic upload
    dialogAddTrainer:{
      show:false,
      trainer:{
      }
    },
    indexToChangeVideo:0,
    indexToChangeImage:0,
    videosToUpload:[],
    imagesToUpload:[],
    counterVideo: 0,
    counterImage:0,
    dialogVideoTrainerLoadingStatus:{
      show:false,
    },
    dialogImageTrainerLoadingStatus:{
      show:false,
    },
    //new logic upload
    isLoadingVideo: {
      videoAlex: false,
      videoJustin: false,
      videoShauna: false,
      videoVeon: false,
    },
    focusedImageFileInput:"",
    pickedVideo: {
      videoAlex: null,
      videoJustin: null,
      videoShauna: null,
      videoVeon: null,
    },
    pickedVideoName:{
      videoAlex: null,
      videoJustin: null,
      videoShauna: null,
      videoVeon: null,
    },
    videoFileSize: {
      videoAlexSize: "",
      videoJustinSize: "",
      videoShaunaSize: "",
      videoVeonSize: "",
    },
     typePickedVideo: {
      videoAlexType: "",
      videoJustinType: "",
      videoShaunaType: "",
      videoVeonType: "",
    },
    pickedImage: {
      imageAlex: null,
      imageJustin: null,
      imageShauna: null,
      imageVeon: null,
    },
    pickedImageName:{
      imageAlex: null,
      imageJustin: null,
      imageShauna: null,
      imageVeon: null,
    },
    imageFileSize: {
      imageAlexSize: "",
      imageJustinSize: "",
      imageShaunaSize: "",
      imageVeonSize: "",
    },
    typePickedImage: {
      imageAlexType: "",
      imageJustinType: "",
      imageShaunaType: "",
      imageVeonType: "",
    },
    isLoadingUniqueVideo:false,
     snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
    actualTranslateLang:"ENG",
    listLanguages:[
      {
        name:"English",
        code:"ENG",
        flag:require("@/assets/flags/usa-flag.png")
      },
      {
        name:"Japanese",
        code:"JAP",
        flag:require("@/assets/flags/japan-flag.jpg")
      },
      {
        name:"Spanish",
        code:"ESP",
        flag:require("@/assets/flags/spain-flag.png")
      },
      {
        name:"Korean",
        code:"KOR",
        flag:require("@/assets/flags/south-korea-flag.png")
      },
    ],
    dialogUploadVideo:{
      show:false,
      message:''
    },
    dialogUploadUnique:{
      show:false,
      message:''
    },
    dialogUploadImage:{
      show:false,
      message:''
    },
    //////////////////////
    /// Select values ///
    ////////////////////
    listDifficulty: [
      {
        name: "Easy",
        value: 1,
      },
      {
        name: "Mid",
        value: 2,
      },
      {
        name: "Hard",
        value: 3,
      },
    ],
    listGoal: [
      {
        name: "Burn",
        value: 1,
      },
      {
        name: "Tone",
        value: 2,
      },
      {
        name: "Build",
        value: 3,
      },
    ],
    listType: [
      {
        name: "Resistance",
        value: 1,
      },
      {
        name: "Suspension",
        value: 2,
      },
      {
        name: "Plyometrics",
        value: 3,
      },
      {
        name: "Stretch",
        value: 4,
      },
    ],
    listGenBodyArea: [
      {
        name: "Upper Body",
        value: 1,
      },
      {
        name: "Lower Body",
        value: 2,
      },
      {
        name: "Full Body",
        value: 3,
      },
      {
        name: "Core",
        value: 4,
      },
    ],
    listSpecMuscleGr: [
      {
        name: "Arms",
        value: 1,
      },
      {
        name: "Legs",
        value: 2,
      },
      {
        name: "Abs",
        value: 3,
      },
      {
        name: "Chest",
        value: 4,
      },
      {
        name: "Lower Back",
        value: 5,
      },
      {
        name: "Upper Back",
        value: 6,
      },
      {
        name: "Shoulders",
        value: 7,
      },
      {
        name: "Hips",
        value: 8,
      },
      {
        name: "Full Body",
        value: 9,
      },
    ],
    listSecMuscleGr: [
      {
        name: "Arms",
        value: 1,
      },
      {
        name: "Legs",
        value: 2,
      },
      {
        name: "Abs",
        value: 3,
      },
      {
        name: "Chest",
        value: 4,
      },
      {
        name: "Lower Back",
        value: 5,
      },
      {
        name: "Upper Back",
        value: 6,
      },
      {
        name: "Shoulders",
        value: 7,
      },
      {
        name: "Hips",
        value: 8,
      },
      {
        name: "Full Body",
        value: 9,
      },
    ],
    listOneGymPlacement: [
      {
        name: "None",
        value: "",
      },
      {
        name: "Floor",
        value: 1,
      },
      {
        name: "Upper Track/Door",
        value: 2,
      },
      {
        name: "Mid Track/Door",
        value: 5,
      },
      {
        name: "Lower Track/Door",
        value: 3,
      },
      {
        name: "Bench",
        value: 4,
      },
    ],
    listOneGymSecPlacement: [
      {
        name: "None",
        value: "",
      },
      {
        name: "Floor",
        value: 1,
      },
      {
        name: "Upper Track/Door",
        value: 2,
      },
      {
        name: "Lower Track/Door",
        value: 3,
      },
      {
        name: "Bench",
        value: 4,
      },
    ],
    listAccessory: [
      {
        name: "None",
        value: "",
      },
      {
        name: "Long/Short Bar",
        value: 1,
      },
      {
        name: "Handles",
        value: 2,
      },
      {
        name: "Suspension Handles",
        value: 3,
      },
      {
        name: "Ankle Straps",
        value: 4,
      },
      {
        name: "Jump Belt",
        value: 5,
      },
      {
        name: "Bench",
        value: 6,
      },
    ],
    listSecAccessory: [
      {
        name: "None",
        value: "",
      },
      {
        name: "Long/Short Bar",
        value: 1,
      },
      {
        name: "Handles",
        value: 2,
      },
      {
        name: "Suspension Handles",
        value: 3,
      },
      {
        name: "Ankle Straps",
        value: 4,
      },
      {
        name: "Jump Belt",
        value: 5,
      },
      {
        name: "Bench",
        value: 6,
      },
    ],
    listPowerScoreAvgWatts:[
      {
        name: "None(0)",
        value: 0,
      },
      {
        name: "Slow(50)",
        value: 50,
      },
      {
        name: "Normal(75)",
        value: 75,
      },
      {
        name: "High(150)",
        value: 150,
      },
      {
        name: "Very High(300)",
        value: 300,
      }
    ]
  }),
  async created() {    
    const vm = this;
    vm.getExercise(vm.$route.params)
    vm.getAllTrainers()
  },
  methods: {
    async getAllTrainers(){
      const vm = this 
      try {
        vm.$emit("showOverlay", true);
          vm.loading = true
          await vm.$store.dispatch("GetTrainersList")
          await vm.$store.dispatch('GetSubscriptionsList')
          vm.loading = false
          vm.$emit("showOverlay", false);
      } catch (error) {
        vm.$emit("showOverlay", false);
          vm.loading = false
          console.log(error);
      }
    },
    openDialogAddTrainer(){
      const vm = this
      vm.dialogAddTrainer.show = true
    },
    closeDialogAddTrainer(){
      const vm = this
      vm.dialogAddTrainer = {
        show:false,
        trainer:{
          
          }
      }
      // vm.$refs.refAddVideoTrainer.$refs.input.value = ''
      // vm.$refs.refAddImageTrainer.$refs.input.value = ''
    },
    saveDialogAddTrainer(){
      const vm = this
      if(!vm.exercise.trainersList){
        vm.$set(vm.exercise, 'trainersList', [])
      }
      const newTrainer = {
        ...vm.dialogAddTrainer.trainer,
        exVideo:{
          video:null,
          link:'',
          ref:'',
          size:'',
          type:''
        },
        exImage:{
          img:null,
          link:'',
          ref:'',
          size:'',
          type:''
        },
        isLoadingVideo: false
      }
      vm.exercise.trainersList.push(newTrainer)
      vm.closeDialogAddTrainer()
    },
    getVideoTrainer(file){
      const vm = this
       if(file){
        if (file.type === 'video/mp4') {
          const fr = new FileReader();
          fr.onload = (el) => {
            const video = {
              video: el.target.result,
              file: file.type.split("/")[1],
              size:vm.getFileSize(file.size, 2),
              link:""
            }
            vm.$set(vm.exercise.trainersList[vm.indexToChangeVideo], 'exVideo', video)
            console.log('added video', vm.exercise.trainersList);
          };
          fr.readAsDataURL(file);
        }else{
           let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Only .mp4 files are accepted",
              color: "red",
            };
          vm.$emit("showSnackBar", emitData);
        }
      }

    },
    getImageTrainer(file){
      const vm = this
      if(file){
        if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
          const fr = new FileReader();
          fr.onload = (el) => {
            const image = {
              img: el.target.result,
              file: file.type.split("/")[1],
              size:vm.getFileSize(file.size, 2),
              link:""
            }
            vm.$set(vm.exercise.trainersList[vm.indexToChangeImage], 'exImage', image)
          };
          fr.readAsDataURL(file);
        }else{
           let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Only .jpeg and .png files are accepted",
              color: "red",
            };
          vm.$emit("showSnackBar", emitData);
        }
      }
    },
    getUniqueVideo(file){
      const vm = this
      if(file){
        if (file.type === 'video/mp4') {
          const fr = new FileReader();
          fr.onload = (el) => {
            const video = {
              vid: el.target.result,
              link:"",
              size:vm.getFileSize(file.size, 2),
              type: file.type.split("/")[1],
            }
            vm.exercise.video = video
          };
          fr.readAsDataURL(file);
        }else{
          let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Only .mp4 files are accepted",
              color: "red",
            };
          vm.$emit("showSnackBar", emitData);
        }
      }

    },
    getUniqueImage(file){
      const vm = this
      if(file){
         if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
          const fr = new FileReader();
          fr.onload = (el) => {
            const image = {
              img: el.target.result,
              link:"",
              size:vm.getFileSize(file.size, 2),
              type: file.type.split("/")[1],
            }
            vm.exercise.image = image
          };
          fr.readAsDataURL(file);
        }else{
          let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Only .jpeg and .png files are accepted",
              color: "red",
            };
          vm.$emit("showSnackBar", emitData);
        }
      }

    },
    isSuccessReAuth(dialogReAuthResponse){
      const vm = this 
        switch (dialogReAuthResponse.action) {
          case 'uploadImagesExercise':
            vm.uploadImagesExercise()
            break;
          default:
            break;
        }
    },
    openDialogReAuth(action){
      let vm = this;
      vm.dialogReAuth.show = true
      vm.dialogReAuth.action = action
    },
    onErrorCopyLink(){
      const vm = this;
      vm.snackbar.text = "Copied !";
      vm.snackbar.color = "dark";
      vm.snackbar.snackbar = true;
    },
    copyLink(e){
      const vm = this;
      vm.snackbar.text = "Copied !";
      vm.snackbar.color = "dark";
      vm.snackbar.snackbar = true;
    },
    getPreviewImage(excercise){
      const images = [
        "imageShaunaLink",
        "imageAlexLink",
        "imageJustinLink",
        "imageVeonLink",
      ];
      let image = ''
      for (let i = 0; i < images.length; i++) {
        const trainer = images[i];
        if(excercise[trainer]){
          image = excercise[trainer]
          break
        }
      }
      if(image === '' && excercise.trainersList.length > 0){
        for (let i = 0; i < excercise.trainersList.length; i++) {
          const trainer = excercise.trainersList[i];
          if(trainer.exImage && trainer.exImage.link){
            image = trainer.exImage.link
            break
          }
        }
      }
      return image
    },
    generateDynamicLink(){
      const vm = this;
        vm.loadingDynamicLink = true

        const payload = {
          routeName: 'exercises',
          title: vm.exercise.name['ENG'],
          id: vm.exercise.id,
          img: vm.getPreviewImage(vm.exercise)
        }
       vm.$store.dispatch('GenerateDynamicLink', payload)
        .then((dynamicLinks) => {
          vm.generatingDynamicLink = true
          vm.loadingDynamicLink = false
          vm.exercise.dynamicLink = dynamicLinks.shortLink
          vm.updateExercise()
        }).catch((err) => {
          vm.generatingDynamicLink = false
          vm.loadingDynamicLink = false
            console.error(err)
        })
      
    },
    getExercise(exercise){
      const vm = this;
      exercise.id = vm.$route.params.id
      vm.$store.dispatch("GetExercise", exercise)
        .then(async (res) => {
          vm.exercise = res
          vm.exercise.id = vm.$route.params.id
          if(!vm.exercise.trainersList){
            vm.$set(vm.exercise, 'trainersList', [])
          }
          if(!vm.exercise.image){
            vm.exercise.image = {
              img:null,
              link:'',
              ref:'',
              size:'',
              type:''
            }
          }
          if(!vm.exercise.video){
            vm.exercise.video = {
              vid:null,
              link:'',
              ref:'',
              size:'',
              type:''
            }
          }
    
        }).catch((err) => {
          console.log('error', err)
        })
    },
    getVideoAlex(file) {
      const vm = this;
      if (file) {
        if (file.type === 'video/mp4') {
          const fr = new FileReader();
          fr.onloadstart = () => {
            vm.isLoadingVideo.videoAlex = true;
          };
          fr.onload = (el) => {
            vm.exercise.storageData.videoAlex = null;
            vm.pickedVideo.videoAlex = el.target.result;
            vm.pickedVideoName.videoAlex = file.name
            vm.videoFileSize.videoAlex = vm.getFileSize(file.size, 2)
            vm.typePickedVideo.videoAlex = file.type.split("/")[1]
            vm.isLoadingVideo.videoAlex = false;
          };
          fr.onerror = () => {
            let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Error charging video",
              color: "red",
            };
            vm.isLoadingVideo.videoAlex = false;
            vm.$emit("showSnackBar", emitData);
          };
          fr.readAsDataURL(file);
        }else{
          let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Only .mp4 files are accepted",
              color: "red",
            };
            vm.$emit("showSnackBar", emitData); 
        }
      }
    },
    getVideoJustin(file) {
      const vm = this;
      if (file) {
        if (file.type === 'video/mp4') {
          const fr = new FileReader();
          fr.onloadstart = () => {
            vm.isLoadingVideo.videoJustin = true;
          };
          fr.onload = (el) => {
            vm.exercise.storageData.videoJustin = null;
            vm.pickedVideo.videoJustin = el.target.result;
            vm.pickedVideoName.videoJustin = file.name
            vm.videoFileSize.videoJustin = vm.getFileSize(file.size, 2)
            vm.typePickedVideo.videoJustin = file.type.split("/")[1]
            vm.isLoadingVideo.videoJustin = false;
            vm.focusedVideoFileInput = "";
          };
          fr.onerror = () => {
            let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Error charging video",
              color: "red",
            };
            vm.isLoadingVideo.videoJustin = false;
            vm.$emit("showSnackBar", emitData);
          };
          fr.readAsDataURL(file);
        }else{
          let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Only .mp4 files are accepted",
              color: "red",
            };
            vm.$emit("showSnackBar", emitData); 
        }
      }
    },
    getVideoShauna(file) {
      const vm = this;
      if (file) {
        if (file.type === 'video/mp4') {
          const fr = new FileReader();
          fr.onloadstart = () => {
            vm.isLoadingVideo.videoShauna = true;
          };
          fr.onload = (el) => {
            vm.exercise.storageData.videoShauna = null;
            vm.pickedVideo.videoShauna = el.target.result;
            vm.pickedVideoName.videoShauna= file.name
            vm.videoFileSize.videoShauna = vm.getFileSize(file.size, 2)
            vm.typePickedVideo.videoShauna = file.type.split("/")[1]
            vm.isLoadingVideo.videoShauna = false;
            vm.focusedVideoFileInput = "";
          };
          fr.onerror = () => {
            let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Error charging video",
              color: "red",
            };
            vm.isLoadingVideo.videoShauna = false;
            vm.$emit("showSnackBar", emitData);
          };
          fr.readAsDataURL(file);
        }else{
          let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Only .mp4 files are accepted",
              color: "red",
            };
            vm.$emit("showSnackBar", emitData); 
        }
      }
    },
    getVideoVeon(file) {
      const vm = this;
      if (file) {
        if (file.type === 'video/mp4') {
          const fr = new FileReader();
          fr.onloadstart = () => {
            vm.isLoadingVideo.videoVeon = true;
          };
          fr.onload = (el) => {
            vm.exercise.storageData.videoVeon = null;
            vm.pickedVideo.videoVeon = el.target.result;
            vm.pickedVideoName.videoVeon = file.name
            vm.videoFileSize.videoVeon = vm.getFileSize(file.size, 2)
            vm.typePickedVideo.videoVeon = file.type.split("/")[1]
            vm.isLoadingVideo.videoVeon = false;
            vm.focusedVideoFileInput = "";
          };
          fr.onerror = () => {
            let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Error charging video",
              color: "red",
            };
            vm.isLoadingVideo.videoVeon = false;
            vm.$emit("showSnackBar", emitData);
          };
          fr.readAsDataURL(file);
        }else{
           let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Only .mp4 files are accepted",
              color: "red",
            };
            vm.$emit("showSnackBar", emitData);
        }
      }
    },
    goFocusImageFileInput(trainer) {
      const vm = this;
      vm.focusedImageFileInput = trainer;
      vm.$refs.exercisesImages.$refs.input.click();
    },
    getExerciseImage(file) {
      const vm = this;
      if (file) {
        if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
          const fr = new FileReader();
          fr.onload = (el) => {
            vm.exercise.storageData[vm.focusedImageFileInput] = null;
            vm.pickedImage[vm.focusedImageFileInput] = el.target.result;
            vm.pickedImageName[vm.focusedImageFileInput] = file.name
            vm.imageFileSize[vm.focusedImageFileInput] = vm.getFileSize(file.size, 2)
            vm.typePickedImage[vm.focusedImageFileInput] = file.type.split("/")[1]
            vm.focusedImageFileInput = "";
          };
          fr.onerror = () => {
            let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Error charging image",
              color: "red",
            };
            vm.$emit("showSnackBar", emitData);
          };
          fr.readAsDataURL(file);
        }else{
          let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Only .png and .jpeg files are accepted",
              color: "red",
            };
          vm.$emit("showSnackBar", emitData);
        }
      }
    },
    changeLanguage(lang){
      const vm = this
      vm.actualTranslateLang = lang.code
    },
    getBaseMETValue(typeValue) {
      if (typeValue === 1) {
        return 4;
      } else if (typeValue === 2) {
        return 5;
      } else if (typeValue === 3) {
        return 6;
      } else if (typeValue === 4) {
        return 2;
      } else {
        return 0;
      }
    },
    bodyEngMETValue(genBodyAreaValue) {
      if (genBodyAreaValue === 1) {
        return 0;
      } else if (genBodyAreaValue === 2) {
        return 1;
      } else if (genBodyAreaValue === 3) {
        return 2;
      } else if (genBodyAreaValue === 4) {
        return 2;
      } else {
        return 0;
      }
    },
    checkUploadTrainerMedia(){
       const vm = this
        vm.videosToUpload = vm.exercise.trainersList.filter(trainer => trainer.exVideo.video)
        vm.imagesToUpload = vm.exercise.trainersList.filter(trainer => trainer.exImage.img)
        if(vm.videosToUpload.length > 0){
            vm.goToUploadVideos();
        }else if(vm.imagesToUpload.length > 0){
          vm.goToUploadImages();
        }else{   
          vm.verifyAndUploadUniqueFiles();
        }

    },
    async goToUploadVideos(){
      const vm = this
      vm.dialogVideoTrainerLoadingStatus.show = true
      for (let i = 0; i < vm.videosToUpload.length; i++) {
        const trainerVideo = vm.videosToUpload[i]
        vm.counterVideo = i+1
        try {
            const storage = await vm.$store.dispatch("UploadTrainerVideo", trainerVideo.exVideo)
            const indexTrainer = vm.exercise.trainersList.findIndex(trainer => trainer.id === trainerVideo.id)
            vm.exercise.trainersList[indexTrainer].exVideo.link = storage.link
            vm.exercise.trainersList[indexTrainer].exVideo.ref = storage.ref
        } catch (error) {
          vm.dialogVideoTrainerLoadingStatus.show = false
          console.log(error);
        }    
      }
      vm.dialogVideoTrainerLoadingStatus.show = false
      vm.videosToUpload = []

      if(vm.imagesToUpload.length > 0){
        vm.goToUploadImages();
      }else{
        vm.verifyAndUploadUniqueFiles();
      }

    },
   async goToUploadImages(){
      const vm = this
      vm.dialogImageTrainerLoadingStatus.show = true
      for (let i = 0; i < vm.imagesToUpload.length; i++) {
        const trainerImage = vm.imagesToUpload[i]
        vm.counterImage = i+1
        try {
            const storage = await vm.$store.dispatch("UploadTrainerImage", trainerImage.exImage)
            const indexTrainer = vm.exercise.trainersList.findIndex(trainer => trainer.id === trainerImage.id)
            vm.exercise.trainersList[indexTrainer].exImage.link = storage.link
            vm.exercise.trainersList[indexTrainer].exImage.ref = storage.ref
        } catch (error) {
          vm.dialogImageTrainerLoadingStatus.show = false
          console.log(error);
        }    
      }
      vm.dialogImageTrainerLoadingStatus.show = false
      vm.imagesToUpload = []

      vm.verifyAndUploadUniqueFiles();

    },
    cleanTrainersFields(){
      const vm = this
      if(vm.exercise.video && vm.exercise.video.vid){
        delete vm.exercise.video.vid
      }
      if(vm.exercise.image && vm.exercise.image.img){
        delete vm.exercise.image.img
      }

      vm.exercise.trainersList = vm.exercise.trainersList.map((trainer) => {
        if(trainer.exVideo.video){
          delete trainer.exVideo.video
        }
        if(trainer.exImage.img){
          delete trainer.exImage.img
        }
        return {
          id: trainer.id,
          firstName: trainer.firstName,
          lastName: trainer.lastName,
          exVideo: trainer.exVideo,
          exImage: trainer.exImage,
        }
      })
    },
    async verifyAndUploadUniqueFiles(){
      const vm = this
      try {
        if(vm.exercise.video && vm.exercise.video.vid){
          vm.dialogUploadUnique.show = true
          const storage = await vm.$store.dispatch("UploadUniqueVideo",  vm.exercise.video)
          vm.exercise.video.link = storage.link
          vm.exercise.video.ref = storage.ref
        } 
        if(vm.exercise.image && vm.exercise.image.img){
          vm.dialogUploadUnique.show = true
          const storage = await vm.$store.dispatch("UploadUniqueImage",  vm.exercise.image)
          vm.exercise.image.link = storage.link
          vm.exercise.image.ref = storage.ref
        }
        vm.updateExercise()
    
      } catch (error) {
        vm.dialogUploadUnique.show = false
        console.log(error);
      }


    },
    numberValid(e){
      e = (e) || window.event
      const charCode = (e.which) ? e.which : e.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        e.preventDefault()
      } else {
        return true
      }
    },
    getFileSize(bytes, dp = 1) {
      const thresh = 1024;

      if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
      }
        //si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']  : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']; // original
      const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      let u = -1;
      const r = 10**dp;

      do {
        bytes /= thresh;
        ++u;
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

      return bytes.toFixed(dp) + ' ' + units[u];
    },
     updateExercise(){
        const vm = this;
        vm.$emit("showOverlay", true);
        vm.cleanTrainersFields()
        vm.$store.dispatch("UpdateExercise", vm.exercise)
        .then((id) => {
          vm.$store.dispatch("GenerateLog", {
            id: id,
            title: vm.exercise.name['ENG'],
            type: "editedExercise",
          });
          vm.$store.dispatch("CleanImageLoader")
          vm.$store.dispatch("CleanVideoLoader")
          let emitData = {
             snackbar: true,
             btn: "",
             y: "bottom",
             x: null,
             mode: "",
             timeout: 2000,
             text: "Exercise Edited successfully",
             color: "success",
           };
           
           if(!vm.generatingDynamicLink){
             vm.$router.push("/admin/exercises")
             .catch((err) => {
                vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
                vm.snackbar.color = "red darken-2";
                vm.snackbar.snackbar = true;
              })
             }
            vm.generatingDynamicLink = false
           
            vm.$emit("showOverlay", false);
           vm.$emit("showSnackBar", emitData);
        }).catch((err) => {
          vm.generatingDynamicLink = false
          let emitData = {
             snackbar: true,
             btn: "",
             y: "bottom",
             x: null,
             mode: "",
             timeout: 2000,
             text: "Error editing exercise",
             color: "red",
           };
           vm.$emit("showOverlay", false);
           vm.$emit("showSnackBar", emitData);
          console.log('error', err)
        })
    },
    async uploadVideosExercise(){
      let vm = this;
      if(Object.values(vm.pickedVideo).some(video => video !== null)){  
        let trainersVideos = [
            "videoAlex",
            "videoJustin",
            "videoShauna",
            "videoVeon",
        ]

        let promises = []

          vm.dialogUploadVideo.show = true
          for (let i = 0; i < trainersVideos.length; i++) {
            if(vm.pickedVideo[trainersVideos[i]] !== null){
              let exerciseVideoData = {
                name:vm.pickedVideoName[trainersVideos[i]],
                vid:vm.pickedVideo[trainersVideos[i]],
                trainer: [trainersVideos[i]]
              }
              promises.push( new Promise( (resolve, reject) => {
                if(vm.exercise[trainersVideos[i]] !== null){
                   vm.$store.dispatch('UploadVideoExercise', exerciseVideoData).then((reference)=> {
                     if(reference.ref === vm.exercise[trainersVideos[i]]){
                        vm.exercise[trainersVideos[i]] = reference.ref
                        vm.exercise[trainersVideos[i]+'Size'] = vm.videoFileSize[trainersVideos[i]]
                        vm.exercise[trainersVideos[i]+'Link'] = reference.link
                        vm.syncFixedVideosToTrainersList(trainersVideos[i], {
                          file: vm.typePickedVideo[trainersVideos[i]],
                          link: reference.link,
                          ref: reference.ref,
                          size: vm.videoFileSize[trainersVideos[i]]
                        })
                        resolve(true)
                     }else{
                       vm.$store.dispatch("DeleteExerciseFileStorage", vm.exercise[trainersVideos[i]])
                        .then((resDelete)=> {
                          vm.exercise[trainersVideos[i]] = reference.ref
                          vm.exercise[trainersVideos[i]+'Size'] = vm.videoFileSize[trainersVideos[i]]
                          vm.exercise[trainersVideos[i]+'Link'] = reference.link
                           vm.syncFixedVideosToTrainersList(trainersVideos[i], {
                            file: vm.typePickedVideo[trainersVideos[i]],
                            link: reference.link,
                            ref: reference.ref,
                            size: vm.videoFileSize[trainersVideos[i]]
                          })
                          resolve(true)
                        }).catch((errDelete) => {
                          console.log(errDelete)
                          reject(false)
                        })
                     }
                    
                  }).catch((err) => {
                    console.log(err)
                    reject(false)
                  })
                  

                }else{
                    vm.$store.dispatch('UploadVideoExercise', exerciseVideoData).then((reference)=> {
                    vm.exercise[trainersVideos[i]] = reference.ref
                    vm.exercise[trainersVideos[i]+'Size'] = vm.videoFileSize[trainersVideos[i]]
                    vm.exercise[trainersVideos[i]+'Link'] = reference.link
                    vm.syncFixedVideosToTrainersList(trainersVideos[i], {
                      file: vm.typePickedVideo[trainersVideos[i]],
                      link: reference.link,
                      ref: reference.ref,
                      size: vm.videoFileSize[trainersVideos[i]]
                    })
                    resolve(true)
                  }).catch((err) => {
                    console.log(err)
                    reject(false)
                  })

                }       
                })
              )
            }

          }

          await Promise.all(promises)
                  .then((pro) =>{
                    vm.dialogUploadVideo.show = false
                    vm.checkUploadTrainerMedia();
                  }).catch((err)=>{
                    vm.dialogUploadVideo.show = false
                      let emitData = {
                      snackbar: true,
                      btn: "",
                      y: "bottom",
                      x: null,
                      mode: "",
                      timeout: 2000,
                      text: "Error uploading videos",
                      color: "red",
                    };
                    vm.$emit("showSnackBar", emitData);
                    
                  })
    }else{
      vm.checkUploadTrainerMedia();
    }
   
   },
   async uploadImagesExercise(){
      let vm = this;
      if(Object.values(vm.pickedImage).some(image => image !== null)){  
        let trainersImages = [
            "imageAlex",
            "imageJustin",
            "imageShauna",
            "imageVeon",
        ]

        let promises = []

          vm.dialogUploadVideo.show = true
          for (let i = 0; i < trainersImages.length; i++) {
            if(vm.pickedImage[trainersImages[i]] !== null){
              let exerciseImageData = {
                name:vm.pickedImageName[trainersImages[i]],
                img:vm.pickedImage[trainersImages[i]],
                trainer: [trainersImages[i]]
              }
              promises.push( new Promise( (resolve, reject) => {
                if(vm.exercise[trainersImages[i]] !== null){
                  vm.$store.dispatch('UploadImageExercise', exerciseImageData).then((reference)=> {
                    if(reference.ref === vm.exercise[trainersImages[i]]){
                      console.log('same');
                      vm.exercise[trainersImages[i]] = reference.ref
                      vm.exercise[trainersImages[i]+'Size'] = vm.imageFileSize[trainersImages[i]]
                      vm.exercise[trainersImages[i]+'Link'] = reference.link
                      vm.syncFixedImagesToTrainersList(trainersImages[i], {
                        file: vm.typePickedImage[trainersImages[i]],
                        link: reference.link,
                        ref: reference.ref,
                        size: vm.imageFileSize[trainersImages[i]]
                      })
                      resolve(true)
                    }else{
                      console.log('not same');
                      vm.$store.dispatch("DeleteExerciseFileStorage", vm.exercise[trainersImages[i]])
                      .then((resDelete)=> {
                        vm.exercise[trainersImages[i]] = reference.ref
                        vm.exercise[trainersImages[i]+'Size'] = vm.imageFileSize[trainersImages[i]]
                        vm.exercise[trainersImages[i]+'Link'] = reference.link
                        vm.syncFixedImagesToTrainersList(trainersImages[i], {
                          file: vm.typePickedImage[trainersImages[i]],
                          link: reference.link,
                          ref: reference.ref,
                          size: vm.imageFileSize[trainersImages[i]]
                        })
                        resolve(true)
                      }).catch((errDelete) => {
                        console.log(errDelete)
                        reject(false)
                      })
                    }
                  }).catch((err) => {
                    console.log(err)
                    reject(false)
                  })
                  

                }else{
                    vm.$store.dispatch('UploadImageExercise', exerciseImageData).then((reference)=> {
                    vm.exercise[trainersImages[i]] = reference.ref
                    vm.exercise[trainersImages[i]+'Size'] = vm.imageFileSize[trainersImages[i]]
                    vm.exercise[trainersImages[i]+'Link'] = reference.link
                    vm.syncFixedImagesToTrainersList(trainersImages[i], {
                        file: vm.typePickedImage[trainersImages[i]],
                        link: reference.link,
                        ref: reference.ref,
                        size: vm.imageFileSize[trainersImages[i]]
                    })
                    resolve(true)
                  }).catch((err) => {
                    console.log(err)
                    reject(false)
                  })

                }       
                })
              )
            }

          }

          await Promise.all(promises)
                  .then((pro) =>{
                     if(Object.values(vm.pickedVideo).some(video => video !== null)){
                       vm.uploadVideosExercise()
                     }else{
                       vm.dialogUploadVideo.show = false
                       vm.checkUploadTrainerMedia();
                     }
                  }).catch((err)=>{
                    vm.dialogUploadVideo.show = false
                      let emitData = {
                      snackbar: true,
                      btn: "",
                      y: "bottom",
                      x: null,
                      mode: "",
                      timeout: 2000,
                      text: "Error uploading images",
                      color: "red",
                    };
                    vm.$emit("showSnackBar", emitData);
                    
                  })
    }else{
      vm.uploadVideosExercise();
    }
   
   },
   syncFixedImagesToTrainersList(trainerField, exImage){
    const vm = this
    const trainersImagesDocs = {
      imageAlex: "3l53JJQVant4pmthfxtW",
      imageJustin: "KIxekMxUeDW4oFTq9QAx",
      imageShauna: "bZeUIPsD46aQQEyiLrzC",
      imageVeon: "ETsQb742IZL5okmlUMuv",
    }
   
   const indexTrainer = vm.exercise.trainersList.findIndex(trainer => trainer.id === trainersImagesDocs[trainerField])

    if(indexTrainer > -1){
      vm.$set(vm.exercise.trainersList[indexTrainer], "exImage", exImage)
    }else{
      const trainerData = vm.getTrainersList.find(trainer => trainer.id === trainersImagesDocs[trainerField])
      vm.exercise.trainersList.push({ 
          exImage: exImage, 
          exVideo:{
            link:'',
            ref:'',
            size:'',
            type:''
          },
          firstName: trainerData.firstName,
          lastName: trainerData.lastName,
          id: trainerData.id
      })
      
    }

   },
   syncFixedVideosToTrainersList(trainerField, exVideo){
    const vm = this
    const trainersVideosDocs = {
      videoAlex: "3l53JJQVant4pmthfxtW",
      videoJustin: "KIxekMxUeDW4oFTq9QAx",
      videoShauna: "bZeUIPsD46aQQEyiLrzC",
      videoVeon: "ETsQb742IZL5okmlUMuv",
    }
   
   const indexTrainer = vm.exercise.trainersList.findIndex(trainer => trainer.id === trainersVideosDocs[trainerField])

    if(indexTrainer > -1){
      vm.$set(vm.exercise.trainersList[indexTrainer], "exVideo", exVideo)
    }else{
      const trainerData = vm.getTrainersList.find(trainer => trainer.id === trainersVideosDocs[trainerField])
      console.log('trainerData');
      vm.exercise.trainersList.push({ 
          exVideo: exVideo, 
          exImage:{
            link:'',
            ref:'',
            size:'',
            type:''
          },
          firstName: trainerData.firstName,
          lastName: trainerData.lastName,
          id: trainerData.id
      })
      
    }

   }
  },
  computed:{
     flagActualTranslate() {
      let vm = this;
      if(vm.listLanguages.length > 0){
        let currentFlag = vm.listLanguages.find(flag => flag.code === vm.actualTranslateLang);
        return currentFlag.flag;
      }
    },
    computedDisabledTrainers() {
      let vm = this;
      if(vm.getTrainersList.length > 0 && vm.exercise.trainersList){
        return vm.getTrainersList.map(trainer => {
          return {
            ...trainer,
            disabled: vm.exercise.trainersList.some(selectedTrainer => selectedTrainer.id === trainer.id)
          }
        })
      }else{
        return vm.getTrainersList
      }
    },
    ...mapGetters(["getUploadVideoLoading", "getUploadImageLoading", "getTrainersList", "getImageTrainerLoadingStatus", "getVideoTrainerLoadingStatus", "getSubscriptionsList", "getUploadUniqueVideoLoading", "getUploadUniqueImageLoading"]),
  },
  watch: {
    "exercise.type": function (newValue) {
      this.exercise.baseMET = this.getBaseMETValue(newValue);
      this.exercise.METPowerFactorWO =
        this.exercise.baseMET + this.exercise.bodyEngMET;
    },
    "exercise.genBodyArea": function (newValue) {
      this.exercise.bodyEngMET = this.bodyEngMETValue(newValue);
      this.exercise.METPowerFactorWO =
        this.exercise.baseMET + this.exercise.bodyEngMET;
    },
  },
};
</script>