<template>
    <v-container
        class="elevation-4 rounded-lg px-8 mb-5"
        style="background-color: #fff; max-width: 1400px !important"
    >
    <v-form ref="categoryArticlesRef" v-model="validCategory">
      <v-row justify="center">
        <v-col cols="12" sm="12">
            <v-btn
                color="primary"
                icon
                size="20"
                class="text-center"
                @click="$router.go(-1)"
            >
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon color="black" dark v-bind="attrs" v-on="on" size="32">mdi-translate</v-icon>
            </template>
            <span>Here you can choose the language of the exercise to translate it</span>
            </v-tooltip>
            <v-menu class="text-center">  
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon class="elevation-3">
                <v-avatar size="32">
                    <v-img :src="flagActualTranslate"></v-img>
                </v-avatar>
                </v-btn>
            </template>
            <v-list v-if="listLanguages">
                <v-list-item
                v-for="(lang, index) in listLanguages"
                :key="index"
                @click="changeLanguage(lang)"
                >
                <v-list-item-action>
                    <v-avatar size="32" class="elevation-3">
                    <v-img :src="lang.flag"></v-img>
                    </v-avatar>
                </v-list-item-action>
                </v-list-item>
             </v-list>
            </v-menu>
             <v-text-field
                v-model="categoryCreate.title[actualTranslateLang]"
                :rules="categoryRules.title"
                :disabled="loadingCreateCategory"
                placeholder="Create a new Article Category"
                class="d-inline-block mx-3"
                style="width:300px;"
                solo
                flat
                background-color="#efefef"
            ></v-text-field>
            <v-btn color="primary" small :loading="loadingCreateCategory" :disabled="!validCategory || loadingCreateCategory" @click="createCategoryArticle()">
                Create
            </v-btn>
        </v-col>
        <v-col cols="12" sm="12">
            <v-data-table
                :loading="loading"
                :headers="headers"
                :items="getCategoriesArticlesList"
                item-key="id"
                fixed-header
                class="elevation-4 mb-5"
            >
            <template v-slot:[`item.action`]="{ item }">
                <v-btn icon color="primary" @click="openDialogEdit(item)">
                <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                icon
                color="red"
                class="mr-3"
                @click="openDialogConfirm(item)"
                >
                <v-icon>mdi-delete</v-icon>
                </v-btn>
            </template>
            </v-data-table>
        </v-col>
       </v-row>
      <v-dialog
        v-model="dialogEditCategory.show"
        width="700"
        persistent
      >
        <v-card class="pa-5">
            <v-card-text class="pb-0">
                <div class="d-flex align-center">
                    <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="black" dark v-bind="attrs" v-on="on" size="32">mdi-translate</v-icon>
                    </template>
                    <span>Here you can choose the language of the exercise to translate it</span>
                    </v-tooltip>
                    <v-menu class="text-center">  
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon class="elevation-3">
                        <v-avatar size="32">
                            <v-img :src="flagActualTranslate"></v-img>
                        </v-avatar>
                        </v-btn>
                    </template>
                    <v-list v-if="listLanguages">
                        <v-list-item
                        v-for="(lang, index) in listLanguages"
                        :key="index"
                        @click="changeLanguage(lang)"
                        >
                        <v-list-item-action>
                            <v-avatar size="32" class="elevation-3">
                            <v-img :src="lang.flag"></v-img>
                            </v-avatar>
                        </v-list-item-action>
                        </v-list-item>
                    </v-list>
                    </v-menu>
                    <v-text-field
                        v-model="dialogEditCategory.category.title[actualTranslateLang]"
                        :disabled="dialogEditCategory.loading"
                        placeholder="Edit Category"
                        class="mx-3"
                        style="width:300px;"
                        solo
                        hide-details
                        flat
                        background-color="#efefef"
                    ></v-text-field>
                </div>
                <div class="text-right mt-4">
                    <v-btn color="secondary" class="mr-2" small @click="closeDialogEdit()">
                        Cancel
                    </v-btn>
                    <v-btn color="primary" small :loading="dialogEditCategory.loading" @click="editCategoryArticle()">
                        Edit
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
     </v-dialog>
     </v-form>
     <v-dialog v-model="dialog.show" width="300" class="rounded-xl">
        <v-card class="text-center">
            <v-icon class="text-center ma-2" size="120" color="primary"
            >mdi-alert</v-icon>
            <v-card-text class="pa-4 text-center"
            >This action is irreversible and will delete all data about this Category
            Article. Including the data, references and information</v-card-text>
            <v-card-text class="text-center">Are you sure?</v-card-text>

            <v-btn
            color="third"
            tile
            width="150"
            class="pa-0 text--secondary"
            @click="closeDialogConfirm()"
            >No</v-btn
            >

            <v-btn
            color="primary"
            tile
            width="150"
            class="pa-0"
            @click="openDialogReAuth('deleteCategory', dialog.item)"
            >Yes</v-btn
            >
            <!-- </v-card-actions> -->
        </v-card>
    </v-dialog>
    <modal-reauth
      :dialogReAuth="dialogReAuth"
      @isSuccessReAuth="isSuccessReAuth"
    ></modal-reauth>
    </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import modalReauth from "@/components/admin/modal-reauth.vue";
export default {
    name: "ArticlesCategories",
    components: { modalReauth },
    data: () => ({
        validCategory: false,
        categoryCreate: {
            title:{
                ENG: "",
                JAP: "",
                ESP: "",
                KOR: ""
            },
        },
        categoryRules:{
            title: [v => !!v || 'Category title is required'],
        },
        loadingCreateCategory:false,
        loading: false,
        actualTranslateLang:"ENG",
        dialogEditCategory:{
            show: false,
            category: {
                title:{}
            },
            loading: false,
        },
        dialogReAuth: {
            show: false,
            action: "",
            item: {},
        },
        dialog: {
            show: false,
            item: {},
        },
        headers: [
            { text: 'Title', align:'center', value:'title.ENG', sortable:true },
            { text: "Action", align: "center", value: "action", sortable: false },
        ],
        listLanguages:[
            {
                name:"English",
                code:"ENG",
                flag:require("@/assets/flags/usa-flag.png")
            },
            {
                name:"Japanese",
                code:"JAP",
                flag:require("@/assets/flags/japan-flag.jpg")
            },
            {
                name:"Spanish",
                code:"ESP",
                flag:require("@/assets/flags/spain-flag.png")
            },
            {
                name:"Korean",
                code:"KOR",
                flag:require("@/assets/flags/south-korea-flag.png")
            },
        ],
    }),
    created(){
        const vm = this
        vm.getAllCategoriesArticles()
    },
    methods: {
        changeLanguage(lang){
          const vm = this
          vm.actualTranslateLang = lang.code
        },
        async getAllCategoriesArticles(){
          const vm = this
          try {
            vm.$emit("showOverlay", true);
            vm.loading = true
            await vm.$store.dispatch("GetCategoriesArticlesList")
            vm.loading = false
            vm.$emit("showOverlay", false);
          } catch (error) {
            vm.$emit("showOverlay", false);
            vm.loading = false
            console.log(error);
          }
        },
        openDialogEdit(category){
          const vm = this;
          vm.dialogEditCategory = {
            show: true,
            category: category,
            loading:false
          }
        },
        closeDialogEdit(){
          const vm = this;
          vm.dialogEditCategory = {
            show: false,
            category: {
                title:{}
            },
            loading:false
          }
        },
    openDialogConfirm(item) {
      const vm = this;
      vm.dialog.show = true;
      vm.dialog.item = item;
    },
    closeDialogConfirm() {
      const vm = this;
      vm.dialog.show = false;
      vm.dialog.item = "";
    },
    openDialogReAuth(action, item) {
      let vm = this;
      vm.dialogReAuth.show = true;
      vm.dialogReAuth.action = action;
      vm.dialogReAuth.item = item;
      vm.closeDialogConfirm();
    },
    isSuccessReAuth(dialogReAuthResponse) {
      const vm = this;
      switch (dialogReAuthResponse.action) {
        case "deleteCategory":
          vm.deleteCategory(dialogReAuthResponse.item);
          break;
        default:
          break;
      }
    },
    async deleteCategory(category){
        const vm = this;
        try {
            vm.$emit("showOverlay", true);
            await vm.$store.dispatch("DeleteCategoryArticle", category)
            await vm.$store.dispatch("GenerateLog", {
                    id: category.id,
                    title: category.title['ENG'],
                    type: "deletedCategoryArticle",
            });
            vm.getAllCategoriesArticles()
            vm.$emit("showOverlay", false);
            
        } catch (error) {
            vm.$emit("showOverlay", false);
            console.error(error);
            let emitData = {
                snackbar: true,
                btn: "",
                y: "bottom",
                x: null,
                mode: "",
                timeout: 2000,
                text: "Error deleting Category Article",
                color: "red",
            };
            vm.$emit("showSnackBar", emitData);
        } 
    },
        async editCategoryArticle(){
          const vm = this
          try {
            vm.dialogEditCategory.loading = true
            const id = await vm.$store.dispatch('UpdateCategoryArticle', vm.dialogEditCategory.category)
            console.log(vm.dialogEditCategory.category.title)
            await vm.$store.dispatch("GenerateLog", {
                id: id,
                title: vm.dialogEditCategory.category.title.ENG,
                type: "editedCategoryArticle",
            })
          
            let emitData = {
                snackbar: true,
                btn: "",
                y: "bottom",
                x: null,
                mode: "",
                timeout: 2000,
                text: "Category Article edited successfully",
                color: "success",
            };
            vm.dialogEditCategory.loading = false
            vm.getAllCategoriesArticles()
            vm.closeDialogEdit()
            vm.$emit("showSnackBar", emitData);
          } catch (error) {

              let emitData = {
                  snackbar: true,
                  btn: "",
                  y: "bottom",
                  x: null,
                  mode: "",
                  timeout: 2000,
                  text: "Error editing category",
                  color: "red",
              };
              vm.loadingCreateCategory = false
              vm.$emit("showSnackBar", emitData);
          }
        },
         async createCategoryArticle(){
          const vm = this;
          try {
            vm.loadingCreateCategory = true
            const id = await vm.$store.dispatch('CreateCategoryArticle', vm.categoryCreate)
            await vm.$store.dispatch("GenerateLog", {
                id: id,
                title: vm.categoryCreate.title["ENG"],
                type: "createdCategoryArticle",
            })
          
            let emitData = {
                snackbar: true,
                btn: "",
                y: "bottom",
                x: null,
                mode: "",
                timeout: 2000,
                text: "Category Article Created successfully",
                color: "success",
            };
            vm.loadingCreateCategory = false
            vm.getAllCategoriesArticles()
            vm.$emit("showSnackBar", emitData);
          } catch (error) {

              let emitData = {
                  snackbar: true,
                  btn: "",
                  y: "bottom",
                  x: null,
                  mode: "",
                  timeout: 2000,
                  text: "Error creating category",
                  color: "red",
              };
              vm.loadingCreateCategory = false
              vm.$emit("showSnackBar", emitData);
          }
        }
    }, 
    computed:{
        flagActualTranslate() {
            let vm = this;
            if(vm.listLanguages.length > 0){
                let currentFlag = vm.listLanguages.find(flag => flag.code === vm.actualTranslateLang);
                return currentFlag.flag;
            }
        },
        ...mapGetters(["getCategoriesArticlesList"])
    }
}
</script>