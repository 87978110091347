<template>
  <v-container fluid>
    <v-row justify="space-between" class="px-10">
      <v-col cols="12" sm="12" md="12">
        <p class="text-h6 text--secondary font-weight-bold">Subscriptions getted by Users history</p>
        <!-- <v-checkbox
            v-model.lazy="queryFreeSubscriptions"
            color="primary"
            label="Show Free subscriptions?"
            hide-details
        >
        </v-checkbox> -->
      </v-col>
      <v-col cols="12">
       <v-data-table
          :items="getSubscriptionsGettedByUsers"
          :loading="loading"
          :headers="headers"
          :options.sync="options"
          :server-items-length="counterSubscriptionsGetted"
          :footer-props="{'items-per-page-options':[1, 10, 30, 50, 100]}"
          :items-per-page="10"
          item-key="id"
          fixed-header
          class="elevation-4"
        >
          <!-- <template v-slot:[`item.user`]="{ item }">
              {{ item.user.id }}
          </template> -->
          <template v-slot:[`item.user`]="{ item }">
              {{ item.user && item.user.firstName ? item.user.firstName : '' }}  {{ item.user && item.user.lastName ? item.user.lastName : ''  }}
          </template>
          <template v-slot:[`item.duration.quantity`]="{ item }">
              {{ item.duration.quantity }}  {{ item.duration.interval }}
          </template>
          <template v-slot:[`item.duration.startDate`]="{ item }">
              {{ timestampToStringDate(item.duration.startDate) }}
          </template>
          <template v-slot:[`item.duration.endDate`]="{ item }">
              {{ timestampToStringDate(item.duration.endDate) }}
          </template>
          
          <template v-slot:[`item.isActive`]="{ item }">
              <h1 v-if="item.user.activeSubscription.ref.id === item.id" :class="item.user.activeSubscription.isPremium ? 'success--text' : 'red--text'">•</h1>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color">{{
      snackbar.text
    }}</v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import modalReauth from "@/components/admin/modal-reauth.vue";
import moment from "moment";
export default {
  name: "SubscriptionsGetted",
  components: { modalReauth },
  data: () => ({
    search: "",
    loading: false,
    headers:[
      { text: 'User', align:'center', value:'user', sortable:false },
      { text: 'Subscription', align:'center', value:'typeSubscription.name.ENG', sortable:false },
      { text: 'Duration', align:'center', value:'duration.quantity', sortable:false },
      { text: 'Start Date', align:'center', value:'duration.startDate', sortable:false },
      { text: 'End Date', align:'center', value:'duration.endDate', sortable:false },
      { text: 'Pay Method', align:'center', value:'billing.payMethod', sortable:false },
      { text: 'Status Pay', align:'center', value:'billing.statusPay', sortable:false },
      { text: 'Active', align:'center', value:'isActive', sortable:false },
    ],
    queryFreeSubscriptions: true,
    options: {},
    dialogReAuth: {
      show: false,
      action: "",
      item: {},
    },
    dialog: {
      show: false,
      item: {},
    },
    snackbar:{
        snackbar:false,
        color:'dark',
        text:''
    },
  }),
  async created(){
      const vm = this
      await this.getCounterSubscriptions();
  },
  methods: {
    async getSubscriptionsByUsers(){
     const vm = this 
      try {
          vm.loading = true
          await vm.$store.dispatch("GetSubscriptionsGettedByUsersList", {...vm.options, isFree: vm.queryFreeSubscriptions })
          vm.loading = false
      } catch (error) {
        vm.loading = false
          console.log(error);
      }
    },
    async getCounterSubscriptions(){
      const vm = this 
      try {
        // vm.loading = true
          await vm.$store.dispatch("GetCounterSubscriptionsGettedByUsers", vm.queryFreeSubscriptions)
          // vm.loading = false
      } catch (error) {
          vm.loading = false
          console.log(error);
      }
    },
    timestampToStringDate(timestamp){
      const dateParsed = new Date(timestamp.seconds*1000)
      return moment(dateParsed).format('MMMM Do YYYY, h:mm:ss a')
    },
    parseToRelativeDate(date){
     return moment(date.toDate()).fromNow()
    },
  },
  computed:{
      ...mapGetters(["getSubscriptionsGettedByUsers", "counterSubscriptionsGetted"])
  },
   watch: {
      options: {
        handler () {
          this.getSubscriptionsByUsers()
        },
        deep: true,
      },
      'queryFreeSubscriptions': async function (isFree) {
          await this.getCounterSubscriptions();
          await this.getSubscriptionsByUsers();
      }
}
};
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
.protrain-image-preview{
    width: 50px; 
    height: 50px; 
    border-radius: 50%; 
    object-fit: cover; 
    vertical-align: middle;
}
</style>