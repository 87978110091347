<template>
    <v-container
        class="elevation-4 rounded-lg px-8 mb-5"
        style="background-color: #fff"
    >
    <v-form ref="formPWO" v-model="validPWO">
        <v-row justify="center">
        <v-col cols="12" sm="12">
            <v-btn color="primary" icon size="20" class="text-center" @click="$router.go(-1)">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <h3 class="secondary--text d-inline mr-2">Create Professional Workout</h3>
            <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="black" dark v-bind="attrs" v-on="on" size="32">mdi-translate</v-icon>
          </template>
          <span>Here you can choose the language of the exercise to translate it</span>
        </v-tooltip>
        <v-menu class="text-center">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon class="elevation-3">
              <v-avatar size="32">
                <v-img :src="flagActualTranslate"></v-img>
              </v-avatar>
            </v-btn>
          </template>
          <v-list v-if="listLanguages">
            <v-list-item
              v-for="(lang, index) in listLanguages"
              :key="index"
              @click="changeLanguage(lang)"
            >
              <v-list-item-action>
                <v-avatar size="32" class="elevation-3">
                  <v-img :src="lang.flag"></v-img>
                </v-avatar>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="6" class="mx-auto text-left">
            <p class="secondary--text pb-1" style="font-size: 14px">Name</p>
            <v-text-field
                v-model="pwo.name[actualTranslateLang]"
                :rules="rulesPWO.name"
                solo
                flat
                counter
                background-color="#efefef"
            ></v-text-field>
            <div class="mx-auto text-center" style="width:150px">
              <p class="secondary--text text-center pb-1">Order number</p>
              <v-text-field
                  v-model.number="pwo.orderNumber"
                  :rules="rulesPWO.orderNumber"
                  @keypress="numberValid($event)"
                  maxlength="4"
                  class="centered-input"
                  solo
                  flat
                  background-color="#efefef"
              ></v-text-field>
            </div>
            <v-expansion-panels accordion flat>
            <v-expansion-panel>
              <v-expansion-panel-header class="secondary--text" style="border-bottom: #efeeee 2px solid">Workout Values</v-expansion-panel-header>
              <v-expansion-panel-content>
            <v-row justify="center">
                <v-col cols="12" sm="4">
                    <p class="secondary--text" style="font-size: 14px">Circuit Begginer</p>
                    <v-select
                    v-model="pwo.circuitBegginer"
                    :items="listCircuit"
                    item-text="value"
                    item-value="value"
                    solo
                    flat
                    background-color="#efefef"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="4">
                    <p class="secondary--text" style="font-size: 14px">Circuit Intermediate</p>
                    <v-select
                    v-model="pwo.circuitIntermediate"
                    :items="listCircuit"
                    item-text="value"
                    item-value="value"
                    solo
                    flat
                    background-color="#efefef"
                    ></v-select>
                </v-col>
                <v-col cols="12" sm="4">
                    <p class="secondary--text" style="font-size: 14px">Circuit Advanced</p>
                    <v-select
                    v-model="pwo.circuitAdvanced"
                    :items="listCircuit"
                    item-text="value"
                    item-value="value"
                    solo
                    flat
                    background-color="#efefef"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" sm="4">
                    <p class="secondary--text" style="font-size: 14px">Est. Cal Begginer</p>
                    <v-text-field
                        v-model.number="pwo.estCalBegginer"
                        :rules="rulesPWO.estCalBegginer"
                        @keypress="numberValid($event)"
                        maxlength="4"
                        class="centered-input"
                        solo
                        flat
                        background-color="#efefef"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                    <p class="secondary--text" style="font-size: 14px">Est. Cal Intermediate</p>
                    <v-text-field
                        v-model.number="pwo.estCalIntermediate"
                        :rules="rulesPWO.estCalIntermediate"
                        @keypress="numberValid($event)"
                        maxlength="4"
                        class="centered-input"
                        solo
                        flat
                        background-color="#efefef"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                    <p class="secondary--text" style="font-size: 14px">Est. Cal Advanced</p>
                    <v-text-field
                        v-model.number="pwo.estCalAdvanced"
                        :rules="rulesPWO.estCalAdvanced"
                        @keypress="numberValid($event)"
                        maxlength="4"
                        class="centered-input"
                        solo
                        flat
                        background-color="#efefef"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row justify="start">
                 <v-col cols="12" sm="4">
                    <p class="secondary--text" style="font-size: 14px">Est. Time Begginer</p>
                    <v-text-field
                        v-model.number="pwo.estTimeBegginer"
                        :rules="rulesPWO.estTimeBegginer"
                        @keypress="numberValid($event)"
                        maxlength="3"
                        class="centered-input"
                        solo
                        flat
                        background-color="#efefef"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                    <p class="secondary--text" style="font-size: 14px">Est. Time Intermediate</p>
                    <v-text-field
                        v-model.number="pwo.estTimeIntermediate"
                        :rules="rulesPWO.estTimeIntermediate"
                        @keypress="numberValid($event)"
                        maxlength="3"
                        class="centered-input"
                        solo
                        flat
                        background-color="#efefef"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                    <p class="secondary--text" style="font-size: 14px">Est. Time Advanced</p>
                    <v-text-field
                        v-model.number="pwo.estTimeAdvanced"
                        :rules="rulesPWO.estTimeAdvanced"
                        @keypress="numberValid($event)"
                        maxlength="3"
                        class="centered-input"
                        solo
                        flat
                        background-color="#efefef"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                <p class="secondary--text" style="font-size: 14px">Default Rest Time</p>
                    <v-text-field
                        v-model.number="pwo.defaultRestTime"
                        :rules="rulesPWO.defaultRestTime"
                        @keypress="numberValid($event)"
                        maxlength="2"
                        class="centered-input"
                        solo
                        flat
                        background-color="#efefef"
                    ></v-text-field>
                 </v-col>
                 <v-col cols="12" sm="4">
                     <p class="secondary--text" style="font-size: 14px">Super Sets</p>
                        <v-select
                        v-model="pwo.superSets"
                        :items="listSuperSet"
                        item-text="value"
                        item-value="value"
                        solo
                        flat
                        background-color="#efefef"
                        ></v-select>
                     <!-- <v-text-field
                        v-model.number="pwo.superSets"
                        :rules="rulesPWO.superSets"
                        @keypress="numberValid($event)"
                        maxlength="2"
                        class="centered-input"
                        solo
                        flat
                        background-color="#efefef"
                    ></v-text-field> -->
                    
                 </v-col>
            </v-row>
            </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="12" sm="12" md="3" class="mx-auto text-left">
            <p class="secondary--text pb-1" style="font-size: 14px">Change image</p>
            <v-card
            color="#efefef"
            class="rounded-lg elevation-0 d-flex mt-5 justify-center align-center"
            min-width="250"
            min-height="250"
            @click="$refs.imagePWO.$refs.input.click()"
            >
            <v-img :src="pwo.image ? pwo.image : ''" cover width="250" class="rounded-lg" height="250">
            </v-img>
                <v-icon large color="primary" style="position: absolute; transform: translate(50%,-50%); top: 50%; right: 50%;"> mdi-camera </v-icon>
            </v-card>
            <v-row>
                <v-col cols="12" sm="6">
                        <v-switch
                        color="primary"
                        class="d-flex justify-center center-switch"
                        v-model="pwo.bySets"
                        :input-value="pwo.bySets"
                        flat
                        >
                        <template v-slot:label>
                            <p class="secondary--text d-inline mb-0" style="font-size: 14px">By sets</p>
                        </template>
                        </v-switch>
                </v-col>
                <v-col cols="12" sm="6">
                       <v-switch
                        color="primary"
                        class="d-flex justify-center center-switch"
                        v-model="pwo.byTime"
                        :input-value="pwo.byTime"
                        flat
                        >
                        <template v-slot:label>
                            <p class="secondary--text d-inline mb-0" style="font-size: 14px">By time</p>
                        </template>
                        </v-switch>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="3" class="mx-auto text-left">
            <p class="secondary--text pb-1" style="font-size: 14px">Description</p>
            <v-textarea
                v-model="pwo.description"
                :rules="rulesPWO.description"
                counter
                solo
                flat
                no-resize
                height="250"
                background-color="#efefef"
            >
            </v-textarea>
            <v-row>
                <v-col cols="12" sm="6" class="pr-0" style="padding-top:2px">
                    <v-switch
                    color="primary"
                    class="d-flex justify-center center-switch ma-0"
                    v-model="pwo.customSets"
                    :input-value="pwo.customSets"
                    flat
                    >
                    <template v-slot:label>
                        <p class="secondary--text d-inline mb-0" style="font-size: 14px">Custom Sets</p>
                    </template>
                    </v-switch>
                </v-col>
                <v-col cols="12" sm="6" style="padding-top:2px">
                    <v-switch
                    color="primary"
                    class="d-flex justify-center center-switch ma-0"
                    v-model="pwo.enabled"
                    :input-value="pwo.enabled"
                    flat
                    >
                    <template v-slot:label>
                        <p class="secondary--text d-inline mb-0" style="font-size: 14px">Enabled</p>
                    </template>
                    </v-switch>
                </v-col>
            </v-row>
        </v-col>
        </v-row>
    </v-form>
    <v-row justify="start" align="center">
      <v-col cols="12" sm="12" md="2">
        <p class="secondary--text mb-0">Exercises</p>
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <!-- <v-btn
          text
          color="secondary"
          :disabled="!proTrainer.principalVideo || isFinal"
          @click="openModalNewVideoSection()"
        >
          <v-icon left dark color="primary"> mdi-plus-circle </v-icon>
          New Video Sections
        </v-btn> -->
        <v-btn
          text
          color="secondary"
          @click="openModalAddOrEditExercise(true)"
        >
          <v-icon left dark color="primary"> mdi-plus-circle </v-icon>
          Add Exercise
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="12" md="2">
        <v-btn color="primary" :disabled="!validPWO || !pwo.image" @click="uploadImage()">Finish</v-btn>
      </v-col>
    </v-row>
    <!-- <hr class="mb-3" /> -->
     <v-row justify="start">
      <v-col cols="12" md="12">
          <v-data-table
            v-if="pwo.exercises.length > 0"
            :headers="headersExercises"
            :items="pwo.exercises"
            :items-per-page="5"
            fixed-header
            class="elevation-4"
          >
          <template v-slot:[`item.time`]="{ item }">
            {{ item.time.minutes }} Minutes {{ item.time.seconds }} seconds
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              icon
              color="primary"
              size="20"
              small
              @click="openModalAddOrEditExercise(false, item, pwo.exercises.indexOf(item))"
              ><v-icon>mdi-pencil</v-icon></v-btn>
            <v-btn
              icon
              color="red"
              size="20"
              small
              @click="deleteSection(pwo.exercises.indexOf(item))"
              ><v-icon>mdi-delete</v-icon></v-btn
            >
          </template>
        </v-data-table>
        <!-- <v-list flat> -->
          <!-- <v-list-item-group v-model="selectedExerciseSection">
            <v-list-item
              v-for="(exercise, i) in pwo.exercises"
              :key="i"
            >
              <v-list-item-content>
                <v-list-item-title class="d-inline"
                  ><p class="font-weight-medium d-inline">
                    {{ exercise.name }}
                  </p>
                   <v-btn
                    icon
                    color="primary"
                    size="20"
                    small
                    @click="openModalEditExercise(exercise, i)"
                    ><v-icon>mdi-pencil</v-icon></v-btn>
                  <v-btn
                    icon
                    color="red"
                    size="20"
                    small
                    @click="deleteSection(i)"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list> -->
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogAddOrEditExercise.show"
      width="700"
      
      class="ma-0"
      persistent
    >
      <v-card style="overflow: hidden !important;">
        <v-card-actions class="pa-0">
          <v-btn icon color="red" class="ml-3 mt-2" @click="closeModalAddOrEditExercise()">
            <v-icon size="20">mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <!-- <v-stepper  v-model="stepperSectionsVideo" class="elevation-0">
           
          <v-stepper-items>
              <v-stepper-content :step="2" class="pa-0 pb-4"> -->
            <v-form ref="formAddExercise" v-model="validAddExercise">
                <v-card class="elevation-0" v-if="dialogAddOrEditExercise.isAdd">
                  <v-container class="pa-0">
                    <v-row justify="center">
                       <v-col cols="12" md="11">
                         <p class="secondary--text pl-2">Exercise</p>
                         <v-autocomplete
                          v-model="dialogAddOrEditExercise.add.selectedExercise"
                          :rules="rulesDialogAddOrEditExercise.selectedExercise"
                          :items="exercisesList"
                          solo
                          flat
                          background-color="#efefef"
                          hide-selected
                          item-text="name.ENG"
                          :item-value="dialogAddOrEditExercise.add.selectedExercise.id"
                          placeholder="Search excercise by name here"
                          @keydown.enter.stop.prevent
                          return-object
                        ></v-autocomplete>
                    </v-col>
                    </v-row>
                    <v-row justify="center">
                       <v-col cols="12" md="3">
                         <p class="secondary--text pl-2 text-center">Multiply Factor</p>
                         <v-text-field
                          v-if="dialogAddOrEditExercise.add"
                          v-model="dialogAddOrEditExercise.add.multiplyFactor"
                          :rules="rulesDialogAddOrEditExercise.multiplyFactor"
                          @keypress="numberFloatValid($event)"
                          maxlength="3"
                          class="centered-input"
                          solo
                          flat
                          background-color="#efefef"
                        ></v-text-field>
                       </v-col>
                       <v-col cols="12" md="3">
                         <p class="secondary--text pl-2 text-center">Reps</p>
                         <v-text-field
                          v-model="dialogAddOrEditExercise.add.reps"
                          :rules="rulesDialogAddOrEditExercise.reps"
                          @keypress="numberValid($event)"
                          maxlength="3"
                          class="centered-input"
                          solo
                          flat
                          background-color="#efefef"
                        ></v-text-field>
                       </v-col>
                       <v-col cols="12" md="3">
                         <p class="secondary--text pl-2 text-center">Rest Time</p>
                         <v-text-field
                          v-model.number="dialogAddOrEditExercise.add.restTime"
                          :rules="rulesDialogAddOrEditExercise.restTime"
                          @keypress="numberValid($event)"
                          maxlength="3"
                          class="centered-input"
                          solo
                          flat
                          background-color="#efefef"
                        ></v-text-field>
                       </v-col>
                    </v-row>
                    <v-row justify="center">
                       <v-col cols="12" md="3">
                         <p class="secondary--text pl-2 text-center">Sets</p>
                         <v-text-field
                          v-model.number="dialogAddOrEditExercise.add.sets"
                          :rules="rulesDialogAddOrEditExercise.sets"
                          @keypress="numberValid($event)"
                          maxlength="3"
                          class="centered-input"
                          solo
                          flat
                          background-color="#efefef"
                        ></v-text-field>
                       </v-col>
                       <v-col cols="12" md="3">
                         <p class="secondary--text pl-2 text-center">Super Set</p>
                          <v-select
                            v-model="dialogAddOrEditExercise.add.superSet"
                            :items="listSuperSet"
                            item-text="value"
                            item-value="value"
                            solo
                            flat
                            background-color="#efefef"
                          ></v-select>
                         <!-- <v-text-field
                          v-model.number="dialogAddOrEditExercise.add.superSet"
                          :rules="rulesDialogAddOrEditExercise.superSet"
                          @keypress="numberValid($event)"
                          maxlength="3"
                          class="centered-input"
                          solo
                          flat
                          background-color="#efefef"
                        ></v-text-field> -->
                       </v-col>
                       <v-col cols="12" md="4">
                         <p class="secondary--text pl-2 text-center">Time</p>
                         <v-text-field
                          v-model="dialogAddOrEditExercise.add.time"
                          :rules="rulesDialogAddOrEditExercise.time"
                          maxlength="5"
                          class="centered-input"
                          solo
                          flat
                          background-color="#efefef"
                        ></v-text-field>
                       </v-col>
                    </v-row>
                  </v-container>
                </v-card>
                <template v-else>
                  <v-card class="elevation-0">
                  <v-container class="pa-0">
                    <v-row justify="center">
                       <v-col cols="12" md="11">
                         <p class="secondary--text pl-2">Exercise</p>
                         <v-autocomplete
                          v-model="dialogAddOrEditExercise.edit.selectedExercise"
                          :rules="rulesDialogAddOrEditExercise.selectedExercise"
                          :items="exercisesList"
                          solo
                          flat
                          background-color="#efefef"
                          hide-selected
                          item-text="name.ENG"
                          :item-value="dialogAddOrEditExercise.add.selectedExercise.id"
                          placeholder="Search excercise by name here"
                          @keydown.enter.stop.prevent
                          return-object
                        ></v-autocomplete>
                    </v-col>
                    </v-row>
                    <v-row justify="center">
                       <v-col cols="12" md="3">
                         <p class="secondary--text pl-2 text-center">Multiply Factor</p>
                         <v-text-field
                          v-if="dialogAddOrEditExercise.edit"
                          v-model="dialogAddOrEditExercise.edit.multiplyFactor"
                          :rules="rulesDialogAddOrEditExercise.multiplyFactor"
                          @keypress="numberFloatValid($event)"
                          maxlength="3"
                          class="centered-input"
                          solo
                          flat
                          background-color="#efefef"
                        ></v-text-field>
                       </v-col>
                       <v-col cols="12" md="3">
                         <p class="secondary--text pl-2 text-center">Reps</p>
                         <v-text-field
                          v-model="dialogAddOrEditExercise.edit.reps"
                          :rules="rulesDialogAddOrEditExercise.reps"
                          @keypress="numberValid($event)"
                          maxlength="3"
                          class="centered-input"
                          solo
                          flat
                          background-color="#efefef"
                        ></v-text-field>
                       </v-col>
                       <v-col cols="12" md="3">
                         <p class="secondary--text pl-2 text-center">Rest Time</p>
                         <v-text-field
                          v-model.number="dialogAddOrEditExercise.edit.restTime"
                          :rules="rulesDialogAddOrEditExercise.restTime"
                          @keypress="numberValid($event)"
                          maxlength="3"
                          class="centered-input"
                          solo
                          flat
                          background-color="#efefef"
                        ></v-text-field>
                       </v-col>
                    </v-row>
                    <v-row justify="center">
                       <v-col cols="12" md="3">
                         <p class="secondary--text pl-2 text-center">Sets</p>
                         <v-text-field
                          v-model.number="dialogAddOrEditExercise.edit.sets"
                          :rules="rulesDialogAddOrEditExercise.sets"
                          @keypress="numberValid($event)"
                          maxlength="3"
                          class="centered-input"
                          solo
                          flat
                          background-color="#efefef"
                        ></v-text-field>
                       </v-col>
                       <v-col cols="12" md="3">
                         <p class="secondary--text pl-2 text-center">Super Set</p>
                         <v-select
                            v-model="dialogAddOrEditExercise.edit.superSet"
                            :items="listSuperSet"
                            item-text="value"
                            item-value="value"
                            solo
                            flat
                            background-color="#efefef"
                          ></v-select>
                         
                         <!-- <v-text-field
                          v-model.number="dialogAddOrEditExercise.edit.superSet"
                          :rules="rulesDialogAddOrEditExercise.superSet"
                          @keypress="numberValid($event)"
                          maxlength="3"
                          class="centered-input"
                          solo
                          flat
                          background-color="#efefef"
                        ></v-text-field> -->
                       </v-col>
                       <v-col cols="12" md="4">
                         <p class="secondary--text pl-2 text-center">Time</p>
                         <v-text-field
                          v-model="dialogAddOrEditExercise.edit.time"
                          :rules="rulesDialogAddOrEditExercise.time"
                          maxlength="5"
                          class="centered-input"
                          solo
                          flat
                          background-color="#efefef"
                        ></v-text-field>
                       </v-col>
                    </v-row>
                  </v-container>
                </v-card>
                </template>
                <div class="text-center">
                  <v-btn
                    color="primary"
                    class="text-left mb-5"
                    :disabled="!validAddExercise"
                    @click="dialogAddOrEditExercise.isAdd ? saveAddExercise() : saveEditExercise()"
                  >
                    Save
                  </v-btn>
                </div>
            </v-form>
              <!-- </v-stepper-content>
          </v-stepper-items>
        </v-stepper> -->
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogUploadImagePwo"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Uploading image, please wait...
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="getLoadingImagePwo"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ##################-->
   <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
        snackbar.text
      }}</v-snackbar>
    <v-file-input
      ref="imagePWO"
      class="d-none"
      color="white"
      accept="image/png, image/jpeg"
      dark
      outlined
      :show-size="1000"
      @change="getImagePWO"
    />
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import firebase from "firebase";
export default {
  name: "ProfessionalWorkoutCreate",
  data: () => ({
      validPWO:false,
      validAddExercise:false,
      dialogUploadImagePwo:false,
      exercisesList: [],
      pwo:{
          bySets:false,
          byTime:true,
          circuitBegginer:1,
          circuitIntermediate:1,
          circuitAdvanced:1,
          customSets:false,
          defaultRestTime:0,
          description:"",
          enabled:true,
          estCalBegginer:0,
          estCalIntermediate:0,
          estCalAdvanced:0,
          estTimeIntermediate:0,
          estTimeBegginer:0,
          estTimeAdvanced:0,
          exercises:[],
          image:'',
          name:{},
          superSets:null, // 0 = null
          orderNumber:''
      },
      rulesPWO:{
        name: [v => !!v || 'Name is required', v => (v && v.length >= 4) || 'Name must be greater than 4'],
        description: [(v) => !!v || "Description section is required"],
        estCalBegginer:[(v) => (v != '' || parseInt(v) === 0)  || "Est. Cal Begginer is required", (v) => v <= 2000  || "Est. Cal Begginer cannot be greater than 2000"],
        estCalIntermediate:[(v) => (v != '' || parseInt(v) === 0)  || "Est. Cal Intermediate is required", (v) => v <= 2000  || "Est. Cal Intermediate cannot be greater than 2000"],
        estCalAdvanced:[(v) => (v != '' || parseInt(v) === 0)  || "Est. Cal Advanced is required", (v) => v <= 2000  || "Est. Cal Advanced cannot be greater than 2000"],
        estTimeBegginer:[(v) => (v != '' || parseInt(v) === 0)  || "Est. Time Begginer is required", (v) => v <= 100  || "Est. Time Begginer cannot be greater than 100"],
        estTimeIntermediate:[(v) => (v != '' || parseInt(v) === 0)  || "Est. Time Intermediate is required", (v) => v <= 100  || "Est. Time Intermediate cannot be greater than 100"],
        estTimeAdvanced:[(v) => (v != '' || parseInt(v) === 0)  || "Est. Time Advanced is required", (v) => v <= 100  || "Est. Time Advanced cannot be greater than 100"],
        defaultRestTime:[(v) => (v != '' || parseInt(v) === 0)  || "Default Rest Time is required", (v) => v <= 30  || "Default Rest Time cannot be greater than 30"],
        superSets:[(v) => (v != '' || parseInt(v) === 0)  || "Super Sets is required", (v) => v <= 10  || "Super Sets cannot be greater than 10"],
      },
      rulesDialogAddOrEditExercise:{
        selectedExercise:[(v) => !!v || "Exercise is required"],
        multiplyFactor:[(v) => (v != '' || parseFloat(v) === 0)  || "Multiply factor is required", (v) =>  (v == 0 || (v.substr(v.length - 1) !== '.' && v.charAt(0) !== '.' ))  || "Please enter a valid decimal value", (v) => v <= 10  || "Multiply factor cannot be greater than 10"],
        reps:[(v) => (v != '' || parseInt(v) === 0)  || "Reps is required", (v) => v <= 10  || "Reps cannot be greater than 10"],
        restTime:[(v) => (v != '' || parseInt(v) === 0)  || "Rest Time is required", (v) => v <= 100  || "Rest Time cannot be greater than 100"],
        sets:[(v) => (v != '' || parseInt(v) === 0)  || "Sets is required", (v) => v <= 50  || "Sets cannot be greater than 50"],
        superSet:[(v) => (v != '' || parseInt(v) === 0)  || "Super Sets is required", (v) => v <= 10  || "Super Sets cannot be greater than 10"],
        time: [(v) => !!v || "End section is required",(v) => /^([0-5]\d:[0-5]\d$)/.test(v) || "End section format must be valid"],
      },
      snackbar: {
        snackbar: false,
        timeout: 2000,
        text: null,
        color: "",
      },
      dialogAddOrEditExercise:{
          show:false,
          isAdd:false,
          add:{
            selectedExercise:'',
            multiplyFactor:0,
            reps:0,
            restTime:0,
            sets:0,
            superSet:null,
            time:'00:00'
          },
          edit:{
            selectedExercise:'',
            multiplyFactor:0,
            reps:0,
            restTime:0,
            sets:0,
            superSet:null,
            time:'00:00'
          }
      },
      headersExercises:[
      { text: 'Excercise', align:'center', value:'exercise.name.ENG', sortable:true },
      { text: 'Multiply Factor', align:'center', value:'multiplyFactor', sortable:true },
      { text: 'Reps', align:'center', value:'reps', sortable:true },
      { text: 'Sets', align:'center', value:'sets', sortable:true },
      { text: 'Rest Time', align:'center', value:'restTime', sortable:true },
      { text: 'Time', align:'center', value:'time', sortable:true },
      { text: 'Actions', align:'center', value:'actions', sortable:true },
    ],
      actualTranslateLang:"ENG",
      listLanguages:[
      {
        name:"English",
        code:"ENG",
        flag:require("@/assets/flags/usa-flag.png")
      },
      {
        name:"Japanese",
        code:"JAP",
        flag:require("@/assets/flags/japan-flag.jpg")
      },
      {
        name:"Spanish",
        code:"ESP",
        flag:require("@/assets/flags/spain-flag.png")
      },
      {
        name:"Korean",
        code:"KOR",
        flag:require("@/assets/flags/south-korea-flag.png")
      },
    ],
    selectedExerciseSection:{},
    //////////////////////
    /// Select values ///
    ////////////////////
    listCircuit:[
        {
            value:1
        },
        {
            value:2
        },
        {
            value:3
        },
        {
            value:4
        },
        {
            value:5
        },
        {
            value:6
        },
        {
            value:7
        },
        {
            value:8
        },
        {
            value:9
        },
        {
            value:10
        },
    ],
    listSuperSet:[
        {
            value:null
        },
        {
            value:1
        },
        {
            value:2
        },
        {
            value:3
        },
        {
            value:4
        },
        {
            value:5
        },
        {
            value:6
        },
        {
            value:7
        },
        {
            value:8
        },
        {
            value:9
        },
        {
            value:10
        },
    ],

  }),
   created() {
    let vm = this;
    vm.$emit("showOverlay", true);
    vm.$store
      .dispatch("GetExercises")
      .then(async(exercises) => {
        vm.exercisesList = exercises
        // await vm.getExercisesNewList()
        vm.$emit("showOverlay", false)
      })
      .catch((err) => {
        console.error(err);
        vm.$emit("showOverlay", false);
      });
  },
  methods:{
    async getExercisesNewList(){
      const vm = this
      try {
        await vm.$store.dispatch('GetExercisesNew')
      } catch (error) {
        console.error(error)
      }
    },
    changeLanguage(lang){
        const vm = this
        vm.actualTranslateLang = lang.code
    },
    getImagePWO(file){
      const vm = this;
      if(file){
        if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
          const fr = new FileReader();
          fr.onload = (el) => {
            vm.pwo.image = el.target.result
          };
          fr.readAsDataURL(file);
        }else{
            let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Only .png and .jpeg files are accepted",
              color: "red",
            };
          vm.$emit("showSnackBar", emitData);
        }     
      }
    },
    openModalAddOrEditExercise(isAdd, exercise, i){
        let vm = this
        if(!isAdd){
          vm.dialogAddOrEditExercise.edit = { 
            selectedExercise:exercise.exercise,
            multiplyFactor:exercise.multiplyFactor,
            reps:exercise.reps,
            restTime:exercise.restTime,
            sets:exercise.sets,
            superSet:exercise.superSet,
            time:moment("1900-01-01 00:00:00").add(`00:${exercise.time.minutes}:${exercise.time.seconds}`, "seconds").format("mm:ss"),
            index:i
          }
        }
        vm.dialogAddOrEditExercise.isAdd = isAdd
        vm.dialogAddOrEditExercise.show = true
    },
    closeModalAddOrEditExercise(){
      let vm = this
      vm.dialogAddOrEditExercise = {
          show:false,
          isAdd:false,
          add:{
            selectedExercise:'',
            multiplyFactor:0,
            reps:0,
            restTime:0,
            sets:0,
            superSet:null,
            time:'00:00'
          },
          edit:{
            selectedExercise:'',
            multiplyFactor:0,
            reps:0,
            restTime:0,
            sets:0,
            superSet:null,
            time:'00:00'
          }
      }
      vm.$nextTick(() => {
        if (vm.$refs.formAddExercise) {
          vm.$refs.formAddExercise.resetValidation();
        }
      });
    },
    saveAddExercise(){
      let vm = this
      let parserTime = moment.duration(`00:${vm.dialogAddOrEditExercise.add.time}`, 'seconds')
      let dataToSave = {
          exercise:vm.dialogAddOrEditExercise.add.selectedExercise,
          multiplyFactor:parseFloat(vm.dialogAddOrEditExercise.add.multiplyFactor),
          reps:vm.dialogAddOrEditExercise.add.reps,
          restTime:vm.dialogAddOrEditExercise.add.restTime,
          sets:vm.dialogAddOrEditExercise.add.sets,
          superSet:vm.dialogAddOrEditExercise.add.superSet,
          time:{
            minutes:parserTime.get('minutes'),
            seconds:parserTime.get('seconds')
          }
      }
      vm.pwo.exercises.push(dataToSave)
      vm.closeModalAddOrEditExercise()
    },
    saveEditExercise(){
      let vm = this
      let parserTime = moment.duration(`00:${vm.dialogAddOrEditExercise.edit.time}`, 'seconds')
      let dataToEdit = {
          exercise:vm.dialogAddOrEditExercise.edit.selectedExercise,
          multiplyFactor:parseFloat(vm.dialogAddOrEditExercise.edit.multiplyFactor),
          reps:vm.dialogAddOrEditExercise.edit.reps,
          restTime:vm.dialogAddOrEditExercise.edit.restTime,
          sets:vm.dialogAddOrEditExercise.edit.sets,
          superSet:vm.dialogAddOrEditExercise.edit.superSet,
          time:{
            minutes:parserTime.get('minutes'),
            seconds:parserTime.get('seconds')
          }
      }
      if(vm.pwo.exercises[vm.dialogAddOrEditExercise.edit.index]){
        vm.$set(vm.pwo.exercises, vm.dialogAddOrEditExercise.edit.index, dataToEdit);
      }
      vm.closeModalAddOrEditExercise()
    },
    deleteSection(index) {
      const vm = this;
      vm.pwo.exercises.splice(index, 1);
    },
    numberValid (e) {
      e = (e) || window.event
      const charCode = (e.which) ? e.which : e.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        e.preventDefault()
      } else {
        return true
      }
    },
    numberFloatValid(e){
      e = (e) || window.event
      const charCode = (e.which) ? e.which : e.keyCode
     if (charCode > 31 && (charCode != 46 &&(charCode < 48 || charCode > 57))) {
        e.preventDefault()
      } else {
        return true
      }
    },
    uploadImage(){
      let vm = this;
      vm.dialogUploadImagePwo = true
       vm.$store
        .dispatch("UploadImagePWO", vm.pwo)
        .then((storage) => {
          vm.pwo.image = storage.link
          vm.dialogUploadImagePwo = false
          vm.createPWO();
          
        }).catch((err) => {
          console.log(err);
          vm.dialogUploadImagePwo = false

           let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Error uploading image",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);
        })
    },
    createPWO(){
      let vm = this;
      vm.$emit("showOverlay", true);
      for (let i = 0; i < vm.pwo.exercises.length; i++) {
        let idExercise = vm.pwo.exercises[i].exercise.id
        let collectionName = vm.pwo.exercises[i].exercise.isReactNativeExercise ? 'excercisesNew' : 'excercises'
        let documentExercise = firebase.firestore().collection(collectionName).doc(idExercise)
        vm.pwo.exercises[i].exercise = documentExercise
      }
      vm.$store.dispatch('CreatePWO', vm.pwo)
        .then((id) => {
          vm.$store.dispatch("GenerateLog", {
            id: id,
            title: vm.pwo.name['ENG'],
            type: "createdPWO",
          });

           let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Professional Workout Created successfully",
            color: "success",
          };
          vm.$emit("showOverlay", false);
          vm.$emit("showSnackBar", emitData);
          vm.$router.push("/admin/pwo")
          .catch((err) => {
            vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
            vm.snackbar.color = "red darken-2";
            vm.snackbar.snackbar = true;
          })
          // vm.$emit("showSnackBar", emitData);
          
        }).catch((err)=>{

           let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Error creating Professional Workout",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);

        })

    }
  },
  computed:{
     flagActualTranslate() {
      let vm = this;
      if(vm.listLanguages.length > 0){
        let currentFlag = vm.listLanguages.find(flag => flag.code === vm.actualTranslateLang);
        return currentFlag.flag;
      }
    },
    ...mapGetters(["getLoadingImagePwo"]),
  },
}
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
</style>