<template>
  <v-container fluid>
    <v-row justify="space-between" class="px-10">
      <v-col cols="12" sm="12" md="2">
        <p class="text-h6 text--secondary font-weight-bold">Challenges</p>
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <v-btn text color="secondary" @click="goCreateProgram()">
          <v-icon left dark color="primary"> mdi-plus-circle </v-icon>
          Create new Challenge
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-text-field label="Search for a Challenge" v-model="search" single-line outlined rounded filled dense
          append-icon="mdi-magnify"></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-data-table :search="search" :loading="loading" :headers="headers" :items="getProgramsList" :sort-desc="true"
          :custom-sort="customSort" item-key="id" fixed-header class="elevation-4"
          :page.sync="page" @update:page="onPageChange"
          :items-per-page.sync="itemsPerPage" @update:items-per-page="onItemsPerPageUpdate" 
          :sortBy.sync="sortBy" @update:sortBy="onSortByChange"
          >
          <template v-slot:[`item.selectedImagePreview`]="{ item }">
            <v-tooltip top color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <img v-if="item.selectedImagePreview.link" v-bind="attrs" v-on="on" class="program-image-preview"
                  style="cursor:pointer;" @click="openDialogPreviewImage(item.selectedImagePreview.link)"
                  :src="item.selectedImagePreview.link">
              </template>
              <span>Show Image</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.presentationVideo`]="{ item }">
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon color="primary" @click="openDialogPreviewVideo(item)">
                  <v-icon>mdi-message-video</v-icon>
                </v-btn>
              </template>
              <span>Show Video</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            {{ item.name }}
          </template>
          <template v-slot:[`item.workoutProgram`]="{ item }">
            {{ `${item.workoutProgram.weeks.length} ${item.workoutProgram.weeks.length === 1 ? 'Week' : 'Weeks'}` }}
          </template>
          <template v-slot:[`item.enabled`]="{ item }">
            <v-switch color="primary" class="center-switch" :input-value="item.enabled" flat
              @click="disableProgram(item)"></v-switch>
          </template>
          <template v-slot:[`item.dynamicLink`]="{ item }">
            <v-btn icon color="primary" @click="openDialogDynamicLink(item)">
              <v-icon>mdi-link</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn icon color="primary" @click="goEditProgram(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon color="red" class="mr-3" @click="openDialogConfirm(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <v-dialog v-model="dialog.show" width="300" class="rounded-xl">
        <v-card class="text-center">
          <!-- <v-card-title class="headline">Use Google's location service?</v-card-title> -->
          <v-icon class="text-center ma-2" size="120" color="primary">mdi-alert</v-icon>
          <v-card-text class="pa-4 text-center">This action is irreversible and will delete all data about the Program.
            Including the video, image, sections and information</v-card-text>
          <v-card-text class="text-center">Are you sure?</v-card-text>

          <!-- <v-card-actions class="pa-0"> -->
          <!-- <v-spacer></v-spacer> -->

          <v-btn color="third" tile width="150" class="pa-0 text--secondary" @click="closeDialogConfirm()">No</v-btn>

          <v-btn color="primary" tile width="150" class="pa-0"
            @click="openDialogReAuth('deleteProgram', dialog.item)">Yes</v-btn>
          <!-- </v-card-actions> -->
        </v-card>
      </v-dialog>
      <modal-reauth :dialogReAuth="dialogReAuth" @isSuccessReAuth="isSuccessReAuth"></modal-reauth>
    </v-row>
    <v-dialog v-model="dialogPreviewVideo.show" transition="dialog-bottom-transition" max-width="700">
      <v-card class="text-center">
        <v-card-actions class="pa-0">
          <v-btn icon color="secondary" class="ml-3 mt-2" @click="closeDialogVideoPreview()">
            <v-icon size="20">mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <img src="@/assets/logo_x.png" style="vertical-align:middle;" class="mx-2 d-inline" width="20">
        <p class="font-weight-bold secondary--text d-inline">{{ dialogPreviewVideo.name }}</p>
        <v-card-text class="pb-8 mt-3">
          <video v-if="dialogPreviewVideo.video" ref="listProgramVideoPreview" width="100%" height="350px" controls
            class="mx-auto" :src="dialogPreviewVideo.video ? dialogPreviewVideo.video : ''"></video>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogPreviewImage.show" max-width="800">
      <v-card style="line-height: 0; !important;">
        <v-btn fab x-small color="red" style="position:absolute; top:5px; left: 5px;" @click="closeDialogImagePreview()">
          <v-icon size="15" color="white">mdi-close</v-icon>
        </v-btn>
        <img :src="dialogPreviewImage.img" style="width:100%; height:100%;">
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDynamicLink.show" transition="dialog-bottom-transition" max-width="700">
      <v-card class="text-center">
        <v-card-actions class="pa-0">
          <v-btn icon color="secondary" class="ml-3 mt-2" @click="closeDialogDynamicLink()">
            <v-icon size="20">mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text class="pb-8 mt-3">
          <div class="text-center d-flex align-center mt-5">
            <template
              v-if="dialogDynamicLink.program && dialogDynamicLink.program.dynamicLink && dialogDynamicLink.program.dynamicLink.length > 0">
              <v-text-field v-model="dialogDynamicLink.program.dynamicLink" ref="programDynamicLinkRef"
                hide-details="auto" maxlength="4" class="centered-input" readonly solo flat
                background-color="#efefef"></v-text-field>
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon color="primary"
                    @click="copyLinkProgram(dialogDynamicLink.program.dynamicLink)">
                    <v-icon>mdi-plus-box-multiple</v-icon>
                  </v-btn>
                </template>
                <span>Copy to clipboard</span>
              </v-tooltip>
            </template>
            <template v-else>
              <p class="secondary--text subtitle-1 mx-auto">There is no dynamic link for this workout yet</p>
            </template>
          </div>
          <div class="text-center mb-3">
            <v-btn class="mt-3 text-center" text color="secondary" :disabled="dialogDynamicLink.loading"
              :loading="dialogDynamicLink.loading" @click="generateDynamicLink()">
              <v-icon left dark color="primary"> mdi-link-plus </v-icon>
              Generate Dynamic Link
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
      snackbar.text
    }}</v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import modalReauth from '@/components/admin/modal-reauth.vue';
export default {
  name: "ProgramsList",
  components: { modalReauth },
  data: () => ({
    search: "",
    loading: true,
    dialogReAuth: {
      show: false,
      action: '',
      item: {}
    },
    dialog: {
      show: false,
      item: {},
    },
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
    headers: [
      { text: "", align: "center", value: "selectedImagePreview", sortable: false },
      { text: "Preview", align: "center", value: "presentationVideo", sortable: false },
      { text: "Order Number", align: "center", value: "orderNumber", sortable: true },
      { text: "Name", align: "center", value: "name", sortable: true },
      { text: "Duration", align: "center", value: "workoutProgram", sortable: true },
      { text: "Enabled", align: "center", value: "enabled", sortable: false },
      { text: "Link", align: "center", value: "dynamicLink", sortable: false, },
      { text: "Action", align: "center", value: "action", sortable: false },
    ],
    dialogPreviewVideo: {
      show: false,
      video: '',
      name: ''
    },
    dialogPreviewImage: {
      show: false,
      video: '',
    },
    dialogDynamicLink: {
      show: false,
      loading: false,
      program: {},
    },
    page: 0,
    itemsPerPage: 10,
    sortBy: "name.ENG"
  }),
  created() {
    const vm = this
    vm.getPrograms();
    if (this.$route.params.previousRoute === 'Program Edit') {
      const tableState = localStorage.getItem('programTableState');
      if (tableState) {
        const tableStateObj = JSON.parse(tableState);
        this.search = tableStateObj.query;
        this.itemsPerPage = tableStateObj.hitsPerPage;
        this.page = tableStateObj.page;
      }
    }
  },
  methods: {
    getPrograms() {
      const vm = this
      vm.$store.dispatch('GetProgramsList')
        .then((res) => {
          vm.loading = false
        }).catch((err) => {
          console.error(err)
          vm.loading = false
        })
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] === "orderNumber") {
          if (!isDesc[0]) {
            if (b.orderNumber === '') {
              return b.orderNumber - a.orderNumber
            } else {
              return a.orderNumber - b.orderNumber
            }
          } else {
            return b.orderNumber - a.orderNumber
          }
        } else {
          if (!isDesc[0]) {
            return a[index] < b[index] ? -1 : 1;
          } else {
            return b[index] < a[index] ? -1 : 1;
          }
        }
      });
      return items;
    },
    isSuccessReAuth(dialogReAuthResponse) {
      const vm = this
      switch (dialogReAuthResponse.action) {
        case 'deleteProgram':
          vm.deleteProgram(dialogReAuthResponse.item)
          break;
        default:
          break;
      }
    },
    openDialogReAuth(action, item) {
      let vm = this;
      vm.dialogReAuth.show = true
      vm.dialogReAuth.action = action
      vm.dialogReAuth.item = item
      vm.closeDialogConfirm();
    },
    goCreateProgram() {
      let vm = this;
      vm.$router.push({ name: "Program Create" })
        .catch((err) => {
          vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
          vm.snackbar.color = "red darken-2";
          vm.snackbar.snackbar = true;
        })
    },
    goEditProgram(item) {
      let vm = this;
      vm.$router.push({ name: "Program Edit", params: item })
        .catch((err) => {
          vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
          vm.snackbar.color = "red darken-2";
          vm.snackbar.snackbar = true;
        })
    },
    async disableProgram(program) {
      const vm = this
      const { collectionName, enabled, id, name } = program
      await vm.$store.dispatch('DisableProgram', { id, collectionName, enabled })
      await vm.$store.dispatch("GenerateLog", {
        id: id,
        title: name,
        type: "editedProgram",
      })

      let emitData = {
        snackbar: true,
        btn: "",
        y: "bottom",
        x: null,
        mode: "",
        timeout: 2000,
        text: "Program edited successfully",
        color: "success",
      };
      vm.$emit("showSnackBar", emitData);
      vm.getPrograms()
    },
    deleteProgram(program) {
      const vm = this
      vm.$emit("showOverlay", true);
      vm.$store
        .dispatch("DeleteProgram", program)
        .then((res) => {
          vm.$store.dispatch("GenerateLog", {
            id: program.id,
            title: program.name,
            type: "deletedProgram",
          })
          vm.$emit("showOverlay", false);
        })
        .catch((err) => {
          vm.$emit("showOverlay", false);
          console.error(err);
          let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Error deleting Program",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);
        });
    },
    openDialogConfirm(item) {
      let vm = this;
      vm.dialog.show = true;
      vm.dialog.item = item;
    },
    closeDialogConfirm() {
      let vm = this;
      vm.dialog.show = false;
      vm.dialog.item = {};
    },

    openDialogPreviewVideo(program) {
      const vm = this
      vm.dialogPreviewVideo = {
        show: true,
        video: program.presentationVideo && program.presentationVideo.link ? program.presentationVideo.link : '',
        name: program.name
      }
    },
    closeDialogVideoPreview() {
      const vm = this
      vm.dialogPreviewVideo = {
        show: false,
        video: '',
        name: ''
      }
    },
    openDialogPreviewImage(img) {
      const vm = this
      vm.dialogPreviewImage = {
        show: true,
        img: img ? img : ''
      }
    },
    closeDialogImagePreview() {
      const vm = this
      vm.dialogPreviewImage.show = false
      // vm.dialogPreviewImage = {
      //   show: false,
      //   img: ''
      // }
    },
    openDialogDynamicLink(program) {
      const vm = this
      vm.dialogDynamicLink = {
        show: true,
        loading: false,
        program
      }
    },
    closeDialogDynamicLink() {
      const vm = this
      vm.dialogDynamicLink = {
        show: false,
        loading: false,
        program: {}
      }
    },
    onErrorCopyLinkProgram() {
      const vm = this;
      vm.snackbar.text = "Error coping link !";
      vm.snackbar.color = "red";
      vm.snackbar.snackbar = true;
    },
    copyLinkProgram(link) {
      const vm = this;
      navigator.clipboard.writeText(link)
        .then(() => {
          vm.snackbar.text = "Copied !";
          vm.snackbar.color = "dark";
          vm.snackbar.snackbar = true;
        }).catch((err) => {
          vm.snackbar.text = "Error coping link !";
          vm.snackbar.color = "red";
          vm.snackbar.snackbar = true;
        })
    },
    generateDynamicLink() {
      const vm = this;
      vm.dialogDynamicLink.loading = true

      const payload = {
        routeName: 'ProgramDetail',
        title: vm.dialogDynamicLink.program.name,
        id: vm.dialogDynamicLink.program.id,
        img: vm.dialogDynamicLink.program.selectedImagePreview.link
      }
      vm.$store.dispatch('GenerateDynamicLink', payload)
        .then((dynamicLinks) => {
          vm.dialogDynamicLink.loading = false
          vm.dialogDynamicLink.program.dynamicLink = dynamicLinks.shortLink
          vm.updateProgram(vm.dialogDynamicLink.program)
            .then((program) => {
              // vm.closeDialogDynamicLink()
            })
            .catch((errUpdate) => {
              vm.dialogDynamicLink.loading = false
              console.error(errUpdate)
            })
        }).catch((err) => {
          vm.dialogDynamicLink.loading = false
          console.error(err)
        })

    },
    updateProgram(program) {
      return new Promise((resolve, reject) => {
        const vm = this
        vm.$store.dispatch('UpdateProgram', program)
          .then((id) => {
            vm.$store.dispatch("GenerateLog", {
              id: id,
              title: program.name,
              type: "editedProgram",
            })

            let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Program Updated successfully",
              color: "success",
            };
            vm.$emit("showSnackBar", emitData);
            resolve(program)

          }).catch((err) => {
            let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Error updating Program",
              color: "red",
            };
            vm.$emit("showSnackBar", emitData);
            reject(err)
          })
      })
    },
    onPageChange(page) {
      console.log(page);
    },
    onItemsPerPageUpdate(items) {
      console.log(items);
    },
    onSortByChange(sortBy, test) {
      console.log(sortBy, test);
    }
  },
  computed: {
    ...mapGetters(["getProgramsList"]),
  },
  beforeRouteLeave(to, from, next) {
    console.log(to);
    if (to.name === 'Program Edit') {
      const currentState = JSON.stringify({ hitsPerPage: this.itemsPerPage, query: this.search, page: this.page });
      localStorage.setItem('programTableState', currentState);
    } else {
      localStorage.removeItem('programTableState');
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === 'Program Edit') {
      to.params.previousRoute = from.name;
    }
    next();
  }
}
</script>
<style scoped>
.program-image-preview {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  vertical-align: middle;
}

.center-switch>.v-input__control>.v-input__slot {
  justify-content: center !important;
}

.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
</style>