<template>
  <v-container
    class="elevation-4 rounded-lg px-8 mb-5"
    style="background-color: #fff"
  >
    <v-form ref="formTrainer" v-model="validArticle">
      <v-row justify="center">
        <v-col cols="12" sm="12">
          <v-btn
            color="primary"
            icon
            size="20"
            class="text-center"
            @click="$router.go(-1)"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <h3 class="secondary--text d-inline mr-2">Create Article</h3>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon color="black" dark v-bind="attrs" v-on="on" size="32">mdi-translate</v-icon>
            </template>
            <span>Here you can choose the language of the Article to translate it</span>
            </v-tooltip>
            <v-menu class="text-center">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon class="elevation-3">
                <v-avatar size="32">
                    <v-img :src="flagActualTranslate"></v-img>
                </v-avatar>
                </v-btn>
            </template>
            <v-list v-if="listLanguages">
                <v-list-item
                v-for="(lang, index) in listLanguages"
                :key="index"
                @click="changeLanguage(lang)"
                >
                <v-list-item-action>
                    <v-avatar size="32" class="elevation-3">
                    <v-img :src="lang.flag"></v-img>
                    </v-avatar>
                </v-list-item-action>
                </v-list-item>
            </v-list>
            </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="4" class="mx-auto text-center">
            <!-- <p class="secondary--text mt-5 text-left d-inline">Avatar Trainer</p> -->
            <v-card
            color="#efefef"
            class="rounded-lg elevation-0 d-flex mt-5 mx-auto justify-center align-center"
            max-width="300"
            max-height="300"
            @click="$refs.articleImage.$refs.input.click()"
            >
            <v-img :src="article.image.img ? article.image.img : ''" cover width="250" class="rounded-lg" height="250">
            </v-img>
                <v-icon large color="primary" style="position: absolute; transform: translate(50%,-50%); top: 50%; right: 50%;"> mdi-camera </v-icon>
            </v-card>
             <div class="mx-auto mt-5 text-center" style="width:150px">
                <p class="secondary--text text-center pb-1">Order number</p>
                <v-text-field
                    v-model.number="article.orderNumber"
                    @keypress="numberValid($event)"
                    maxlength="4"
                    class="centered-input"
                    solo
                    flat
                    background-color="#efefef"
                ></v-text-field>
            </div>
            <p class="secondary--text text-center pb-1">Enabled</p>
            <v-switch
                color="primary"
                class="d-flex justify-center center-switch"
                v-model="article.enabled"
                :input-value="article.enabled"
                flat
            ></v-switch>
            <p class="secondary--text text-center pb-1">Show in Home</p>
            <v-switch
                color="primary"
                class="d-flex justify-center center-switch"
                v-model="article.showInHome"
                :input-value="article.showInHome"
                flat
            ></v-switch>
        </v-col>
          <v-col cols="12" sm="12" md="4" class="mx-auto">
             <p class="secondary--text pb-1">Category</p>
              <v-select
                v-model="article.category"
                :rules="articleRules.category"
                :items="getCategoriesArticlesList"
                item-text="title.ENG"
                return-object
                solo
                flat
                background-color="#efefef"
              ></v-select>
            <p class="secondary--text pb-1">Title</p>
            <v-text-field
            v-model="article.title[actualTranslateLang]"
            :rules="articleRules.title"
            solo
            flat
            background-color="#efefef"
            ></v-text-field>
            <p class="secondary--text pb-1">Link</p>
             <v-text-field
              v-model="article.link"
              :rules="articleRules.link"
              solo
              flat
              background-color="#efefef"
            ></v-text-field>
            <p class="secondary--text pb-1">Content</p>
            <v-textarea v-model="article.content[actualTranslateLang]" :rules="articleRules.content" solo flat counter auto-grow min-height="355" background-color="#efefef">
            </v-textarea>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="primary" class="float-right" :disabled="!article.image.img || !validArticle" @click="uploadArticleImage()">Create</v-btn>
         </v-col>
      </v-row>
    </v-form>
    <v-dialog
      v-model="dialogUploadImage"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Uploading image, please wait...
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="getLoadingStatusArticleImage"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
        snackbar.text
      }}</v-snackbar>
     <v-file-input
      ref="articleImage"
      class="d-none"
      color="white"
      accept="image/png, image/jpeg"
      dark
      outlined
      :show-size="1000"
      @change="getArticleImage"
    />
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name:"ArticlesCreate",
  data: () => ({
      validArticle:false,
      article:{
          title:{
            ENG:"",
            KOR:"",
            ESP:"",
            JAP:"",
          },
          content:{
            ENG:"",
            KOR:"",
            ESP:"",
            JAP:"",
          },
          image:{
            img:null,
            link:'',
            ref:'',
            size:'',
            type:''
          },
          category:"",
          enabled:true,
          orderNumber:'',
          link:"",
          showInHome:false,
      },
      articleRules:{
        category: [v => !!v || 'Category is required'],
        title: [v => !!v || 'Title is required'],
        content: [(v) => !!v || "Content section is required"],
        link:[v => /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(^$)/.test(v) || 'Insert a valid url, like: https://maxprofitness.com/']
      },
      snackbar: {
        snackbar: false,
        timeout: 2000,
        text: null,
        color: "",
      },
      dialogUploadImage:false,
      actualTranslateLang:"ENG",
      listLanguages:[
        {
            name:"English",
            code:"ENG",
            flag:require("@/assets/flags/usa-flag.png")
        },
        {
            name:"Japanese",
            code:"JAP",
            flag:require("@/assets/flags/japan-flag.jpg")
        },
        {
            name:"Spanish",
            code:"ESP",
            flag:require("@/assets/flags/spain-flag.png")
        },
        {
            name:"Korean",
            code:"KOR",
            flag:require("@/assets/flags/south-korea-flag.png")
        },
      ],
  }),
  created(){
      const vm = this
      vm.getArticlesCategories()
  },
  methods:{
    async getArticlesCategories(){
        const vm = this
        try {
            vm.$emit("showOverlay", true);
            await vm.$store.dispatch("GetCategoriesArticlesList")
            vm.$emit("showOverlay", false);
        } catch (error) {
            vm.$emit("showOverlay", false);
            console.log(error);
        }

    },
    numberValid(e){
        e = (e) || window.event
        const charCode = (e.which) ? e.which : e.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault()
        } else {
            return true
        }
    },
    getFileSize(bytes, dp = 1) {
      const thresh = 1024;

      if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
      }
        //si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']  : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']; // original
      const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      let u = -1;
      const r = 10**dp;

      do {
        bytes /= thresh;
        ++u;
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

      return bytes.toFixed(dp) + ' ' + units[u];
    },
    changeLanguage(lang){
      const vm = this
      vm.actualTranslateLang = lang.code
    },
    getArticleImage(file){
        let vm = this
        if(file){
        if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
          const fr = new FileReader();
          fr.onload = (el) => {
              //3415460
            console.log(vm.getFileSize(3415460, 2))
            vm.article.image = {
              img: el.target.result,
              file: file.type.split("/")[1],
              size:vm.getFileSize(file.size, 2),
              link: ""
            }
          };
          fr.readAsDataURL(file);
        }else{
           let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Only .png and .jpeg files are accepted",
              color: "red",
            };
          vm.$emit("showSnackBar", emitData);
        }
      }
    },
    uploadArticleImage(){
      let vm = this;
      vm.dialogUploadImage = true
       vm.$store
        .dispatch("UploadImageArticle", vm.article)
        .then((storage) => {
          vm.article.image.link = storage.link
          vm.article.image.ref = storage.ref
          vm.dialogUploadImage = false
          vm.createArticle();
          
        }).catch((err) => {
          console.log(err);
          vm.dialogUploadImage = false

           let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Error uploading image",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);
        })
    },
    async createArticle(){
     const vm = this;
          try {
            const id = await vm.$store.dispatch('CreateArticle', vm.article)
            await vm.$store.dispatch("GenerateLog", {
                id: id,
                title: vm.article.title['ENG'],
                type: "createdArticle",
            })
          
            let emitData = {
                snackbar: true,
                btn: "",
                y: "bottom",
                x: null,
                mode: "",
                timeout: 2000,
                text: "Article Created successfully",
                color: "success",
            };
            vm.$router.push("/admin/articles")
            .catch((err) => {
                vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
                vm.snackbar.color = "red darken-2";
                vm.snackbar.snackbar = true;
            })
            vm.$emit("showSnackBar", emitData);
          } catch (error) {

              let emitData = {
                  snackbar: true,
                  btn: "",
                  y: "bottom",
                  x: null,
                  mode: "",
                  timeout: 2000,
                  text: "Error creating Article",
                  color: "red",
              };
              vm.$emit("showSnackBar", emitData);
          }
    }
  },
  computed:{
      flagActualTranslate() {
        let vm = this;
        if(vm.listLanguages.length > 0){
            let currentFlag = vm.listLanguages.find(flag => flag.code === vm.actualTranslateLang);
            return currentFlag.flag;
        }
      },
      ...mapGetters(["getCategoriesArticlesList", "getLoadingStatusArticleImage"])
  }
};
</script>