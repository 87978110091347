<template>
  <v-container fluid>
    <v-row justify="space-between" class="px-10">
      <v-col cols="12" sm="12" md="4">
        <p class="text-h6 text--secondary font-weight-bold">
          Professional Workout
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-btn
          text
          color="secondary"
          disabled
          @click="createProfessionalWorkout()"
        >
        <v-icon left dark color="primary"> mdi-plus-circle </v-icon>
         New Professional Workout
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="5">
        <v-text-field
          label="Search for a Professional Workouts"
          v-model="search"
          single-line
          outlined
          rounded
          filled
          dense
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-data-table
          :search="search"
          :loading="loading"
          :headers="headers"
          :items="getProfessionalWorkouts"
          item-key="id"
          fixed-header
          class="elevation-4"
        >
          <template v-slot:[`item.image`]="{ item }">
            <v-tooltip top color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-avatar class="my-2">
                  <img
                    v-if="item.image"
                    v-bind="attrs" 
                    v-on="on" 
                    style="cursor:pointer;"
                    @click="openDialogPreviewImage(item.image)"
                    :src="item.image" 
                    />
                </v-avatar> 
              </template>
              <span>Show Image</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            {{ item.name["ENG"] }}
          </template>
          <template v-slot:[`item.exercises`]="{ item }">
            {{ item.exercises.length }}
          </template>
          <template v-slot:[`item.enabled`]="{ item }">
            <v-switch
                color="primary"
                class="center-switch"
                :input-value="item.enabled"
                flat
                @click="disablePWO(item)"
            ></v-switch>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn icon color="primary" @click="changeRoutePWO(item)">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              icon
              color="red"
              class="mr-3"
              @click="openDialogConfirm(item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <!-- <template v-slot:footer="{}">
            <v-row justify="end">
              <v-col cols="1">
                <v-select
                  class="mr-auto"
                  v-model="itemsPerPage"
                  :items="[5,10,15,20,30]"
                >
              </v-select>
              </v-col>
              <v-col cols="2" class="text-right mr-3" align-self="center">
              <v-btn color="primary" x-small class="mx-1" :disabled="pageCounter === 1" @click="goPrevPagePWO()">
                <v-icon size="15">
                  mdi-arrow-left
                </v-icon>
              </v-btn>
              <p class="d-inline">{{ pageCounter }}</p>
              <v-btn color="primary" x-small class="mx-1" :disabled="getProfessionalWorkouts.length < itemsPerPage || getProfessionalWorkouts.length === 0" @click="goNextPagePWO()">
                <v-icon size="15">
                  mdi-arrow-right
                </v-icon>
              </v-btn>
              </v-col>
            </v-row>
          </template> -->
          <!-- <template v-slot:[`item.exercises`]="{ item }">
            {{ item.exercises.length }}
          </template> -->
         
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog.show" width="300" class="rounded-xl">
      <v-card class="text-center">
        <!-- <v-card-title class="headline">Use Google's location service?</v-card-title> -->
        <v-icon class="text-center ma-2" size="120" color="primary"
          >mdi-alert</v-icon
        >
        <v-card-text class="pa-4 text-center"
          >This action is irreversible and will delete all data about the Professional Workout. Including the video, sections and information</v-card-text
        >
        <v-card-text class="text-center">Are you sure?</v-card-text>

        <!-- <v-card-actions class="pa-0"> -->
        <!-- <v-spacer></v-spacer> -->

        <v-btn
          color="third"
          tile
          width="150"
          class="pa-0 text--secondary"
          @click="closeDialogConfirm()"
          >No</v-btn
        >

        <v-btn
          color="primary"
          tile
          width="150"
          class="pa-0"
          @click="openDialogReAuth('deletePWO', dialog.item)"
          >Yes</v-btn
        >
        <!-- </v-card-actions> -->
      </v-card>
      </v-dialog>
       <v-dialog
        v-model="dialogPreviewImage.show" 
        max-width="800"
      >
        <v-card style="line-height: 0; !important;">
          <v-btn fab x-small color="red" style="position:absolute; top:5px; left: 5px;" @click="closeDialogImagePreview()">
              <v-icon size="15" color="white">mdi-close</v-icon>
          </v-btn>
            <img :src="dialogPreviewImage.img" style="width:100%; height:100%;">
        </v-card>
      </v-dialog>
    <modal-reauth :dialogReAuth="dialogReAuth" @isSuccessReAuth="isSuccessReAuth"></modal-reauth>
    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
        snackbar.text
      }}</v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import modalReauth from '@/components/admin/modal-reauth.vue';
export default {
  name: "ProfessionalWorkout",
  components: { modalReauth },
  data: () => ({
    dialogReAuth:{
      show: false,
      action: '',
      item:{}
    },
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
    dialog:{
      show:false,
      item:{},
    },
    dialogPreviewImage:{
      show:false,
      img:''
    },
    headers: [
      { text: "", align: "center", value: "image", sortable: false },
      { text: "Order Number", align: "center", value: "orderNumber", sortable: true },
      { text: "Name", align: "center", value: "name.ENG", sortable: true },
      { text: "Excercises", align: "center", value: "exercises", sortable: true },
      { text: "Enabled", align: "center", value: "enabled", sortable: true },
      { text: "Action", align: "center", value: "action", sortable: false },
    ],
    itemsPerPage:10,
    pageCounter:1,
    search:"",
    loading:false,

  }),
  created() {
    let vm = this;
    vm.getProfessionalWorkoutsList()
  },
  methods: {
    getProfessionalWorkoutsList(){
      let vm = this
      vm.loading = true
      vm.$store.dispatch('GetPWO')
      .then((res)=>{
        vm.loading = false
      }).catch((err)=>{
        console.error(err)
        vm.loading = false
      })
    },
    getFirstPagePWO(){
      let vm = this
      vm.$store.dispatch('GetPWOPaginatedFirstPage', vm.itemsPerPage)
      .then((res)=>{
        vm.pageCounter = 1
        vm.loading = false
      }).catch((err)=>{
        console.error(err)
        vm.loading = false
      })
    },
    goNextPagePWO(){
      let vm = this
      vm.$store.dispatch('GetPWONextPage', vm.itemsPerPage)
      .then((res)=>{
        if(res.length > 0){
          vm.pageCounter = vm.pageCounter + 1
        }
        vm.loading = false
      }).catch((err)=>{
        console.error(err)
        vm.loading = false
      })
    },
    goPrevPagePWO(){
      let vm = this
      vm.$store.dispatch('GetPWOPrevPage', vm.itemsPerPage)
      .then((res)=>{
        vm.pageCounter = vm.pageCounter - 1
        vm.loading = false
      }).catch((err)=>{
        console.error(err)
        vm.loading = false
      })
    },
    changeRoutePWO(pwo){
      let vm = this;
      vm.$router.push({ name: "ProfessionalWorkout Edit", params: pwo })
      .catch((err) => {
        vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      })
    },
    openDialogConfirm(item){
      let vm = this;
      vm.dialog.show = true;
      vm.dialog.item = item;
    },
    closeDialogConfirm() {
      let vm = this;
      vm.dialog.show = false;
      vm.dialog.item = "";
    },
    isSuccessReAuth(dialogReAuthResponse){
      const vm = this 
        switch (dialogReAuthResponse.action) {
          case 'deletePWO':
            vm.deletePWO(dialogReAuthResponse.item)
            break;
          default:
            break;
        }
    },
    openDialogReAuth(action, item){
      let vm = this;
      vm.dialogReAuth.show = true
      vm.dialogReAuth.action = action
      vm.dialogReAuth.item = item
      vm.closeDialogConfirm();
    },
    openDialogPreviewImage(img){
      const vm = this
      vm.dialogPreviewImage = {
        show: true,
        img: img ? img : ''
      }
    },
    closeDialogImagePreview(){
      const vm = this
      vm.dialogPreviewImage.show = false
    },
    async disablePWO(pwo){
      const vm = this
      const { enabled, id, name } = pwo
      await vm.$store.dispatch('DisablePWO', { id, enabled })
      await vm.$store.dispatch("GenerateLog", {
        id: id,
        title: name['ENG'],
        type: "editedPWO",
      })
          
      let emitData = {
          snackbar: true,
          btn: "",
          y: "bottom",
          x: null,
          mode: "",
          timeout: 2000,
          text: "Professional Workout edited successfully",
          color: "success",
      };
      vm.$emit("showSnackBar", emitData);
      vm.getProfessionalWorkoutsList()
    },
    deletePWO(pwo){
      const vm = this
      vm.$emit("showOverlay", true);
      vm.$store
      .dispatch("DeletePWO", pwo)
      .then((res) => {
        vm.$store.dispatch("GenerateLog", {
          id: pwo.id,
          title: pwo.name['ENG'],
          type: "deletedPWO",
        })
        vm.$store.commit('DELETE_PWO_FROM_ARRAY', pwo)
        vm.$emit("showOverlay", false);
        // vm.loading = false;
      })
      .catch((err) => {
        vm.$emit("showOverlay", false);
        console.error(err);
         let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Error deleting Professional Workout",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);
      });

    },
    createProfessionalWorkout() {
      let vm = this;
      vm.$router.push({ name: "ProfessionalWorkout Create"})
      .catch((err) => {
        vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
        vm.snackbar.color = "red darken-2";
        vm.snackbar.snackbar = true;
      })
    },
  },
  destroyed() {
    let vm = this;
    // vm.$store.dispatch("GoUnsuscribePwoList");
  },
   computed: {
    ...mapGetters(["getProfessionalWorkouts"]),
  },
  // watch:{
  //   itemsPerPage:{
  //     handler(newValue){
  //       this.getFirstPagePWO()
  //     }
  //   }
  // }
};
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
</style>