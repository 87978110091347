<template>
  <v-app light class="app">
    <v-overlay :value="overlay">
      <v-progress-circular :size="250" :width="15" color="primary" indeterminate></v-progress-circular>
    </v-overlay>

    <template v-if="$route.meta.forCustomerService || $route.meta.forAdmin">
      <div>
        <v-app-bar color="third text--secondary px-10" dense flat height="100">
          <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

          <!-- <v-app-bar-nav-icon @click.stop="openDrawer()"></v-app-bar-nav-icon> -->
          <v-toolbar-title class="d-none d-sm-flex">
            <v-img src="@/assets/logo.svg" width="200"></v-img>
          </v-toolbar-title>


          <v-spacer></v-spacer>

          <template v-for="(view, index) in menuLinks">

            <v-menu open-on-hover offset-y :key="index">
              <template v-if="getAdmin.adminLevel === 3 || getAdmin.allowedRoutes" v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="getAdmin.adminLevel === 3 || view.childs.some(routeDash => getAdmin.allowedRoutes.indexOf(routeDash.name) >= 0) || checkRoute(view.name)"
                  text small color="secondary" :to="view.route" :key="index" v-bind="attrs" v-on="on">
                  {{ view.button }}
                </v-btn>
              </template>

              <v-list v-if="view.childs.length > 0" class="pa-0">
                <template v-for="(childView, indexChild) in view.childs">
                  <v-list-item :key="indexChild" v-if="checkRoute(childView.name) || getAdmin.adminLevel === 3">
                    <v-btn v-if="checkRoute(childView.name) || getAdmin.adminLevel === 3" text small color="secondary"
                      :to="childView.route" :key="indexChild">
                      <v-badge v-if="childView.name === 'Feedback List' && getCountFeedbackWithoutCategorieForBadge > 0"
                        color="red"
                        :content="getCountFeedbackWithoutCategorieForBadge < 10 ? getCountFeedbackWithoutCategorieForBadge : '+10'">
                        {{ childView.button }}
                      </v-badge>
                      <template v-else>
                        {{ childView.button }}
                      </template>
                    </v-btn>

                  </v-list-item>
                </template>
              </v-list>
            </v-menu>


          </template>



          <!-- <template v-if="getAdmin.adminLevel == 3">
          <v-btn text small color="secondary" to="/admin/dashboard">Dashboard</v-btn>
          <v-btn text small color="secondary" to="/admin/push-notifications">Push Notifications</v-btn>
          <v-btn text small color="secondary" to="/admin/pwo">Professional Workout</v-btn>
          <v-btn text small color="secondary" to="/admin/pro-trainers">Pro-Trainers</v-btn>
          <v-btn text small color="secondary" to="/admin/exercises">Exercises</v-btn>
          </template>
         <v-btn text small color="secondary" to="/admin/user-list">Users</v-btn>
         <v-btn text small color="secondary" to="/admin/logs">Logs</v-btn> -->
          <p class="text-decoration-underline font-weight-bold mb-0 ml-5 mr-5" @click="logOut()" style="cursor: pointer">
            Log Out
          </p>

          <p class="font-weight-light mb-0 mr-5">
            Hi , {{ getAdmin.firstName ? getAdmin.firstName : "Admin" }}!
          </p>

          <!-- <v-avatar size="45">
            <img src="https://cdn.vuetifyjs.com/images/john.jpg" alt="avatar" />
          </v-avatar> -->
        </v-app-bar>
      </div>
      <v-navigation-drawer v-model="drawer.show" absolute temporary>
        <div v-if="getAdmin.photoURL" class="rounded-circle avatar-profile-image mx-auto mt-2"
          :style="`background-image:url(${getAdmin.photoURL})`"></div>
        <template v-else>
          <div class="text-center">
            <img class="rounded-circle avatar-profile-image-drawer mt-2" :src="require('@/assets/default-avatar.png')">
          </div>
        </template>
        <p class="font-weight-light mb-0 text-center">
          Hi , {{ getAdmin.firstName ? getAdmin.firstName : "Admin" }}!
        </p>
        <p class="text-decoration-underline font-weight-bold mb-0 text-center secondary--text" @click="logOut()"
          style="cursor: pointer">
          Log Out
        </p>

        <v-list nav dense>
          <div v-for="(view, index) in routes" :key="index">
            <v-btn v-if="checkRoute(view.name) || getAdmin.adminLevel === 3" text small color="secondary"
              :to="view.route">
              <v-badge v-if="view.name === 'Feedback List' && getCountFeedbackWithoutCategorieForBadge > 0" color="red"
                :content="getCountFeedbackWithoutCategorieForBadge < 10 ? getCountFeedbackWithoutCategorieForBadge : '+10'">
                {{ view.button }}
              </v-badge>
              <template v-else>
                {{ view.button }}
              </template>
            </v-btn>
          </div>
          <!-- <v-list-item-group
            v-model="group"
            active-class="deep-orange--text text--accent-4"
          >
            <v-list-item>
              <v-list-item-title>Foo</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>Bar</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>Fizz</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>Buzz</v-list-item-title>
            </v-list-item>
          </v-list-item-group> -->
        </v-list>
      </v-navigation-drawer>
    </template>
    <v-content>
      <router-view @showOverlay="showOverlay" @showSnackBar="showSnackBar" />
    </v-content>
    <v-snackbar v-model="snackbar.snackbar" :bottom="snackbar.y === 'bottom'" :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'" :right="snackbar.x === 'right'" :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'" :vertical="snackbar.mode === 'vertical'" :color="snackbar.color">
      {{ snackbar.text }}
      <v-btn text @click="closeSnackBar()">{{
        snackbar.btn ? snackbar.btn : "Ok"
      }}</v-btn>
    </v-snackbar>
    <v-snackbar v-model="snackbarFeedback.show" timeout="5000" color="black lighten-2" bottom right>
      <v-icon left>mdi-alert-circle</v-icon>
      {{ snackbarFeedback.text }}
      <v-btn text @click="goToFeedback(snackbarFeedback.feedback)">
        SHOW
      </v-btn>
      <v-btn text @click="closeSnackBarFeedback()">
        <v-icon size="20">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import 'instantsearch.css/themes/satellite-min.css';
export default {
  name: "App",
  components: {},
  data: () => ({
    overlay: false,
    snackbar: {
      snackbar: false,
      btn: "",
      y: "bottom",
      x: null,
      mode: "",
      timeout: null,
      text: null,
      color: "black lighten-2",
    },
    snackbarFeedback: {
      show: false,
      text: '',
      feedback: {}
    },
    drawer: {
      show: false
    },
    group: null,
    routes: [
      {
        name: "Dashboard",
        button: "Dashboard",
        route: "/admin/dashboard",
      },
      {
        name: "Push Notifications List",
        button: "Push Notifications",
        route: "/admin/push-notifications",
      },
      {
        name: "Pre Sets List",
        button: "Pre-Sets",
        route: "/admin/pre-sets",
      },
      {
        name: "Professional Workout List",
        button: "Pre-Sets(Legacy)",
        route: "/admin/pwo",
      },
      {
        name: "CoachedClasses List",
        button: "Coached Classes",
        route: "/admin/coached-classes",
      },
      {
        name: "Programs List",
        route: "/admin/programs",
        button: "Challenges",
      },
      {
        name: "On Demand List",
        route: "/admin/on-demand",
        button: "On Demand",
      },
      {
        name: "Exercises List",
        button: "Excercises",
        route: "/admin/exercises",
      },
      {
        name: "User List",
        button: "Users",
        route: "/admin/user-list",
      },
      {
        name: "Logs",
        button: "Logs",
        route: "/admin/logs",
      },
      {
        name: "Feedback List",
        button: "Feedback",
        route: "/admin/feedback",
      },
      {
        name: "Tutorial List",
        button: "Tutorials",
        route: "/admin/tutorials",
      },
      {
        name: "Achievements List",
        button: "Achievements",
        route: "/admin/achievements",
      },
      {
        name: "App Config",
        button: "App Config",
        route: "/admin/app-config",
      },
      {
        name: "MAXPRO Devices List",
        button: "MAXPRO Devices",
        route: "/admin/maxpro-devices",
      },
      {
        name: "Subscriptions Manage List",
        button: "Subscriptions Manage",
        route: "/admin/subscriptions-manage",
      },
      {
        name: "Trainer List",
        button: "Trainers",
        route: "/admin/trainers",
      },
      {
        name: "Requests Exercises List",
        button: "Requested Exercises",
        route: "/admin/requests-exercises",
      },
      {
        name: "Exercises By Users List",
        button: "Exercises By Users",
        route: "/admin/exercises-by-users",
      },
      {
        name: "Tips List",
        button: "Tips",
        route: "/admin/tips",
      },
      {
        name: "Artcles List",
        button: "Articles",
        route: "/admin/articles",
      },
    ],
    menuLinks: [
      {
        name: "Dashboard",
        button: "Dashboard",
        route: "/admin/dashboard",
        childs: []
      },
      {
        name: "Workouts",
        button: "Workouts",
        route: "",
        childs: [
          {
            name: "Pre Sets List",
            button: "Pre-Sets",
            route: "/admin/pre-sets",
          },
          {
            name: "Professional Workout List",
            button: "Pre-Sets(Legacy)",
            route: "/admin/pwo",
          },
          {
            name: "CoachedClasses List",
            button: "Coached Classes",
            route: "/admin/coached-classes",
          },
          {
            name: "Programs List",
            route: "/admin/programs",
            button: "Challenges",
          },
          {
            name: "Exercises List",
            button: "Excercises",
            route: "/admin/exercises",
          },
          {
            name: "On Demand List",
            route: "/admin/on-demand",
            button: "On Demand",
          },
          {
            name: "Trainer List",
            button: "Trainers",
            route: "/admin/trainers",
          },
          {
            name: "Lives List",
            button: "Lives",
            route: "/admin/lives",
          },
          {
            name: "Achievements List",
            button: "Achievements",
            route: "/admin/achievements",
          },
        ]
      },
      {
        name: "Community",
        button: "Community",
        route: null,
        childs: [
          {
            name: "User List",
            button: "Users",
            route: "/admin/user-list",
          },
          {
            name: "Subscriptions Manage List",
            button: "Subscriptions Manage",
            route: "/admin/subscriptions-manage",
          },
          {
            name: "Subscriptions Getted List",
            button: "Subscriptions Getted",
            route: "/admin/subscriptions-getted",
          },
          {
            name: "Feedback List",
            button: "Feedback",
            route: "/admin/feedback",
          },

          {
            name: "Coupons List",
            button: "Coupons",
            route: "/admin/coupons",
          },
          {
            name: "Push Notifications List",
            button: "Push Notifications",
            route: "/admin/push-notifications",
          },
          {
            name: "Push Notifications By Group List",
            button: "Push Notifications By Group",
            route: "/admin/push-notifications-by-group",
          },
          {
            name: "Logs",
            button: "Logs",
            route: "/admin/logs",
          },
          {
            name: "Requests Exercises List",
            button: "Requested Exercises",
            route: "/admin/requests-exercises",
          },
          {
            name: "Exercises By Users List",
            button: "Exercises By User",
            route: "/admin/exercises-by-users",
          },
          {
            name: "MAXPRO Devices List",
            button: "MAXPRO Devices",
            route: "/admin/maxpro-devices",
          },
        ]
      },
      {
        name: "Content",
        button: "Content",
        route: null,
        childs: [
          {
            name: "Artcles List",
            button: "Articles",
            route: "/admin/articles",
          },
          {
            name: "Tutorial List",
            button: "Tutorials",
            route: "/admin/tutorials",
          },
          {
            name: "Tips List",
            button: "Tips",
            route: "/admin/tips",
          },
          {
            name: "App Config",
            button: "App Config",
            route: "/admin/app-config",
          },
          {
            name: "Firmware Version List",
            button: "Firmware Versions",
            route: "/admin/firmware-versions",
          },
        ]
      },
    ]
  }),
  created() {
    let vm = this;
    // vm.$store.dispatch('GetFeedbackNotifications')
  },
  mounted() {
    let vm = this;

    const publicRoute = vm.$route.meta.forVisitors ? true : false;

    if (publicRoute === false) {
      vm.overlay = true;
      vm.isAuthenticated()
        .then((admin) => {
          const canGetFeedbackNotifications = admin.adminLevel !== 3 ? admin.allowedRoutes.some(route => route === 'Feedback Detail') : true
          if (canGetFeedbackNotifications) {
            if (!vm.isSubscribedFeedbackListForBadge) {
              vm.$store.dispatch('GetFeedbackListWithoutCategoriePaginatedFirstPageForBadge', 10)
                .then((res) => {
                  vm.overlay = false;
                  if (!vm.isSubscribedFeedbackNotifications) {
                    vm.$store.dispatch('GetFeedbackNotifications')
                    vm.verifyPermisionsToChangeRoute();
                  } else {
                    vm.verifyPermisionsToChangeRoute();
                  }
                })
                .catch((errFeedback) => {
                  console.log(errFeedback);
                  vm.overlay = false;
                  vm.snackbar.text = "Error connecting with notifications";
                  vm.snackbar.color = "red darken-2";
                  vm.snackbar.snackbar = true;
                });
            } else {
              vm.overlay = false;
              vm.verifyPermisionsToChangeRoute();
            }
          } else {
            vm.overlay = false;
            vm.verifyPermisionsToChangeRoute();
          }
        })
        .catch((err) => {
          console.error(err)
        })

    }
  },
  methods: {
    ShowHideToolbar(isShow) {
      let vm = this;
      vm.toolbarShow = !vm.toolbarShow;
    },
    showOverlay(overlay) {
      let vm = this;
      vm.overlay = overlay;
    },
    closeSnackBar() {
      let vm = this;
      vm.snackbar.snackbar = false;
    },
    showSnackBar(snackbar) {
      let vm = this;
      vm.snackbar = snackbar;
    },
    goToFeedback(item) {
      let vm = this
      vm.$router.push({ name: "Feedback Detail", params: item })
        .catch((err) => {
          vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
          vm.snackbar.color = "red darken-2";
          vm.snackbar.snackbar = true;
        })
    },
    closeSnackBarFeedback() {
      let vm = this;
      vm.snackbarFeedback.show = false;
    },
    verifyPermisionsToChangeRoute() {
      let vm = this;
      if (vm.getAdmin.adminLevel === 3) {
        vm.$router.push({ name: 'Dashboard' })
      } else {
        vm.$router.push({ name: 'User List' });
      }
    },
    isAuthenticated() {
      let vm = this;
      return new Promise((resolve, reject) => {
        vm.$store
          .dispatch("isAuthenticated")
          .then((userID) => {
            vm.$store
              .dispatch("GetAdmin", userID)
              .then((admin) => {
                resolve(admin)
              })
              .catch((err) => {
                vm.logOut();
                reject(err)
              });
          })
          .catch((err) => {
            vm.logOut();
          });
      })
    },
    openDrawer() {
      let vm = this;
      vm.drawer.show = true
    },
    checkRoute(name) {
      let vm = this;
      if (this.getAdmin.allowedRoutes) {
        return this.getAdmin.allowedRoutes.some((route) => route === name);
      }
    },
    logOut() {
      let vm = this;
      vm.overlay = true;
      vm.$store.dispatch("Logout").then(() => {
        if (vm.isSubscribedFeedbackListForBadge) {
          vm.$store.dispatch("GoUnsuscribeGetFeedbackListForBadge")
        }
        if (vm.isSubscribedFeedbackNotifications) {
          vm.$store.dispatch("GoUnsuscribeGetFeedbackNotifications")
        }
        vm.drawer.show = false
        vm.$router.push({ path: "/" });
        vm.overlay = false;
      });
    }
  },
  computed: {
    ...mapGetters(["getAdmin", "getFeedbackNotifications", "isSubscribedFeedbackListForBadge", "isSubscribedFeedbackNotifications", "getCountFeedbackWithoutCategorieForBadge"]),
  },
  watch: {
    getFeedbackNotifications: {
      handler(newValue) {
        let notificationEntry = newValue[newValue.length - 1]
        this.snackbarFeedback = {
          show: true,
          text: `A new feedback has been generated`,
          feedback: notificationEntry
        }
      }
    },
    group() {
      this.drawer.show = false
    }
  },
  destroyed() {
    let vm = this
    if (vm.isSubscribedFeedbackListForBadge) {
      vm.$store.dispatch("GoUnsuscribeGetFeedbackListForBadge")
    }
    if (vm.isSubscribedFeedbackNotifications) {
      vm.$store.dispatch("GoUnsuscribeGetFeedbackNotifications")
    }
  }
};
</script>
<style lang="scss">
.app {
  background: rgba(0, 0, 0, 0) !important;
}

.v-btn--active span {
  color: #ec5c00 !important;
}

.v-badge__badge.red {
  color: #fff !important;
}

.avatar-profile-image-drawer {
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-position: center center;
  object-fit: contain;
}

.w-100 {
  width: 100% !important;
}
</style>
