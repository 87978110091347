<template>
  <v-container
    class="elevation-4 rounded-lg px-8 mb-5"
    style="background-color: #fff"
  >
  <v-form ref="formOnDemand" v-model="validOnDemand">
    <v-row justify="center">
      <v-col cols="12" sm="12">
        <v-btn color="primary" icon size="20" class="text-center" @click="$router.go(-1)">
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <h3 class="secondary--text d-inline mr-2">Create On Demand</h3>
      </v-col>
      <v-col cols="12" sm="12" md="4" class="mx-auto text-left">
        <p class="secondary--text pb-1 text-left d-inline">Charge Video</p>
        <!-- <v-card
          color="#efefef"
          class="rounded-lg elevation-0 d-flex justify-center align-center"
          min-width="250"
          min-height="250"
          style="position: relative"
          @click="$refs.presenTationVideo.$refs.input.click()"
        >
          <v-btn text color="#efefef" type="file">
            <v-icon large color="primary"> mdi-upload </v-icon>
          </v-btn>
          <p
            style="position: absolute; bottom: 0; right: 25px"
            class="secondary--text"
          >
            Upload a presetation video
          </p>
        </v-card> -->
        <v-card
          color="#efefef"
          class="rounded-lg elevation-0 d-flex mb-5 justify-center align-center mt-5"
          min-width="250"
          min-height="250"
          max-height="250px"
          style="position: relative"
          @click="$refs.principalVideo.$refs.input.click()"
        >
          <video
            v-if="onDemand.principalVideo"
            ref="principalVideoPreview"
            width="100%"
            height="250px"
            max-height="250px"
            class="mx-auto"
            :src="
              onDemand.principalVideo ? onDemand.principalVideo.video : ''
            "
          ></video>

          <v-icon
            v-if="!isLoadingVideo"
            large
            color="primary"
            style="
              position: absolute;
              transform: translate(50%, -50%);
              top: 50%;
              right: 50%;
            "
          >
            mdi-upload
          </v-icon>
          <v-progress-circular
            v-if="isLoadingVideo"
            indeterminate
            color="primary"
            style="
              position: absolute;
              transform: translate(50%, -50%);
              top: 50%;
              right: 50%;
            "
          ></v-progress-circular>
          <!-- <p
            style="position: absolute; bottom: 0; right: 25px"
            class="secondary--text"
          >
            Charge a video
          </p> -->
        </v-card>
        <p class="secondary--text mt-5 text-left d-inline">Image Preview</p>
         <v-card
          color="#efefef"
          class="rounded-lg elevation-0 d-flex mt-5 justify-center align-center"
          min-width="250"
          min-height="250"
          @click="$refs.imagePreview.$refs.input.click()"
        >
         <v-img :src="onDemand.selectedImagePreview.img ? onDemand.selectedImagePreview.img : ''" cover width="250" class="rounded-lg" height="250">
         </v-img>
        
            <v-icon large color="primary" style="position: absolute; transform: translate(50%,-50%); top: 50%; right: 50%;"> mdi-camera </v-icon>

        </v-card>
        <p class="secondary--text mt-3 pb-1 text-center">Enabled</p>
        <v-switch
          color="primary"
          class="d-flex justify-center center-switch"
          v-model="onDemand.enabled"
          :input-value="onDemand.enabled"
          flat
        ></v-switch>
        <div class="text-center">
        <v-btn class="mt-3 text-center" color="primary" :disabled="!onDemand.principalVideo" @click="openModalNewVideoSection('presentation')">Choose Presentation</v-btn>

        </div>
      </v-col>
      <v-col cols="12" sm="12" md="4" class="mx-auto">
        <p class="secondary--text pb-1">Name</p>
        <v-text-field
          v-model="onDemand.name"
          :rules="onDemandRules.name"
          solo
          flat
          counter
          background-color="#efefef"
        ></v-text-field>
        <p class="secondary--text pb-1">Description</p>
        <v-textarea v-model="onDemand.description" :rules="onDemandRules.description" counter solo flat no-resize height="355" background-color="#efefef">
        </v-textarea>
        <div class="mx-auto text-center" style="width:150px">
          <p class="secondary--text text-center pb-1">Order number</p>
          <v-text-field
              v-model.number="onDemand.orderNumber"
              :rules="onDemandRules.orderNumber"
              @keypress="numberValid($event)"
              maxlength="4"
              class="centered-input"
              solo
              flat
              background-color="#efefef"
          ></v-text-field>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="4" class="mx-auto">
        <p class="secondary--text pb-1">MAXPRO accessories</p>
        <v-checkbox
          v-model="onDemand.selectedAccessories"
          v-for="(accessorie, i) in maxProAccessories"
          :key="i"
          :label="accessorie.name"
          :value="accessorie"
        >
          <template v-slot:label>
            <label class="mr-1">{{ accessorie.name }}</label>
            <v-btn
              icon
              color="red"
              v-if="accessorie.custom"
              @click="deleteNewCheckbox(accessorie.name, i)"
            >
              <v-icon size="20">mdi-close</v-icon>
            </v-btn>
          </template>
        </v-checkbox>

        <v-text-field
          v-if="
            onDemand.selectedAccessories.length < 10 &&
            maxProAccessories.length < 12
          "
          v-model="otherCheckbox"
          placeholder="Other accessory"
          flat
          required
          append-outer-icon="mdi-plus"
          @click:append-outer="addNewCheckbox()"
          @keyup.enter="addNewCheckbox()"
        ></v-text-field>
        <p class="secondary--text pb-1">Type of workout</p>
        <v-radio-group v-model="onDemand.selectedChallengeWorkout" :rules="rulesRadio" required row>
          <v-radio
            v-for="challenge in challengeWorkouts"
            :key="challenge.name"
            :value="challenge"
            :label="challenge.name"   
            class="mr-5"
          ></v-radio>
        </v-radio-group>
        <p class="secondary--text mt-3 pb-1 text-center">With Smart MaxPro?</p>
        <v-switch
          color="primary"
          class="d-flex justify-center center-switch"
          v-model="onDemand.withSmartMaxpro"
          :input-value="onDemand.withSmartMaxpro"
          flat
        ></v-switch>
        <p class="secondary--text pb-1">Difficulty</p>
        <v-radio-group v-model="onDemand.selectedDifficulty" :rules="rulesDifficulty" required row>
          <v-radio
            v-for="difficulty in listDifficulty"
            :key="difficulty"
            :value="difficulty"
            :label="difficulty"   
            class="mr-5"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </v-form>
    <v-row justify="start" align="center">
      <v-col cols="12" sm="12" md="2">
        <p class="secondary--text mb-0">Video Sections</p>
      </v-col>
      <v-col cols="12" sm="12" md="2">
        <!-- <v-btn
          text
          color="secondary"
          :disabled="!proTrainer.principalVideo || isFinal"
          @click="openModalNewVideoSection()"
        >
          <v-icon left dark color="primary"> mdi-plus-circle </v-icon>
          New Video Sections
        </v-btn> -->
        <v-btn
          text
          color="secondary"
          :disabled="!onDemand.principalVideo || isFinal"
          @click="openModalNewVideoSection('section')"
        >
          <v-icon left dark color="primary"> mdi-plus-circle </v-icon>
          New Video Sections
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="12" md="2">
        <v-btn color="primary" :disabled="(onDemand.principalVideo &&  !isFinal) || !onDemand.principalVideo || !validOnDemand || !onDemand.selectedImagePreview || !onDemand.selectedDifficulty" @click="uploadImage()">Finish </v-btn>
      </v-col>
    </v-row>
    <hr />
    <v-row justify="start">
      <v-col cols="12" md="12">
        <v-list flat>
          <v-list-item-group v-model="selectedVideoSection">
            <v-list-item
              v-for="(videoSection, i) in onDemand.listPrincipalVideoSections"
              :key="i"
            >
              <v-list-item-content>
                <v-list-item-title class="d-inline"
                  ><p class="font-weight-medium d-inline">
                    Section: {{ videoSection.startTime }} to
                    {{ videoSection.endTime }} -
                    <span class="text--secondary" v-if="videoSection.exercise.title">
                      {{ videoSection.exercise.title }}
                      <v-tooltip bottom color="primary" class="text-center">
                          <template v-slot:activator="{ on, attrs }">
                            <img v-if="videoSection.exercise.withMaxpro" src="@/assets/logo_x.png" style="vertical-align:middle;" class="mx-2" v-bind="attrs" v-on="on" width="20">
                          </template>
                          <v-icon color="white" class="mr-2"
                            >mdi-alert-circle</v-icon
                          >
                          <span
                            >This exercise need MaxPro Connection</span
                          >
                        </v-tooltip>
                    <span class="font-italic d-inline">({{ videoSection.exercise.typeExercise.name }} + {{ videoSection.exercise.typeBody.name }} )</span> 
                    </span>
                    <template v-else>
                      <span class="primary--text font-weight-bold">
                          {{ videoSection.typeSection.name }}
                      </span>
                    </template>
                  </p>
                   <v-btn
                    icon
                    color="primary"
                    size="20"
                    small
                    @click="openModalEditSection(videoSection, i)"
                    ><v-icon>mdi-pencil</v-icon></v-btn>
                  <v-btn
                    v-if="
                      i === onDemand.listPrincipalVideoSections.length - 1
                    "
                    icon
                    color="red"
                    size="20"
                    small
                    @click="deleteSection(i)"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialogVideoSectionsData.dialog"
      width="700"
      class="ma-0"
      persistent
    >
      <v-card>
        <v-card-actions class="pa-0">
          <v-btn icon color="red" class="ml-3 mt-2" @click="closeModal()">
            <v-icon size="20">mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <v-stepper  v-model="stepperSectionsVideo" class="elevation-0">
         
          <v-stepper-header class="elevation-0">
            <!-- <template v-for="n in steps"> -->
            <v-stepper-step
              :key="'1-step'"
              :complete="stepperSectionsVideo > 1"
              :step="1"
              editable
              class="mx-auto"
            >
              Step 1
            </v-stepper-step>
            <v-divider v-if="dialogVideoSectionsData.typeModal === 'section'"></v-divider>
            <v-stepper-step
              :key="'2-step'"
              :complete="stepperSectionsVideo > 2"
              :step="2"
              :editable="validStepOne"
              v-if="dialogVideoSectionsData.typeModal === 'section'"
              @click="$refs.videoDOM.pause()"
            >
              Step 2
            </v-stepper-step>

            <!-- <v-divider v-if="n !== steps" :key="n"></v-divider> -->
            <!-- </template> -->
          </v-stepper-header>

          <v-stepper-items>
              <v-stepper-content class="pa-0 pb-4" :step="1">
            <v-form ref="formStepOne" v-model="validStepOne">
                <v-card class="elevation-0">
                  <v-container class="pa-0">
                    <v-row justify="center">
                      <v-col cols="12" class="text-center ma-0 pa-0">
                        <video
                          v-if="onDemand.principalVideo"
                          ref="videoDOM"
                          width="95%"
                          @timeupdate="dialogVideoSectionsData.typeModal === 'section' ? controllerVideo() : controllerVideoPreview()"
                          height="250"
                          class="mx-auto rounded-xl px-10"
                          :src="
                            onDemand.principalVideo
                              ? onDemand.principalVideo.video
                              : ''
                          "
                        ></video>
                      </v-col>
                      <v-col cols="12" class="text-center">
                        <template v-if="onDemand.principalVideo && $refs.videoDOM && dialogVideoSectionsData.typeModal === 'section'">
                          <v-btn icon color="primary" small @click="playVideo()" v-if="!dialogVideoSectionsData.isPlaying">
                            <v-icon>mdi-play</v-icon>
                          </v-btn>
                          <template v-else>
                            <v-btn icon color="primary" small @click="pauseVideo()">
                            <v-icon>mdi-pause</v-icon>
                            </v-btn>

                          </template>
                        </template>
                        <template v-if="onDemand.principalVideo && $refs.videoDOM && dialogVideoSectionsData.typeModal === 'presentation'">
                          <v-btn icon color="primary" small @click="playVideo()" v-if="!dialogPreview.isPlaying">
                            <v-icon>mdi-play</v-icon>
                          </v-btn>
                          <template v-else>
                            <v-btn icon color="primary" small @click="pauseVideo()">
                            <v-icon>mdi-pause</v-icon>
                            </v-btn>
                          </template>
                        </template>

                        <p class="secondary--text mb-0 d-inline" style="vertical-align: bottom;" v-if="$refs.videoDOM && dialogVideoSectionsData.typeModal === 'section'">{{ dialogVideoSectionsData.currentTimePlaying }}</p>
                        <p class="secondary--text mb-0 d-inline" style="vertical-align: bottom;" v-if="$refs.videoDOM && dialogVideoSectionsData.typeModal === 'presentation'">{{ dialogPreview.currentTimePlaying }}</p>

                      </v-col>
                    </v-row>
                    <v-row justify="center" v-if="dialogVideoSectionsData.typeModal === 'section'">
                      <v-col
                        cols="2"
                        align-self="center"
                        class="pa-0 text-right"
                      >
                        <span class="mr-2">00:00:00</span>
                      </v-col>
                      <v-col cols="7" class="pa-0 text-center">
                        
                        <v-range-slider
                          v-model="dialogVideoSectionsData.range"
                          ref="controllerRangeSlider"
                          :min="0"
                          :max="maxVideo()"
                          height="50"
                          @change="changeRange"
                        >
                        </v-range-slider>
                      </v-col>
                      <v-col
                        cols="2"
                        align-self="center"
                        class="pa-0 text-left"
                      >
                        <span class="ml-2">{{ onDemand.totalDuration }}</span>
                      </v-col>
                    </v-row>
                    <v-row justify="space-around" v-if="dialogVideoSectionsData.typeModal === 'section'">
                      <v-col cols="2" class="text-center pa-0">
                        <v-tooltip bottom color="secondary" class="text-center">
                          <template v-slot:activator="{ on, attrs }">
                            <p class="secondary--text mb-0">Start Section</p>
                            <v-text-field
                              v-model="dialogVideoSectionsData.startTime"
                              :rules="dialogStepOneRules.startTime"
                              v-bind="attrs"
                              v-on="on"
                              class="text-center pa-0 centered-input"
                              solo
                              flat
                              background-color="#efefef"
                              maxlength="8"
                              readonly
                            ></v-text-field>
                          </template>
                          <v-icon color="white" class="mr-2"
                            >mdi-alert-circle</v-icon
                          >
                          <span
                            >This field is read-only to avoid save empty seconds
                            without sectioning</span
                          >
                        </v-tooltip>
                      </v-col>
                      <v-col cols="2" class="text-center pa-0">
                        <p class="secondary--text mb-0">End Section</p>
                        <v-text-field
                          v-model="dialogVideoSectionsData.endTime"
                          :rules="dialogStepOneRules.endTime"
                          class="text-center pa-0 centered-input"
                          solo
                          flat
                          text
                          background-color="#efefef"
                          @input="changeTextField"
                          maxlength="8"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- ################################### -->
                    <!-- ################################### -->
                    <!-- ################################### -->
                    <!-- ################################### -->
                    <!-- ################################### -->
                    <!-- ################################### -->
                    <v-row justify="center" v-if="dialogVideoSectionsData.typeModal === 'presentation'">
                      <v-col
                        cols="2"
                        align-self="center"
                        class="pa-0 text-right"
                      >
                        <span class="mr-2">00:00:00</span>
                      </v-col>
                      <v-col cols="7" class="pa-0">
                        <!-- <template v-if="proTrainer.principalVideo && $refs.videoDOM">
                          <v-btn icon color="primary" small @click="playVideo()" v-if="!dialogVideoSectionsData.isPlaying">
                            <v-icon>mdi-play</v-icon>
                          </v-btn>
                          <template v-else>
                            <v-btn icon color="primary" small @click="pauseVideo()">
                            <v-icon>mdi-pause</v-icon>
                            </v-btn>

                          </template>
                        </template>
                        <p class="secondary--text mb-0 d-inline" v-if="$refs.videoDOM">{{ dialogVideoSectionsData.currentTimePlaying }}</p> -->
                        <v-range-slider
                          v-model="dialogPreview.range"
                          :min="0"
                          :max="maxVideo()"
                          height="50"
                          @change="changeRangePreview"
                        >
                        </v-range-slider>
                      </v-col>
                      <v-col
                        cols="2"
                        align-self="center"
                        class="pa-0 text-left"
                      >
                        <span class="ml-2">{{ onDemand.totalDuration }}</span>
                      </v-col>
                    </v-row>
                    <v-row justify="space-around" v-if="dialogVideoSectionsData.typeModal === 'presentation'">
                      <v-col cols="2" class="text-center pa-0">

                            <p class="secondary--text mb-0">Start Section</p>
                            <v-text-field
                              v-model="dialogPreview.startTime"
                              class="text-center pa-0 centered-input"
                              solo
                              flat
                              background-color="#efefef"
                              maxlength="8"
                            ></v-text-field>
                      </v-col>
                      <v-col cols="2" class="text-center pa-0">
                        <p class="secondary--text mb-0">End Section</p>
                        <v-text-field
                          v-model="dialogPreview.endTime"
                          class="text-center pa-0 centered-input"
                          solo
                          flat
                          text
                          background-color="#efefef"
                          maxlength="8"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <div class="text-center" v-if="dialogVideoSectionsData.typeModal === 'presentation'">
                      <v-btn
                        color="primary"
                        class="mr-auto"
                        :disabled="dialogPreview.startTime.length < 8 && dialogPreview.startTime.length < 8"
                        @click="savePreview()"
                      >
                        Save Preview
                      </v-btn>
                  </div>
                    <!-- ################################### -->
                    <!-- ################################### -->
                    <!-- ################################### -->
                    <!-- ################################### -->
                    <!-- ################################### -->
                  </v-container>
                </v-card>
                <div class="text-center" v-if="dialogVideoSectionsData.typeModal === 'section'">
                  <v-btn
                    color="primary"
                    class="mr-auto"
                    :disabled="!validStepOne || !endTimeIsValid"
                    @click="stepperSectionsVideo = 2, $refs.videoDOM.pause()"
                  >
                    Continue
                  </v-btn>
                </div>
            </v-form>
              </v-stepper-content>
              <v-stepper-content :step="2" class="pa-0 pb-4">
            <v-form ref="formStepTwo" v-model="validStepTwo">
                <v-card class="elevation-0">
                  <v-container class="pa-0">
                    <v-row justify="center">
                      <v-col cols="5" class="text-center pa-0">
                        <v-select
                          v-model="dialogVideoSectionsData.typeSection"
                          :items="listTypeSection"
                          :rules="dialogStepTwoRules.typeSection"
                          item-text="name"
                          label="Type of section"
                          solo
                          flat
                          return-object
                          background-color="#efefef"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row
                      justify="center"
                      v-if="!dialogVideoSectionsData.typeSection || dialogVideoSectionsData.typeSection.value === 1"
                    >
                      <v-col cols="7" class="pa-0">
                        <v-text-field
                          placeholder="Write exercise Title"
                          :rules="dialogStepTwoRules.title"
                          v-model="dialogVideoSectionsData.exercise.title"
                          solo
                          flat
                          background-color="#efefef"
                        ></v-text-field>
                        <v-textarea
                          v-model="dialogVideoSectionsData.exercise.description"
                          :rules="dialogStepTwoRules.description"
                          placeholder="Write exercise Description"
                          solo
                          flat
                          no-resize
                          height="150"
                          background-color="#efefef"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row
                      justify="center"
                      v-if="!dialogVideoSectionsData.typeSection || dialogVideoSectionsData.typeSection.value === 1"
                    >
                      <v-col cols="4" class="pa-0 text-center">
                        <v-select
                          v-model="dialogVideoSectionsData.exercise.typeExercise"
                          :items="listTypeExercises"
                          :rules="dialogStepTwoRules.typeExercise"
                          item-text="name"
                          label="Type of exercise"
                          solo
                          flat
                          return-object
                          background-color="#efefef"
                        ></v-select>
                        <v-select
                          v-model="dialogVideoSectionsData.exercise.typeBody"
                          :items="listTypeBody"
                          :rules="dialogStepTwoRules.typeBody"
                          item-text="name"
                          label="Type of body"
                          solo
                          flat
                          return-object
                          background-color="#efefef"
                        ></v-select>
                      </v-col>
                      <v-col cols="3" class="pa-0 text-center">
                        <p class="secondary--text mb-0">Need Maxpro?</p>
                        <v-switch
                          color="primary"
                          v-model="dialogVideoSectionsData.exercise.withMaxpro"
                          :input-value="dialogVideoSectionsData.exercise.withMaxpro"
                          flat
                          class="center-switch"
                        >
                        </v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
                <div class="text-center">
                  <v-btn
                    text
                    color="secondary"
                    @click="stepperSectionsVideo = 1"
                  >
                    Back
                  </v-btn>
                  <v-btn
                    color="primary"
                    class="text-left"
                    :disabled="!validStepTwo"
                    @click="saveNewSectionVideo()"
                  >
                    Save
                  </v-btn>
                </div>
            </v-form>
              </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
        <!--##########################################################################-->
    <!--##########################################################################-->
    <!--##########################################################################-->
    <!--############################DIALOG EDIT DATA##############################-->
    <!--##########################################################################-->
    <!--##########################################################################-->
    <!--##########################################################################-->
    <v-dialog
      v-model="dialogVideoEditData.dialog"
      width="700"
      class="ma-0"
      persistent
    >
      <v-card>
        <v-card-actions class="pa-0">
          <v-btn icon color="red" class="ml-3 mt-2" @click="closeModalEdit()">
            <v-icon size="20">mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
        <p class="font-weight-bold text-center">Section: {{ dialogVideoEditData.startTime }} - {{ dialogVideoEditData.endTime }}</p>
        <!-- <v-stepper  v-model="stepperSectionsVideo" class="elevation-0">
           
          <v-stepper-items>
              <v-stepper-content :step="2" class="pa-0 pb-4"> -->
            <v-form ref="formStepTwo" v-model="validStepTwo">
                <v-card class="elevation-0">
                  <v-container class="pa-0">
                    <v-row justify="center">
                      <v-col cols="5" class="text-center pa-0">
                        <v-select
                          v-model="dialogVideoEditData.typeSection"
                          :items="listTypeSection"
                          :rules="dialogStepTwoRules.typeSection"
                          item-text="name"
                          label="Type of section"
                          solo
                          flat
                          return-object
                          background-color="#efefef"
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row
                      justify="center"
                      v-if="!dialogVideoEditData.typeSection || dialogVideoEditData.typeSection.value === 1"
                    >
                      <v-col cols="7" class="pa-0">
                        <v-text-field
                          placeholder="Write exercise Title"
                          :rules="dialogStepTwoRules.title"
                          @change="listenChange($event, 'title')"
                          :value="dialogVideoEditData.exercise.title"
                          solo
                          flat
                          background-color="#efefef"
                        ></v-text-field>
                        <v-textarea
                          @change="listenChange($event, 'description')"
                          :value="dialogVideoEditData.exercise.description"
                          :rules="dialogStepTwoRules.description"
                          placeholder="Write exercise Description"
                          solo
                          flat
                          no-resize
                          height="150"
                          background-color="#efefef"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                    <v-row
                      justify="center"
                      v-if="!dialogVideoEditData.typeSection || dialogVideoEditData.typeSection.value === 1"
                    >
                      <v-col cols="4" class="pa-0 text-center">
                        <v-select
                          @change="listenChange($event, 'typeExercise')"
                          :value="dialogVideoEditData.exercise.typeExercise"
                          :items="listTypeExercises"
                          :rules="dialogStepTwoRules.typeExercise"
                          item-text="name"
                          label="Type of exercise"
                          solo
                          flat
                          return-object
                          background-color="#efefef"
                        ></v-select>
                        <v-select
                          @change="listenChange($event, 'typeBody')"
                          :value="dialogVideoEditData.exercise.typeBody"
                          :items="listTypeBody"
                          :rules="dialogStepTwoRules.typeBody"
                          item-text="name"
                          label="Type of body"
                          solo
                          flat
                          return-object
                          background-color="#efefef"
                        ></v-select>
                      </v-col>
                      <v-col cols="3" class="pa-0 text-center">
                        <p class="secondary--text mb-0">Need Maxpro?</p>
                        <v-switch
                          color="primary"
                          @change="listenChange($event, 'withMaxpro')"
                          :value="dialogVideoEditData.exercise.withMaxpro"
                          :input-value="dialogVideoEditData.exercise.withMaxpro"
                          flat
                          class="center-switch"
                        >
                        </v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
                <div class="text-center">
                  <v-btn
                    color="primary"
                    class="text-left mb-5"
                    :disabled="!validStepTwo"
                    @click="saveEditSectionVideo()"
                  >
                    Save
                  </v-btn>
                </div>
            </v-form>
              <!-- </v-stepper-content>
          </v-stepper-items>
        </v-stepper> -->
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogUploadVideo"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Uploading video, please wait...
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="getLoadingStatusOnDemand"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogUploadImage"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Uploading image, please wait...
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="getLoadingImageOnDemand"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ##################-->
   <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
        snackbar.text
      }}</v-snackbar>
    <!-- <v-file-input
      ref="presenTationVideo"
      class="d-none"
      color="white"
      dark
      outlined
      :show-size="1000"
      @change="getPresentationVideo"
    /> -->
    <v-file-input
      ref="principalVideo"
      class="d-none"
      color="white"
      accept="video/mp4"
      dark
      outlined
      :show-size="1000"
      @change="getPrincipalVideo"
    />
     <v-file-input
      ref="imagePreview"
      class="d-none"
      color="white"
      accept="image/png, image/jpeg"
      dark
      outlined
      :show-size="1000"
      @change="getPreviewImage"
    />
  </v-container>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  name: "OnDemandCreateSet",
  data: () => ({
    validStepOne: false,
    validStepTwo: false,
    validOnDemand:false,
    dialogVideoSectionsData: { 
      dialog: false,
      exercise: {
        title: "",
        description: "",
        withMaxpro: true,
        typeExercise : null,
        typeBody : null,
        METPowerFactorWO:0
      },
      startTime: "00:00:00",
      endTime: "00:00:00",
      range: [0, 12],
      typeSection:null,
      typeModal:'section',
      isPlaying:false,
      currentTimePlaying:"00:00:00"
    },
    onDemand: {
      name: "",
      description: "",
      totalDuration: "",
      principalVideo: null,
      selectedImagePreview:"",
      previewPrincipalVideo:{
       startTime: "",
       endTime: "",
      },
      selectedAccessories: [],
      selectedChallengeWorkout: {},
      selectedDifficulty:"",
      withSmartMaxpro: true,
      listPrincipalVideoSections: [],
      enabled:true,
      orderNumber:''
    },
    onDemandRules:{
      name: [v => !!v || 'Name is required', v => (v && v.length >= 4) || 'Name must be greater than 4'],
      description: [(v) => !!v || "Description section is required"],
    },
    dialogPreview:{
      startTime: "00:00:00",
      endTime: "00:00:00",
      range:[0,12],
      isPlaying:false,
      currentTimePlaying:"00:00:00"
    },
    dialogStepOneRules: {
      startTime: [
        (v) => !!v || "Start section is required",
        (v) =>
          /^((?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$)/.test(v) ||
          "Start section must be valid",
      ],
      endTime: [
        (v) => !!v || "End section is required",
        (v) =>
          /^((?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$)/.test(v) ||
          "End section format must be valid",
      ],
    },
    dialogStepTwoRules: {
      title: [(v) => !!v || "Title is required"],
      description: [(v) => !!v || "Description section is required"],
      typeExercise: [(v) => !!v || "Type exercise is required"],
      typeBody: [(v) => !!v || "Type body is required"],
      typeSection: [(v) => !!v || "Type section is required"],
    },
    selectedVideoSection: {},
    maxProAccessories: [
      {
        custom: false,
        name: "Long/Short Bar",
        value: 1,
      },
      {
        custom: false,
        name: "Handles",
        value: 2,
      },
      {
        custom: false,
        name: "Suspension Handles",
        value: 3,
      },
      {
        custom: false,
        name: "Ankle Strap",
        value: 4,
      },
      {
        custom: false,
        name: "Jump Belt",
        value: 5,
      },
      {
        custom: false,
        name: "Bench",
        value: 6,
      },
    ],
    listTypeExercises: [
      {
        name: "Resistance",
        value: 1,
        baseMET:4
      },
      {
        name: "Suspension",
        value: 2,
        baseMET:4
      },
      {
        name: "Pylometrics",
        value: 3,
        baseMET:6
      },
      {
        name: "Stretch",
        value: 4,
        baseMET:2
      },
      {
        name: "Rowing",
        value: 4,
        baseMET:5
      },
    ],
    listTypeBody: [
      {
        name: "Upper Body",
        value: 1,
        bodyEngMET:0
      },
      {
        name: "Lower Body",
        value: 2,
        bodyEngMET:1
      },
      {
        name: "Fully Body",
        value: 3,
        bodyEngMET:2
      },
      {
        name: "Core",
        value: 4,
        bodyEngMET:2
      },
    ],
    challengeWorkouts: [
      {
        name: "Burn",
        value: 1,
      },
      {
        name: "Tone",
        value: 2,
      },
      {
        name: "Build",
        value: 3,
      },
    ],
    listTypeSection:[
      {
        name: "Exercise",
        value: 1,
      },
      {
        name: "Rest",
        value: 2,
      },
      {
        name: "Intro",
        value: 3,
      },
      {
        name: "End",
        value: 4,
      },
    ],
    listDifficulty:[
      "Easy",
      "Normal",
      "Hard"
    ],
    dialogVideoEditData: {
      dialog: false,
      exercise: {},
      startTime: "00:00:00",
      endTime: "00:00:00",
      range: [0, 12],
      typeSection:null,
    },
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
    endTimeIsValid:false,
    otherCheckbox: "",
    videoData: null,
    stepperSectionsVideo: 1,
    steps: 2,
    isLoadingVideo: false,
    dialogUploadVideo:false,
    dialogUploadImage:false,
    controllerExerciseEdit:{
      title: "",
      description: "",
      withMaxpro: true,
      typeExercise : null,
      typeBody : null,
      METPowerFactorWO:0
    }
    
  }),
  methods: {
    addNewCheckbox() {
      let vm = this;
      if (vm.otherCheckbox != "") {
        if (
          !vm.maxProAccessories.some(
            (accessorie) => accessorie.name == vm.otherCheckbox
          )
        ) {
          let newCheck = {
            custom: true,
            name: vm.otherCheckbox,
          };
          vm.maxProAccessories.push(newCheck);
          vm.onDemand.selectedAccessories.push(newCheck);
          vm.otherCheckbox = "";
        } else {
          vm.snackbar.text = "This accessory already exists";
          vm.snackbar.color = "red";
          vm.snackbar.snackbar = true;
        }
      }
    },
    deleteNewCheckbox(item, index) {
      let vm = this;
      let selectedIndex = vm.onDemand.selectedAccessories.findIndex(
        (accessorie) => accessorie.name === item
      );
      if (selectedIndex != -1) {
        vm.onDemand.selectedAccessories.splice(selectedIndex, 1);
      }
      vm.maxProAccessories.splice(index, 1);
    },
    deleteSection(index) {
      const vm = this;
      vm.onDemand.listPrincipalVideoSections.splice(index, 1);
    },
    getPreviewImage(file) {
      const vm = this;
      // const notificationImg = vm.onDemand.selectedImagePreview;
      if(file){
        if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') {
          const fr = new FileReader();
          fr.onload = (el) => {
            vm.onDemand.selectedImagePreview = {
              img: el.target.result,
              filetype: file.type.split("/")[1],
            }
          };
          fr.readAsDataURL(file);
        } else {
          let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Only .png and .jpeg files are accepted",
              color: "red",
            };
          vm.$emit("showSnackBar", emitData);
        }
      }
     },
    openModalNewVideoSection(type) {
      const vm = this;
      vm.dialogVideoSectionsData.typeModal = type
      if(type === 'section'){
        
        vm.stepperSectionsVideo = 1;
         console.log('type', type);
         console.log('vm.onDemand.listPrincipalVideoSections', vm.onDemand.listPrincipalVideoSections);
         vm.onDemand.totalDuration = moment("1900-01-01 00:00:00")
           .add(vm.$refs.principalVideoPreview.duration, "seconds")
           .format("HH:mm:ss");
         if (vm.onDemand.listPrincipalVideoSections.length > 0) {
           let videoSections = vm.onDemand.listPrincipalVideoSections;
           let incrementLastSectioned =
             moment
               .duration(videoSections[videoSections.length - 1].endTime)
               .asSeconds();
           vm.$set(vm.dialogVideoSectionsData.range, 0, incrementLastSectioned);
           vm.$set(
             vm.dialogVideoSectionsData,
             "startTime",
             moment("1900-01-01 00:00:00")
               .add(incrementLastSectioned, "seconds")
               .format("HH:mm:ss")
           );
           vm.$refs.videoDOM.currentTime = incrementLastSectioned
         } else {
           vm.$set(vm.dialogVideoSectionsData.range, 0, 0);
           vm.$set(vm.dialogVideoSectionsData, "startTime", "00:00:00");
            vm.dialogVideoSectionsData.startTime = "00:00:00"
            if(vm.$refs.videoDOM){
              vm.$refs.videoDOM.currentTime = 0
            }
         }
         vm.$set(
           vm.dialogVideoSectionsData,
           "endTime",
           moment("1900-01-01 00:00:00")
             .add(vm.onDemand.totalDuration, "seconds")
             .format("HH:mm:ss")
         );
         vm.$set(
           vm.dialogVideoSectionsData.range,
           1,
           vm.$refs.principalVideoPreview.duration
         );
        vm.changeTextField();
      }else if(type == 'presentation'){
        vm.onDemand.totalDuration = moment("1900-01-01 00:00:00")
           .add(vm.$refs.principalVideoPreview.duration, "seconds")
           .format("HH:mm:ss");
        if(vm.onDemand.previewPrincipalVideo.startTime.length > 0 && vm.onDemand.previewPrincipalVideo.endTime.length > 0){
            vm.$set(
             vm.dialogPreview,
             "startTime", vm.onDemand.previewPrincipalVideo.startTime
           );
            vm.$set(
             vm.dialogPreview,
             "endTime", vm.onDemand.previewPrincipalVideo.endTime
           );
           vm.$set(
             vm.dialogPreview.range,
             0,
             moment.duration(vm.onDemand.previewPrincipalVideo.startTime).asSeconds()
           );
           vm.$set(
             vm.dialogPreview.range,
             1,
             moment.duration(vm.onDemand.previewPrincipalVideo.endTime).asSeconds()
           );
          vm.$refs.videoDOM.currentTime = moment.duration(vm.onDemand.previewPrincipalVideo.startTime).asSeconds()
        }else{
          let percent = parseInt(moment.duration(vm.onDemand.totalDuration).asSeconds() * 0.1)
          vm.$set(
             vm.dialogPreview,
             "endTime",
             moment("1900-01-01 00:00:00")
               .add(percent, "seconds")
               .format("HH:mm:ss")
           );
           vm.$set(
             vm.dialogPreview.range,
             1,
             percent
           );
           if(vm.$refs.videoDOM){
             vm.$refs.videoDOM.currentTime = 0
           }
        }

      }
      vm.dialogVideoSectionsData.dialog = true;
    },
     listenChange(value, type){
      let vm = this;
      vm.controllerExerciseEdit[type] = value
      console.log('vm.controllerExerciseEdit[type]', vm.controllerExerciseEdit[type]);
      console.log('value', value);
      console.log("type", type);
    },
    openModalEditSection(section, index){
      let vm = this;
      let dataToEdit = {
        startTime: section.startTime,
        endTime: section.endTime,
        exercise: section.exercise,
        typeSection:section.typeSection,
      }
      vm.controllerExerciseEdit = { ...section.exercise }

      vm.dialogVideoEditData = dataToEdit
      vm.dialogVideoEditData.index = index
      vm.dialogVideoEditData.dialog = true
      console.log('editmodal', vm.dialogVideoEditData);
    },
    saveNewSectionVideo() {
      let vm = this;
      if(vm.dialogVideoSectionsData.typeSection.value == 1){
        vm.dialogVideoSectionsData.exercise.METPowerFactorWO = vm.dialogVideoSectionsData.exercise.typeExercise.baseMET + vm.dialogVideoSectionsData.exercise.typeBody.bodyEngMET 
      }
      let dataToSave = {
        startTime: vm.dialogVideoSectionsData.startTime,
        endTime: vm.dialogVideoSectionsData.endTime,
        exercise: vm.dialogVideoSectionsData.typeSection.value == 1 ? vm.dialogVideoSectionsData.exercise : {},
        typeSection:vm.dialogVideoSectionsData.typeSection,
      };
      vm.$refs.videoDOM.pause();
      console.log('dataToSave', dataToSave);
      vm.onDemand.listPrincipalVideoSections.push(dataToSave);
      vm.cleanDialogObject();
      // vm.dialogVideoSectionsData.dialog = false;
    },
    saveEditSectionVideo(index){
      let vm = this;
      if(vm.dialogVideoEditData.typeSection.value == 1){
        vm.dialogVideoEditData.exercise = vm.controllerExerciseEdit
        vm.dialogVideoEditData.exercise.METPowerFactorWO = vm.dialogVideoEditData.exercise.typeExercise.baseMET + vm.dialogVideoEditData.exercise.typeBody.bodyEngMET
      }
      let dataToSave = {
        startTime: vm.dialogVideoEditData.startTime,
        endTime: vm.dialogVideoEditData.endTime,
        exercise: vm.dialogVideoEditData.typeSection.value == 1 ? vm.dialogVideoEditData.exercise : {},
        typeSection:vm.dialogVideoEditData.typeSection,
      };

      console.log('dataToSave', dataToSave);

      if(vm.onDemand.listPrincipalVideoSections[vm.dialogVideoEditData.index]){
        vm.onDemand.listPrincipalVideoSections[ vm.dialogVideoEditData.index] = dataToSave;
      }
      vm.closeModalEdit();
    },
    closeModalEdit() {
      let vm = this;
      
      vm.$nextTick(() => {
        if (vm.$refs.formStepTwo) {
          vm.$refs.formStepTwo.resetValidation();
        }
      });

      vm.dialogVideoEditData = {
          dialog: false,
          exercise: {},
          startTime: "00:00:00",
          endTime: "00:00:00",
          typeSection:null,
      }
      vm.controllerExerciseEdit = {
          title: "",
          description: "",
          withMaxpro: true,
          typeExercise: null,
          typeBody: null,
          METPowerFactorWO:0
      }
    },
    closeModal() {
      let vm = this;
      vm.stepperSectionsVideo = 1;
      vm.$refs.videoDOM.pause();
    //   console.log(vm.proTrainer);
      vm.dialogVideoSectionsData.dialog = false;
    },
    cleanDialogObject() {
      let vm = this;
      // vm.$refs.formStepOne.reset();
      // vm.$refs.formStepTwo.reset();
      vm.$nextTick(() => {
        if (vm.$refs.formStepTwo) {
          vm.$refs.formStepTwo.resetValidation();
        }
      });

      vm.dialogVideoSectionsData = {
        dialog: false,
        exercise: {
          title: "",
          description: "",
          withMaxpro: true,
          typeExercise: null,
          typeBody: null,
          METPowerFactorWO:0
        },
        startTime: "00:00:00",
        endTime: "00:00:00",
        range: [0, 12],
        typeSection: null,
        typeModal:'section', 
        isPlaying:false,
        currentTimePlaying:"00:00:00"        
      };
      vm.dialogVideoSectionsData.dialog = false;
      vm.stepperSectionsVideo = 1;
    },
    changeRange(changedRange) {
      const vm = this;
      const video = vm.$refs.videoDOM;
      vm.dialogVideoSectionsData.startTime = moment("1900-01-01 00:00:00")
        .add(vm.dialogVideoSectionsData.range[0], "seconds")
        .format("HH:mm:ss");
      vm.dialogVideoSectionsData.endTime = moment("1900-01-01 00:00:00")
        .add(vm.dialogVideoSectionsData.range[1], "seconds")
        .format("HH:mm:ss");

      // vm.dialogVideoSectionsData.startTime = vm.dialogVideoSectionsData.range[0]
      // vm.dialogVideoSectionsData.endTime = vm.dialogVideoSectionsData.range[1]
      // video.currentTime = moment.duration(vm.dialogVideoSectionsData.startTime).asSeconds();
    },
    changeTextField(value){
      const vm = this
      let endTimeSetted = moment.duration(vm.dialogVideoSectionsData.endTime).asSeconds()
      let totalDuration = moment.duration(vm.onDemand.totalDuration).asSeconds()
      if(vm.onDemand.listPrincipalVideoSections.length > 0){
      let lastSectioned = moment.duration(vm.onDemand.listPrincipalVideoSections[vm.onDemand.listPrincipalVideoSections.length - 1].endTime).asSeconds()
        if (endTimeSetted > lastSectioned && endTimeSetted <= totalDuration) {
          vm.endTimeIsValid = true
          vm.$set(vm.dialogVideoSectionsData.range, 1, endTimeSetted);
        } else {
          vm.endTimeIsValid = false
        }
      }else if(endTimeSetted <= totalDuration){
        vm.$set(vm.dialogVideoSectionsData.range, 1, endTimeSetted);
        vm.endTimeIsValid = true
      }else{
        vm.endTimeIsValid = false
      }
    },
    numberValid(e){
      e = (e) || window.event
      const charCode = (e.which) ? e.which : e.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        e.preventDefault()
      } else {
        return true
      }
    },
    savePreview(){
      const vm = this
      const video = vm.$refs.videoDOM; 
      vm.$set(vm.onDemand.previewPrincipalVideo, "startTime", vm.dialogPreview.startTime);
      vm.$set(vm.onDemand.previewPrincipalVideo, "endTime", vm.dialogPreview.endTime);
      vm.closeModal();
      vm.dialogPreview = {
        startTime:"00:00:00",
        endTime:"00:00:00",
        range:[0,12],
        isPlaying:false,
        currentTimePlaying:vm.onDemand.previewPrincipalVideo.startTime
      }
      video.pause();
      video.currentTime = 0
    },
    changeRangePreview(changedRange){
      const vm = this;
      vm.dialogPreview.startTime = moment("1900-01-01 00:00:00")
        .add(vm.dialogPreview.range[0], "seconds")
        .format("HH:mm:ss");
      vm.dialogPreview.endTime = moment("1900-01-01 00:00:00")
        .add(vm.dialogPreview.range[1], "seconds")
        .format("HH:mm:ss");
    },
    playVideo(){
      const vm = this
      const video = vm.$refs.videoDOM;
      video.play();
    },
    controllerVideo(){
      const vm = this
      const video = vm.$refs.videoDOM;
      let handlerPlaying = !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)
      vm.dialogVideoSectionsData.currentTimePlaying = moment("1900-01-01 00:00:00").add(video.currentTime, "seconds").format("HH:mm:ss")
      if(handlerPlaying != vm.dialogVideoSectionsData.isPlaying){
        vm.dialogVideoSectionsData.isPlaying = handlerPlaying
      }
      if (video.currentTime >= moment.duration(vm.dialogVideoSectionsData.endTime).asSeconds()) {
        video.currentTime = moment.duration(vm.dialogVideoSectionsData.startTime).asSeconds();
        // video.pause();
      }

    },
    controllerVideoPreview(){
      const vm = this
      const video = vm.$refs.videoDOM;
      let handlerPlaying = !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2)
      vm.dialogPreview.currentTimePlaying = moment("1900-01-01 00:00:00").add(video.currentTime, "seconds").format("HH:mm:ss")
      if(handlerPlaying != vm.dialogPreview.isPlaying){
        vm.dialogPreview.isPlaying = handlerPlaying
      }
      if (video.currentTime >= moment.duration(vm.dialogPreview.endTime).asSeconds()) {
        video.currentTime = moment.duration(vm.dialogPreview.startTime).asSeconds();
        // video.pause();
      }

    },
    pauseVideo() {
      const vm = this;
      const video = vm.$refs.videoDOM;
      video.pause();
      // if (
      //   video.currentTime >=
      //   moment.duration(vm.dialogVideoSectionsData.endTime).asSeconds()
      // ) {
      //   video.currentTime = moment
      //     .duration(vm.dialogVideoSectionsData.startTime)
      //     .asSeconds();
      //   // video.pause();
      // }
    },
    setTimerCurrentStart() {
      //Video method
      const vm = this;
      const video = vm.$refs.videoDOM;

      // const data = {
      //   test:vm.$refs.videoDOM
      // }
      // video.currentTime = moment.duration(vm.dialogVideoSectionsData.startTime).asSeconds();
      vm.dialogVideoSectionsData.range[0] = moment
        .duration(vm.dialogVideoSectionsData.startTime)
        .asSeconds();
      // vm.dialogVideoSectionsData.range[1] = parseInt(video.duration)
    },
    maxVideo() {
      //Video method
      const vm = this;
      if (vm.onDemand.totalDuration) {
        return moment.duration(vm.onDemand.totalDuration).asSeconds();
      }
    },
   async getPrincipalVideo(file) {
      const vm = this;
      if (file) {
        if (file.type === 'video/mp4') {
          const fr = new FileReader();
          fr.onloadstart = () => {
            vm.isLoadingVideo = true;
            if (vm.onDemand.principalVideo) {
              vm.onDemand.principalVideo = null;
              vm.onDemand.listPrincipalVideoSections = [];
            }
          };
          fr.onload = (el) => {
            let videoBlob = new Blob([new Uint8Array(el.target.result)], { type: file.type });
            let url = window.URL.createObjectURL(videoBlob);
            console.log(el.target.result);
            vm.onDemand.principalVideo = {
              video: url,
              blob:el.target.result,
              filetype: file.type.split("/")[1],
              link:""
            };
            vm.dialogVideoSectionsData.currentTimePlaying = "00:00:00"
              vm.isLoadingVideo = false;
          };
          fr.onerror = () => {
            let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Error charging video",
              color: "red",
            };
            vm.$emit("showSnackBar", emitData);
          };
          fr.readAsArrayBuffer(file);
        } else {
          let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Only .mp4 files are accepted",
              color: "red",
            };
            vm.$emit("showSnackBar", emitData); 
        }
      }
    },
    uploadImage(){
      let vm = this;
      vm.dialogUploadImage = true
      console.log(vm.onDemand);
       vm.$store
        .dispatch("UploadImagePreviewOnDemand", vm.onDemand)
        .then((storage) => {
          vm.onDemand.selectedImagePreview.link = storage.link
          vm.onDemand.selectedImagePreview.ref = storage.ref
          vm.dialogUploadImage = false
          vm.uploadVideo();
          
        }).catch((err) => {

          vm.dialogUploadImage = false

           let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Error uploading image",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);
        })

    },
    uploadVideo() {
      let vm = this;
      vm.dialogUploadVideo = true
      console.log(vm.onDemand);
       vm.$store
        .dispatch("UploadVideoOnDemand", vm.onDemand)
        .then((storage) => {
          console.log('linkkk', storage);
          
          delete vm.onDemand.principalVideo.blob
          vm.onDemand.principalVideo.link = storage.link
          vm.onDemand.principalVideo.ref = storage.ref
          vm.createSet();
          
        }).catch((err) => {

          vm.dialogUploadVideo = false

           let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Error uploading video",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);
        })
    },
    createSet(){
      let vm = this;
      vm.$store.dispatch('CreateSetOnDemand', vm.onDemand)
        .then((id) => {
          vm.$store.dispatch("GenerateLog", {
            id: id,
            title: vm.onDemand.name,
            type: "createdOnDemand",
          })
          vm.dialogUploadVideo = false

           let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "On Demand Created successfully",
            color: "success",
          };
          vm.$router.push("/admin/on-demand")
           .catch((err) => {
            vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
            vm.snackbar.color = "red darken-2";
            vm.snackbar.snackbar = true;
          })
          vm.$emit("showSnackBar", emitData);
          
        }).catch((err)=>{
          vm.dialogUploadVideo = false

           let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Error creating On Demand",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);

        })
    }
  },
  computed: {
    isFinal() {
      const vm = this;
      if (
        vm.onDemand.listPrincipalVideoSections.length > 0 &&
        vm.onDemand.totalDuration
      ) {
        return (
          moment
            .duration(
              vm.onDemand.listPrincipalVideoSections[
                vm.onDemand.listPrincipalVideoSections.length - 1
              ].endTime
            )
            .asSeconds() >=
          moment.duration(vm.onDemand.totalDuration).asSeconds()
        );
      } else {
        return false;
      }
    },
    rulesRadio(){
       return [!!this.onDemand.selectedChallengeWorkout.value || "Type of workout is required"]
    },
    rulesDifficulty(){
      return [!!this.onDemand.selectedDifficulty || "Difficulty is required"]
    },
     ...mapGetters(["getLoadingStatusOnDemand", "getLoadingImageOnDemand"]),
  },
  watch: {
    "dialogVideoSectionsData.startTime": function (
      newStartValue,
      oldStartValue
    ) {
      let start = moment.duration(newStartValue).asSeconds();
      if (start < this.dialogVideoSectionsData.range[1]) {
        if (this.onDemand.listPrincipalVideoSections.length > 0) {
          let correctValue =
            moment
              .duration(
                this.onDemand.listPrincipalVideoSections[
                  this.onDemand.listPrincipalVideoSections.length - 1
                ].endTime
              )
              .asSeconds()

          if (start == correctValue) {
            this.$set(this.dialogVideoSectionsData.range, 0, start);
          } else {
            this.$set(
              this.dialogVideoSectionsData,
              "startTime",
              moment("1900-01-01 00:00:00")
                .add(correctValue, "seconds")
                .format("HH:mm:ss")
            );
          }
        } else {
          if (start < 1) {
            this.$set(this.dialogVideoSectionsData.range, 0, start);
          } else {
            this.$set(this.dialogVideoSectionsData, "startTime", "00:00:00");
          }
        }
      } else {
        if (this.onDemand.listPrincipalVideoSections.length > 0) {
          let incrementLastSectioned =
            moment
              .duration(
                this.onDemand.listPrincipalVideoSections[
                  this.onDemand.listPrincipalVideoSections.length - 1
                ].endTime
              )
              .asSeconds();
          this.$set(
            this.dialogVideoSectionsData,
            "startTime",
            moment("1900-01-01 00:00:00")
              .add(incrementLastSectioned, "seconds")
              .format("HH:mm:ss")
          );
        } else {
          this.$set(this.dialogVideoSectionsData, "startTime", "00:00:00");
        }

        // this.$set(this.dialogVideoSectionsData, "startTime", "");
        // this.$set(this.dialogVideoSectionsData.range, 0, moment.duration(oldStartValue).asSeconds())
      }
    },
    "dialogVideoSectionsData.endTime": function (newEndValue, oldEndValue) {
      let end = moment.duration(newEndValue).asSeconds();
      if(this.onDemand.listPrincipalVideoSections.length > 0){
      //  let lastSectioned = moment.duration(this.onDemand.listPrincipalVideoSections[this.onDemand.listPrincipalVideoSections.length - 1].endTime).asSeconds()
      //   if (end > lastSectioned) {
      //     this.$set(this.dialogVideoSectionsData.range, 1, end);
      //   } else {
      //     this.$set(
      //       this.dialogVideoSectionsData,
      //       "endTime",
      //       this.onDemand.totalDuration
      //     );
      //   }
      }else{ 
        this.$set(this.dialogVideoSectionsData.range, 1, end);
      }
    },
    "dialogPreview.startTime":function(newStartValue, oldStartValue){
      let start = moment.duration(newStartValue).asSeconds();
      if(start < this.dialogPreview.range[1]){
        this.$set(
            this.dialogPreview.range,
            0,
            start
        );
      }else{
        if(newStartValue.length == 8){
          this.$set(this.dialogPreview, "startTime", "00:00:00");
        }
      }
      

    },
    "dialogPreview.endTime":function(newEndValue, oldEndValue){
      let end = moment.duration(newEndValue).asSeconds();
      if(end < moment.duration(this.onDemand.totalDuration).asSeconds()){

      }
      this.$set(
          this.dialogPreview.range,
          1,
          end
      );
    }

    //  'dialogVideoSectionsData.range':function(newValue){
    //    console.log(newValue);
    //    this.dialogVideoSectionsData.startTime = newValue[0]
    //  }
  },
};
</script>
<style lang="scss">
.center-switch > .v-input__control > .v-input__slot {
  justify-content: center !important;
}
.centered-input
  > .v-input__control
  > .v-input__slot
  > .v-text-field__slot
  > input {
  text-align: center !important;
}
</style>