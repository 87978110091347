import firebase from "firebase";
import Vue from "vue";
import Axios from "axios";
import VueAxios from "vue-axios";
import { v4 as uuidv4 } from "uuid";

Vue.use(VueAxios, Axios);

const db = firebase.firestore();


Vue.use(VueAxios, Axios);

const storageImages = firebase.storage().ref("livesImages/");
const storageVideo = firebase.storage().ref("livesPresentationVideo/");

export default {
    state:{
        livesList: [],
        loadingStatusLiveImage:0,
        loadingStatusLivePresentationVideo:0,
        categoriesLiveList:[],
        livesFilters:[]
    },
    mutations:{
        SET_LIVES_LIST(state, livesList){
            state.livesList = livesList
        },
        SET_CATEGORIES_LIVES_LIST(state, categoriesLivesList){
            state.categoriesLivesList = categoriesLivesList
        },
        SET_VALUE_LOADING_STATUS_LIVE_IMAGE(state, progress) {
            state.loadingStatusLiveImage = progress
        },
        SET_VALUE_LOADING_STATUS_PRESENTATION_VIDEO_LIVE(state, value) {
            state.loadingStatusLivePresentationVideo = value
        },
        SET_LIVES_FILTER_LIST(state, livesFilters) {
            state.livesFilters = livesFilters
        },
        ADD_ANY_VALUE_LIVES_FILTER_LIST(state, data) {
            let indexPrincipal = state.livesFilters.findIndex(filter => filter.type === data.doc)
            state.livesFilters[indexPrincipal].listFilters.push({ name: data.name, children: data.children })
        },
        DELETE_ANY_VALUE_LIVES_FILTER_LIST(state, data) {
            let indexPrincipal = state.livesFilters.findIndex(filter => filter.type === data.doc)
            let index = state.livesFilters[indexPrincipal].listFilters.indexOf(data.item.name)
            state.livesFilters[indexPrincipal].listFilters.splice(index, 1)
        },
        UPDATE_WITH_NEW_VALUES_LIVES_FILTER_LIST_CHILDREN(state, data) {
            let indexPrincipal = state.livesFilters.findIndex(filter => filter.type === data.doc)
            state.livesFilters[indexPrincipal].listFilters = data.updatedArray
        },
        UPDATE_FILTER_LIST_LIVES(state, data) {
            state.livesFilters.push({
                type: data.doc,
                listFilters: data.listFilters,
                name: data.name
            })
        },
        DELETE_VALUE_ROOT_FILTER_LIVES(state, data) {
            let indexPrincipal = state.livesFilters.findIndex(filter => filter.type === data.doc)
            state.livesFilters.splice(indexPrincipal, 1)
        }
    },
    actions:{
        UpdatedParsedLinkLives(){
            return new Promise(async(resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("livesList").get()
                    const livesList = []
                    for (let i = 0; i < querySnapshot.docs.length; i++) {
                        let live = querySnapshot.docs[i].data();
                        const regExp = /\/tv\/(.*?)\//;
                        const isValidUrlInstagram =  regExp.test(live.videoLink);
                        if(isValidUrlInstagram){
                            live.parsedVideoLink = `https://www.instagram.com/p/${live.videoLink.match(regExp)[1]}/`
                            await db.collection("livesList").doc(querySnapshot.docs[i].id).update({
                                parsedVideoLink: live.parsedVideoLink
                            })
                        }
                        livesList.push(live)
                        console.log('updated documents: ', i+1)
                    }
                    console.log(livesList);
                } catch (error) {
                    console.error(error);
                    reject(error)
                }

            })

        },
        UpdateLivesOrientation(){
            return new Promise(async(resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("livesList").get()
                    const livesList = []
                    for (let i = 0; i < querySnapshot.docs.length; i++) {
                        let live = querySnapshot.docs[i].data();
                        await db.collection("livesList").doc(querySnapshot.docs[i].id).update({
                            turnOrientationLandscape: null
                        })
                        livesList.push(live)
                        console.log('updated documents: ', i+1)
                    }
                    console.log(livesList);
                } catch (error) {
                    console.error(error);
                    reject(error)
                }

            })

        },
        UploadImageLive({ commit }, live) {
            return new Promise((resolve, reject) => {
                let random = uuidv4();
                let storageRef = storageImages.child(random);
                console.log('storageRef', storageRef.name);
                let uploadTask = storageRef.putString(live.image.img, "data_url")
                uploadTask.on('state_changed', (listener) => {
                    let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
                    commit('SET_VALUE_LOADING_STATUS_LIVE_IMAGE', progress);
                }, (error) => {
                    console.log('error', error);
                    reject(error)
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        resolve({ link: downloadURL, ref: storageRef.name });
                    })
                })
            });
        },
        UploadPresentationVideoLive({ commit }, live) {
            return new Promise((resolve, reject) => {
                let random = uuidv4();
                let storageRef = storageVideo.child(random);
                let bytes = new Blob([new Uint8Array(live.presentationVideo.blob)]);
                console.log('storageRef', storageRef.name);
                let metadata = {
                    contentType: 'video/mp4'
                }
                let uploadTask = storageRef.put(bytes, metadata)
                uploadTask.on('state_changed', (listener) => {
                    let progress = parseInt((listener.bytesTransferred / listener.totalBytes) * 100);
                    commit('SET_VALUE_LOADING_STATUS_PRESENTATION_VIDEO_LIVE', progress);
                }, (error) => {
                    console.log('error', error);
                    reject(error)
                }, () => {
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        resolve({ link: downloadURL, ref: storageRef.name });
                    })
                })
            });
        },
        GetLivesList({ commit }){
            return new Promise(async (resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("livesList").get()
                    const livesList = []
                    querySnapshot.forEach(async(doc) =>  {
                        let live = doc.data()
                        livesList.push(live)
                    })
                    commit('SET_LIVES_LIST', livesList)
                    resolve(livesList)
                } catch(error) {
                    reject(error)
                }
            })
        },
        GetLive({ }, live){
            return new Promise(async (resolve, reject) => {
                try {
                    let resLive = await db.collection("livesList").doc(live.id).get()
                    let gettedLive = resLive.data()
                    if(gettedLive.subscription && gettedLive.subscription != ""){
                        let subscription = await gettedLive.subscription.get()
                        gettedLive.subscription = subscription.data()
                    }
                    resolve(gettedLive)
                } catch(error) {
                    reject(error)
                }
            })
        },
        GetCategoriesLivesList({ commit }){
            return new Promise(async (resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("categoriesLivesList").get()
                    const categoriesLivesList = []
                    querySnapshot.forEach((doc) =>  {
                        categoriesLivesList.push(doc.data())
                    })
                    console.log(categoriesLivesList)
                    commit('SET_CATEGORIES_LIVES_LIST', categoriesLivesList)
                    resolve(categoriesLivesList)
                } catch(error) {
                    reject(error)
                }
            })
        },
        CreateLive({}, live) {
            return new Promise(async(resolve, reject) => {
                try {
                    delete live.image.img
                    if(live.subscription && live.subscription != ""){
                        live.subscription = db.collection("subscriptionsList").doc(live.subscription.id)
                    }
                    const regExp = /\/tv\/(.*?)\//;
                    const isValidUrlInstagram =  regExp.test(live.videoLink);
                    if(isValidUrlInstagram){
                        live.parsedVideoLink = `https://www.instagram.com/p/${live.videoLink.match(regExp)[1]}/`
                    }
                    const ref = db.collection("livesList").doc();
                    const newLive = await ref.get();
                    live.id = ref.id;
                    live.createdAt = new Date()
                    live.updatedAt = new Date()
                    await db.collection("livesList").doc(newLive.id).set(live)
                    resolve(newLive.id);
                } catch (error) {
                    reject(error);
                }
            })
        },
        CreateCategoryLive({}, categoryLive) {
            return new Promise(async(resolve, reject) => {
                try {
                    const ref = db.collection("categoriesLivesList").doc();
                    const newCategoryLive = await ref.get();
                    categoryLive.id = ref.id;
                    await db.collection("categoriesLivesList").doc(newCategoryLive.id).set(categoryLive)
                    resolve(newCategoryLive.id);
                } catch (error) {
                    reject(err);
                }
            })
        },
        UpdateLive({}, live) {
            return new Promise(async(resolve, reject) => {
                try {
                    if(live.createdAt) {
                        delete live.createdAt
                    }
                    live.updatedAt = new Date()
                    if (live.image.img) {
                        delete live.image.img
                    }
                    if(live.subscription && live.subscription != ""){
                        live.subscription = db.collection("subscriptionsList").doc(live.subscription.id)
                    }
                    const regExp = /\/tv\/(.*?)\//;
                    const isValidUrlInstagram =  regExp.test(live.videoLink);
                    if(isValidUrlInstagram){
                        live.parsedVideoLink = `https://www.instagram.com/p/${live.videoLink.match(regExp)[1]}/`
                    }
                    await db.collection("livesList").doc(live.id).update(live)
                    resolve(live.id);
                } catch (error) {
                    reject(error);
                }
            });
        },
        DisableLive({}, live){
            return new Promise(async(resolve, reject) => {
                try {
                    await db.collection("livesList").doc(live.id).update(live)
                    resolve(live.id);
                } catch (error) {
                    reject(error);
                }
            });

        },
        UpdateCategoryLive({}, category) {
            return new Promise(async(resolve, reject) => {
                try {
                    await db.collection("categoriesLivesList").doc(category.id).update(category)
                    resolve(category.id);
                } catch (error) {
                    reject(error);
                }
            });
        },
        DeleteLive({}, live) {
            return new Promise(async(resolve, reject) => {
                try {
                    let storageRefImage = storageImages.child(live.image.ref);
                    await storageRefImage.delete()
                    await db.collection("livesList").doc(live.id).delete()
                    resolve(live.id)
                } catch (error) {
                    reject(error);
                }
            });
        },
        DeleteCategoryLive({}, category) {
            return new Promise(async(resolve, reject) => {
                try {
                    await db.collection("categoriesLivesList").doc(category.id).delete()
                    resolve(category.id)
                } catch (error) {
                    reject(error);
                }
            });
        },
        //Filters
        GetFiltersListLives({ commit }) {
            return new Promise(async(resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("livesFilters").get()
                    const livesFilters = [];
                    querySnapshot.forEach((doc) => {
                        let filterLives = doc.data();
                        filterLives.type = doc.id
                        livesFilters.push(filterLives);
                    });
                    commit("SET_LIVES_FILTER_LIST", livesFilters);
                    resolve(livesFilters); 
                } catch (error) {
                    reject(error)
                }
            });
        },
        AddNewTypeToListOfFiltersLives({ commit }, data) {
            return new Promise(async(resolve, reject) => {
                try {
                    const res = await db.collection("livesFilters")
                    .doc(data.doc)
                    .update({
                        listFilters: firebase.firestore.FieldValue.arrayUnion({ name: data.name, children: data.children })
                    })
                    commit("ADD_ANY_VALUE_LIVES_FILTER_LIST", data);
                    resolve(res);
                } catch (error) {
                    reject(error)
                }
            })
        },
        DeleteTypeToListOfFiltersLives({ commit }, data) {
            return new Promise(async(resolve, reject) => {
                try {
                   const res = await db.collection("livesFilters")
                        .doc(data.doc)
                        .update({
                            listFilters: firebase.firestore.FieldValue.arrayRemove(data.item)
                        })
                        commit('DELETE_ANY_VALUE_LIVES_FILTER_LIST', data)
                        resolve(res);
                } catch (error) {
                        reject(error);
                }
            })
        },
        AddOrDeleteChildrenToListOfFiltersLives({ commit }, data) {
            return new Promise(async(resolve, reject) => {
                try {
                    const res = await db.collection("livesFilters").doc(data.doc)
                    .update({
                        listFilters: data.updatedArray
                    })
                    commit("UPDATE_WITH_NEW_VALUES_LIVES_FILTER_LIST_CHILDREN", data);
                    resolve(res);
                } catch (error) {
                    reject(err);
                }
            })
        },
        CreateNewFilterLives({ commit }, data) {
            return new Promise(async(resolve, reject) => {
                try {
                    const res = await db.collection("livesFilters").doc(data.doc)
                    .set({
                        name: data.name,
                        listFilters: data.listFilters,
                    })
                    commit("UPDATE_FILTER_LIST_LIVES", data);
                    resolve(res);
                } catch (error) {
                    reject(err);
                }
            })
        },
        DeleteFilterLives({ commit }, data) {
            return new Promise(async(resolve, reject) => {
                try {
                    const res = await db.collection("livesFilters").doc(data.doc).delete()
                    commit("DELETE_VALUE_ROOT_FILTER_LIVES", data);
                    resolve(res);
                } catch (error) {
                    reject(err);
                }
            })
        },
    },
    getters:{
        getLivesList(state){
            return state.livesList;
        },
        getLoadingStatusLiveImage(state){
            return state.loadingStatusLiveImage;
        },
        getLoadingStatusLivePresentationVideo(state){
            return state.loadingStatusLivePresentationVideo;
        },
        getCategoriesLivesList(state){
            return state.categoriesLivesList;
        },
        getLivesFilters(state) {
            return state.livesFilters
        },
    }
}