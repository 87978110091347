import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#ec5c00',
            'font-primary': '#ec5c00',
            secondary: '#606060',
            'font-secondary': '#606060',
            third: '#efefef',
            'font-third': '#efefef',
            four: '#38A67E',
            accent: '#8c9eff',
            error: '#b71c1c',
          },
        },
      },
});
