import firebase from "firebase";
import Vue from "vue";
const db = firebase.firestore();
import Axios from "axios";
import VueAxios from "vue-axios";
import axios from "axios";
import moment from 'moment';

Vue.use(VueAxios, Axios);

const stateCollections = {
  gender: 'byGender',
  fitnessLevel: 'byLevel',
  challenge: 'byChallenge',
}
export default {
  state: {
    byGender: {},
    byLevel: {},
    byChallenge: {},
  },
  mutations: {
    SET_COUNTERS(state, counters) {
      state[stateCollections[counters.type]] = counters.data
    }
  },
  actions: {
    GetCounterAnalitycs({ commit }, type) {
      return new Promise((resolve, reject) => {
        db.collection("countersAnalytics").doc(type).get()
          .then((res) => {
            let counters = res.data()
            commit('SET_COUNTERS', { data: counters, type: type })
            resolve(counters)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getUserResults({ commit }, userId) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getUserResults', { userId }, { headers: null });
          resolve(res.data);
        } catch (error) {
          reject(error)
          console.error(error);
        }
      })
    },
    getWorkoutsInfo({ commit }, type) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getWorkoutsInfo', null, { headers: null });
          resolve(res.data);
        } catch (error) {
          reject(error)
          console.error(error);
        }
      })
    },
    getPresetsInfo({ commit }, type) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getPresetsInfo', null, { headers: null });
          resolve(res.data);
        } catch (error) {
          reject(error)
          console.error(error);
        }
      })
    },
    getExercisesData({ commit }, type) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getNewWorkoutsData', null, { headers: null });
          resolve(res.data);
        } catch (error) {
          reject(error)
          console.error(error);
        }
      })
    },
    getWorkoutsData({ commit }, type) {
      return new Promise(async (resolve, reject) => {
        try {
          // const res = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getUsersWorkoutsInfo', null, { headers: null });

          // const db = admin.firestore();
          const usersCollection = db.collection('users');

          const users = await usersCollection.where('firstTimeInApp', '==', true).get();
          const usersData = users.docs.map(u => u.id);

          let rows = [];

          function sliceIntoChunks(arr, chunkSize) {
            const res = [];
            for (let i = 0; i < arr.length; i += chunkSize) {
              const chunk = arr.slice(i, i + chunkSize);
              res.push(chunk);
            }
            return res;
          }

          const parts = sliceIntoChunks(usersData, 100);
          // const parts = [['9nw0Q8BUEIczpHznvs3mi0DRFNC3']]

          for (let index = 0; index < parts.length; index++) {

            const element = parts[index];
            // console.log(element.length, element.includes(undefined));

            const elementRows = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getUsersWorkoutsInfo', { users: element }, { headers: null });

            const newRows = elementRows.data;
            // console.log('rows: ', newRows.length);

            rows = rows.concat(newRows);
            console.log(((usersData / parts.length) * (index + 1)).toFixed(1));
          }
          resolve(rows);
        } catch (error) {
          reject(error)
          console.error(error);
        }
      })
    },
    getProgramUsers({ commit }, type) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getProgramUsers', null, { headers: null });
          resolve(res.data);
        } catch (error) {
          reject(error)
          console.error(error);
        }
      })
    },
    getFreeUsersData({ commit }, type) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getFreeUsersInfo', null, { headers: null });
          resolve(res.data);
        } catch (error) {
          reject(error)
          console.error(error);
        }
      })
    },
    getUsersData({ commit }, type) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getUsersData', null, { headers: null });
          console.log(res.data.length, res.data[res.data.length - 1].id);
          const last = res.data[res.data.length - 1];
          const res2 = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getUsersData', { last: last.uid }, { headers: null });
          console.log(res2.data.length, res2.data[res.data.length - 1].id);
          resolve([...res.data, ...res2.data]);
        } catch (error) {
          reject(error)
          console.error(error);
        }
      })
    },
    fixPosts({ commit }, type) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/fixPostBeforeAlgolia', null, { headers: null });
          resolve(res.data);
        } catch (error) {
          reject(error)
          console.error(error);
        }
      })
    },
    getJanuaryPosts({ commit }, type) {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await Vue.axios.post('https://us-central1-maxfit-app.cloudfunctions.net/getPostsByRange', null, { headers: null });
          resolve(res.data);
        } catch (error) {
          reject(error)
          console.error(error);
        }
      })
    },
  },
  getters: {
    getCounterByGender(state) {
      return state.byGender
    },
    getCounterByLevel(state) {
      return state.byLevel
    },
    getCounterByChallenge(state) {
      return state.byChallenge
    },
  },
};
