<template>
  <v-container fluid>
    <v-row justify="space-between" class="px-10">
      <v-col cols="12" sm="12">
        <p class="text-h6 text--secondary font-weight-bold">App Configuration</p>
      </v-col>
      <v-col cols="12" sm="12">
        <v-row>
          <template v-for="(opt, i) in options">
            <v-col cols="3">
              <app-config-card :options="opt" />
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import appConfigCard from "@/components/app-config/app-config-card.vue";

export default {
  name: "AppConfig",
  components: { appConfigCard },
  data: () => ({
    options: [
      {
        name: 'Background Image App',
        icon: 'mdi-cellphone-screenshot',
        route: '/admin/app-config/bg-app'
      },
      {
        name: 'Featured Splash',
        icon: 'mdi-cellphone-screenshot',
        route: '/admin/app-config/featured-splash'
      }
    ]

  }),
  created() {
  },
  methods: {

  }
};
</script>
<style scoped></style>