import firebase from "firebase";
import Vue from "vue";
import Axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, Axios);

const db = firebase.firestore();

Vue.use(VueAxios, Axios);

export default {
    state:{
        couponsList: [],
    },
    mutations:{
        SET_COUPONS_LIST(state, couponsList){
            state.couponsList = couponsList
        },
    },
    actions:{
        GetCouponsList({ commit }){
            return new Promise(async (resolve, reject) => {
                try {
                    const querySnapshot = await db.collection("couponsList").get()
                    const couponsList = []
                    querySnapshot.forEach(async(doc) =>  {
                        let coupon = doc.data()
                        if(coupon.subscription !== ''){
                            coupon.subscription = await(await coupon.subscription.get()).data()
                        }
                        couponsList.push(coupon)
                    })
                    commit('SET_COUPONS_LIST', couponsList)
                    resolve(couponsList)
                } catch(error) {
                    reject(error)
                }
            })
        },
        GetCoupon({ }, coupon){
            return new Promise(async (resolve, reject) => {
                try {
                    let resCoupon = await db.collection("couponsList").doc(coupon.id).get()
                    let gettedCoupon = resCoupon.data()
                    if(gettedCoupon.subscription !== ''){
                        gettedCoupon.subscription = await(await gettedCoupon.subscription.get()).data()
                    }
                    gettedCoupon.expirationDate = gettedCoupon.expirationDate.toDate()
                    resolve(gettedCoupon)
                } catch(error) {
                    reject(error)
                }
            })
        },
        CreateCoupon({}, coupon) {
            return new Promise(async(resolve, reject) => {
                try {
                    if(coupon.subscription !== ''){
                        coupon.subscription = db.collection("subscriptionsList").doc(coupon.subscription.id)
                    }
                    const ref = db.collection("couponsList").doc();
                    const newCoupon = await ref.get();
                    coupon.id = ref.id;
                    coupon.createdAt = new Date()
                    coupon.updatedAt = new Date()
                    await db.collection("couponsList").doc(newCoupon.id).set(coupon)
                    resolve(newCoupon.id);
                } catch (error) {
                    reject(error);
                }
            })
        },
        UpdateCoupon({}, coupon) {
            return new Promise(async(resolve, reject) => {
                try {
                    if(coupon.subscription !== ''){
                        coupon.subscription = db.collection("subscriptionsList").doc(coupon.subscription.id)
                    }
                    if(coupon.createdAt) {
                        delete coupon.createdAt
                    }
                    coupon.updatedAt = new Date()
                    await db.collection("couponsList").doc(coupon.id).update(coupon)
                    resolve(coupon.id);
                } catch (error) {
                    reject(error);
                }
            });
        },
        DisableCoupon({}, coupon){
            return new Promise(async(resolve, reject) => {
                try {
                    await db.collection("couponsList").doc(coupon.id).update({
                      enabled: !coupon.enabled
                    })
                    resolve(coupon.id);
                } catch (error) {
                    reject(error);
                }
            });
        },
        DeleteCoupon({}, coupon) {
            return new Promise(async(resolve, reject) => {
                try {
                    await db.collection("couponsList").doc(coupon.id).delete()
                    resolve(coupon.id)
                } catch (error) {
                    reject(error);
                }
            });
        },
        VerifyExistingCoupon({}, coupon) {
            return new Promise(async(resolve, reject) => {
                try {
                    let validateCoupon = db.collection("couponsList").where("code", "==", coupon.code)
                    validateCoupon = coupon.id ? await validateCoupon.where("id", "!=" , coupon.id).get() : await validateCoupon.get()
                    resolve(validateCoupon.empty)
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
    getters:{
        getCouponsList(state){
            return state.couponsList;
        },
    }
}