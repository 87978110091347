<template>
    <v-container
    class="elevation-4 rounded-lg px-8 mb-5"
    style="background-color: #fff; max-width: 1400px !important"
  >
    <v-form ref="typeBodyProTrainerRef" v-model="validType">
      <v-row justify="center">
        <v-col cols="12" sm="12">
            <v-btn
                color="primary"
                icon
                size="20"
                class="text-center"
                @click="$router.go(-1)"
            >
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon color="black" dark v-bind="attrs" v-on="on" size="32">mdi-translate</v-icon>
            </template>
            <span>Here you can choose the language of the type of body to translate it</span>
            </v-tooltip>
            <v-menu class="text-center">  
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon class="elevation-3">
                <v-avatar size="32">
                    <v-img :src="flagActualTranslate"></v-img>
                </v-avatar>
                </v-btn>
            </template>
            <v-list v-if="listLanguages">
                <v-list-item
                v-for="(lang, index) in listLanguages"
                :key="index"
                @click="changeLanguage(lang)"
                >
                <v-list-item-action>
                    <v-avatar size="32" class="elevation-3">
                    <v-img :src="lang.flag"></v-img>
                    </v-avatar>
                </v-list-item-action>
                </v-list-item>
             </v-list>
            </v-menu>
             <v-text-field
                v-model="typeBodyCreate.name[actualTranslateLang]"
                :rules="typeRules.name"
                :disabled="loadingCreateType"
                placeholder="Create a new Type of Body"
                class="d-inline-block mx-3"
                style="width:300px;"
                solo
                flat
                background-color="#efefef"
            ></v-text-field>
            <v-text-field
                v-model.number="typeBodyCreate.orderNumber"
                @keypress="numberValid($event)"
                placeholder="Order number"
                maxlength="4"
                style="width:150px;"
                class="centered-input d-inline-block mx-3"
                solo
                flat
                background-color="#efefef"
            ></v-text-field>
            <v-btn color="primary" small :loading="loadingCreateType" :disabled="!validType || loadingCreateType" @click="createProTrainerTypeOfBody()">
                Create
            </v-btn>
        </v-col>
        <v-col cols="12" sm="12">
            <v-data-table
                :loading="loading"
                :headers="headers"
                :items="getProTrainerTypeOfBodyList"
                item-key="id"
                fixed-header
                class="elevation-4 mb-5"
            >
            <template v-slot:[`item.action`]="{ item }">
                <v-btn icon color="primary" @click="openDialogEdit(item)">
                <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <!-- <v-btn
                icon
                color="red"
                class="mr-3"
                @click="openDialogConfirm(item)"
                >
                <v-icon>mdi-delete</v-icon>
                </v-btn> -->
            </template>
            </v-data-table>
        </v-col>
       </v-row>
      <v-dialog
        v-model="dialogEditType.show"
        width="700"
        persistent
      >
        <v-card class="pa-5">
            <v-card-text class="pb-0">
                <div class="d-flex align-center">
                    <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="black" dark v-bind="attrs" v-on="on" size="32">mdi-translate</v-icon>
                    </template>
                    <span>Here you can choose the language of the type of body to translate it</span>
                    </v-tooltip>
                    <v-menu class="text-center">  
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon class="elevation-3">
                        <v-avatar size="32">
                            <v-img :src="flagActualTranslate"></v-img>
                        </v-avatar>
                        </v-btn>
                    </template>
                    <v-list v-if="listLanguages">
                        <v-list-item
                        v-for="(lang, index) in listLanguages"
                        :key="index"
                        @click="changeLanguage(lang)"
                        >
                        <v-list-item-action>
                            <v-avatar size="32" class="elevation-3">
                            <v-img :src="lang.flag"></v-img>
                            </v-avatar>
                        </v-list-item-action>
                        </v-list-item>
                    </v-list>
                    </v-menu>
                    <v-text-field
                        v-model="dialogEditType.type.name[actualTranslateLang]"
                        :disabled="dialogEditType.loading"
                        placeholder="Edit Type"
                        class="mx-3"
                        style="width:300px;"
                        solo
                        hide-details
                        flat
                        background-color="#efefef"
                    ></v-text-field>
                    <v-text-field
                        :value="dialogEditType.type.orderNumber"
                        ref="editOrderNumberProTrainerTypeOfBody"
                        @keypress="numberValid($event)"
                        hide-details
                        placeholder="Order number"
                        maxlength="4"
                        style="width:150px;"
                        class="centered-input d-inline-block mx-3"
                        solo
                        flat
                        background-color="#efefef"
                    ></v-text-field>
                </div>
                <div class="text-right mt-4">
                    <v-btn color="secondary" class="mr-2" small @click="closeDialogEdit()">
                        Cancel
                    </v-btn>
                    <v-btn color="primary" small :loading="dialogEditType.loading" @click="editProTrainerTypeOfBody()">
                        Edit
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
     </v-dialog>
     </v-form>
     <v-dialog v-model="dialog.show" width="300" class="rounded-xl">
        <v-card class="text-center">
            <v-icon class="text-center ma-2" size="120" color="primary"
            >mdi-alert</v-icon>
            <v-card-text class="pa-4 text-center"
            >This action is irreversible and will delete all data about this Pro Trainer Type
            of Body. Including the data, references and information</v-card-text>
            <v-card-text class="text-center">Are you sure?</v-card-text>

            <v-btn
            color="third"
            tile
            width="150"
            class="pa-0 text--secondary"
            @click="closeDialogConfirm()"
            >No</v-btn
            >

            <v-btn
            color="primary"
            tile
            width="150"
            class="pa-0"
            @click="openDialogReAuth('deleteType', dialog.item)"
            >Yes</v-btn
            >
            <!-- </v-card-actions> -->
        </v-card>
    </v-dialog>
    <modal-reauth
      :dialogReAuth="dialogReAuth"
      @isSuccessReAuth="isSuccessReAuth"
    ></modal-reauth>
    </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import modalReauth from "@/components/admin/modal-reauth.vue";
export default {
    name: "ProTrainersTypeOfBody",
    components: { modalReauth },
    data: () => ({
        validType: false,
        typeBodyCreate: {
            name:{
                ENG: "",
                JAP: "",
                ESP: "",
                KOR: "",
            },
            orderNumber:''
        },
        typeRules:{
            name: [v => !!v || 'Type of Body Name is required'],
        },
        loadingCreateType:false,
        loading: false,
        actualTranslateLang:"ENG",
        dialogEditType:{
            show: false,
            type: {
                name:{},
                orderNumber:''
            },
            loading: false,
        },
        dialogReAuth: {
            show: false,
            action: "",
            item: {},
        },
        dialog: {
            show: false,
            item: {},
        },
        headers: [
            { text: 'Name', align:'center', value:'name.ENG', sortable:true },
            { text: 'Order Number', align:'center', value:'orderNumber', sortable:true },
            { text: "Action", align: "center", value: "action", sortable: false },
        ],
        listLanguages:[
            {
                name:"English",
                code:"ENG",
                flag:require("@/assets/flags/usa-flag.png")
            },
            {
                name:"Japanese",
                code:"JAP",
                flag:require("@/assets/flags/japan-flag.jpg")
            },
            {
                name:"Spanish",
                code:"ESP",
                flag:require("@/assets/flags/spain-flag.png")
            },
            {
                name:"Korean",
                code:"KOR",
                flag:require("@/assets/flags/south-korea-flag.png")
            },
        ],
    }),
    created(){
        const vm = this
        vm.getAllProTrainerTypeOfBody()
    },
    methods: {
        changeLanguage(lang){
          const vm = this
          vm.actualTranslateLang = lang.code
        },
        async getAllProTrainerTypeOfBody(){
          const vm = this
          try {
            vm.$emit("showOverlay", true);
            vm.loading = true
            await vm.$store.dispatch("GetProTrainerTypeOfBodyList")
            vm.loading = false
            vm.$emit("showOverlay", false);
          } catch (error) {
            vm.$emit("showOverlay", false);
            vm.loading = false
            console.log(error);
          }
        },
        openDialogEdit(type){
          const vm = this;
          vm.dialogEditType = {
            show: true,
            type: type,
            loading:false
          }
        },
        closeDialogEdit(){
          const vm = this;
          vm.dialogEditType = {
            show: false,
            type: {
                name:{},
                orderNumber:''
            },
            loading:false
          }
        },
    openDialogConfirm(item) {
      const vm = this;
      vm.dialog.show = true;
      vm.dialog.item = item;
    },
    closeDialogConfirm() {
      const vm = this;
      vm.dialog.show = false;
      vm.dialog.item = "";
    },
    openDialogReAuth(action, item) {
      let vm = this;
      vm.dialogReAuth.show = true;
      vm.dialogReAuth.action = action;
      vm.dialogReAuth.item = item;
      vm.closeDialogConfirm();
    },
    isSuccessReAuth(dialogReAuthResponse) {
      const vm = this;
      switch (dialogReAuthResponse.action) {
        case "deleteType":
          vm.deleteType(dialogReAuthResponse.item);
          break;
        default:
          break;
      }
    },
    numberValid(e){
        e = (e) || window.event
        const charCode = (e.which) ? e.which : e.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault()
        } else {
            return true
        }
    },
    async deleteType(type){
        const vm = this;
        try {
            vm.$emit("showOverlay", true);
            await vm.$store.dispatch("DeleteProTrainerTypeOfBody", type)
            await vm.$store.dispatch("GenerateLog", {
                id: type.id,
                name: type.name['ENG'],
                type: "deletedProTrainerTypeOfBody",
            });
            vm.getAllProTrainerTypeOfBody()
            vm.$emit("showOverlay", false);
            
        } catch (error) {
            vm.$emit("showOverlay", false);
            console.error(error);
            let emitData = {
                snackbar: true,
                btn: "",
                y: "bottom",
                x: null,
                mode: "",
                timeout: 2000,
                text: "Error deleting Type of Body",
                color: "red",
            };
            vm.$emit("showSnackBar", emitData);
        } 
    },
        async editProTrainerTypeOfBody(){
          const vm = this
          try {
            vm.dialogEditType.loading = true
            if(vm.$refs.editOrderNumberProTrainerTypeOfBody.$refs.input.value!== ''){
                vm.dialogEditType.type.orderNumber = parseInt(vm.$refs.editOrderNumberProTrainerTypeOfBody.$refs.input.value)
            }
            const id = await vm.$store.dispatch('UpdateProTrainerTypeOfBody', vm.dialogEditType.type)
            console.log(vm.dialogEditType.type.name)
            await vm.$store.dispatch("GenerateLog", {
                id: id,
                name: vm.dialogEditType.type.name.ENG,
                type: "editedProTrainerTypeOfBody",
            })
          
            let emitData = {
                snackbar: true,
                btn: "",
                y: "bottom",
                x: null,
                mode: "",
                timeout: 2000,
                text: "Type of Body edited successfully",
                color: "success",
            };
            vm.dialogEditType.loading = false
            vm.getAllProTrainerTypeOfBody()
            vm.closeDialogEdit()
            vm.$emit("showSnackBar", emitData);
          } catch (error) {

              let emitData = {
                  snackbar: true,
                  btn: "",
                  y: "bottom",
                  x: null,
                  mode: "",
                  timeout: 2000,
                  text: "Error editing type of Body",
                  color: "red",
              };
              vm.loadingCreateType = false
              vm.$emit("showSnackBar", emitData);
          }
        },
         async createProTrainerTypeOfBody(){
          const vm = this;
          try {
            vm.loadingCreateType = true
            const id = await vm.$store.dispatch('CreateProTrainerTypeOfBody', vm.typeBodyCreate)
            await vm.$store.dispatch("GenerateLog", {
                id: id,
                name: vm.typeBodyCreate.name["ENG"],
                type: "createdProTrainerTypeOfBody",
            })
          
            let emitData = {
                snackbar: true,
                btn: "",
                y: "bottom",
                x: null,
                mode: "",
                timeout: 2000,
                text: "Type of Body Created successfully",
                color: "success",
            };
            vm.loadingCreateType = false
            vm.getAllProTrainerTypeOfBody()
            vm.$emit("showSnackBar", emitData);
          } catch (error) {
              console.log('error', error);
              let emitData = {
                  snackbar: true,
                  btn: "",
                  y: "bottom",
                  x: null,
                  mode: "",
                  timeout: 2000,
                  text: "Error creating Type of Body",
                  color: "red",
              };
              vm.loadingCreateType = false
              vm.$emit("showSnackBar", emitData);
          }
        }
    }, 
    computed:{
        flagActualTranslate() {
            let vm = this;
            if(vm.listLanguages.length > 0){
                let currentFlag = vm.listLanguages.find(flag => flag.code === vm.actualTranslateLang);
                return currentFlag.flag;
            }
        },
        ...mapGetters(["getProTrainerTypeOfBodyList"])
    }
}
</script>