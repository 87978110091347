<template>
    <v-container fluid>
    <v-row justify="space-between">
      <v-col cols="12" sm="12" md="12">
        <v-btn color="primary" icon size="20" @click="$router.go(-1)">
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <p class="text-h6 text--secondary d-inline font-weight-bold">Filters Pre-Sets</p>
      </v-col>
      <v-col cols="12" sm="12" md="12" align-self="center">
        <p class="secondary--text ml-4 mr-2 font-weight-bold d-inline">Filters</p>
        <v-btn
          text
          color="secondary"
          @click="dialogCreateNewFilter.show = true"
          >
          <v-icon left dark color="primary"> mdi-plus </v-icon>
          Generate new Filter
        </v-btn>
        
      </v-col>
    </v-row>
    <v-row justify="start" v-if="getPreSetsFilters.length > 0">
      <v-col cols="4" v-for="(filter, i) in getPreSetsFilters" :key="i">
           <v-btn
            text
            color="secondary"   
            class="mb-3"
            @click="openDialogCreate(filter.type, filter.name)"
            >
            <v-icon left dark color="primary"> mdi-plus </v-icon>
            Add new {{ filter.name['ENG'] }}
            </v-btn>
           <v-btn
            text
            color="red"
            class="mb-3"
            @click="openDialogDeleteConfirm(filter)"
            >
            <v-icon left dark color="primary"> mdi-delete </v-icon>
            Delete 
            </v-btn>
            
            <v-data-table
              :headers="headers"
              :items-per-page="5"
              :items="filter.listFilters"
              fixed-header
              class="elevation-4"
            >
             <template v-slot:[`item.name`]="{ item }">
                  {{ item.name }}
              </template>
              <template v-slot:[`item.action`]="{ item }">
                 <v-btn
                    v-if="item"
                    text
                    icon
                    color="primary"
                    @click="openSubfiltersModal(item, filter.type)"
                  >
                  <v-icon>mdi-format-list-bulleted</v-icon>
                </v-btn>
                <v-btn
                    v-if="item"
                    text
                    icon
                    color="secondary"
                    @click="deleteItem(filter.type, item)"
                  >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
           </v-data-table>
      </v-col>
    </v-row>
     <v-dialog
      v-model="dialogCreate.show"
      persistent
      max-width="500"
    >
      <v-card class="text-center">
       
          <v-card-title class="secondary--text">
            Create new {{ dialogCreate.name.ENG }}
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="11">
                 <v-text-field
                    v-model="dialogCreate.name"
                    placeholder="Name"
                    solo
                    flat
                    background-color="#efefef"
                  ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              small
              :disabled="dialogCreate.loading"
              @click="closeDialogCreate()"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              small
              :disabled="dialogCreate.loading"
              :loading="dialogCreate.loading"
              @click="saveNewCreate()"
            >
              Add
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
     <v-dialog
      v-model="dialogChildrenFilters.show"
      persistent
      max-width="500"
    >
      <v-card class="text-center">
       
          <v-card-title class="secondary--text">
            Sub-Filters of {{ dialogChildrenFilters.item.name }}
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="10">
                 <v-text-field
                    v-model="dialogChildrenFilters.newSubfilter"
                    placeholder="Add new sub-filter"
                    :disabled="dialogChildrenFilters.loading"
                    solo
                    flat
                    background-color="#efefef"
                    @keyup.enter="saveNewSubfilter()"
                  ></v-text-field>
              </v-col>
              <v-col cols="1">
                  <v-btn
                    text
                    icon
                    :disabled="dialogChildrenFilters.loading || dialogChildrenFilters.newSubfilter.length <= 0"
                    :loading="dialogChildrenFilters.loading"
                    color="primary"
                    @click="saveNewSubfilter()"
                  >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="11">
                <v-data-table
                  v-if="getPreSetsFilters.length > 0"
                  :headers="headers"
                  :items-per-page="5"
                  :items="dialogChildrenFilters.item.children"
                  no-data-text="This filter does not have subfilters"
                  fixed-header
                  class="elevation-4"
                >
                <template v-slot:[`item.name`]="{ item }">
                      {{ item.name }}
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                    <v-btn
                        v-if="item"
                        text
                        icon
                        color="primary"
                        @click="openThirdLevelFiltersModal(item, dialogChildrenFilters.type, dialogChildrenFilters.item.name)"
                      >
                      <v-icon>mdi-format-list-bulleted</v-icon>
                    </v-btn>
                      <v-btn
                        v-if="item"
                        text
                        icon
                        color="secondary"
                        @click="deleteSubfilter(item)"
                      >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
              </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              small
              :disabled="dialogCreate.loading"
              @click="dialogChildrenFilters.show = false"
            >
              Close
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogThirdLevelFilters.show"
      persistent
      max-width="500"
    >
      <v-card class="text-center">
       
          <v-card-title class="secondary--text">
            Third level filters of {{ dialogThirdLevelFilters.item.name }}
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="10">
                 <v-text-field
                    v-model="dialogThirdLevelFilters.newThirdLevel"
                    placeholder="Add new third level"
                    :disabled="dialogThirdLevelFilters.loading"
                    solo
                    flat
                    background-color="#efefef"
                    @keyup.enter="saveNewThirdLevelFilter()"
                  ></v-text-field>
              </v-col>
              <v-col cols="1">
                  <v-btn
                    text
                    icon
                    :disabled="dialogThirdLevelFilters.loading || dialogThirdLevelFilters.newThirdLevel.length <= 0"
                    :loading="dialogThirdLevelFilters.loading"
                    color="primary"
                    @click="saveNewThirdLevelFilter()"
                  >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="11">
                <v-data-table
                  v-if="getPreSetsFilters.length > 0"
                  :headers="headers"
                  :items-per-page="5"
                  :items="dialogThirdLevelFilters.item.children"
                  no-data-text="This filter does not have third level filters"
                  fixed-header
                  class="elevation-4"
                >
                <template v-slot:[`item.name`]="{ item }">
                      {{ item.name }}
                  </template>
                  <template v-slot:[`item.action`]="{ item }">
                      <v-btn
                        v-if="item"
                        text
                        icon
                        color="secondary"
                        @click="deleteThirdLevelFilter(item)"
                      >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
              </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              small
              :disabled="dialogThirdLevelFilters.loading"
              @click="dialogThirdLevelFilters.show = false"
            >
              Close
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogCreateNewFilter.show"
      persistent
      max-width="500"
      
    >
      <v-card class="text-center pb-4 pt-4">
          <v-card-title class="secondary--text d-inline">
            Generate new filter
          </v-card-title>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="black" dark v-bind="attrs" v-on="on" size="32">mdi-translate</v-icon>
            </template>
            <span>Here you can choose the language of the filter to translate it</span>
          </v-tooltip>
          <v-menu class="text-center">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon class="elevation-3">
                <v-avatar size="32">
                  <v-img :src="flagActualTranslate"></v-img>
                </v-avatar>
              </v-btn>
            </template>
            <v-list v-if="listLanguages">
              <v-list-item
                v-for="(lang, index) in listLanguages"
                :key="index"
                @click="changeLanguage(lang)"
              >
                <v-list-item-action>
                  <v-avatar size="32" class="elevation-3">
                    <v-img :src="lang.flag"></v-img>
                  </v-avatar>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-card-text class="mt-4">
            <v-row justify="center">
              <v-col cols="11">
                 <v-text-field
                    v-model="dialogCreateNewFilter.name[actualTranslateLang]"
                    placeholder="Name"
                    solo
                    flat
                    background-color="#efefef"
                  ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              small
              :disabled="dialogCreateNewFilter.loading"
              @click="closeDialogCreateNewFilter()"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              small
              :disabled="dialogCreateNewFilter.loading"
              :loading="dialogCreateNewFilter.loading"
              @click="saveNewCreateFilter()"
            >
              Generate
            </v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteConfirm.show" width="300" class="rounded-xl">
      <v-card class="text-center">
        <!-- <v-card-title class="headline">Use Google's location service?</v-card-title> -->
        <v-icon class="text-center ma-2" size="120" color="primary"
          >mdi-alert</v-icon
        >
        <v-card-text class="pa-4 text-center"
          >This action is irreversible and will delete all data about the Filter and Sub-filter.</v-card-text
        >
        <v-card-text class="text-center">Are you sure?</v-card-text>

        <!-- <v-card-actions class="pa-0"> -->
        <!-- <v-spacer></v-spacer> -->

        <v-btn
          color="third"
          tile
          width="150"
          class="pa-0 text--secondary"
          @click="closeDialogDeleteConfirm()"
          >No</v-btn
        >

        <v-btn
          color="primary"
          tile
          width="150"
          class="pa-0"
          @click="openDialogReAuth('deleteRootFilter', dialogDeleteConfirm.item)"
          >Yes</v-btn
        >
        <!-- </v-card-actions> -->
      </v-card>
      </v-dialog>
      <modal-reauth :dialogReAuth="dialogReAuth" @isSuccessReAuth="isSuccessReAuth"></modal-reauth>
    </v-container>
</template>
<script>
import modalReauth from '@/components/admin/modal-reauth.vue';
import { mapGetters } from "vuex";
export default {
  name: "PreSetsFilters",
  components:{ modalReauth },
  data: () => ({
    headers:[
        { text: "Name", align: "center", value: "name", sortable: true, },
        { text: "Action", align: "center", value: "action", sortable: false },
    ],
    dialogCreate:{
        show:false,
        type:'',
        name:'',
        loading:false,
    },
    dialogChildrenFilters:{
        show:false,
        type:'',
        item:{},
        newSubfilter:'',
        loading:false,
    },
    dialogThirdLevelFilters:{
        show:false,
        type:'',
        item:{},
        parent: '',
        newThirdLevel:'',
        loading:false,
    },
    dialogCreateNewFilter:{
      show:false,
      loading:false,
      name:{
        ENG:"",
        KOR:"",
        ESP:"",
        JAP:"",
      }
    },
    dialogReAuth:{
      show: false,
      action: '',
      item:{}
    },
    dialogDeleteConfirm:{
      show: false,
      item:{}
    },
    actualTranslateLang:"ENG",
    listLanguages:[
      {
        name:"English",
        code:"ENG",
        flag:require("@/assets/flags/usa-flag.png")
      },
      {
        name:"Japanese",
        code:"JAP",
        flag:require("@/assets/flags/japan-flag.jpg")
      },
      {
        name:"Spanish",
        code:"ESP",
        flag:require("@/assets/flags/spain-flag.png")
      },
      {
        name:"Korean",
        code:"KOR",
        flag:require("@/assets/flags/south-korea-flag.png")
      },
    ]
  }),
   async created() {
    const vm = this
    await vm.getPreSetsFiltersList()
  },
  methods: {
    async getPreSetsFiltersList(){
       const vm = this;
       try {
           vm.$emit("showOverlay", true);
           await vm.$store.dispatch('GetFiltersListPreSets')
           vm.$emit("showOverlay", false);
       } catch (error) {
           vm.$emit("showOverlay", false);
           console.error(error)
       }
    },
    getTypeTextPlaceholder(type){
        if(type === 'areaOfFocus'){ return 'Area of Focus'}else if(type === 'placement'){ return 'Placement'}else if(type === 'trainers'){ return 'Trainer'}else if(type === 'workoutType'){ return 'Workout Type'}
    },
    closeDialogCreate(){
        let vm = this;
        vm.dialogCreate = {
            show:false,
            type:'',
            name:'',
            loading:false,
        }
    },
    openDialogCreate(type, name){
        let vm = this;
        vm.dialogCreate.show = true
        vm.dialogCreate.type = type
        vm.dialogCreate.name = name
        // vm.dialogCreate.array = array
    },
    async saveNewCreate(){
      let vm = this;
      try {
          vm.dialogCreate.loading = true 
          let data = {
              name:vm.dialogCreate.name,
              doc:vm.dialogCreate.type,
              children:[],
          } 
          await vm.$store.dispatch('AddNewTypeToListOfFiltersPreSets', data)
          await vm.$store.dispatch("GenerateLog", {
                    name: data.name,
                    doc: data.doc,
                    type: "createdNewFilterPreSets",
                });
            let emitData = {
                snackbar: true,
                btn: "",
                y: "bottom",
                x: null,
                mode: "",
                timeout: 2000,
                text: "New "+data.doc+" created successfully",
                color: "success",
            };
            vm.$emit("showSnackBar", emitData);
            vm.closeDialogCreate()
      } catch (error) {
          vm.dialogCreate.loading = false
          console.error(error)
      }
    },
    isSuccessReAuth(dialogReAuthResponse){
      const vm = this 
        switch (dialogReAuthResponse.action) {
          case 'deleteRootFilter':
            vm.deleteRootFilter(dialogReAuthResponse.item)
            break;
          default:
            break;
        }
    },
    async deleteItem(type, item){
      let vm = this;
      try {
        let data = {
            item:item,
            doc:type
        } 
        await vm.$store.dispatch('DeleteTypeToListOfFiltersPreSets', data)
        await  vm.$store.dispatch("GenerateLog", {
          name: data.name,
          doc:data.doc,
          type: "deletedFilterPreSets",
        });
        let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: item.name+" deleted successfully",
            color: "dark",
        };
        vm.$emit("showSnackBar", emitData);
      } catch (error) {
           let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 3000,
            text: item.name+" has an error deleting, please restart the page and try again",
            color: "red",
        };
        vm.$emit("showSnackBar", emitData);
        console.error(error)
          
      }
    },
    openThirdLevelFiltersModal(item, type, parent){
      let vm = this
      vm.dialogThirdLevelFilters = {
        show:true,
        type:type,
        item:item,
        parent:parent,
        newThirdLevel:'',
        loading:false,
      }
      console.log('dialogThirdLevelFilters', vm.dialogThirdLevelFilters);
    },
    openSubfiltersModal(item, type){
      let vm = this
      console.log(item);
      vm.dialogChildrenFilters = {
        show:true,
        type:type,
        item:item,
        newSubfilter:'',
        loading:false,
      }
    },
    async saveNewSubfilter(){
      let vm = this
      try {
          if(vm.dialogChildrenFilters.newSubfilter.length > 0){
            vm.dialogChildrenFilters.loading = true
            let updatedArray = []
            vm.getPreSetsFilters.forEach((filter) => {
              if(filter.type === vm.dialogChildrenFilters.type){
                updatedArray = filter.listFilters.map((subfilter) => {
                  if(subfilter.name === vm.dialogChildrenFilters.item.name){
                    subfilter.children.push({name: vm.dialogChildrenFilters.newSubfilter, children: []})
                  }
                  return subfilter
                })
              }
            })
            let data ={
              doc:vm.dialogChildrenFilters.type,
              item:vm.dialogChildrenFilters.item,
              updatedArray:updatedArray
            }
      
            await vm.$store.dispatch('AddOrDeleteChildrenToListOfFiltersPreSets', data)
            await vm.$store.dispatch("GenerateLog", {
                name: vm.dialogChildrenFilters.newSubfilter,
                doc: data.item.name,
                type: "createdSubfilterPreSets",
              });
              let emitData = {
                snackbar: true,
                  btn: "",
                  y: "bottom",
                  x: null,
                  mode: "",
                  timeout: 2000,
                  text: vm.dialogChildrenFilters.newSubfilter+" added successfully",
                  color: "dark",
              };
               vm.dialogChildrenFilters.newSubfilter = ''
               vm.dialogChildrenFilters.loading = false
              vm.$emit("showSnackBar", emitData);
          }
      } catch (error) {
         vm.dialogChildrenFilters.loading = false
         let emitData = {
             snackbar: true,
             btn: "",
             y: "bottom",
             x: null,
             mode: "",
             timeout: 3000,
             text: data.newSubfilter+" has an error adding new sub-filter, please restart the page and try again",
             color: "red",
         };
         vm.$emit("showSnackBar", emitData);
         console.error(error)
      }
    },
    async saveNewThirdLevelFilter(){
      try {
        const vm = this
        let updatedArray = []
        vm.getPreSetsFilters.forEach((filter) => {
            if(filter.type === vm.dialogThirdLevelFilters.type){
              updatedArray = filter.listFilters.map((subfilter) => {
                if(subfilter.name === vm.dialogThirdLevelFilters.parent){
                  const indexSubFilter = subfilter.children.findIndex(subF => subF.name === vm.dialogThirdLevelFilters.item.name)
                  subfilter.children[indexSubFilter].children.push({ name: vm.dialogThirdLevelFilters.newThirdLevel })
                }
                return subfilter
              })
            }
        })

        let data ={
          doc:vm.dialogThirdLevelFilters.type,
          item:vm.dialogThirdLevelFilters.item,
          updatedArray: updatedArray
        }
        await vm.$store.dispatch('AddOrDeleteChildrenToListOfFiltersPreSets', data)
        
        await vm.$store.dispatch("GenerateLog", {
          name: vm.dialogThirdLevelFilters.newThirdLevel,
          doc: vm.getTypeTextPlaceholder(data.doc),
          type: "createdThirdLevelPreSets",
        });
        let emitData = {
          snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: vm.dialogThirdLevelFilters.newThirdLevel+" added successfully",
            color: "dark",
        };
        vm.dialogThirdLevelFilters.newThirdLevel = ''
        vm.dialogThirdLevelFilters.loading = false
        vm.$emit("showSnackBar", emitData);
        
      } catch (error) {
        vm.dialogThirdLevelFilters.loading = false
          let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 3000,
              text: vm.dialogThirdLevelFilters.newSubfilter+" has an error adding new third level filter, please restart the page and try again",
              color: "red",
          };
          vm.$emit("showSnackBar", emitData);
          console.error(error)
      }
    },
    async deleteSubfilter(itemToDelete){
      let vm = this
      try {
          vm.dialogChildrenFilters.loading = true
          let indexPrincipal = vm.getPreSetsFilters.findIndex(filter => filter.type === vm.dialogChildrenFilters.type)
          let selectedFilterList = vm.getPreSetsFilters[indexPrincipal].listFilters
          let indexFilterList = selectedFilterList.findIndex(filter => filter.name === vm.dialogChildrenFilters.item.name )
          let indexChildren = vm.dialogChildrenFilters.item.children.findIndex(child => child.name === itemToDelete.name )
          selectedFilterList[indexFilterList].children.splice(indexChildren, 1)
          let data ={
            doc:vm.dialogChildrenFilters.type,
            item:vm.dialogChildrenFilters.item,
            updatedArray:selectedFilterList
          }

          console.log('itemToDelete', itemToDelete)

          await vm.$store.dispatch('AddOrDeleteChildrenToListOfFiltersPreSets', data)
          await vm.$store.dispatch("GenerateLog", {
              name: itemToDelete.name,
              doc: data.item.name,
              type: "deletedSubfilterPreSets",
            });
          let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: itemToDelete.name.ENG+" deleted successfully",
            color: "dark",
         };
         vm.dialogChildrenFilters.loading = false
         vm.$emit("showSnackBar", emitData);
          
      } catch (error) {
        vm.dialogChildrenFilters.loading = false
        let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 3000,
            text: itemToDelete.name+" has an error deleting sub-filter, please restart the page and try again",
            color: "red",
        };
        vm.$emit("showSnackBar", emitData);
        console.error(error)
      }
    },
    async deleteThirdLevelFilter(itemToDelete){
      try {
        const vm = this
        vm.dialogThirdLevelFilters.loading = true
        let indexPrincipal = vm.getPreSetsFilters.findIndex(filter => filter.type === vm.dialogThirdLevelFilters.type)
        let selectedFilterList = vm.getPreSetsFilters[indexPrincipal].listFilters
        let indexFilterList = selectedFilterList.findIndex(filter => filter.name === vm.dialogThirdLevelFilters.parent )
        let indexSubFilter =  vm.getPreSetsFilters[indexPrincipal].listFilters[indexFilterList].children.findIndex(subfilter => subfilter.name ===  vm.dialogThirdLevelFilters.item.name)
        let indexThirdLevel = vm.getPreSetsFilters[indexPrincipal].listFilters[indexFilterList].children[indexSubFilter].children.findIndex(thirdLevel => thirdLevel.name === itemToDelete.name)
        selectedFilterList[indexFilterList].children[indexSubFilter].children.splice(indexThirdLevel, 1)

        let data ={
          doc:vm.dialogThirdLevelFilters.type,
          item:vm.dialogThirdLevelFilters.item,
          updatedArray:selectedFilterList
        }
        vm.$store.dispatch('AddOrDeleteChildrenToListOfFiltersPreSets', data)
       
        vm.$store.dispatch("GenerateLog", {
            name: itemToDelete.name,
            doc: vm.getTypeTextPlaceholder(data.doc),
            type: "deletedThirdLevelPreSets",
        });

        let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: itemToDelete.name+" deleted successfully",
            color: "dark",
        };
        vm.dialogThirdLevelFilters.loading = false
        vm.$emit("showSnackBar", emitData);
                
      } catch (error) {
          vm.dialogThirdLevelFilters.loading = false
          let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 3000,
              text: itemToDelete.name+" has an error deleting third level filter, please restart the page and try again",
              color: "red",
          };
          vm.$emit("showSnackBar", emitData);
          console.error(error)   
      }
    },
    closeDialogCreateNewFilter(){
      const vm = this
      vm.dialogCreateNewFilter = {
        show:false,
        loading:false,
        name:{
          ENG:"",
          KOR:"",
          ESP:"",
          JAP:"",
        }
      }
    },
    async saveNewCreateFilter(){
      const vm = this
      try {
          let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 3000,
            text: "",
            color: "red",
          }
          vm.dialogCreateNewFilter.loading = true
          if(vm.dialogCreateNewFilter.name['ENG'].length > 0){
            if(!vm.getPreSetsFilters.some(filter => filter.type === vm.camelCaseFilterName)){
              let data = {
                name: vm.dialogCreateNewFilter.name,
                listFilters: [],
                doc: vm.camelCaseFilterName
              }
              console.log(data);
            await vm.$store.dispatch('CreateNewFilterPreSets', data)
            await vm.$store.dispatch("GenerateLog", {
                      name: data.name['ENG'],
                      doc: data.doc,
                      type: "createdRootFilterPreSets",
                  });
                  vm.closeDialogCreateNewFilter()
                  emitData.text = "Filter generated successfully",
                  emitData.color = "success"
                  vm.$emit("showSnackBar", emitData);

            }else{
              vm.dialogCreateNewFilter.loading = false
              emitData.text = "A filter with this name already exists"
              emitData.color = "red"
              vm.$emit("showSnackBar", emitData);
            }
    
          }else{
            vm.dialogCreateNewFilter.loading = false
            emitData.text = "The name is required in the English language"
            emitData.color = "red"
            vm.$emit("showSnackBar", emitData);
          }
          
      } catch (error) {
         vm.dialogCreateNewFilter.loading = false
         emitData.text = "An error has occured generating filter",
         emitData.color = "red"
         vm.$emit("showSnackBar", emitData);
         console.error(err)
      }

    },
    changeLanguage(lang){
      const vm = this
      vm.actualTranslateLang = lang.code
    },
    openDialogDeleteConfirm(filter){
      const vm = this
      vm.dialogDeleteConfirm = {
        show: true,
        item:filter
      }
    },
    closeDialogDeleteConfirm(){
      const vm = this
      vm.dialogDeleteConfirm = {
        show: false,
        item:{}
      }
    },
    openDialogReAuth(action, item){
      const vm = this;
      vm.dialogReAuth.show = true
      vm.dialogReAuth.action = action
      vm.dialogReAuth.item = item
      vm.closeDialogDeleteConfirm();
    },
    async deleteRootFilter(item){
      const vm = this
      try {
        vm.$emit("showOverlay", true);
        let emitData = {
        snackbar: true,
        btn: "",
        y: "bottom",
        x: null,
        mode: "",
        timeout: 3000,
        text: "",
        color: "red",
        }
        let data = {
            doc:item.type,
            name:item.name
        }
        await vm.$store.dispatch('DeleteFilterPreSets', data)
        await  vm.$store.dispatch("GenerateLog", {
              name: data.name['ENG'],
              doc: data.doc,
              type: "deletedRootFilterPreSets",
            });
        vm.$emit("showOverlay", false);
        emitData.text = "Filter deleted successfully",
        emitData.color = "dark"
        vm.$emit("showSnackBar", emitData);
          
      } catch (error) {
        vm.$emit("showOverlay", false);
        emitData.text = "An error has occured deleting filter",
        emitData.color = "red"
        vm.$emit("showSnackBar", emitData);
        console.error(err) 
      }
    }
  },
  computed:{
      camelCaseFilterName(){
        return this.dialogCreateNewFilter.name['ENG'].replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
          if (/\s+/.test(match)) return '';
          return index === 0 ? match.toLowerCase() : match.toUpperCase();
        });
      },
      flagActualTranslate() {
        let vm = this;
        if(vm.listLanguages.length > 0){
          let currentFlag = vm.listLanguages.find(flag => flag.code === vm.actualTranslateLang);
          return currentFlag.flag;
      }
      },
     ...mapGetters(["getPreSetsFilters"]),
     
  }
}
</script>