<template>
  <v-container
    class="elevation-4 rounded-lg px-8 mb-5"
    style="background-color: #fff"
  >
    <v-form ref="formFirmwareVersion" v-model="validFirmwareVersion">
      <v-row justify="center">
        <v-col cols="12" sm="12">
          <v-btn
            color="primary"
            icon
            size="20"
            class="text-center"
            @click="$router.go(-1)"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <h3 class="secondary--text d-inline mr-2">Create Firmware Version</h3>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon color="black" dark v-bind="attrs" v-on="on" size="32">mdi-translate</v-icon>
            </template>
            <span>Here you can choose the language of the Firmware Version to translate it</span>
            </v-tooltip>
            <v-menu class="text-center">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon class="elevation-3">
                <v-avatar size="32">
                    <v-img :src="flagActualTranslate"></v-img>
                </v-avatar>
                </v-btn>
            </template>
            <v-list v-if="listLanguages">
                <v-list-item
                v-for="(lang, index) in listLanguages"
                :key="index"
                @click="changeLanguage(lang)"
                >
                <v-list-item-action>
                    <v-avatar size="32" class="elevation-3">
                    <v-img :src="lang.flag"></v-img>
                    </v-avatar>
                </v-list-item-action>
                </v-list-item>
            </v-list>
            </v-menu>
        </v-col>
        <v-col cols="12" sm="12" md="4" class="mx-auto text-center">
            <p class="secondary--text pb-1">File Firmware</p>
            <v-file-input
              ref="firmwareVersionFile"
              show-size
              solo
              flat
              background-color="#efefef"
              accept="application/octet-stream"
              @change="getFirmwareVersionFile"
              truncate-length="50"
            ></v-file-input>
   
             <div class="mx-auto mt-5 text-center" style="width:150px">
                <p class="secondary--text text-center pb-1">Order number</p>
                <v-text-field
                    v-model.number="firmwareVersion.orderNumber"
                    @keypress="numberValid($event)"
                    maxlength="4"
                    class="centered-input"
                    solo
                    flat
                    background-color="#efefef"
                ></v-text-field>
            </div>
            <p class="secondary--text text-center pb-1">Enabled</p>
            <v-switch
                color="primary"
                class="d-flex justify-center center-switch"
                v-model="firmwareVersion.enabled"
                :input-value="firmwareVersion.enabled"
                flat
            ></v-switch>
        </v-col>
          <v-col cols="12" sm="12" md="4" class="mx-auto">
            <p class="secondary--text pb-1">Title</p>
            <v-text-field
            v-model="firmwareVersion.title[actualTranslateLang]"
            :rules="firmwareVersionRules.title"
            solo
            flat
            background-color="#efefef"
            ></v-text-field>
            <p class="secondary--text pb-1">Link</p>
             <v-text-field
              v-model="firmwareVersion.link"
              :rules="firmwareVersionRules.link"
              solo
              flat
              background-color="#efefef"
            ></v-text-field>
            <p class="secondary--text pb-1">Version</p>
            <v-text-field
            v-model.number="firmwareVersion.version"
            :rules="firmwareVersionRules.version"
            solo
            flat
            background-color="#efefef"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="primary" class="float-right" :disabled="!firmwareVersion.file.data || !validFirmwareVersion" @click="uploadFirmwareVersionFile()">Create</v-btn>
         </v-col>
      </v-row>
    </v-form>
    <v-dialog
      v-model="dialogUploadFile"
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Uploading file, please wait...
          <v-progress-linear
            color="white"
            class="mb-0"
            :value="getLoadingStatusFirmwareVersionFile"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
     <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
        snackbar.text
      }}</v-snackbar>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import * as buffer from "buffer"
export default {
  name:"FirmwareVersionCreate",
  data: () => ({
      validFirmwareVersion:false,
      firmwareVersion:{
          title:{},
          link:"",
          version:0,
          file:{
            data:null,
            link:'',
            ref:'',
            size:'',
            name:'',
            type: ''
          },
          enabled:true,
          orderNumber:''
      },
      firmwareVersionRules:{
        title: [v => !!v || 'Title is required'],
        link:[v => /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(^$)/.test(v) || 'Insert a valid url, like: https://maxprofitness.com/'],
        version: [v => /^(\d+\.)?(\d+\.)?(\*|\d+)$/.test(v) || 'Insert a valid version like: 1.2'],
      },
      snackbar: {
        snackbar: false,
        timeout: 2000,
        text: null,
        color: "",
      },
      dialogUploadFile:false,
      actualTranslateLang:"ENG",
      listLanguages:[
        {
            name:"English",
            code:"ENG",
            flag:require("@/assets/flags/usa-flag.png")
        },
        {
            name:"Japanese",
            code:"JAP",
            flag:require("@/assets/flags/japan-flag.jpg")
        },
        {
            name:"Spanish",
            code:"ESP",
            flag:require("@/assets/flags/spain-flag.png")
        },
        {
            name:"Korean",
            code:"KOR",
            flag:require("@/assets/flags/south-korea-flag.png")
        },
      ],
  }),
  created(){
      const vm = this
  },
  methods:{
    numberValid(e){
        e = (e) || window.event
        const charCode = (e.which) ? e.which : e.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault()
        } else {
            return true
        }
    },
    getFileSize(bytes, dp = 1) {
      const thresh = 1024;

      if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
      }
        //si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']  : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']; // original
      const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
      let u = -1;
      const r = 10**dp;

      do {
        bytes /= thresh;
        ++u;
      } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

      return bytes.toFixed(dp) + ' ' + units[u];
    },
    changeLanguage(lang){
      const vm = this
      vm.actualTranslateLang = lang.code
    },
    getFirmwareVersionFile(file){
        let vm = this
        if(file){
          if(file.name.split('.')[1] === 'bin'){
            const fr = new FileReader();
            fr.onload = async (el) => {
              const arrayFirmware = vm.displayHexData(await buffer.Buffer.from(el.target.result, "binary"));
              vm.firmwareVersion.file = {
                data: JSON.stringify(arrayFirmware),
                type: file.name.split('.')[1],
                size:vm.getFileSize(file.size, 2),
                name: file.name.split('.')[0],
                link: ""
              }
            };
            fr.readAsBinaryString(file);
          }else{
           vm.$refs.firmwareVersionFile.reset();
           let emitData = {
              snackbar: true,
              btn: "",
              y: "bottom",
              x: null,
              mode: "",
              timeout: 2000,
              text: "Only .bin files are accepted",
              color: "red",
            };
          vm.$emit("showSnackBar", emitData);
        }
          
      }
    },
     displayHexData(data) {
        const startOffsetInDecimal = 2048;
        const blocksLength = 128;
        let actualOffset = null;
        let blockOfData = [];

        for (let addr = 0; addr <= data.length; addr += 16) {
          const displayAddr = addr.toString(16).toUpperCase().padStart(8, "0");
          const block = data.slice(addr, Math.min(addr + 16, data.length));

          let hexblock = Array.from(block)
            .map((b) => b.toString(16).toUpperCase().padStart(2, "0"))
            .join("");

          if (addr + 16 > data.length) {
            hexblock += "  ".repeat(16 - (data.length - addr));
          }

          const charsblock = Array.from(block)
            .map((b) => (b >= 32 && b <= 126 ? String.fromCharCode(b) : "."))
            .join("");

          if (parseInt(displayAddr, 16) >= startOffsetInDecimal) {
            //start building blocks
            const actualBlock = blockOfData.findIndex((block) => block.offset === actualOffset);
            const addressShort = displayAddr.substr(displayAddr.length - 4);
            if (actualBlock !== -1) {
              if (blockOfData[actualBlock].data.length >= blocksLength) {
                actualOffset = addressShort;
                blockOfData.push({ offset: addressShort, data: hexblock });
              } else {
                blockOfData[actualBlock].data += hexblock;
              }
            } else {
              actualOffset = addressShort;
              blockOfData.push({ offset: addressShort, data: hexblock });
            }
          }
        }
        //checkLastBlock
        blockOfData[blockOfData.length - 1].data = blockOfData[blockOfData.length - 1].data.replace(/ /g, "");
        const lastBlockDataLength = blockOfData[blockOfData.length - 1].data.length;
        if (lastBlockDataLength < blocksLength) {
          // fill block of data with 0
          for (let i = lastBlockDataLength; i < blocksLength; i++) {
            blockOfData[blockOfData.length - 1].data += "0";
          }
        }
        return blockOfData
    },
    uploadFirmwareVersionFile(){
      let vm = this;
      vm.dialogUploadFile = true
       vm.$store
        .dispatch("UploadFileFirmwareVersion", vm.firmwareVersion)
        .then((storage) => {
          vm.firmwareVersion.file.link = storage.link
          vm.firmwareVersion.file.ref = storage.ref
          vm.dialogUploadFile = false
          vm.createFirmwareVersion();
          
        }).catch((err) => {
          console.log(err);
          vm.dialogUploadFile = false

           let emitData = {
            snackbar: true,
            btn: "",
            y: "bottom",
            x: null,
            mode: "",
            timeout: 2000,
            text: "Error uploading file",
            color: "red",
          };
          vm.$emit("showSnackBar", emitData);
        })
    },
    async createFirmwareVersion(){
     const vm = this;
          try {
            const id = await vm.$store.dispatch('CreateFirmwareVersion', vm.firmwareVersion)
            await vm.$store.dispatch("GenerateLog", {
                id: id,
                title: vm.firmwareVersion.title['ENG'],
                type: "createdFirmwareVersion",
            })
          
            let emitData = {
                snackbar: true,
                btn: "",
                y: "bottom",
                x: null,
                mode: "",
                timeout: 2000,
                text: "Firmware Version Created successfully",
                color: "success",
            };
            vm.$router.push("/admin/firmware-versions")
            .catch((err) => {
                vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
                vm.snackbar.color = "red darken-2";
                vm.snackbar.snackbar = true;
            })
            vm.$emit("showSnackBar", emitData);
          } catch (error) {
            console.log(error);
              let emitData = {
                  snackbar: true,
                  btn: "",
                  y: "bottom",
                  x: null,
                  mode: "",
                  timeout: 2000,
                  text: "Error creating firmware version",
                  color: "red",
              };
              vm.$emit("showSnackBar", emitData);
          }
    }
  },
  computed:{
      flagActualTranslate() {
        let vm = this;
        if(vm.listLanguages.length > 0){
            let currentFlag = vm.listLanguages.find(flag => flag.code === vm.actualTranslateLang);
            return currentFlag.flag;
        }
      },
      ...mapGetters(["getLoadingStatusFirmwareVersionFile"])
  }
};
</script>