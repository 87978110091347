<template>
    <v-container fluid>
        <v-row justify="space-between" class="px-10">
            <v-col cols="12" sm="12" md="7" lg="6">
                <v-btn color="primary" icon size="20" class="text-center" @click="$router.go(-1)">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <p class="text-h6 text--secondary d-inline font-weight-bold">Custom Splash Screens</p>

                <v-btn style="margin-left: 32px;" text color="secondary" @click="createBackground()">
                    <v-icon left dark color="primary"> mdi-plus-circle </v-icon>
                    Create Splash
                </v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="5" lg="6">
                <v-text-field label="Search for a splash" v-model="search" single-line outlined rounded filled dense
                    append-icon="mdi-magnify"></v-text-field>
            </v-col>
            <v-col cols="12">
                <v-data-table :search="search" :loading="loading" :headers="headers" :items="getFeaturedSplashes"
                    item-key="id" fixed-header class="elevation-4" :page.sync="page" @update:page="onPageChange"
                    :items-per-page.sync="itemsPerPage" @update:items-per-page="onItemsPerPageUpdate" :sortBy.sync="sortBy"
                    @update:sortBy="onSortByChange">
                    <template v-slot:[`item.splash`]="{ item }">
                        <v-tooltip top color="secondary">
                            <template v-slot:activator="{ on, attrs }">
                                <img v-if="item.splash" v-bind="attrs" v-on="on" class="bg-app-image-image-preview"
                                    style="cursor:pointer;" @click="openDialogPreviewImage(item.splash)" :src="item.splash">
                            </template>
                            <span>Show Image</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.header`]="{ item }">
                        <v-tooltip top color="secondary">
                            <template v-slot:activator="{ on, attrs }">
                                <img v-if="item.header" v-bind="attrs" v-on="on" class="bg-app-image-image-preview"
                                    style="cursor:pointer;" @click="openDialogPreviewImage(item.header)" :src="item.header">
                            </template>
                            <span>Show Image</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:[`item.startDate`]="{ item }">
                        <div>{{ moment(item.startDate).format('MM/DD/YYYY') }}</div>
                    </template>
                    <template v-slot:[`item.endDate`]="{ item }">
                        <div>{{ moment(item.endDate).format('MM/DD/YYYY') }}</div>
                    </template>
                    <!-- <template v-slot:[`item.enabled`]="{ item }">
                        <v-switch color="primary" class="center-switch" :input-value="item.enabled" readonly
                            flat></v-switch>
                    </template> -->
                    <template v-slot:[`item.action`]="{ item }">
                        <v-btn icon color="primary" @click="changeRouteBackgroundAppImageEdit(item)">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon color="red" class="mr-3" @click="openDialogConfirm(item)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogPreviewImage.show" max-width="300">
            <v-card style="line-height: 0; !important;">
                <v-btn fab x-small color="red" style="position:absolute; top:5px; left: 5px;"
                    @click="closeDialogImagePreview()">
                    <v-icon size="15" color="white">mdi-close</v-icon>
                </v-btn>
                <img :src="dialogPreviewImage.img" style="width:100%; height:100%;">
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog.show" width="300" class="rounded-xl">
            <v-card class="text-center">
                <!-- <v-card-title class="headline">Use Google's location service?</v-card-title> -->
                <v-icon class="text-center ma-2" size="120" color="primary">mdi-alert</v-icon>
                <v-card-text class="pa-4 text-center">This action is irreversible and will delete all data about this
                    Custom Splash. Including the image and information</v-card-text>
                <v-card-text class="text-center">Are you sure?</v-card-text>

                <!-- <v-card-actions class="pa-0"> -->
                <!-- <v-spacer></v-spacer> -->

                <v-btn color="third" tile width="150" class="pa-0 text--secondary" @click="closeDialogConfirm()">No</v-btn>

                <v-btn color="primary" tile width="150" class="pa-0"
                    @click="openDialogReAuth('deleteAppBackground', dialog.item)">Yes</v-btn>
                <!-- </v-card-actions> -->
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
            snackbar.text
        }}</v-snackbar>
        <modal-reauth :dialogReAuth="dialogReAuth" @isSuccessReAuth="isSuccessReAuth"></modal-reauth>
    </v-container>
</template>

<script>

import { mapGetters } from "vuex";
import modalReauth from "@/components/admin/modal-reauth.vue";
import moment from "moment";

export default {
    name: "BackgroundAppImageList",
    components: { modalReauth },
    data: () => ({
        search: "",
        loading: false,
        dialogReAuth: {
            show: false,
            action: "",
            item: {},
        },
        dialog: {
            show: false,
            item: {},
        },
        snackbar: {
            snackbar: false,
            timeout: 2000,
            text: null,
            color: "",
        },
        headers: [
            { text: "Splash", align: "center", value: "splash", sortable: false },
            { text: "Header", align: "center", value: "header", sortable: false },
            { text: "Name", align: "center", value: "name", sortable: true },
            { text: "Start Date", align: "center", value: "startDate", sortable: true },
            { text: "End Date", align: "center", value: "endDate", sortable: false },
            { text: "Action", align: "center", value: "action", sortable: false },
        ],
        dialogPreviewImage: {
            show: false,
            img: '',
        },
        page: 0,
        itemsPerPage: 10,
        sortBy: "name.ENG"
    }),
    created() {
        const vm = this
        vm.getSplashes();
        if (this.$route.params.previousRoute === 'App Splash Edit') {
            const tableState = localStorage.getItem('splashTableState');
            if (tableState) {
                const tableStateObj = JSON.parse(tableState);
                this.search = tableStateObj.query;
                this.itemsPerPage = tableStateObj.hitsPerPage;
                this.page = tableStateObj.page;
            }
        }
    },
    methods: {
        moment: function (value) {
            return moment(value);
        },
        getSplashes() {
            const vm = this
            vm.loading = true
            vm.$store.dispatch('GetFeaturedSplashScreens')
                .then((res) => {
                    vm.loading = false;
                }).catch((err) => {
                    console.error(err)
                    vm.loading = false;
                });
        },
        createBackground() {
            const vm = this;
            vm.$router.push({ name: "Featured Splash Screen Create" }).catch((err) => {
                vm.snackbar.text =
                    "Sorry, you don't have permissions to enter this view.";
                vm.snackbar.color = "red darken-2";
                vm.snackbar.snackbar = true;
            });
        },
        openDialogConfirm(item) {
            let vm = this;
            vm.dialog.show = true;
            vm.dialog.item = item;
        },
        openDialogReAuth(action, item) {
            let vm = this;
            vm.dialogReAuth.show = true;
            vm.dialogReAuth.action = action;
            vm.dialogReAuth.item = item;
            vm.closeDialogConfirm();
        },
        deleteAppBackground(splash) {
            const vm = this
            vm.$emit("showOverlay", true);
            vm.$store
                .dispatch("DeleteSplash", splash)
                .then((res) => {
                    // vm.$store.dispatch("GenerateLog", {
                    //     id: splash.id,
                    //     title: splash.name,
                    //     type: "deletedBackgroundAppImage",
                    // });
                    vm.getSplashes();
                    vm.$emit("showOverlay", false);
                    // vm.loading = false;
                })
                .catch((err) => {
                    vm.$emit("showOverlay", false);
                    console.error(err);
                    let emitData = {
                        snackbar: true,
                        btn: "",
                        y: "bottom",
                        x: null,
                        mode: "",
                        timeout: 2000,
                        text: "Error deleting Splash",
                        color: "red",
                    };
                    vm.$emit("showSnackBar", emitData);
                });
        },
        closeDialogConfirm() {
            const vm = this;
            vm.dialog.show = false;
            vm.dialog.item = "";
        },
        isSuccessReAuth(dialogReAuthResponse) {
            const vm = this;
            switch (dialogReAuthResponse.action) {
                case "deleteAppBackground":
                    vm.deleteAppBackground(dialogReAuthResponse.item);
                    break;
                default:
                    break;
            }
        },
        changeRouteBackgroundAppImageEdit(appBackground) {
            const vm = this;
            vm.$router.push({ name: "App Splash Edit", params: appBackground })
                .catch((err) => {
                    vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
                    vm.snackbar.color = "red darken-2";
                    vm.snackbar.snackbar = true;
                })
        },
        openDialogPreviewImage(img) {
            const vm = this
            vm.dialogPreviewImage = {
                show: true,
                img: img ? img : ''
            }
        },
        closeDialogImagePreview() {
            const vm = this
            vm.dialogPreviewImage.show = false
        },
        onPageChange(page) {
            console.log(page);
        },
        onItemsPerPageUpdate(items) {
            console.log(items);
        },
        onSortByChange(sortBy, test) {
            console.log(sortBy, test);
        }
    },
    computed: {
        ...mapGetters(["getFeaturedSplashes"])
    },
    beforeRouteLeave(to, from, next) {
        console.log(to);
        if (to.name === 'App Splash Edit') {
            const currentState = JSON.stringify({ hitsPerPage: this.itemsPerPage, query: this.search, page: this.page });
            localStorage.setItem('splashTableState', currentState);
        } else {
            localStorage.removeItem('splashTableState');
        }
        next();
    },
    beforeRouteEnter(to, from, next) {
        if (from.name === 'App Splash Edit') {
            to.params.previousRoute = from.name;
        }
        next();
    }
}
</script>
<style scoped>
.bg-app-image-image-preview {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    vertical-align: middle;
}
</style>