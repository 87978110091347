<template>
  <v-container fluid>
    <v-row justify="space-between" class="px-10">
      <v-col cols="12" sm="12" md="4">
        <p class="text-h6 text--secondary font-weight-bold">
          Pre-Sets
        </p>
      </v-col>
      <v-col cols="12" sm="12" md="3">
        <v-btn text color="secondary" @click="changeRouteFilters()">
          <v-icon left dark color="primary"> mdi-cog </v-icon>
          Filters
        </v-btn>
        <v-btn text color="secondary" @click="createPreSet()">
          <v-icon left dark color="primary"> mdi-plus-circle </v-icon>
          New Pre-Set
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="5">
        <v-text-field label="Search for a Pre-Set" v-model="search" single-line outlined rounded filled dense
          append-icon="mdi-magnify"></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-data-table :search="search" :loading="loading" :headers="headers" :items="getPreSets" sort-by="name.ENG"
          item-key="id" fixed-header class="elevation-4" :page.sync="page" @update:page="onPageChange"
          :items-per-page.sync="itemsPerPage" @update:items-per-page="onItemsPerPageUpdate" :sortBy.sync="sortBy"
          @update:sortBy="onSortByChange">
          <template v-slot:[`item.image`]="{ item }">
            <v-tooltip top color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-avatar class="my-2">
                  <img v-if="item.image.link" v-bind="attrs" v-on="on" style="cursor:pointer;"
                    @click="openDialogPreviewImage(item.image.link)" :src="item.image.link" />
                </v-avatar>
              </template>
              <span>Show Image</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.name`]="{ item }">
            {{ item.name["ENG"] }}
          </template>
          <template v-slot:[`item.typeBy`]="{ item }">
            {{ item.typeBy === 'circuit' ? 'By Circuit' : 'By Station' }}
          </template>
          <template v-slot:[`item.enabled`]="{ item }">
            <v-switch color="primary" class="center-switch" :input-value="item.enabled" flat
              @click="disablePreSet(item)"></v-switch>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn icon color="secondary" :disabled="duplicateLoading" :loading="duplicateLoading"
              @click="duplicatePreSet(item)">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
            <v-btn icon color="primary" @click="changeRoutePreSet(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon color="red" class="mr-3" @click="openDialogConfirm(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog.show" width="300" class="rounded-xl">
      <v-card class="text-center">
        <v-icon class="text-center ma-2" size="120" color="primary">mdi-alert</v-icon>
        <v-card-text class="pa-4 text-center">This action is irreversible and will delete all data about the Pre-Set.
          Including the image, circuits, and information</v-card-text>
        <v-card-text class="text-center">Are you sure?</v-card-text>

        <v-btn color="third" tile width="150" class="pa-0 text--secondary" @click="closeDialogConfirm()">No</v-btn>

        <v-btn color="primary" tile width="150" class="pa-0"
          @click="openDialogReAuth('deletePreSet', dialog.item)">Yes</v-btn>
        <!-- </v-card-actions> -->
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogPreviewImage.show" max-width="800">
      <v-card style="line-height: 0; !important;">
        <v-btn fab x-small color="red" style="position:absolute; top:5px; left: 5px;" @click="closeDialogImagePreview()">
          <v-icon size="15" color="white">mdi-close</v-icon>
        </v-btn>
        <img :src="dialogPreviewImage.img" style="width:100%; height:100%;">
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar.snackbar" :color="snackbar.color" :timeout="snackbar.timeout">{{
      snackbar.text
    }}</v-snackbar>
    <modal-reauth :dialogReAuth="dialogReAuth" @isSuccessReAuth="isSuccessReAuth"></modal-reauth>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import modalReauth from '@/components/admin/modal-reauth.vue';
export default {
  name: "PreSetsList",
  components: { modalReauth },
  data: () => ({
    dialogReAuth: {
      show: false,
      action: '',
      item: {}
    },
    dialog: {
      show: false,
      item: {},
    },
    dialogPreviewImage: {
      show: false,
      img: ''
    },
    snackbar: {
      snackbar: false,
      timeout: 2000,
      text: null,
      color: "",
    },
    headers: [
      { text: "", align: "center", value: "image", sortable: false },
      { text: "Img size", align: "center", value: "image.size", sortable: false },
      { text: "Order Number", align: "center", value: "orderNumber", sortable: true },
      { text: "Name", align: "center", value: "name.ENG", sortable: true },
      { text: "Type", align: "center", value: "typeBy", sortable: true },
      { text: "Enabled", align: "center", value: "enabled", sortable: true },
      { text: "Action", align: "center", value: "action", sortable: false },
    ],
    search: "",
    loading: false,
    duplicateLoading: false,
    page: 0,
    itemsPerPage: 10,
    sortBy: "name.ENG"
  }),
  created() {
    let vm = this;
    vm.getAllPreSets();
    if (this.$route.params.previousRoute === 'Pre Sets Edit') {
      const tableState = localStorage.getItem('preSetsTableState');
      if (tableState) {
        const tableStateObj = JSON.parse(tableState);
        this.search = tableStateObj.query;
        this.itemsPerPage = tableStateObj.hitsPerPage;
        this.page = tableStateObj.page;
      }
    }
  },
  methods: {
    async getAllPreSets() {
      const vm = this
      try {
        vm.loading = true
        await vm.$store.dispatch("GetPreSets")
        vm.loading = false
      } catch (error) {
        vm.loading = false
        console.log(error);
      }
    },
    changeRoutePreSet(preSet) {
      let vm = this;
      vm.$router.push({ name: "Pre Sets Edit", params: preSet })
        .catch((err) => {
          vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
          vm.snackbar.color = "red darken-2";
          vm.snackbar.snackbar = true;
        })
    },
    openDialogConfirm(item) {
      let vm = this;
      vm.dialog.show = true;
      vm.dialog.item = item;
    },
    closeDialogConfirm() {
      let vm = this;
      vm.dialog.show = false;
      vm.dialog.item = "";
    },
    isSuccessReAuth(dialogReAuthResponse) {
      const vm = this
      switch (dialogReAuthResponse.action) {
        case 'deletePreSet':
          vm.deletePreSet(dialogReAuthResponse.item)
          break;
        default:
          break;
      }
    },
    openDialogReAuth(action, item) {
      let vm = this;
      vm.dialogReAuth.show = true
      vm.dialogReAuth.action = action
      vm.dialogReAuth.item = item
      vm.closeDialogConfirm();
    },
    openDialogPreviewImage(img) {
      const vm = this
      vm.dialogPreviewImage = {
        show: true,
        img: img ? img : ''
      }
    },
    closeDialogImagePreview() {
      const vm = this
      vm.dialogPreviewImage.show = false
    },
    async duplicatePreSet(preSet) {
      const vm = this
      try {
        vm.duplicateLoading = true
        const newPreSet = await vm.$store.dispatch('CopyPreSet', preSet.id)
        await vm.$store.dispatch("GenerateLog", {
          id: newPreSet.id,
          name: newPreSet.name['ENG'],
          type: "createdPreSet",
        })
        vm.duplicateLoading = false
        vm.getAllPreSets()

      } catch (error) {
        vm.duplicateLoading = false
        let emitData = {
          snackbar: true,
          btn: "",
          y: "bottom",
          x: null,
          mode: "",
          timeout: 2000,
          text: "An error occurred duplicating the PreSet",
          color: "red",
        };
        vm.$emit("showSnackBar", emitData);
        console.log(error)
      }

    },
    async disablePreSet(preSet) {
      const vm = this
      const { enabled, id, name } = preSet
      await vm.$store.dispatch('DisablePreSet', { id, enabled })
      await vm.$store.dispatch("GenerateLog", {
        id: id,
        title: name['ENG'],
        type: "editedPreSet",
      })

      let emitData = {
        snackbar: true,
        btn: "",
        y: "bottom",
        x: null,
        mode: "",
        timeout: 2000,
        text: "Pre-Set edited successfully",
        color: "success",
      };
      vm.$emit("showSnackBar", emitData);
      vm.getAllPreSets()
    },
    async deletePreSet(preSet) {
      const vm = this;
      try {
        vm.$emit("showOverlay", true);
        await vm.$store.dispatch("DeletePreSet", preSet)
        await vm.$store.dispatch("GenerateLog", {
          id: preSet.id,
          name: preSet.name['ENG'],
          type: "deletedPreSet",
        });
        vm.getAllPreSets()
        vm.$emit("showOverlay", false);

      } catch (error) {
        vm.$emit("showOverlay", false);
        console.error(error);
        let emitData = {
          snackbar: true,
          btn: "",
          y: "bottom",
          x: null,
          mode: "",
          timeout: 2000,
          text: "Error deleting PreSet",
          color: "red",
        };
        vm.$emit("showSnackBar", emitData);
      }

    },
    changeRouteFilters() {
      let vm = this;
      vm.$router.push({ name: "Pre Sets Filters" })
        .catch((err) => {
          vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
          vm.snackbar.color = "red darken-2";
          vm.snackbar.snackbar = true;
        })
    },
    createPreSet() {
      let vm = this;
      vm.$router.push({ name: "Pre Sets Create" })
        .catch((err) => {
          vm.snackbar.text = "Sorry, you don't have permissions to enter this view.";
          vm.snackbar.color = "red darken-2";
          vm.snackbar.snackbar = true;
        })
    },
    onPageChange(page) {
      console.log(page);
    },
    onItemsPerPageUpdate(items) {
      console.log(items);
    },
    onSortByChange(sortBy, test) {
      console.log(sortBy, test);
    }
  },
  computed: {
    ...mapGetters(["getPreSets"]),
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'Pre Sets Edit') {
      const currentState = JSON.stringify({ hitsPerPage: this.itemsPerPage, query: this.search, page: this.page });
      localStorage.setItem('preSetsTableState', currentState);
    } else {
      localStorage.removeItem('preSetsTableState');
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === 'Pre Sets Edit') {
      to.params.previousRoute = from.name;
    }
    next();
  }
};
</script>
<style scoped>
.v-data-table /deep/ .sticky-header {
  position: sticky;
  top: var(--toolbarHeight);
}

.v-data-table /deep/ .v-data-table__wrapper {
  overflow: unset;
}
</style>